var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import Icon from "@shared/atoms/Icon";
import { palette, key } from "@shared/utils/styled";
import ArticleDescription from "@shared/molecules/ArticleDescription";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  grid-column: 1 / 3;\n  margin-right: 1.0625rem;\n  max-height: \"37.5rem\";\n  overflow: hidden;\n  position: relative;\n  transition: max-height 0.5s linear;\n  @media screen and (min-width: ", ") {\n    margin-left: 7.5rem;\n  }\n"], ["\n  grid-column: 1 / 3;\n  margin-right: 1.0625rem;\n  max-height: \"37.5rem\";\n  overflow: hidden;\n  position: relative;\n  transition: max-height 0.5s linear;\n  @media screen and (min-width: ", ") {\n    margin-left: 7.5rem;\n  }\n"])), key("breakpoints.large", "1720px"));
var DescriptionToggle = styled.a(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  cursor: ", ";\n  display: block;\n  margin-top: 1.875rem;\n  opacity: ", ";\n  transition: opacity 0.2s ease-in;\n"], ["\n  color: ", ";\n  cursor: ", ";\n  display: block;\n  margin-top: 1.875rem;\n  opacity: ", ";\n  transition: opacity 0.2s ease-in;\n"])), palette("linkText"), function (props) { return (props.visible ? "default" : "pointer"); }, function (props) { return (props.visible ? 0 : 1); });
var ArticleMoreInformation = /** @class */ (function (_super) {
    __extends(ArticleMoreInformation, _super);
    function ArticleMoreInformation() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            showDescription: false,
        };
        _this.toggleDescription = function () {
            _this.setState({
                showDescription: !_this.state.showDescription,
            });
        };
        return _this;
    }
    ArticleMoreInformation.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.params && this.props.params.id !== nextProps.params.id) {
            if (this.state.showDescription) {
                this.toggleDescription();
            }
        }
    };
    ArticleMoreInformation.prototype.render = function () {
        var _this = this;
        var _a = this.props, article = _a.article, appName = _a.appName;
        var showDescription = this.state.showDescription;
        return (_jsx(Wrapper, __assign({ "data-cy-id": "article-more-info" }, { children: (article.description || article.content) && (_jsxs(Fragment, { children: [_jsxs(DescriptionToggle, __assign({ onClick: function () { return _this.toggleDescription(); }, visible: showDescription }, { children: [_jsx(FormattedMessage, { id: "article.description.toggle", defaultMessage: "Mehr Informationen" }, void 0), _jsx(Icon, { icon: "drilldown", width: 10 }, void 0)] }), void 0), _jsx(ArticleDescription, { appName: appName, articleNumber: article.number, fabart: article.fabart, content: article.content, attributes: article.attributes, description: article.description, toggleDescription: function () { return _this.toggleDescription(); }, visible: showDescription }, void 0)] }, void 0)) }), void 0));
    };
    return ArticleMoreInformation;
}(Component));
/** @component */
export default ArticleMoreInformation;
var templateObject_1, templateObject_2;
