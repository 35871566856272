var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
// utils
import { fontWeight, palette } from '@shared/utils/styled';
import store from 'store';
import { defineMessages, FormattedMessage } from 'react-intl';
// components
import SizeSelectItem from 'molecules/SizeSelectItem';
var Name = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-weight: ", ";\n"])), palette('text'), fontWeight('bold'));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-top: 1rem;\n  padding-right: 1.0635rem;\n"], ["\n  margin-top: 1rem;\n  padding-right: 1.0635rem;\n"])));
var Grid = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 0.5rem;\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n  margin-top: 0.5rem;\n"])));
var NoSizesInfo = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 0.625rem 0;\n"], ["\n  padding: 0.625rem 0;\n"])));
var SizeSelect = /** @class */ (function (_super) {
    __extends(SizeSelect, _super);
    function SizeSelect(props) {
        var _this = _super.call(this, props) || this;
        // sizeIconDict = {
        //   small: 'tooSmall',
        //   equal: 'matching',
        //   big: 'tooBig'
        // }
        _this.sizeIconDict = {
            small: 'public/Klein.png',
            equal: 'public/Passend.png',
            big: 'public/Gross.png',
        };
        _this.checkSizeUsage = function (sizeId) {
            var _a = _this.props, watchlist = _a.watchlist, currentArticleId = _a.currentArticleId;
            var entry = watchlist
                ? watchlist.find(function (item) {
                    return item.articleId === currentArticleId && item.sizeId === sizeId;
                })
                : null;
            return Boolean(entry);
        };
        return _this;
    }
    SizeSelect.prototype.render = function () {
        var _this = this;
        var _a = this.props, clickHandler = _a.clickHandler, sizes = _a.sizes, current = _a.current, customHeading = _a.customHeading;
        var defautlHeadline = defineMessages({
            defautlHeadline: {
                id: 'partials.sizeSelect.headline',
                defaultMessage: 'Größe wählen',
            },
        }).defautlHeadline;
        var headline = customHeading || defautlHeadline;
        /* NEED-332 */
        var shouldShowSizeMatch = !!store.get('footId');
        return (_jsxs(Wrapper, { children: [_jsx(Name, { children: _jsx(FormattedMessage, __assign({}, headline), void 0) }, void 0), Boolean(sizes) && sizes.length > 0 ? (_jsx(Grid, { children: sizes.map(function (size) {
                        var disabled = _this.checkSizeUsage(size.sizeId);
                        var icon = shouldShowSizeMatch
                            ? _this.sizeIconDict[size.sizeMatch]
                            : null;
                        return (_jsx(SizeSelectItem, { active: Boolean(current) && current === size.sizeId, icon: icon, clickHandler: function () { return clickHandler(size); }, disabled: disabled, size: size }, size.sizeId));
                    }) }, void 0)) : (_jsx(NoSizesInfo, { children: _jsx(FormattedMessage, { id: 'partials.sizeSelect.noSizes', defaultMessage: 'Keine Gr\u00F6\u00DFen verf\u00FCgbar!' }, void 0) }, void 0))] }, void 0));
    };
    return SizeSelect;
}(Component));
export default SizeSelect;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
