var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import styled from 'styled-components';
import { key } from 'styled-theme';
import SearchMenu from 'organisms/SearchMenu';
import CategoryGridItem from 'molecules/CategoryGridItem';
import InfiniteScroll from '@shared/organisms/InfiniteScroll';
import PrimaryCategorySelect from 'molecules/PrimaryCategorySelect';
var Grid = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    display: grid;\n    flex: 1;\n    grid-gap: 0.25rem;\n    grid-template-areas: 'header header header header header' 'footer footer footer footer footer';\n    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n    grid-auto-rows: max-content;\n    align-content: end;\n    overflow-y: auto;\n    padding: 0 1.875rem 1.875rem;\n    \n    @media screen and (max-width: ", ") {\n      grid-template-areas: 'header header header header' 'footer footer footer footer';\n      grid-template-columns: 1fr 1fr 1fr 1fr;\n    }\n    \n    @media screen and (max-width: ", ") {\n      grid-template-areas: 'header header header' 'footer footer footer';\n      grid-template-columns: 1fr 1fr 1fr;\n    }\n  "], ["\n    display: grid;\n    flex: 1;\n    grid-gap: 0.25rem;\n    grid-template-areas: 'header header header header header' 'footer footer footer footer footer';\n    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n    grid-auto-rows: max-content;\n    align-content: end;\n    overflow-y: auto;\n    padding: 0 1.875rem 1.875rem;\n    \n    @media screen and (max-width: ", ") {\n      grid-template-areas: 'header header header header' 'footer footer footer footer';\n      grid-template-columns: 1fr 1fr 1fr 1fr;\n    }\n    \n    @media screen and (max-width: ", ") {\n      grid-template-areas: 'header header header' 'footer footer footer';\n      grid-template-columns: 1fr 1fr 1fr;\n    }\n  "])), key('breakpoints.medium', '1720px'), key('breakpoints.medium', '1280px'));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  margin-top: 0.0625rem;\n"], ["\n  display: flex;\n  flex: 1;\n  margin-top: 0.0625rem;\n"])));
var CategoryGrid = function (_a) {
    var basePath = _a.basePath, categories = _a.categories, checkIfTabIsActive = _a.checkIfTabIsActive, primaryCategoryId = _a.primaryCategoryId, primaryCategories = _a.primaryCategories, primaryCategoriesLoading = _a.primaryCategoriesLoading, categoriesLoading = _a.categoriesLoading, tabs = _a.tabs, pageCount = _a.pageCount, getCategories = _a.getCategories;
    var _b = useState(1), currentPage = _b[0], setCurrentPage = _b[1];
    var fetchMore = function () {
        getCategories({
            page: currentPage + 1,
            primaryCategory: primaryCategoryId,
        });
        setCurrentPage(currentPage + 1);
    };
    var renderItems = function () {
        var result = [];
        if (!primaryCategoryId &&
            (!categoriesLoading || (categories && categories.length > 0)) &&
            !primaryCategoriesLoading) {
            result.push(_jsx(PrimaryCategorySelect, { primaryCategories: primaryCategories }, 'priCatSelect'));
        }
        result.push.apply(result, categories.map(function (category) { return (_jsx(CategoryGridItem, { category: category, basePath: basePath, primaryCategoryId: primaryCategoryId }, category.id)); }));
        return result;
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ style: { width: "100%", overflowY: "auto" } }, { children: [_jsx(SearchMenu, { checkIfTabIsActive: checkIfTabIsActive, primaryCategoryId: primaryCategoryId, tabs: tabs }, 'searchbar'), _jsx(Wrapper, { children: _jsx(InfiniteScroll, { currentPage: currentPage, customThreshold: 1, hasMore: currentPage < pageCount, items: renderItems(), loading: categoriesLoading, loadMore: fetchMore, wrapper: Grid }, void 0) }, void 0)] }), void 0) }, void 0));
};
export default CategoryGrid;
var templateObject_1, templateObject_2;
