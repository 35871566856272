var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import { palette, fontSize } from '@shared/utils/styled';
import styled, { withTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
// import theme from "shared/themes/default";
import TextDisplayModal from 'organisms/TextDisplayModal';
// components
import CustomerDataForm from 'molecules/CustomerDataForm';
import HighlightButton from '@shared/atoms/HighlightButton';
import H2 from '@shared/atoms/H2';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  overflow: auto;\n  padding: 1.25rem 5rem 1.875rem 5rem;\n"], ["\n  background-color: ", ";\n  overflow: auto;\n  padding: 1.25rem 5rem 1.875rem 5rem;\n"])), palette('white'));
var ButtonsRow = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 0 0 auto;\n  padding: 0;\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n"], ["\n  flex: 0 0 auto;\n  padding: 0;\n  display: flex;\n  flex-flow: row nowrap;\n  justify-content: space-between;\n"])));
var DialogButton = styled(HighlightButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 0 0 auto;\n  border: 0.0625rem solid ", ";\n  padding: 0 1rem;\n  height: 2rem;\n  font-size: 1rem;\n"], ["\n  flex: 0 0 auto;\n  border: 0.0625rem solid ", ";\n  padding: 0 1rem;\n  height: 2rem;\n  font-size: 1rem;\n"])), palette('secondaryBackground'));
var Headline = styled(H2)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 3.125rem;\n  margin-top: 1.875rem;\n"], ["\n  margin-bottom: 3.125rem;\n  margin-top: 1.875rem;\n"])));
var Description = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  line-height: 18pt;\n  margin-bottom: 1.875rem;\n  flex: 0 0 auto;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  line-height: 18pt;\n  margin-bottom: 1.875rem;\n  flex: 0 0 auto;\n"])), palette('text'), fontSize('large'));
var Body = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  flex: 1 0;\n"], ["\n  flex: 1 0;\n"])));
var CustomerData = /** @class */ (function (_super) {
    __extends(CustomerData, _super);
    function CustomerData() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            show: null,
        };
        return _this;
    }
    CustomerData.prototype.showModal = function (id) {
        this.state.show = id;
        this.forceUpdate();
    };
    CustomerData.prototype.hideModal = function () {
        this.state.show = null;
        this.forceUpdate();
    };
    CustomerData.prototype.render = function () {
        var _this = this;
        var _a = this.props, updateCustomerFormState = _a.updateCustomerFormState, createCustomer = _a.createCustomer, finishCheckout = _a.finishCheckout, formId = _a.formId, theme = _a.theme;
        return (_jsxs(Wrapper, { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'checkout.buy.customerData.form.headline', defaultMessage: 'Lieferadresse' }, void 0) }, void 0), _jsx(Description, { children: _jsx(FormattedMessage, { id: 'checkout.buy.customerData.form.description', defaultMessage: 'Bitte geben Sie die Lieferadresse ein.' }, void 0) }, void 0), _jsx(Body, { children: _jsx(CustomerDataForm, { createCustomer: createCustomer, finishCheckout: finishCheckout, formId: formId, updateCustomerFormState: updateCustomerFormState }, void 0) }, void 0), _jsxs(ButtonsRow, { children: [theme.conditions && (_jsx(DialogButton, __assign({ onClick: function () { return _this.showModal('conditions'); } }, { children: _jsx("div", { children: "AGB" }, void 0) }), void 0)), theme.privacyPolicy && (_jsx(DialogButton, __assign({ onClick: function () { return _this.showModal('privacyPolicy'); } }, { children: _jsx("div", { children: "Datenschutzerkl\u00E4rung" }, void 0) }), void 0)), theme.imprint && (_jsx(DialogButton, __assign({ onClick: function () { return _this.showModal('imprint'); } }, { children: _jsx("div", { children: "Impressum" }, void 0) }), void 0))] }, void 0), this.state.show && (_jsx(TextDisplayModal, { name: this.state.show, text: theme[this.state.show], close: function () { return _this.hideModal(); } }, void 0))] }, void 0));
    };
    return CustomerData;
}(Component));
export default withTheme(CustomerData);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
