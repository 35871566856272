// @flow
// @ts-nocheck
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    defaultGender: null,
    gender: null,
});
var reducer = handleActions({
    RESET_GENDER: function (state) { return state.set('gender', null); },
    SET_DEFAULT_GENDER: function (state, action) {
        return state.set('defaultGender', action.payload).set('gender', action.payload);
    },
    SET_GENDER: function (state, action) {
        return state.set('gender', action.payload);
    },
}, initialState);
export default reducer;
