var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import { palette, fontSize } from '@shared/utils/styled';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
// components
import VerticalFlex from '@shared/atoms/VerticalFlex';
import Icon from '@shared/atoms/Icon';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  flex: 1;\n  justify-content: center;\n  overflow: hidden;\n  padding-bottom: 2rem;\n"], ["\n  align-items: center;\n  flex: 1;\n  justify-content: center;\n  overflow: hidden;\n  padding-bottom: 2rem;\n"])));
var Bubble = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.1);\n  border-radius: 50%;\n  display: grid;\n  grid-gap: 1.25rem;\n  grid-template-rows: 1fr 1fr;\n  height: 30rem;\n  justify-content: center;\n  min-width: 30rem;\n  width: 30rem;\n"], ["\n  align-items: center;\n  background-color: rgba(0, 0, 0, 0.1);\n  border-radius: 50%;\n  display: grid;\n  grid-gap: 1.25rem;\n  grid-template-rows: 1fr 1fr;\n  height: 30rem;\n  justify-content: center;\n  min-width: 30rem;\n  width: 30rem;\n"])));
var Description = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: white;\n  font-size: ", ";\n  letter-spacing: 0.05859375rem;\n  line-height: 18pt;\n  max-width: 15.625rem;\n  text-align: center;\n"], ["\n  color: white;\n  font-size: ", ";\n  letter-spacing: 0.05859375rem;\n  line-height: 18pt;\n  max-width: 15.625rem;\n  text-align: center;\n"])), fontSize('large'));
var IconWrapper = styled(VerticalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  align-items: center;\n  justify-content: flex-end;\n"])));
var StyledIcon = styled(Icon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  > svg {\n    > path {\n      opacity: 0.2;\n    }\n  }\n"], ["\n  color: ", ";\n  > svg {\n    > path {\n      opacity: 0.2;\n    }\n  }\n"])), palette('secondaryBackground'));
var InfoBubble = /** @class */ (function (_super) {
    __extends(InfoBubble, _super);
    function InfoBubble() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InfoBubble.prototype.render = function () {
        var _a = this.props, description = _a.description, icon = _a.icon;
        var iconName = icon || 'buy';
        return (_jsx(Wrapper, { children: _jsxs(Bubble, { children: [_jsx(IconWrapper, { children: _jsx(StyledIcon, { icon: iconName, width: 165 }, void 0) }, void 0), _jsx(VerticalFlex, { children: _jsx(Description, { children: _jsx(FormattedMessage, __assign({}, description), void 0) }, void 0) }, void 0)] }, void 0) }, void 0));
    };
    return InfoBubble;
}(Component));
export default InfoBubble;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
