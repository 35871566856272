var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
import styled from 'styled-components';
import BrandModal from 'molecules/BrandModal';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import H2 from '@shared/atoms/H2';
import Icon from '@shared/atoms/Icon';
import { FormattedMessage, defineMessages } from 'react-intl';
var ButtonRow = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 2.5rem;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 2.5rem;\n"])));
var CancelButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  font-size: ", ";\n  height: 2.875rem;\n  min-width: 7.5rem;\n  padding: 0 1.25rem;\n"], ["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  font-size: ", ";\n  height: 2.875rem;\n  min-width: 7.5rem;\n  padding: 0 1.25rem;\n"])), palette('secondaryBackground'), palette('white'), fontSize('large'));
var FinishButton = styled(CancelButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('primaryHighlight'), palette('activePrimaryHighlight'));
var Headline = styled(H2)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 1.25rem;\n  margin-top: 0;\n"], ["\n  margin-bottom: 1.25rem;\n  margin-top: 0;\n"])));
var ShareOptions = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 26.875rem;\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 26.875rem;\n"])));
var ImageSection = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 1.875rem;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 1.875rem;\n"])));
var ImageWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  align-items: center;\n  border-radius: 50%;\n  display: flex;\n  height: 8.75rem;\n  justify-content: center;\n  width: 8.75rem;\n"], ["\n  align-items: center;\n  border-radius: 50%;\n  display: flex;\n  height: 8.75rem;\n  justify-content: center;\n  width: 8.75rem;\n"])));
var OrderNumber = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 1.625rem;\n  font-weight: ", ";\n  margin-top: 1.25rem;\n"], ["\n  color: ", ";\n  font-size: 1.625rem;\n  font-weight: ", ";\n  margin-top: 1.25rem;\n"])), palette('highlightText'), fontWeight('bold'));
var OrderError = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: red;\n  font-size: 1.625rem;\n  font-weight: ", ";\n  margin-top: 1.25rem;\n"], ["\n  color: red;\n  font-size: 1.625rem;\n  font-weight: ", ";\n  margin-top: 1.25rem;\n"])), fontWeight('bold'));
var AssistantIcon = styled(Icon)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), palette('border'));
var SpinnerWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  float: left;\n  margin-left: 3.5rem;\n"], ["\n  float: left;\n  margin-left: 3.5rem;\n"])));
var StyledInput = styled.input(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  border: 1px solid #e0e0e0;\n  background-color: #efefef;\n  flex: 1;\n  font-size: ", ";\n  height: 2.8125rem;\n  padding: 0 0.625rem;\n"], ["\n  border: 1px solid #e0e0e0;\n  background-color: #efefef;\n  flex: 1;\n  font-size: ", ";\n  height: 2.8125rem;\n  padding: 0 0.625rem;\n"])), fontSize('large'));
var Digit = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: 4rem;\n  height: 4rem;\n  line-height: 4rem;\n  background-color: #e8e8e8;\n  border: 1px solid #d0d0d0;\n  border-radius: 3px;\n  font-size: 3rem;\n  flex: 0 0 auto;\n  margin: 0.3rem;\n  text-align: center;\n  padding: 0;\n  cursor: pointer;\n  &:hover {\n    background-color: #e0e0e0;\n  }\n  &:active {\n    background-color: #e8ffe8;\n  }\n"], ["\n  width: 4rem;\n  height: 4rem;\n  line-height: 4rem;\n  background-color: #e8e8e8;\n  border: 1px solid #d0d0d0;\n  border-radius: 3px;\n  font-size: 3rem;\n  flex: 0 0 auto;\n  margin: 0.3rem;\n  text-align: center;\n  padding: 0;\n  cursor: pointer;\n  &:hover {\n    background-color: #e0e0e0;\n  }\n  &:active {\n    background-color: #e8ffe8;\n  }\n"])));
var SvgDigit = styled(Digit)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var Numpad = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: flex-end;\n  margin: 0;\n"], ["\n  display: flex;\n  flex-flow: column nowrap;\n  align-items: flex-end;\n  margin: 0;\n"])));
var Numrow = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: flex-end;\n  margin: 0;\n"], ["\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: flex-end;\n  margin: 0;\n"])));
var OrderFinishModal = /** @class */ (function (_super) {
    __extends(OrderFinishModal, _super);
    function OrderFinishModal() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            value: '',
            orderNumber: '',
            orderError: '',
        };
        _this.definedMessages = {
            DONE: defineMessages({
                title: {
                    id: 'modal.checkout.buy.finish',
                    defaultMessage: 'Abschließen und Terminal freigeben',
                },
            }),
            COMPLETE: defineMessages({
                title: {
                    id: 'modal.checkout.order.finish',
                    defaultMessage: 'Bestellung absenden',
                },
            }),
            0: defineMessages({
                title: {
                    id: 'modal.checkout.order.error',
                    defaultMessage: 'Fehler: Ein unbekannter Fehler ist aufgetreten.',
                },
            }),
            1: defineMessages({
                title: {
                    id: 'modal.checkout.order.error.1',
                    defaultMessage: 'Fehler: Ein unbekannter Fehler ist aufgetreten.',
                },
            }),
        };
        return _this;
    }
    OrderFinishModal.prototype.componentDidMount = function () {
        this.clear();
        // this.props.navigation.addListener('willFocus', this.clear);
    };
    OrderFinishModal.prototype.clear = function () {
        this.setState({
            value: '',
            orderNumber: '',
            orderError: '',
        });
    };
    OrderFinishModal.prototype.renderNumPad = function () {
        var _this = this;
        return (_jsxs(Numpad, { children: [_jsxs(Numrow, { children: [_jsx(Digit, __assign({ onClick: function () { return _this.enter(7); } }, { children: "7" }), void 0), _jsx(Digit, __assign({ onClick: function () { return _this.enter(8); } }, { children: "8" }), void 0), _jsx(Digit, __assign({ onClick: function () { return _this.enter(9); } }, { children: "9" }), void 0)] }, void 0), _jsxs(Numrow, { children: [_jsx(Digit, __assign({ onClick: function () { return _this.enter(4); } }, { children: "4" }), void 0), _jsx(Digit, __assign({ onClick: function () { return _this.enter(5); } }, { children: "5" }), void 0), _jsx(Digit, __assign({ onClick: function () { return _this.enter(6); } }, { children: "6" }), void 0)] }, void 0), _jsxs(Numrow, { children: [_jsx(Digit, __assign({ onClick: function () { return _this.enter(1); } }, { children: "1" }), void 0), _jsx(Digit, __assign({ onClick: function () { return _this.enter(2); } }, { children: "2" }), void 0), _jsx(Digit, __assign({ onClick: function () { return _this.enter(3); } }, { children: "3" }), void 0)] }, void 0), _jsxs(Numrow, { children: [_jsx(Digit, __assign({ onClick: function () { return _this.enter(0); } }, { children: "0" }), void 0), _jsx(SvgDigit, __assign({ onClick: function () { return _this.enter(10); } }, { children: this.bsp() }), void 0)] }, void 0)] }, void 0));
    };
    OrderFinishModal.prototype.renderModalActions = function () {
        var _this = this;
        var _a = this.props, finish = _a.finish, reset = _a.reset, orderNumber = _a.orderNumber;
        var buttonAction = function () {
            if (orderNumber) {
                reset();
            }
            else {
                finish({ pin: _this.state.value });
            }
            _this.clear();
        };
        var message = this.definedMessages[orderNumber ? 'DONE' : 'COMPLETE'];
        return (_jsx(ButtonRow, { children: _jsx(FinishButton, __assign({ onClick: function () { return buttonAction(); } }, { children: _jsx(FormattedMessage, __assign({}, message.title), void 0) }), void 0) }, void 0));
    };
    OrderFinishModal.prototype.renderOrderNumber = function (num) {
        return (_jsx(OrderNumber, { children: _jsx("div", { children: num }, void 0) }, void 0));
    };
    OrderFinishModal.prototype.renderOrderError = function (err) {
        var messages = this.definedMessages[err] || this.definedMessages[0];
        return (_jsx(OrderError, { children: _jsx(FormattedMessage, __assign({}, messages.title), void 0) }, void 0));
    };
    OrderFinishModal.prototype.bsp = function () {
        return (_jsxs("svg", __assign({ version: '1.1', xmlns: 'http://www.w3.org/2000/svg', xmlnsXlink: 'http://www.w3.org/1999/xlink', x: '0px', y: '0px', viewBox: '0 0 44.18 44.18', width: '80%', height: '80%', xmlSpace: 'preserve' }, { children: [_jsx("path", { d: 'M10.625,5.09L0,22.09l10.625,17H44.18v-34H10.625z M42.18,37.09H11.734l-9.375-15l9.375-15H42.18V37.09z' }, void 0), _jsx("polygon", { points: '18.887,30.797 26.18,23.504 33.473,30.797 34.887,29.383 27.594,22.09 34.887,14.797 33.473,13.383 26.18,20.676\n      18.887,13.383 17.473,14.797 24.766,22.09 17.473,29.383 \t' }, void 0)] }), void 0));
    };
    OrderFinishModal.prototype.enter = function (d) {
        if (d < 10) {
            this.setState({ value: this.state.value + d });
            return;
        }
        if (d == 10) {
            this.setState({ value: this.state.value.slice(0, -1) });
            return;
        }
    };
    OrderFinishModal.prototype.handleChange = function (e) {
        this.setState({ value: e.target.value });
    };
    OrderFinishModal.prototype.renderSuccessContent = function () {
        return (_jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'modal.order.orderNumber', defaultMessage: 'Ihre Bestellnummer:' }, void 0) }, void 0), this.renderOrderNumber(this.props.orderNumber)] }, void 0));
    };
    OrderFinishModal.prototype.renderDefaultContent = function () {
        return (_jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'modal.order.description', defaultMessage: 'Bitte geben Sie die PIN ein:' }, void 0) }, void 0), _jsx(StyledInput, { readOnly: true, autoFocus: true, type: 'password', value: this.state.value }, void 0), this.props.orderError && this.renderOrderError(this.props.orderError)] }, void 0));
    };
    OrderFinishModal.prototype.render = function () {
        var _a = this.props, orderNumber = _a.orderNumber, orderError = _a.orderError, open = _a.open, toggleAction = _a.toggleAction, init = _a.init;
        return (_jsxs(BrandModal, __assign({ hideCloseIcon: true, label: 'Merkliste mitnehmen', open: open, shouldCloseOnOverlayClick: false, toggleAction: toggleAction }, this.props, { children: [_jsxs(HorizontalFlex, { children: [orderNumber && this.renderSuccessContent(), !orderNumber && this.renderDefaultContent(), !orderNumber && this.renderNumPad()] }, void 0), this.renderModalActions()] }), void 0));
    };
    return OrderFinishModal;
}(Component));
export default OrderFinishModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
