var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
import { rem } from 'polished';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Icon from '@shared/atoms/Icon';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: ", ";\n  height: ", ";\n  min-width: ", ";\n  transition: all 0.2s ease-out;\n  width: ", ";\n"], ["\n  background-color: ", ";\n  border-radius: ", ";\n  height: ", ";\n  min-width: ", ";\n  transition: all 0.2s ease-out;\n  width: ", ";\n"])), palette('secondaryBackground'), function (props) { return (props.extended ? '0' : '50%'); }, function (props) { return (props.extended ? '100%' : props.customHeight); }, function (props) { return props.customHeight; }, function (props) { return (props.extended ? '100%' : props.customHeight); });
var Description = styled.h3(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: ", ";\n  line-height: 18pt;\n  max-width: 15.625rem;\n  text-align: center;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: ", ";\n  line-height: 18pt;\n  max-width: 15.625rem;\n  text-align: center;\n"])), palette('white'), fontSize('large'), fontWeight('light'), rem('0.9375px'));
var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  opacity: ", ";\n  transition: opacity 0.4s;\n  transition: max-height 1s;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  opacity: ", ";\n  transition: opacity 0.4s;\n  transition: max-height 1s;\n"])), function (props) { return (props.extended ? '0' : '1'); });
var WatchlistIcon = styled(Icon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), palette('secondaryBackground'));
var WatchlistInfo = function (props) { return (_jsx(Wrapper, __assign({ extended: props.extended, customHeight: props.customHeight || '30rem' }, { children: _jsxs(Content, __assign({ extended: props.extended }, { children: [_jsx(WatchlistIcon, { width: 165, icon: 'watchlistBig' }, void 0), _jsx(Description, { children: _jsx(FormattedMessage, __assign({}, props.text), void 0) }, void 0)] }), void 0) }), void 0)); };
export default WatchlistInfo;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
