var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { palette, fontSize } from '@shared/utils/styled';
import { rem } from 'polished';
import { FormattedMessage } from 'react-intl';
import FlexButton from '@shared/atoms/FlexButton';
import H2 from '@shared/atoms/H2';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import Icon from '@shared/atoms/Icon';
import P from '@shared/atoms/P';
var Button = styled(FlexButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  float: right;\n  font-size: ", ";\n  height: 2.875rem;\n  letter-spacing: ", ";\n  margin-left: 1.25rem;\n  padding: 0 1.75rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  float: right;\n  font-size: ", ";\n  height: 2.875rem;\n  letter-spacing: ", ";\n  margin-left: 1.25rem;\n  padding: 0 1.75rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('secondaryHighlight'), palette('white'), fontSize('large'), rem('0.1px'), palette('activeSecondaryHighlight'));
var Confirm = styled(Button)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('primaryHighlight'), palette('white'), palette('activePrimaryHighlight'));
var StyledIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  float: right;\n  color: #304256;\n"], ["\n  float: right;\n  color: #304256;\n"])));
var Row = styled(HorizontalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: flex-start;\n  justify-content: space-between;\n"], ["\n  align-items: flex-start;\n  justify-content: space-between;\n"])));
var BottomRow = styled(Row)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 1.75rem;\n"], ["\n  margin-top: 1.75rem;\n"])));
var Column = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: ", ";\n"], ["\n  width: ", ";\n"])), function (props) { return props.width; });
var CallConfirm = function (_a) {
    var cancel = _a.cancel, confirm = _a.confirm, disableCancel = _a.disableCancel;
    return (_jsxs("div", { children: [_jsxs(Row, { children: [_jsx(Column, __assign({ width: '32.8%' }, { children: _jsx(StyledIcon, { icon: 'serviceDefault', height: '140' }, void 0) }), void 0), _jsxs(Column, __assign({ width: '63%' }, { children: [_jsx(H2, { children: _jsx(FormattedMessage, { id: 'modal.serviceRequest.headline', defaultMessage: 'Beratung anfragen' }, void 0) }, void 0), _jsx(P, { children: _jsx(FormattedMessage, { id: 'modal.serviceRequest.description', defaultMessage: "Ben\u00F6tigen Sie Hilfe? Klicken Sie auf 'Anfrage abschicken' und eine Mitarbeiterin wird sich in wenigen Minuten um Ihr Anliegen k\u00FCmmern. Bitte warten Sie in der N\u00E4he des Terminals." }, void 0) }, void 0)] }), void 0)] }, void 0), _jsx(BottomRow, { children: _jsxs(Column, __assign({ width: '100%' }, { children: [_jsx(Confirm, __assign({ onClick: confirm }, { children: _jsx(FormattedMessage, { id: 'modal.serviceRequest.submit', defaultMessage: 'Anfrage abschicken' }, void 0) }), void 0), !disableCancel && (_jsx(Button, __assign({ onClick: cancel }, { children: _jsx(FormattedMessage, { id: 'modal.serviceRequest.cancel', defaultMessage: 'Abbrechen' }, void 0) }), void 0))] }), void 0) }, void 0)] }, void 0));
};
export var CancelConfirm = function (_a) {
    var cancel = _a.cancel, confirm = _a.confirm, disableCancel = _a.disableCancel;
    return (_jsxs("div", { children: [_jsxs(Row, { children: [_jsx(Column, __assign({ width: '32.8%' }, { children: _jsx(StyledIcon, { icon: 'serviceDefault', height: '140' }, void 0) }), void 0), _jsxs(Column, __assign({ width: '63%' }, { children: [_jsx(H2, { children: _jsx(FormattedMessage, { id: 'modal.serviceRequestActive.headline', defaultMessage: 'Beratungsanfrage l\u00E4uft' }, void 0) }, void 0), _jsx(P, { children: _jsx(FormattedMessage, { id: 'modal.serviceRequestActive.description', defaultMessage: 'Unser Team wurden informiert. Eine Mitarbeiterin wird sich in wenigen Minuten um Ihr Anliegen k\u00FCmmern. Bitte warten Sie in der N\u00E4he des Terminals.' }, void 0) }, void 0)] }), void 0)] }, void 0), _jsx(BottomRow, { children: _jsxs(Column, __assign({ width: '100%' }, { children: [!disableCancel && (_jsx(Confirm, __assign({ onClick: cancel }, { children: _jsx(FormattedMessage, { id: 'modal.serviceRequestActive.submit', defaultMessage: 'Ok' }, void 0) }), void 0)), _jsx(Button, __assign({ onClick: confirm }, { children: _jsx(FormattedMessage, { id: 'modal.serviceRequestActive.cancel', defaultMessage: 'Anfrage abbrechen' }, void 0) }), void 0)] }), void 0) }, void 0)] }, void 0));
};
export default CallConfirm;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
