var giftcard = function (state, action) {
    if (state === void 0) { state = { loading: false, scanValue: '' }; }
    if (!action)
        return state;
    switch (action.type) {
        case 'LOAD_GIFTCARD_BALANCE': {
            return Object.assign({}, state, {
                loading: true,
            });
        }
        case 'LOAD_GIFTCARD_BALANCE_COMPLETE': {
            return Object.assign({}, state, {
                loading: false,
                balanceResult: {
                    giftcardNumber: action.balanceResult.giftcardNumber,
                    balance: action.balanceResult.balance,
                    error: action.balanceResult.error,
                },
            });
        }
        case 'SCAN_GIFT_CARD_COMPLETE': {
            return Object.assign({}, state, {
                scanValue: action.scanValue,
            });
        }
        case 'SCAN_GIFT_CARD_RESET': {
            return Object.assign({}, state, {
                scanValue: '',
            });
        }
        default: {
            return state;
        }
    }
};
export default giftcard;
