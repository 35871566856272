// @flow
// @ts-nocheck
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    data: Map(),
    loaded: false,
    checkingOut: false,
    checkoutNumber: null,
    orderNumber: null,
    orderError: null,
    creating: false,
    updating: false,
    deleting: false,
    archiving: false,
    ordering: false,
});
var reducer = handleActions({
    CREATE_SESSION: function (state) { return state.set('creating', true); },
    CREATE_SESSION_COMPLETE: {
        next: function (state, action) {
            return state
                .set('creating', false)
                .set('data', action.payload)
                .set('checkoutNumber', null)
                .set('loaded', true);
        },
        throw: function (state) {
            return state.set('creating', false).set('loaded', false);
        },
    },
    CHECKOUT: function (state) { return state.set('checkingOut', true); },
    CHECKOUT_COMPLETE: {
        next: function (state, action) {
            return state
                .set('checkoutNumber', action.payload.checkoutNumber)
                .set('checkingOut', false);
        },
        throw: function (state) {
            return state.set('checkingOut', false);
        },
    },
    DELETE_SESSION: function (state) { return state.set('deleting', true); },
    DELETE_SESSION_COMPLETE: {
        next: function (state) {
            return state
                .set('deleting', false)
                .set('data', Map())
                .set('checkoutNumber', null)
                .set('orderNumber', null)
                .set('loaded', false);
        },
        throw: function (state) {
            return state.set('deleting', false);
        },
    },
    ARCHIVE_SESSION: function (state) { return state.set('archiving', true); },
    ARCHIVE_SESSION_COMPLETE: {
        next: function (state) {
            return state
                .set('archiving', false)
                .set('data', Map())
                .set('checkoutNumber', null)
                .set('loaded', false)
                .set('orderNumber', null)
                .set('orderError', null);
        },
        throw: function (state) {
            return state.set('deleting', false);
        },
    },
    GET_SESSION_COMPLETE: {
        next: function (state, action) {
            return state
                .set('data', action.payload.get('session') || Map())
                .set('loaded', true);
        },
        throw: function (state) {
            return state;
        },
    },
    UPDATE_SESSION: function (state) {
        return state.set('updating', true).set('loaded', false);
    },
    UPDATE_SESSION_COMPLETE: {
        next: function (state, action) {
            return state
                .set('updating', false)
                .set('data', action.payload)
                .set('loaded', true);
        },
        throw: function (state) {
            return state.set('updating', false).set('loaded', false);
        },
    },
    RESET_SESSION: function (state) {
        state
            .set('data', Map())
            .set('checkoutNumber', null)
            .set('orderNumber', null)
            .set('orderError', null);
    },
    SEND_ORDER: function (state) { return state.set('ordering', true); },
    SEND_ORDER_COMPLETE: {
        next: function (state, action) {
            return state
                .set('ordering', false)
                .set('orderNumber', action.payload.orderNumber)
                .set('orderError', action.payload.error)
                .set('loaded', true);
        },
        throw: function (state) {
            return state.set('ordering', false).set('loaded', false);
        },
    },
}, initialState);
export default reducer;
