var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { injectIntl, defineMessages } from 'react-intl';
// utils
import { palette, fontSize } from '@shared/utils/styled';
import styled from 'styled-components';
// components
import VerticalFlex from '@shared/atoms/VerticalFlex';
import CheckButton from '@shared/molecules/CheckButton';
import RadioButton from '@shared/molecules/RadioButton';
// form validation
import { formValidation } from './validate';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  height: auto;\n  width: 100%;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  height: auto;\n  width: 100%;\n"])), palette('white'));
var StyledForm = styled.form(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  width: 100%;\n"], ["\n  font-size: ", ";\n  width: 100%;\n"])), fontSize('large'));
var FavoritInput = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  margin-bottom: 1.875rem;\n"], ["\n  display: flex;\n  margin-bottom: 1.875rem;\n"])));
var CustomerData = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-top: 0.0625rem solid ", ";\n  padding-top: 1.875rem;\n"], ["\n  border-top: 0.0625rem solid ", ";\n  padding-top: 1.875rem;\n"])), palette('border'));
var Row = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 0.625rem;\n  margin-bottom: 1.25rem;\n  grid-template-columns: 4fr 1fr;\n"], ["\n  display: grid;\n  grid-gap: 0.625rem;\n  margin-bottom: 1.25rem;\n  grid-template-columns: 4fr 1fr;\n"])));
var NameRow = styled(Row)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  grid-template-columns: 1fr 1fr;\n"], ["\n  grid-template-columns: 1fr 1fr;\n"])));
var CheckboxLabel = styled.label(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  &:first-child {\n    margin-right: 1.25rem;\n  }\n  input {\n    margin-right: 0.625rem;\n  }\n"], ["\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  display: flex;\n  &:first-child {\n    margin-right: 1.25rem;\n  }\n  input {\n    margin-right: 0.625rem;\n  }\n"])), palette('text'));
var StyledInput = styled.input(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border: 0.0625rem solid ", ";\n  flex: 7;\n  height: 2.875rem;\n  padding: 0 0.675rem;\n  width: 100%;\n"], ["\n  border: 0.0625rem solid ", ";\n  flex: 7;\n  height: 2.875rem;\n  padding: 0 0.675rem;\n  width: 100%;\n"])), palette('secondaryBackground'));
var Error = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: red;\n  font-size: ", ";\n  margin-top: 0.3125rem;\n"], ["\n  color: red;\n  font-size: ", ";\n  margin-top: 0.3125rem;\n"])), fontSize('default'));
var CustomerDataBasicComp = /** @class */ (function (_super) {
    __extends(CustomerDataBasicComp, _super);
    function CustomerDataBasicComp(props) {
        var _this = _super.call(this, props) || this;
        _this.renderInput = function (_a) {
            var input = _a.input, label = _a.label, type = _a.type, _b = _a.meta, touched = _b.touched, error = _b.error;
            return (_jsxs("div", { children: [_jsx(StyledInput, __assign({}, input, { placeholder: label, type: type }), void 0), touched && error && _jsx(Error, { children: error }, void 0)] }, void 0));
        };
        _this.renderCheckbox = function (_a) {
            var input = _a.input, label = _a.label, _b = _a.meta, touched = _b.touched, error = _b.error, warning = _b.warning;
            return (_jsxs("div", { children: [_jsxs(CheckboxLabel, { children: [_jsx(CheckButton, __assign({ id: input.name }, input), void 0), label] }, void 0), touched &&
                        ((error && _jsx("span", { children: error }, void 0)) ||
                            (warning && _jsx("span", { children: warning }, void 0)))] }, void 0));
        };
        _this.renderRadioButton = function (_a) {
            var input = _a.input, label = _a.label, _b = _a.meta, touched = _b.touched, error = _b.error, warning = _b.warning;
            return (_jsxs("div", { children: [_jsxs(CheckboxLabel, { children: [_jsx(RadioButton, __assign({ id: input.name }, input, { onChange: function (e) {
                                    var _a;
                                    _this.setState((_a = {}, _a[e.target.name] = e.target.value, _a));
                                } }), void 0), label] }, void 0), touched &&
                        ((error && _jsx("span", { children: error }, void 0)) ||
                            (warning && _jsx("span", { children: warning }, void 0)))] }, void 0));
        };
        _this.OnCustomerBasicFormSubmit = function (values) {
            _this.props.finishCheckout();
            _this.props.createCustomer(values);
        };
        _this.state = {
            selectedInput: 'terminal',
        };
        return _this;
    }
    CustomerDataBasicComp.prototype.render = function () {
        var _this = this;
        var _a = this.props, intl = _a.intl, updateCustomerFormState = _a.updateCustomerFormState, formId = _a.formId;
        var useTerminalForm = this.state.selectedInput === 'terminal';
        var labels = defineMessages({
            useForm: {
                id: 'checkout.buy.customerData.form.useForm',
                defaultMessage: 'Daten jetzt angeben',
            },
            useCashbox: {
                id: 'checkout.buy.customerData.form.useInput',
                defaultMessage: 'Daten an der Kasse nennen',
            },
            lastName: {
                id: 'checkout.buy.customerData.form.lastName',
                defaultMessage: 'Nachname',
            },
            firstName: {
                id: 'checkout.buy.customerData.form.firstName',
                defaultMessage: 'Vorname',
            },
            email: {
                id: 'checkout.buy.customerData.form.email',
                defaultMessage: 'E-Mail-Adresse',
            },
            createAccount: {
                id: 'checkout.buy.customerData.form.createAccount',
                defaultMessage: 'Kundenkonto anlegen',
            },
        });
        return (_jsx(Wrapper, { children: _jsx(Form, { onSubmit: this.OnCustomerBasicFormSubmit, initialValues: {
                    createAccount: true,
                    selectedInput: this.state.selectedInput,
                }, validate: formValidation, render: function (_a) {
                    var handleSubmit = _a.handleSubmit;
                    return (_jsxs(StyledForm, __assign({ onSubmit: handleSubmit, id: formId }, { children: [_jsxs(FavoritInput, { children: [_jsx(CheckboxLabel, { children: _jsx(Field, { render: _this.renderRadioButton, name: 'selectedInput', type: 'radio', value: 'terminal', label: intl.formatMessage(labels.useForm) }, void 0) }, void 0), _jsx(CheckboxLabel, { children: _jsx(Field, { render: _this.renderRadioButton, name: 'selectedInput', type: 'radio', value: 'cashbox', label: intl.formatMessage(labels.useCashbox) }, void 0) }, void 0)] }, void 0), useTerminalForm && (_jsxs(CustomerData, { children: [_jsxs(NameRow, { children: [_jsx(Field, { autoComplete: 'off', render: _this.renderInput, name: 'firstName', label: intl.formatMessage(labels.firstName), required: useTerminalForm }, void 0), _jsx(Field, { autoComplete: 'off', render: _this.renderInput, name: 'lastName', label: intl.formatMessage(labels.lastName), required: useTerminalForm }, void 0)] }, void 0), _jsx(Row, { children: _jsx(Field, { autoComplete: 'off', type: 'email', name: 'email', label: intl.formatMessage(labels.email), render: _this.renderInput, required: useTerminalForm }, void 0) }, void 0), _jsx(CheckboxLabel, { children: _jsx(Field, { render: _this.renderCheckbox, name: 'createAccount', type: 'checkbox', label: intl.formatMessage(labels.createAccount) }, void 0) }, void 0)] }, void 0)), _jsx(FormSpy, { subscription: { valid: true }, onChange: function (props) { return updateCustomerFormState(props.valid); } }, void 0)] }), void 0));
                } }, void 0) }, void 0));
    };
    return CustomerDataBasicComp;
}(Component));
export default injectIntl(CustomerDataBasicComp);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
