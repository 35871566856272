var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import ListLayout from 'templates/ListLayout';
import CollectionArticleGrid from 'organisms/CollectionArticleGrid';
import Spinner from '@shared/atoms/Spinner';
var Collection = /** @class */ (function (_super) {
    __extends(Collection, _super);
    function Collection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Collection.prototype.render = function () {
        var _a = this.props, articleStacks = _a.articleStacks, checkingOut = _a.checkingOut, checkout = _a.checkout, checkoutNumber = _a.checkoutNumber, collection = _a.collection, collectionLoading = _a.collectionLoading, createArticleStack = _a.createArticleStack, createCustomer = _a.createCustomer, createWatchlistItem = _a.createWatchlistItem, currentGender = _a.currentGender, currentLanguage = _a.currentLanguage, currentPageUrl = _a.currentPageUrl, currentStoreId = _a.currentStoreId, deleteArticleStack = _a.deleteArticleStack, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, furniture = _a.furniture, furnituresReady = _a.furnituresReady, getDuration = _a.getDuration, getFurnitures = _a.getFurnitures, getStackArticle = _a.getStackArticle, getStackArticles = _a.getStackArticles, hiddenItem = _a.hiddenItem, primaryCategories = _a.primaryCategories, resetHidden = _a.resetHidden, saveHidden = _a.saveHidden, selectLanguage = _a.selectLanguage, session = _a.session, setGender = _a.setGender, shareSession = _a.shareSession, stores = _a.stores, submitCustomerBasicForm = _a.submitCustomerBasicForm, submitCustomerForm = _a.submitCustomerForm, updateSession = _a.updateSession, updateWatchlistItem = _a.updateWatchlistItem, waitingDuration = _a.waitingDuration, watchlist = _a.watchlist;
        return (_jsxs(ListLayout, __assign({ articleStacks: articleStacks, checkingOut: checkingOut, checkout: checkout, checkoutNumber: checkoutNumber, createArticleStack: createArticleStack, createCustomer: createCustomer, createWatchlistItem: createWatchlistItem, currentGender: currentGender, currentLanguage: currentLanguage, currentPageUrl: currentPageUrl, currentStoreId: currentStoreId, deleteArticleStack: deleteArticleStack, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, furniture: furniture, furnituresReady: furnituresReady, getDuration: getDuration, getFurnitures: getFurnitures, getStackArticle: getStackArticle, getStackArticles: getStackArticles, hiddenItem: hiddenItem, primaryCategories: primaryCategories, resetHidden: resetHidden, saveHidden: saveHidden, selectLanguage: selectLanguage, session: session, setGender: setGender, shareSession: shareSession, stores: stores, submitCustomerBasicForm: submitCustomerBasicForm, submitCustomerForm: submitCustomerForm, updateSession: updateSession, updateWatchlistItem: updateWatchlistItem, waitingDuration: waitingDuration, watchlist: watchlist }, { children: [collection && !collectionLoading && (_jsx(CollectionArticleGrid, { collection: collection, createArticleStack: createArticleStack, createWatchlistItem: createWatchlistItem, deleteWatchlistItem: deleteWatchlistItem, watchlist: watchlist }, void 0)), collectionLoading && _jsx(Spinner, {}, void 0)] }), void 0));
    };
    return Collection;
}(Component));
export default Collection;
