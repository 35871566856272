var _a;
import { createActions, createAction } from 'redux-actions';
export var callSession = (_a = createActions('CALL_SESSION', 'CALL_SESSION_COMPLETE', 'CHECKOUT_COMPLETE', 'CREATE_SESSION', 'CREATE_SESSION_COMPLETE', 'SHARE_SESSION_COMPLETE', 'DELETE_SESSION', 'DELETE_SESSION_COMPLETE', 'GET_SESSION_COMPLETE', 'UPDATE_SESSION_COMPLETE', 'RESET_SESSION', 'ARCHIVE_SESSION', 'ARCHIVE_SESSION_COMPLETE', 'SEND_ORDER_COMPLETE'), _a.callSession), callSessionComplete = _a.callSessionComplete, checkoutComplete = _a.checkoutComplete, createSession = _a.createSession, createSessionComplete = _a.createSessionComplete, shareSessionComplete = _a.shareSessionComplete, deleteSession = _a.deleteSession, deleteSessionComplete = _a.deleteSessionComplete, getSessionComplete = _a.getSessionComplete, updateSessionComplete = _a.updateSessionComplete, resetSession = _a.resetSession, archiveSession = _a.archiveSession, archiveSessionComplete = _a.archiveSessionComplete, sendOrderComplete = _a.sendOrderComplete;
export var updateSession = createAction('UPDATE_SESSION', function (payload) { return payload; }, function (payload, id) { return ({
    id: id,
}); });
export var shareSession = createAction('SHARE_SESSION', function (payload) { return payload; }, function (payload, id) { return ({
    id: id,
}); });
export var checkout = createAction('CHECKOUT', function (payload) { return payload; }, function (payload, id) { return ({
    id: id,
}); });
export var sendOrder = createAction('SEND_ORDER', function (payload) { return payload; }, function (payload, id) { return ({ id: id }); });
