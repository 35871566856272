var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { toJS } from '@shared/utils/immutable';
import { connect } from 'react-redux';
import { resetArticlesState, searchArticle } from '@store/article/actions';
import { selectArticleError, selectScanError, selectLoadingAllComplete, selectTotal, } from '@store/article/selectors';
import Session from 'containers/Session';
import Scan from 'pages/Scan';
var Container = /** @class */ (function (_super) {
    __extends(Container, _super);
    function Container() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Reset articles flags and data for a fresh new search.
     */
    Container.prototype.componentDidMount = function () {
        this.props.resetArticlesState();
    };
    /**
     * Handles the incoming search query and clears the previous data.
     *
     * @param query - {String} - Result from the search.
     */
    Container.prototype.handleSearch = function (query) {
        this.props.resetArticlesState();
        this.props.searchArticle(query);
    };
    Container.prototype.render = function () {
        var _this = this;
        return (_jsx(Session, { children: function (sessionProps) { return (_jsx(Scan, __assign({}, sessionProps, { searchArticle: function (query) { return _this.handleSearch(query); }, searchError: _this.props.searchError, scanError: _this.props.scanError, textSearchError: _this.props.textSearchError, resetArticlesState: _this.props.resetArticlesState }), void 0)); } }, void 0));
    };
    return Container;
}(Component));
var select = function () { return function (state) { return ({
    /**
     * True, when the article number search returns an error.
     */
    searchError: selectArticleError(state),
    scanError: selectScanError(state),
    /**
     * True, when all articles are loaded and the total number is zero.
     */
    textSearchError: selectLoadingAllComplete(state) && selectTotal(state) === 0,
}); }; };
export var ScanContainer = Scan;
export default connect(select, { resetArticlesState: resetArticlesState, searchArticle: searchArticle })(toJS(Container));
