import { handleActions } from 'redux-actions';
import { Map, List, fromJS } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    data: List(),
    error: false,
    scanError: false,
    loaded: false,
    loadedAll: false,
    loading: false,
    loadingAll: false,
    page: 0,
    pageCount: 0,
    total: 0,
});
export var updateArticle = function (article) { return function (existingArticles) {
    var index = existingArticles.findIndex(function (existingArticle) { return existingArticle.get('id') === article.get('id'); });
    if (index === -1) {
        return existingArticles.push(article);
    }
    return existingArticles.set(index, article);
}; };
var updateOrSetArticles = function (articles) { return function (existingArticles) {
    if (existingArticles.size === 0) {
        return articles;
    }
    var newArticles = existingArticles;
    articles.forEach(function (article) {
        newArticles = newArticles.update(updateArticle(article));
    });
    // $FlowIgnoreNextLine
    return newArticles;
}; };
var reducer = handleActions({
    GET_ARTICLE: function (state) {
        return state.set('loading', true).set('error', false).set('scanError', false);
    },
    ARTICLE_NOT_FOUND: function (state) {
        return state
            .set('loading', false)
            .set('loaded', true)
            .set('scanError', false)
            .set('error', true);
    },
    SCAN_ERROR: function (state) {
        return state.set('loading', false).set('loaded', true).set('scanError', true);
    },
    GET_ARTICLE_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .set('error', false)
                .set('scanError', false)
                .update('data', updateArticle(action.payload));
        },
        throw: function (state) {
            return state
                .set('loading', false)
                .set('loaded', false)
                .set('scanError', false)
                .set('error', false);
        },
    },
    GET_ARTICLES: function (state) {
        return state.set('loadingAll', true).set('error', false).set('scanError', false);
    },
    GET_ARTICLES_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .set('total', action.payload.totalElements)
                .set('page', action.payload.page)
                .set('pageCount', action.payload.totalPages)
                .update('data', updateOrSetArticles(fromJS(action.payload.content)));
        },
        throw: function (state) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', false)
                .set('scanError', false)
                .set('error', false);
        },
    },
    GET_ARTICLES_COMPLETE_SEARCH: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .set('total', action.payload.totalElements)
                .set('page', action.payload.page)
                .set('pageCount', action.payload.totalPages)
                .update('data', updateOrSetArticles(fromJS(action.payload)));
        },
        throw: function (state) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', false)
                .set('scanError', false)
                .set('error', false);
        },
    },
    RESET_ARTICLES: function (state) {
        return state.set('data', List()).set('page', 0).set('total', 0);
    },
    RESET_ARTICLES_STATE: function (state) {
        return state
            .set('loadingAll', false)
            .set('loadedAll', false)
            .set('scanError', false)
            .set('error', false)
            .set('data', List())
            .set('page', 0)
            .set('total', 0);
    },
    SEARCH_ARTICLE: function (state) {
        return state.set('loading', true).set('error', false);
    },
    SEARCH_ARTICLE_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .set('error', false)
                .set('scanError', false)
                .update('data', updateArticle(action.payload));
        },
        throw: function (state) {
            return state
                .set('loading', false)
                .set('loaded', false)
                .set('scanError', false)
                .set('error', true);
        },
    },
}, initialState);
export default reducer;
