// @flow
// @ts-nocheck
import { handleActions } from 'redux-actions';
import { Map, fromJS } from 'immutable';
export var initialState = Map({
    data: fromJS([]),
    loading: false,
    loaded: false,
});
var reducer = handleActions({
    GET_TEASERS: function (state) { return state.set('loading', true); },
    GET_TEASERS_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true) // $FlowIgnoreNextLine
                .update('data', function (data) { return data.concat(action.payload); });
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
}, initialState);
export default reducer;
