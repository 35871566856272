var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { palette, fontSize } from '@shared/utils/styled';
import BrandModal from 'molecules/BrandModal';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import H2 from '@shared/atoms/H2';
import Icon from '@shared/atoms/Icon';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
var ButtonRow = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 2.5rem;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 2.5rem;\n"])));
var CancelButton = styled.button(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  font-size: ", ";\n  height: 2.875rem;\n  padding: 0 1.25rem;\n  min-width: 7.5rem;\n"], ["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  font-size: ", ";\n  height: 2.875rem;\n  padding: 0 1.25rem;\n  min-width: 7.5rem;\n"])), palette('secondaryBackground'), palette('white'), fontSize('large'));
var FinishButton = styled(CancelButton)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-right: 1.25rem;\n  background-color: ", ";\n  margin-left: 0.625rem;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  margin-right: 1.25rem;\n  background-color: ", ";\n  margin-left: 0.625rem;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('primaryHighlight'), palette('activePrimaryHighlight'));
var Headline = styled(H2)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-bottom: 1.25rem;\n  margin-top: 0;\n"], ["\n  margin-bottom: 1.25rem;\n  margin-top: 0;\n"])));
var ShareOptions = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 26.875rem;\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 26.875rem;\n"])));
var Text = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), palette('text'), fontSize('large'));
var Bold = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: bold;\n"], ["\n  font-weight: bold;\n"])));
var ImageSection = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 1.875rem;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 1.875rem;\n"])));
var ImageWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  border-radius: 50%;\n  display: flex;\n  height: 8.75rem;\n  justify-content: center;\n  width: 8.75rem;\n"], ["\n  align-items: center;\n  border-radius: 50%;\n  display: flex;\n  height: 8.75rem;\n  justify-content: center;\n  width: 8.75rem;\n"])));
var AssistantIcon = styled(Icon)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n"], ["\n  color: ", ";\n"])), palette('border'));
var ReserveFinishModal = /** @class */ (function (_super) {
    __extends(ReserveFinishModal, _super);
    function ReserveFinishModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReserveFinishModal.prototype.renderModalActions = function () {
        var _a = this.props, toggleAction = _a.toggleAction, finish = _a.finish;
        return (_jsxs(ButtonRow, { children: [_jsx(CancelButton, __assign({ onClick: function () { return toggleAction(); } }, { children: _jsx(FormattedMessage, { id: 'modal.checkout.reserve.close', defaultMessage: 'Anfrage abbrechen' }, void 0) }), void 0), _jsx(FinishButton, __assign({ onClick: function () { return finish(); } }, { children: _jsx(FormattedMessage, { id: 'modal.checkout.reserve.confirm', defaultMessage: 'Ok' }, void 0) }), void 0)] }, void 0));
    };
    ReserveFinishModal.prototype.render = function () {
        var _a = this.props, open = _a.open, toggleAction = _a.toggleAction;
        var i = this.props.image;
        // eslint-disable-next-line no-unused-vars
        var style = {
            backgroundImage: "url(" + i + ")",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
        };
        return (_jsxs(BrandModal, __assign({ open: open, toggleAction: toggleAction }, this.props, { children: [_jsxs(ShareOptions, { children: [_jsx(ImageSection, { children: _jsx(ImageWrapper, { children: _jsx(AssistantIcon, { icon: 'serviceDefault', width: 200 }, void 0) }, void 0) }, void 0), _jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'modal.checkout.reserve.headline', defaultMessage: 'Bitte haben Sie einen Augenblick Geduld.' }, void 0) }, void 0), _jsxs(Text, { children: [_jsx(FormattedMessage, { id: 'modal.checkout.reserve.description', defaultMessage: 'Ein Verk\u00E4ufer kommt in K\u00FCrze zu Ihnen, um die Artikel zu reservieren.' }, void 0), _jsx("br", {}, void 0), _jsx("br", {}, void 0), _jsx(Bold, { children: _jsx(FormattedMessage, { id: 'modal.checkout.reserve.subDescription', defaultMessage: 'Bitte warten Sie am Terminal' }, void 0) }, void 0)] }, void 0)] }, void 0)] }, void 0), this.renderModalActions()] }), void 0));
    };
    return ReserveFinishModal;
}(Component));
export default ReserveFinishModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
