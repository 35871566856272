var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
// utils
import { toJS } from '@shared/utils/immutable';
// actions
import { getArticle } from '@store/article/actions';
// selectors
import { makeSelectArticle, loadSingleArticle } from '@store/article/selectors';
// components
import Session from 'containers/Session';
import Page from 'pages/Article';
var Article = /** @class */ (function (_super) {
    __extends(Article, _super);
    function Article() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Article.prototype.componentDidMount = function () {
        if (Boolean(this.props.match.params.id)) {
            this.props.getArticle(this.props.match.params.id);
        }
    };
    Article.prototype.componentWillReceiveProps = function (nextProps) {
        if (Boolean(nextProps.match.params.id)) {
            nextProps.getArticle(nextProps.match.params.id);
            parseInt(nextProps.match.params.id, 10);
        }
    };
    Article.prototype.render = function () {
        var _a = this.props, article = _a.article, match = _a.match;
        return (_jsx(Session, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { article: article, params: match.params }), void 0)); } }, void 0));
    };
    return Article;
}(Component));
var select = function () { return function (state, props) { return ({
    article: makeSelectArticle()(state, parseInt(props.match.params.id, 10)),
    shouldLoadArticle: loadSingleArticle()(state, parseInt(props.match.params.id, 10)),
}); }; };
export var ArticleCont = Article;
export default withRouter(connect(select, { getArticle: getArticle })(toJS(Article)));
