var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import TilesSmall from 'molecules/TilesSmall';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import TilesBig from 'molecules/TilesBig';
import Banner from 'molecules/Banner';
import { Link } from 'react-router-dom';
import SimpleBanner from 'molecules/SimpleBanner';
import ArticleSlider from 'molecules/ArticleSlider';
import { key } from '@shared/utils/styled';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  overflow-y: auto;\n  padding-top: 2.5rem;\n"], ["\n  flex: 1;\n  overflow-y: auto;\n  padding-top: 2.5rem;\n"])));
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  margin: 0 auto;\n  max-width: 90%;\n"], ["\n  display: block;\n  margin: 0 auto;\n  max-width: 90%;\n"])));
var Section = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin: 0 auto 2.5rem auto;\n  max-width: 81.25rem;\n  padding: 0 2.5rem;\n  width: 100%;\n"], ["\n  margin: 0 auto 2.5rem auto;\n  max-width: 81.25rem;\n  padding: 0 2.5rem;\n  width: 100%;\n"])));
var SectionBig = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0 auto 2.5rem auto;\n  padding: 0 2.5rem;\n  @media screen and (min-width: ", ") {\n    width: 90%;\n  }\n"], ["\n  margin: 0 auto 2.5rem auto;\n  padding: 0 2.5rem;\n  @media screen and (min-width: ", ") {\n    width: 90%;\n  }\n"])), key('breakpoints.medium', '1720px'));
var InspirationSections = /** @class */ (function (_super) {
    __extends(InspirationSections, _super);
    function InspirationSections() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InspirationSections.prototype.render = function () {
        var _a = this.props, createArticleStack = _a.createArticleStack, createWatchlistItem = _a.createWatchlistItem, deleteWatchlistItem = _a.deleteWatchlistItem, sections = _a.sections, watchlist = _a.watchlist;
        return (_jsx(Wrapper, { children: sortBy(sections, 'sort').map(function (section) {
                if (section.type === 'banner' && Boolean(section.imageUrl)) {
                    return (_jsx(SectionBig, { children: _jsx(Banner, { buttonColor: section.buttonColor || null, buttonPosition: section.buttonPosition || null, buttonTextColor: section.buttonTextColor || null, buttonText: section.buttonText, imageUrl: section.imageUrl, targetUrl: section.targetUrl }, void 0) }, section.sort));
                }
                if (section.type === 'simpleBanner' && Boolean(section.imageUrl)) {
                    return (_jsx(SectionBig, { children: _jsx(SimpleBanner, { imageUrl: section.imageUrl, targetUrl: section.targetUrl }, void 0) }, section.sort));
                }
                if (section.type === 'tilesSmall') {
                    return (_jsx(Section, { children: _jsx(TilesSmall, { bannerUrl: section.bannerUrl, tiles: section.items }, void 0) }, section.sort));
                }
                if (section.type === 'tilesBig') {
                    return (_jsx(Section, { children: _jsx(TilesBig, { tiles: section.items }, void 0) }, section.sort));
                }
                if (section.type === 'articleSlider') {
                    return (_jsx(Section, { children: _jsx(ArticleSlider, { createArticleStack: createArticleStack, articles: section.items, createWatchlistItem: createWatchlistItem, deleteWatchlistItem: deleteWatchlistItem, headline: section.headline, watchlist: watchlist }, void 0) }, section.sort));
                }
                if (section.type === 'image' && Boolean(section.imageUrl)) {
                    if (!section.targetUrl) {
                        return (_jsx(Section, { children: _jsx(Image, { src: section.imageUrl }, void 0) }, section.sort));
                    }
                    return (_jsx(Section, { children: _jsx(Link, __assign({ to: section.targetUrl }, { children: _jsx(Image, { src: section.imageUrl }, void 0) }), void 0) }, section.sort));
                }
                return null;
            }) }, void 0));
    };
    return InspirationSections;
}(Component));
export default InspirationSections;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
