var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import ArticleItem from 'molecules/ArticleItem';
import ListLayout from 'templates/ListLayout';
import InfiniteScroll from '@shared/organisms/InfiniteScroll';
import { pageSize } from '@shared/utils/constants';
import { ArticleGrid } from './style';
// TODO: Refactor watchlist action to one function only. onWatchlistClick
var ScanResults = /** @class */ (function (_super) {
    __extends(ScanResults, _super);
    function ScanResults() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Calls the remove from watchlist action with the needed watchlist id.
     *
     * @param id - Article id that we have to search a watchlist item for.
     */
    ScanResults.prototype.handleRemoveFromWatchlist = function (id) {
        var _a = this.props, onRemoveWatchlist = _a.onRemoveWatchlist, watchlist = _a.watchlist;
        var watchlistItem = watchlist.find(function (item) { return item.articleId === id; });
        onRemoveWatchlist(watchlistItem.id);
    };
    /**
     * Checks if the given article id matches an article in the watchlist.
     *
     * @param id - The article id that we have to search for.
     * @returns {boolean} - Returns true or false if it's stored in the watchlist.
     */
    ScanResults.prototype.isOnWatchlist = function (id) {
        var watchlist = this.props.watchlist;
        var watchlistItemIds = watchlist.map(function (watchlistItem) { return watchlistItem.articleId; });
        return watchlistItemIds.includes(id);
    };
    /**
     * Renders the articles as react components with expected handlers.
     *
     * @returns Articles {array} - React components.
     */
    ScanResults.prototype.renderArticles = function () {
        var _this = this;
        var _a = this.props, articles = _a.articles, onArticleClick = _a.onArticleClick, onAddWatchlist = _a.onAddWatchlist;
        return articles.map(function (article) { return (_jsx(ArticleItem, { article: article, onClick: function () { return onArticleClick(article.id); }, createWatchlistItem: onAddWatchlist, deleteWatchlistItem: function () { return _this.handleRemoveFromWatchlist(article.id); }, watchlistItem: _this.isOnWatchlist(article.id) }, article.id)); });
    };
    ScanResults.prototype.render = function () {
        var _a = this.props, articles = _a.articles, isLoading = _a.isLoading, loadMore = _a.loadMore, onRemoveWatchlist = _a.onRemoveWatchlist, watchlist = _a.watchlist, others = __rest(_a, ["articles", "isLoading", "loadMore", "onRemoveWatchlist", "watchlist"]);
        var currentPage = Math.ceil(articles.length / pageSize);
        return (_jsx(ListLayout, __assign({ deleteWatchlistItem: onRemoveWatchlist, watchlist: watchlist }, others, { children: _jsx(InfiniteScroll, { currentPage: currentPage, hasMore: true, items: this.renderArticles(), loading: isLoading, loadMore: function () { return loadMore(currentPage + 1); }, wrapper: ArticleGrid }, void 0) }), void 0));
    };
    return ScanResults;
}(Component));
export default ScanResults;
