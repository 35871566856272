var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { normalize } from 'polished';
import { Switch, Route, withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { toJS } from '@shared/utils/immutable';
import HomePage from 'containers/HomePage';
import ArticleContainer from 'containers/Article';
import InspirationIntroCont from 'containers/InspirationIntro';
import InspirationCont from 'containers/Inspiration';
import Collection from 'containers/Collection';
import Result from 'containers/Result';
import Scan from 'containers/Scan';
import ScanResults from 'containers/ScanResults';
import Search from 'containers/Search';
import Support from 'containers/Support';
import Watchlist from 'containers/Watchlist';
import Giftcard from 'containers/Giftcard';
import { selectFullTheme } from '@store/theme/selectors';
// eslint-disable-next-line no-unused-expressions
var GlobalStyles = createGlobalStyle(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n\n  html, body, #app {\n    height: 100%;\n    -webkit-font-smoothing: antialiased;\n  }\n  body {\n    font-family: Nunito, Helvetica Neue, Helvetica, Roboto, sans-serif;\n    font-weight: 400;\n    letter-spacing: 0.2px;\n    margin: 0;\n    touch-action: pan-x pan-y;\n  }\n  button, input {\n    font-family: inherit;\n    letter-spacing: 0.2px;\n  }\n  input::placeholder {\n    font-style: italic;\n    font-weight: 300;\n  }\n  * {\n    box-sizing: border-box;\n  }\n  svg:not(:root) {\n    overflow: visible;\n  }\n\n  @keyframes fadeIn {\n    0% {\n      opacity: 0;\n    }\n    100% {\n      opacity: 1;\n    }\n  }\n  .odd {\n    background-color:#eee;\n  }\n"], ["\n  ", "\n\n  html, body, #app {\n    height: 100%;\n    -webkit-font-smoothing: antialiased;\n  }\n  body {\n    font-family: Nunito, Helvetica Neue, Helvetica, Roboto, sans-serif;\n    font-weight: 400;\n    letter-spacing: 0.2px;\n    margin: 0;\n    touch-action: pan-x pan-y;\n  }\n  button, input {\n    font-family: inherit;\n    letter-spacing: 0.2px;\n  }\n  input::placeholder {\n    font-style: italic;\n    font-weight: 300;\n  }\n  * {\n    box-sizing: border-box;\n  }\n  svg:not(:root) {\n    overflow: visible;\n  }\n\n  @keyframes fadeIn {\n    0% {\n      opacity: 0;\n    }\n    100% {\n      opacity: 1;\n    }\n  }\n  .odd {\n    background-color:#eee;\n  }\n"])), normalize());
var App = /** @class */ (function (_super) {
    __extends(App, _super);
    function App() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    App.prototype.render = function () {
        return (_jsxs(ThemeProvider, __assign({ theme: this.props.theme }, { children: [_jsx(GlobalStyles, {}, void 0), _jsxs(Switch, { children: [_jsx(Route, { path: '/articles/:id', component: ArticleContainer, exact: true }, void 0), _jsx(Route, { path: '/inspiration/:genderId', component: InspirationCont, exact: true }, void 0), _jsx(Route, { path: '/inspiration', component: InspirationIntroCont, exact: true }, void 0), _jsx(Route, { path: '/collections/:id', component: Collection, exact: true }, void 0), _jsx(Route, { path: '/results', component: Result, exact: true }, void 0), _jsx(Route, { path: '/scan/results', component: ScanResults, exact: true }, void 0), _jsx(Route, { path: '/scan', component: Scan, exact: true }, void 0), _jsx(Route, { path: '/support', component: Support, exact: true }, void 0), _jsx(Route, { path: '/search/:primaryCategoryId', component: Search, exact: true }, void 0), _jsx(Route, { path: '/search', component: Search, exact: true }, void 0), _jsx(Route, { path: '/watchlist', component: Watchlist, exact: true }, void 0), _jsx(Route, { path: '/giftcard', component: Giftcard, exact: true }, void 0), _jsx(Route, { path: '/', component: HomePage, exact: true }, void 0)] }, void 0)] }), void 0));
    };
    return App;
}(Component));
var select = createStructuredSelector({
    theme: selectFullTheme,
});
export default withRouter(connect(select, {})(toJS(App)));
var templateObject_1;
