var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { parse } from 'query-string';
import ListLayout from 'templates/ListLayout';
import ArticleGrid from 'organisms/ArticleGrid';
import { getSearchParams } from '@shared/utils/params';
var Result = /** @class */ (function (_super) {
    __extends(Result, _super);
    function Result() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Result.prototype.componentWillReceiveProps = function () {
        var _a = this.props, params = _a.params, currentGender = _a.currentGender;
        var parsed = parse(params, { arrayFormat: 'bracket' });
        if (parsed.primaryCategory !== currentGender) {
            this.props.setGender(parseInt(parsed.primaryCategory, 10));
        }
    };
    Result.prototype.getSearchPageUrl = function () {
        var params = this.props.params;
        var paramObjects = getSearchParams(params);
        var target = "/search";
        if (Boolean(paramObjects.primaryCategory)) {
            target += "/" + paramObjects.primaryCategory;
        }
        return target;
    };
    Result.prototype.render = function () {
        var _a = this.props, articlePage = _a.articlePage, articlePageCount = _a.articlePageCount, articles = _a.articles, articlesLoading = _a.articlesLoading, articleStacks = _a.articleStacks, articlesTotal = _a.articlesTotal, checkingOut = _a.checkingOut, checkout = _a.checkout, checkoutNumber = _a.checkoutNumber, createArticleStack = _a.createArticleStack, createCustomer = _a.createCustomer, createWatchlistItem = _a.createWatchlistItem, currentGender = _a.currentGender, currentLanguage = _a.currentLanguage, currentPageUrl = _a.currentPageUrl, currentSortId = _a.currentSortId, currentStoreId = _a.currentStoreId, deleteArticleStack = _a.deleteArticleStack, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, deselectAndApplyFilItem = _a.deselectAndApplyFilItem, fetch = _a.fetch, furniture = _a.furniture, furnituresReady = _a.furnituresReady, getDuration = _a.getDuration, getFurnitures = _a.getFurnitures, getStackArticle = _a.getStackArticle, getStackArticles = _a.getStackArticles, hiddenItem = _a.hiddenItem, orderNumber = _a.orderNumber, orderError = _a.orderError, params = _a.params, primaryCategories = _a.primaryCategories, resetHidden = _a.resetHidden, saveHidden = _a.saveHidden, selectLanguage = _a.selectLanguage, sendOrder = _a.sendOrder, session = _a.session, setGender = _a.setGender, shareSession = _a.shareSession, sorts = _a.sorts, stores = _a.stores, submitCustomerBasicForm = _a.submitCustomerBasicForm, submitCustomerForm = _a.submitCustomerForm, title = _a.title, updateSession = _a.updateSession, updateWatchlistItem = _a.updateWatchlistItem, waitingDuration = _a.waitingDuration, watchlist = _a.watchlist;
        return (_jsx(ListLayout, __assign({ articleStacks: articleStacks, checkingOut: checkingOut, checkout: checkout, checkoutNumber: checkoutNumber, createArticleStack: createArticleStack, createCustomer: createCustomer, createWatchlistItem: createWatchlistItem, currentGender: currentGender, currentLanguage: currentLanguage, currentPageUrl: currentPageUrl, currentStoreId: currentStoreId, deleteArticleStack: deleteArticleStack, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, furniture: furniture, furnituresReady: furnituresReady, getDuration: getDuration, getFurnitures: getFurnitures, getStackArticle: getStackArticle, getStackArticles: getStackArticles, hiddenItem: hiddenItem, orderNumber: orderNumber, orderError: orderError, primaryCategories: primaryCategories, resetHidden: resetHidden, saveHidden: saveHidden, selectLanguage: selectLanguage, sendOrder: sendOrder, session: session, setGender: setGender, shareSession: shareSession, stores: stores, submitCustomerBasicForm: submitCustomerBasicForm, submitCustomerForm: submitCustomerForm, updateSession: updateSession, updateWatchlistItem: updateWatchlistItem, waitingDuration: waitingDuration, watchlist: watchlist }, { children: _jsx(ArticleGrid, { articles: articles, createArticleStack: createArticleStack, createWatchlistItem: createWatchlistItem, currentPage: articlePage, currentSortId: currentSortId, deleteWatchlistItem: deleteWatchlistItem, deselectAndApplyFilItem: deselectAndApplyFilItem, fetch: fetch, loading: articlesLoading, pageCount: articlePageCount, params: params, sorts: sorts, source: this.getSearchPageUrl(), title: title, total: articlesTotal, watchlist: watchlist }, void 0) }), void 0));
    };
    return Result;
}(Component));
export default Result;
