var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import { palette, fontSize } from '@shared/utils/styled';
import { FormattedMessage } from 'react-intl';
import HighlightButton from '@shared/atoms/HighlightButton';
import Icon from '@shared/atoms/Icon';
var WatchlistAddButton = styled(HighlightButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: ", ";\n  font-size: ", ";\n  height: 3.125rem;\n  margin-top: 1rem;\n  padding: 0.5rem 1rem;\n  text-align: center;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n  opacity: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: ", ";\n  font-size: ", ";\n  height: 3.125rem;\n  margin-top: 1rem;\n  padding: 0.5rem 1rem;\n  text-align: center;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n  opacity: ", ";\n"])), palette('secondaryHighlight'), fontSize('default'), palette('activeSecondaryHighlight'), function (props) { return (props.disabled ? 0.3 : 1); });
var WatchlistDeleteButton = styled(WatchlistAddButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('activeSecondaryHighlight'));
var WatchListIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: white;\n  border-radius: 50%;\n  margin-top: -0.0625rem;\n"], ["\n  background-color: white;\n  border-radius: 50%;\n  margin-top: -0.0625rem;\n"])));
var TextWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin-left: 0.6125rem;\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  margin-left: 0.6125rem;\n"])));
var InnerButtonWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 2.25rem 1fr;\n  width: 14.25rem;\n"], ["\n  display: grid;\n  grid-template-columns: 2.25rem 1fr;\n  width: 14.25rem;\n"])));
var WatchlistButton = /** @class */ (function (_super) {
    __extends(WatchlistButton, _super);
    function WatchlistButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WatchlistButton.prototype.render = function () {
        var _a = this.props, addAction = _a.addAction, articleId = _a.articleId, onList = _a.onList, removeAction = _a.removeAction, sizeId = _a.sizeId, watchlistItem = _a.watchlistItem;
        return onList ? (_jsx(WatchlistDeleteButton, __assign({ onClick: function () {
                return Boolean(watchlistItem) && watchlistItem && watchlistItem.id
                    ? removeAction(watchlistItem.id)
                    : null;
            } }, { children: _jsxs(InnerButtonWrapper, { children: [_jsx(WatchListIcon, { icon: 'watchlistRemove', width: 36 }, void 0), _jsx(TextWrapper, { children: _jsx(FormattedMessage, { id: 'article.watchlist.delete', defaultMessage: 'Von Merkliste entfernen' }, void 0) }, void 0)] }, void 0) }), void 0)) : (_jsx(WatchlistAddButton, __assign({ onClick: function () {
                return addAction({
                    articleId: articleId,
                    sizeId: sizeId,
                });
            }, disabled: !sizeId }, { children: _jsxs(InnerButtonWrapper, { children: [_jsx(WatchListIcon, { icon: 'watchlist', width: 36 }, void 0), _jsx(TextWrapper, { children: _jsx(FormattedMessage, { id: 'article.watchlist.add', defaultMessage: 'Zur Merkliste hinzuf\u00FCgen' }, void 0) }, void 0)] }, void 0) }), void 0));
    };
    return WatchlistButton;
}(Component));
export default WatchlistButton;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
