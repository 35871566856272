// @flow
// @ts-nocheck
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    data: Map(),
});
var reducer = handleActions({
    GET_DEVICE: function (state) { return state; },
    GET_DEVICE_THEME: function (state) { return state; },
    GET_DEVICE_COMPLETE: {
        next: function (state, action) {
            return state.set('data', action.payload);
        },
        throw: function (state) {
            return state;
        },
    },
}, initialState);
export default reducer;
