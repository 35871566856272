var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import LinkWrapper from 'molecules/LinkWrapper';
import MenuButton from '@shared/molecules/MenuButton';
import { defineMessages } from 'react-intl';
import { useHistory } from 'react-router-dom';
var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-self: center;\n  flex: 1.8;\n  margin-bottom: 1%;\n  max-height: 17.25rem;\n  max-width: 100rem;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    margin-bottom: 2%;\n  }\n"], ["\n  align-self: center;\n  flex: 1.8;\n  margin-bottom: 1%;\n  max-height: 17.25rem;\n  max-width: 100rem;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    margin-bottom: 2%;\n  }\n"])), function (props) {
    return props.theme.breakpoints.medium;
});
var MenuLinkWrapper = styled(LinkWrapper)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-right: 1%;\n  &:last-child {\n    margin-right: 0;\n  }\n  @media screen and (min-width: ", ") {\n    margin-right: 1.32%;\n  }\n"], ["\n  margin-right: 1%;\n  &:last-child {\n    margin-right: 0;\n  }\n  @media screen and (min-width: ", ") {\n    margin-right: 1.32%;\n  }\n"])), function (props) {
    return props.theme.breakpoints.large;
});
var NoLinkWrapper = styled(MenuLinkWrapper.withComponent('div'))(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  flex: 1;\n  height: 100%;\n"], ["\n  cursor: pointer;\n  flex: 1;\n  height: 100%;\n"])));
var HomeBar = function (_a) {
    var theme = _a.theme, activeSlide = _a.activeSlide, toggleSelection = _a.toggleSelection, currentGender = _a.currentGender;
    var history = useHistory();
    var switches = (theme && theme.switches) || {};
    var hide = switches.hide || [];
    var isInactive = function (key) {
        return Boolean(activeSlide) && activeSlide !== key;
    };
    return (_jsxs(Wrapper, { children: [!hide.includes('inspiration') && (_jsx(NoLinkWrapper, __assign({ onClick: function () {
                    if (currentGender) {
                        history.push("/inspiration/" + currentGender);
                        return;
                    }
                    toggleSelection('inspiration');
                } }, { children: _jsx(MenuButton, __assign({ icon: 'inspiration' }, defineMessages({
                    text: {
                        id: 'startMenu.inspiration',
                        defaultMessage: 'Highlights',
                    },
                }), { inactive: isInactive('inspiration') }), void 0) }), void 0)), _jsx(NoLinkWrapper, __assign({ "data-cy-id": 'start-menu-range', onClick: function () { return toggleSelection('search'); } }, { children: _jsx(MenuButton, __assign({ icon: 'range' }, defineMessages({
                    text: {
                        id: 'startMenu.stock',
                        defaultMessage: 'Alle Artikel anzeigen',
                    },
                }), { inactive: isInactive('search') }), void 0) }), void 0), _jsx(MenuLinkWrapper, __assign({ to: '/scan' }, { children: _jsx(MenuButton, __assign({ icon: 'search', inactive: isInactive('scan') }, defineMessages({
                    text: {
                        id: 'startMenu.scan',
                        defaultMessage: 'Suchen',
                    },
                })), void 0) }), void 0), switches.giftcard && (_jsx(MenuLinkWrapper, __assign({ to: '/giftcard' }, { children: _jsx(MenuButton, __assign({ icon: 'search', inactive: isInactive('giftcard') }, defineMessages({
                    text: {
                        id: 'giftcard.name',
                        defaultMessage: 'Gift Card',
                    },
                })), void 0) }), void 0)), !hide.includes('support') && (_jsx(MenuLinkWrapper, __assign({ to: '/support' }, { children: _jsx(MenuButton, __assign({ icon: 'support', inactive: isInactive('support') }, defineMessages({
                    text: {
                        id: 'startMenu.support',
                        defaultMessage: 'Beratung anfragen',
                    },
                })), void 0) }), void 0))] }, void 0));
};
export default HomeBar;
var templateObject_1, templateObject_2, templateObject_3;
