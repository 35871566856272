var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { key } from 'styled-theme';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import H2 from '@shared/atoms/H2';
import TabMenu from 'molecules/TabMenu';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import NavIcon from 'atoms/NavIcon';
import { stringify } from 'query-string';
import DropDownHandler from '../ArticleGrid/Filters/components/DropDownHandler';
var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  padding: 0 1.875rem;\n  grid-column: 1 / 6;\n  @media screen and (max-width: ", ") {\n    grid-column: 1 / 5;\n  }\n  @media screen and (max-width: ", ") {\n    grid-column: 1 / 4;\n    flex-wrap: wrap;\n  }\n"], ["\n  width: 100%;\n  padding: 0 1.875rem;\n  grid-column: 1 / 6;\n  @media screen and (max-width: ", ") {\n    grid-column: 1 / 5;\n  }\n  @media screen and (max-width: ", ") {\n    grid-column: 1 / 4;\n    flex-wrap: wrap;\n  }\n"])), key('breakpoints.medium', '1720px'), key('breakpoints.medium', '1280px'));
var OuterWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  grid-area: header;\n  padding: 0 1.875rem;\n"], ["\n  width: 100%;\n  grid-area: header;\n  padding: 0 1.875rem;\n"])));
var Column = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 33%;\n  height: 5.375rem;\n  @media screen and (max-width: ", ") {\n    height: 4.375rem;\n  }\n"], ["\n  width: 33%;\n  height: 5.375rem;\n  @media screen and (max-width: ", ") {\n    height: 4.375rem;\n  }\n"])), key('breakpoints.medium', '1280px'));
var NestedFlex = styled(HorizontalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 1.25rem;\n  justify-content: flex-start;\n  @media screen and (max-width: ", ") {\n    margin-top: 0.25rem;\n  }\n"], ["\n  margin-top: 1.25rem;\n  justify-content: flex-start;\n  @media screen and (max-width: ", ") {\n    margin-top: 0.25rem;\n  }\n"])), key('breakpoints.medium', '1280px'));
var HeadlineWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  height: 100%;\n  justify-content: center;\n  display: flex;\n"], ["\n  align-items: center;\n  height: 100%;\n  justify-content: center;\n  display: flex;\n"])));
var CenteredH2 = styled(H2)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  text-align: center;\n  margin: 0;\n  @media screen and (max-width: ", ") {\n    margin-bottom: 0;\n  }\n"], ["\n  text-align: center;\n  margin: 0;\n  @media screen and (max-width: ", ") {\n    margin-bottom: 0;\n  }\n"])), key('breakpoints.medium', '1280px'));
var GoBackWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 0.1875rem;\n"], ["\n  margin-top: 0.1875rem;\n"])));
var SearchMenu = function (_a) {
    var checkIfTabIsActive = _a.checkIfTabIsActive, tabs = _a.tabs, primaryCategoryId = _a.primaryCategoryId;
    var history = useHistory();
    var location = useLocation();
    var searchArticle = function (searchValue) {
        var params = {
            search: searchValue,
            primaryCategory: primaryCategoryId,
        };
        history.push("/results?" + stringify(params, { arrayFormat: 'bracket' }));
    };
    var backfunc = function () {
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Wrapper, { children: [_jsx(Column, { children: _jsx(NestedFlex, { children: primaryCategoryId && (_jsx(GoBackWrapper, { children: _jsx(NavIcon, { icon: 'iconBack', onClick: function () { return history.location.pathname.split('/')[1] === 'search' ? history.push('/') : history.push('/'); } }, void 0) }, void 0)) }, void 0) }, void 0), _jsx(Column, { children: _jsx(HeadlineWrapper, { children: _jsx(CenteredH2, { children: _jsx(FormattedMessage, { id: 'stock.headline', defaultMessage: 'Alle Artikel anzeigen' }, void 0) }, void 0) }, void 0) }, void 0), _jsx(Column, { children: false && _jsx(TabMenu, { checkIfTabIsActive: checkIfTabIsActive, tabs: tabs }, void 0) }, void 0)] }, void 0), _jsx("div", __assign({ style: { padding: '0 1.875rem' } }, { children: _jsx(DropDownHandler, { endPoint: 'category' }, void 0) }), void 0)] }, void 0));
};
export default SearchMenu;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
