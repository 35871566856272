var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { useEffect, useCallback } from 'react';
// utils
import styled from 'styled-components';
// types
// components
import ListLayout from 'templates/ListLayout';
import CallConfirm, { CancelConfirm } from 'organisms/CallConfirm';
import moment from 'moment';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  padding-top: 8.75rem;\n  > div {\n    max-width: 62.5rem;\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  padding-top: 8.75rem;\n  > div {\n    max-width: 62.5rem;\n    width: 100%;\n  }\n"])));
var Support = function (props) {
    var updateSession = props.updateSession, watchlist = props.watchlist, updateWatchlistItem = props.updateWatchlistItem, getDuration = props.getDuration;
    var confirmCall = useCallback(function () {
        var now = moment().format();
        updateSession({ serviceCallAt: now });
    }, [updateSession]);
    var confirmCancel = useCallback(function () {
        updateSession({ serviceCallAt: null });
        var selectedWatchlistItems = watchlist.filter(function (item) { return item.selected === true; });
        var deselectedWatchlistItems = watchlist.filter(function (item) { return item.selected === false; });
        selectedWatchlistItems.forEach(function (item) {
            updateWatchlistItem({ usage: nextStateValue }, item.id);
        });
        deselectedWatchlistItems.forEach(function (item) {
            updateWatchlistItem({ usage: initialStateValue }, item.id);
        });
    }, [updateSession, watchlist, updateWatchlistItem]);
    useEffect(function () {
        getDuration();
    }, [getDuration]);
    var session = props.session;
    return (_jsx(ListLayout, __assign({}, props, { children: _jsx(Wrapper, { children: Boolean(session.serviceCallAt) ? (_jsx(CancelConfirm, { confirm: confirmCancel, disableCancel: true }, void 0)) : (_jsx(CallConfirm, { confirm: confirmCall, disableCancel: true }, void 0)) }, void 0) }), void 0));
};
export default Support;
var templateObject_1;
