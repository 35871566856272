var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from "react";
// utils
import styled from "styled-components";
import { key, palette } from "@shared/utils/styled";
import { getImageUrl } from "@shared/utils/images";
// components
import Slider from "@shared/organisms/Slider";
import AbsoluteContainer from "@shared/atoms/AbsoluteContainer";
import { HideLarge, HideMediumDown } from "@shared/atoms/VisibilityUtils";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      display: grid;\n      grid-auto-rows: max-content;\n      grid-gap: 0.625rem;\n      grid-template-areas: 'header' 'footer';\n      min-height: 0;\n      min-width: 0;\n      overflow: hidden;\n      grid-column: 1 / 3;\n      grid-template-rows: max-content;\n      @media screen and (min-width: ", ") {\n        grid-column: unset;\n      }\n      @media screen and (min-width: ", ") {\n        grid-gap: 1.25rem;\n        grid-template-areas: unset;\n        grid-template-columns: 6.25rem 1fr;\n      }\n    "], ["\n      display: grid;\n      grid-auto-rows: max-content;\n      grid-gap: 0.625rem;\n      grid-template-areas: 'header' 'footer';\n      min-height: 0;\n      min-width: 0;\n      overflow: hidden;\n      grid-column: 1 / 3;\n      grid-template-rows: max-content;\n      @media screen and (min-width: ", ") {\n        grid-column: unset;\n      }\n      @media screen and (min-width: ", ") {\n        grid-gap: 1.25rem;\n        grid-template-areas: unset;\n        grid-template-columns: 6.25rem 1fr;\n      }\n    "])), key("breakpoints.medium", "1280px"), key("breakpoints.medium", "1720px"));
var ImageList = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      grid-area: footer;\n      height: 6.25rem;\n      overflow: hidden;\n      position: relative;\n      width: 100%;\n\n      @media screen and (min-width: ", ") {\n        display: block;\n        grid-area: unset;\n        height: 100%;\n        width: 6.25rem;\n      }\n    "], ["\n      grid-area: footer;\n      height: 6.25rem;\n      overflow: hidden;\n      position: relative;\n      width: 100%;\n\n      @media screen and (min-width: ", ") {\n        display: block;\n        grid-area: unset;\n        height: 100%;\n        width: 6.25rem;\n      }\n    "])), key("breakpoints.medium", "1720px"));
var MainImageWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      grid-area: header;\n      @media screen and (min-width: ", ") {\n        grid-area: unset;\n        height: auto;\n      }\n    "], ["\n      grid-area: header;\n      @media screen and (min-width: ", ") {\n        grid-area: unset;\n        height: auto;\n      }\n    "])), key("breakpoints.medium", "1720px"));
var MainImage = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      border: 0.0625rem solid ", ";\n      font-size: 0; // Fix for code whitespaces\n      position: relative;\n      text-align: center;\n\n      img {\n        max-height: 15.625rem;\n        width: auto;\n        @media screen and (min-width: ", ") {\n          max-height: none;\n          width: 100%;\n        }\n      }\n    "], ["\n      border: 0.0625rem solid ", ";\n      font-size: 0; // Fix for code whitespaces\n      position: relative;\n      text-align: center;\n\n      img {\n        max-height: 15.625rem;\n        width: auto;\n        @media screen and (min-width: ", ") {\n          max-height: none;\n          width: 100%;\n        }\n      }\n    "])), palette("border"), key("breakpoints.medium", "1280px"));
var ListImageWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      > img {\n        border-color: ", ";\n        border: ", ";\n      }\n      \n      .swiper-slide {\n        max-height: 100px;\n        width: 100%;\n      }\n\n      height: 100%;\n      width: 100%;\n    "], ["\n      > img {\n        border-color: ", ";\n        border: ", ";\n      }\n      \n      .swiper-slide {\n        max-height: 100px;\n        width: 100%;\n      }\n\n      height: 100%;\n      width: 100%;\n    "])), function (props) { return props.active ? palette("highlightText") : "transparent"; }, function (props) { return (props.active ? "0.0625rem solid" : "none"); });
var ImageSlide = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      height: 6.125rem;\n      width: 6.125rem;\n    "], ["\n      height: 6.125rem;\n      width: 6.125rem;\n    "])));
var ProductImage = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      cursor: pointer;\n      max-width: 100%;\n      width: 100%;\n    "], ["\n      cursor: pointer;\n      max-width: 100%;\n      width: 100%;\n    "])));
var ArticleImages = /** @class */ (function (_super) {
    __extends(ArticleImages, _super);
    function ArticleImages(props) {
        var _this = _super.call(this, props) || this;
        _this.initImages = function (properties) {
            var _a, _b;
            return ({
                currentImage: ((_a = properties === null || properties === void 0 ? void 0 : properties.article.images) === null || _a === void 0 ? void 0 : _a.large) || ((_b = properties === null || properties === void 0 ? void 0 : properties.article.images.main) === null || _b === void 0 ? void 0 : _b.large),
                currentIndex: "main",
                fullScreen: false
            });
        };
        _this.state = _this.initImages(props);
        _this.toggleImage = function (url, index) {
            _this.setState({
                currentImage: url,
                currentIndex: index
            });
        };
        _this.getInitialSlide = function () {
            if (_this.state.currentIndex === "main") {
                return 0;
            }
            return parseInt(_this.state.currentIndex, 10) + 1;
        };
        return _this;
    }
    ArticleImages.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.article.id !== nextProps.article.id) {
            this.setState(this.initImages(nextProps));
        }
    };
    // unfortunatly attributes like direction can not be changed by using
    // react-id-sliders build in breakpoints this is why whe have to render 2 sliders
    ArticleImages.prototype.renderLargeSlider = function () {
        var _this = this;
        var _a, _b, _c;
        var _d = this.props, article = _d.article, nested = _d.nested;
        var currentIndex = this.state.currentIndex;
        var config = {
            loop: false,
            slidesPerView: 5,
            direction: "vertical",
            spaceBetween: 20,
            nested: nested
        };
        var mainSlide = (_jsx(ImageSlide, { children: _jsx(ListImageWrapper, __assign({ active: currentIndex === "main", onClick: function () { var _a; return _this.toggleImage(article.images.large || ((_a = article.images.main) === null || _a === void 0 ? void 0 : _a.large), "main"); } }, { children: _jsx(ProductImage, { src: article.images.large || ((_a = article.images.main) === null || _a === void 0 ? void 0 : _a.large) }, void 0) }), void 0) }, void 0));
        var slides = (_c = (_b = article.images) === null || _b === void 0 ? void 0 : _b.detail) === null || _c === void 0 ? void 0 : _c.map(function (image, idx) {
            return image.thumbnail && (_jsx(ImageSlide, { children: _jsx(ListImageWrapper, __assign({ active: currentIndex === idx, onClick: function () {
                        return _this.toggleImage(getImageUrl(image, "large"), idx);
                    } }, { children: _jsx(ProductImage, { src: image.thumbnail }, void 0) }), void 0) }, image.thumbnail));
        });
        var allSlides = [mainSlide].concat(slides);
        return (_jsx(HideMediumDown, { children: _jsx(Slider, __assign({ config: config }, { children: allSlides }), void 0) }, void 0));
    };
    ArticleImages.prototype.renderSmallerSlider = function () {
        var _this = this;
        var _a, _b, _c;
        var _d = this.props, article = _d.article, nested = _d.nested;
        var currentIndex = this.state.currentIndex;
        var config = {
            loop: false,
            containerclass: "image-list slider-container",
            slidesPerView: "auto"
        };
        if (window.innerWidth > 1279) {
            config = __assign({ direction: "vertical" }, config);
        }
        else {
            config = __assign(__assign({}, config), { slidesPerView: 6 });
        }
        var slides = (_b = (_a = article.images) === null || _a === void 0 ? void 0 : _a.detail) === null || _b === void 0 ? void 0 : _b.map(function (image, idx) { return (_jsx(ImageSlide, { children: _jsx(ListImageWrapper, __assign({ active: currentIndex === idx, onClick: function () { return _this.toggleImage(image.large, idx); } }, { children: _jsx(ProductImage, { src: image.thumbnail }, void 0) }), void 0) }, image.thumbnail)); });
        var mainSlide = (_jsx(ImageSlide, { children: _jsx(ListImageWrapper, __assign({ active: currentIndex === "main", onClick: function () { var _a; return _this.toggleImage(article.images.large || ((_a = article.images.main) === null || _a === void 0 ? void 0 : _a.large), "main"); } }, { children: _jsx(ProductImage, { src: article.images.large || ((_c = article.images.main) === null || _c === void 0 ? void 0 : _c.large) }, void 0) }), void 0) }, void 0));
        var allSlides = [mainSlide].concat(slides);
        return (_jsx(HideLarge, { children: slides && _jsx(Slider, __assign({ config: config }, { children: allSlides }), void 0) }, void 0));
    };
    ArticleImages.prototype.render = function () {
        var _this = this;
        var _a = this.props, article = _a.article, openImagesModal = _a.openImagesModal, hidden = _a.hidden;
        var currentImage = this.state.currentImage;
        if (hidden) {
            return _jsx(_Fragment, {}, void 0);
        }
        return (_jsxs(Wrapper, { children: [_jsx(ImageList, { children: _jsxs(AbsoluteContainer, { children: [this.renderLargeSlider(), this.renderSmallerSlider()] }, void 0) }, void 0), _jsx(MainImageWrapper, { children: _jsx(MainImage, __assign({ "data-cy-id": "article-main-image", onClick: function () { return openImagesModal(article, _this.getInitialSlide()); } }, { children: _jsx(ProductImage, { src: currentImage }, void 0) }), void 0) }, void 0)] }, void 0));
    };
    return ArticleImages;
}(Component));
export default ArticleImages;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
