import { combineReducers } from 'redux-immutable';
import article from './article/reducer';
import articleStack from './articleStack/reducer';
import category from './category/reducer';
import connection from './connection/reducer';
import collection from './collection/reducer';
import customer from './customer/reducer';
import device from './device/reducer';
import filterReducer from './filterSelections/filterReducer';
import furniture from './furniture/reducer';
import gender from './gender/reducer';
import hiddenWatchlist from './hiddenWatchlist/reducer';
import identity from './identity/reducer';
import intl from './intl/reducer';
import inspiration from './inspiration/reducer';
import metric from './metric/reducer';
import primaryCategory from './primaryCategory/reducer';
import session from './session/reducer';
import store from './store/reducer';
import sort from './sort/reducer';
import teaser from './teaser/reducer';
import theme from './theme/reducer';
import watchlist from './watchlist/reducer';
import error from './error/reducer';
import giftcard from './giftcard';
export default combineReducers({
    article: article,
    articleStack: articleStack,
    category: category,
    connection: connection,
    collection: collection,
    customer: customer,
    device: device,
    furniture: furniture,
    gender: gender,
    hiddenWatchlist: hiddenWatchlist,
    identity: identity,
    intl: intl,
    inspiration: inspiration,
    metric: metric,
    primaryCategory: primaryCategory,
    session: session,
    store: store,
    sort: sort,
    teaser: teaser,
    theme: theme,
    watchlist: watchlist,
    error: error,
    giftcard: giftcard,
    filterReducer: filterReducer,
});
