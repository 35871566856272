var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { getImageUrl } from '@shared/utils/images';
import { palette, fontWeight, key } from '@shared/utils/styled';
import styled from 'styled-components';
import { defineMessages, FormattedMessage } from 'react-intl';
import { getPriceString, hasDiscounts } from '@shared/utils/currency';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import VerticalFlex from '@shared/atoms/VerticalFlex';
import CheckButton from '@shared/molecules/CheckButton';
import SizeSelect from 'molecules/SizeSelect';
import PriceTag from '@shared/molecules/PriceTag';
import DiscountBadgeSmall from 'molecules/DiscountBadgeSmall';
var ItemWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 0.0625rem;\n  grid-template-columns: 3.75rem 17rem 1fr;\n  margin-bottom: 0.625rem;\n  min-height: 8.375rem;\n  overflow: hidden;\n  width: 100%;\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  @media screen and (min-width: ", ") {\n    grid-template-columns: 3.75rem 23rem 1fr;\n  }\n"], ["\n  display: grid;\n  grid-gap: 0.0625rem;\n  grid-template-columns: 3.75rem 17rem 1fr;\n  margin-bottom: 0.625rem;\n  min-height: 8.375rem;\n  overflow: hidden;\n  width: 100%;\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  @media screen and (min-width: ", ") {\n    grid-template-columns: 3.75rem 23rem 1fr;\n  }\n"])), key('breakpoints.medium', '1280px'));
var ItemInnerWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  opacity: ", ";\n  padding: 0.3125rem 0.5rem;\n  position: relative;\n  width: 100%;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  opacity: ", ";\n  padding: 0.3125rem 0.5rem;\n  position: relative;\n  width: 100%;\n"])), palette('white'), function (props) { return (!props.active ? 0.5 : 1); });
var ThumbnailImage = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border: 0.0625rem solid ", ";\n  flex: 2;\n  margin-right: 0.3125rem;\n  max-width: 35%;\n  font-size: 0;\n  position: relative;\n  img {\n    width: 100%;\n    max-width: 100%;\n  }\n"], ["\n  background: ", ";\n  border: 0.0625rem solid ", ";\n  flex: 2;\n  margin-right: 0.3125rem;\n  max-width: 35%;\n  font-size: 0;\n  position: relative;\n  img {\n    width: 100%;\n    max-width: 100%;\n  }\n"])), palette('white'), palette('border'));
var InfoWrapper = styled(VerticalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  flex: 3;\n  font-weight: ", ";\n  justify-content: center;\n"], ["\n  color: ", ";\n  display: flex;\n  flex: 3;\n  font-weight: ", ";\n  justify-content: center;\n"])), palette('text'), fontWeight('light'));
var Selections = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  opacity: ", ";\n  width: 100%;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  opacity: ", ";\n  width: 100%;\n"])), palette('white'), function (props) { return (!props.active ? 0.5 : 1); });
var SizeSelection = styled(VerticalFlex)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0.3125rem solid\n    ", ";\n  justify-content: center;\n  padding-left: 1rem;\n  opacity: ", ";\n"], ["\n  background-color: ", ";\n  border: 0.3125rem solid\n    ", ";\n  justify-content: center;\n  padding-left: 1rem;\n  opacity: ", ";\n"])), palette('white'), function (props) {
    return props.hasSelection ? 'transparent' : palette('primaryHighlight');
}, function (props) { return (!props.active ? 0.5 : 1); });
var Bold = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var Tag = styled.span(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: ", ";\n"], ["\n  color: ", ";\n  text-decoration: ", ";\n"])), function (props) { return (props.discount ? '#b52025' : 'inherit'); }, function (props) { return (props.crossed ? 'line-through' : 'none'); });
var SelectedSize = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-item: center;\n  display: flex;\n  justify-content: center;\n  width: 21.875rem;\n"], ["\n  align-item: center;\n  display: flex;\n  justify-content: center;\n  width: 21.875rem;\n"])));
var SelectedSizeInfo = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 0.1875rem;\n  font-weight: ", ";\n  color: white;\n  padding: 0.75rem 0.9375rem;\n"], ["\n  background-color: ", ";\n  border-radius: 0.1875rem;\n  font-weight: ", ";\n  color: white;\n  padding: 0.75rem 0.9375rem;\n"])), palette('primaryHighlight'), fontWeight('default'));
var WatchlistExpandedItem = /** @class */ (function (_super) {
    __extends(WatchlistExpandedItem, _super);
    function WatchlistExpandedItem(props) {
        var _this = _super.call(this, props) || this;
        _this.getCurrentPrice = function () {
            var item = _this.props.item;
            var article = item.article;
            var prices = article.prices, targetPrices = article.targetPrices;
            if (item.sizeId) {
                var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
                if (size) {
                    if (size.price === size.targetPrice) {
                        return _jsx(Tag, { children: getPriceString(size.price) }, void 0);
                    }
                    return (_jsxs("span", { children: [_jsx(Tag, __assign({ crossed: true }, { children: getPriceString(size.targetPrice) }), void 0), ' ', _jsx(Tag, __assign({ discount: true }, { children: getPriceString(size.price) }), void 0), ' '] }, void 0));
                }
            }
            return _jsx(PriceTag, { prices: prices, targetPrices: targetPrices }, void 0);
        };
        _this.isDiscounted = function () {
            var item = _this.props.item;
            if (!item.sizeId) {
                return hasDiscounts(item.article.prices, item.article.targetPrices);
            }
            var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
            if (size) {
                return size.price < size.targetPrice;
            }
            return false;
        };
        _this.renderSizeSelection = function () {
            var _a = _this.props, item = _a.item, updateWatchlistItem = _a.updateWatchlistItem, disableSizeSelect = _a.disableSizeSelect, watchlist = _a.watchlist;
            var article = item.article;
            var currentSize = item.sizeId
                ? Boolean(article.sizes) &&
                    article.sizes.find(function (si) { return si.sizeId === item.sizeId; })
                : null;
            if (article.isAccessoire && article.sizes.length === 0) {
                return (_jsx(SelectedSize, { children: _jsx(FormattedMessage, { defaultMessage: 'eine Gr\u00F6\u00DFe', id: 'checkout.sizeSelect.oneSize' }, void 0) }, void 0));
            }
            return disableSizeSelect && currentSize ? (_jsx(SelectedSize, { children: _jsxs(SelectedSizeInfo, { children: [_jsx(FormattedMessage, { id: 'checkout.sizeSelect.selected', defaultMessage: 'Gr\u00F6\u00DFe:' }, void 0), ' ', currentSize.labelSize] }, void 0) }, void 0)) : (_jsx(SizeSelect, __assign({ currentArticleId: article.id, watchlist: watchlist, current: item.sizeId, clickHandler: function (size) {
                    return updateWatchlistItem({ sizeId: size.sizeId }, item.id);
                }, sizes: article.sizes }, defineMessages({
                customHeading: {
                    id: 'checkout.sizeSelect.notSelected',
                    defaultMessage: 'Größe bitte wählen:',
                },
            })), void 0));
        };
        _this.toggleItemSelection = function () {
            var item = _this.props.item;
            _this.props.updateWatchlistItem({ selected: !item.selected }, item.id);
        };
        return _this;
    }
    WatchlistExpandedItem.prototype.render = function () {
        var item = this.props.item;
        var active = item.selected;
        var price = this.getCurrentPrice();
        var article = item.article;
        return (_jsxs(ItemWrapper, { children: [_jsx(Selections, __assign({ active: active }, { children: _jsx(CheckButton, { checked: active, onChange: this.toggleItemSelection }, void 0) }), void 0), _jsxs(ItemInnerWrapper, __assign({ active: active }, { children: [_jsxs(ThumbnailImage, { children: [_jsx("img", { src: getImageUrl(article.images.main), alt: '' }, void 0), this.isDiscounted() && _jsx(DiscountBadgeSmall, {}, void 0)] }, void 0), _jsxs(InfoWrapper, { children: [article.brand && article.brand.name && (_jsx(Bold, { children: article.brand.name }, void 0)), article.color && article.color, price && _jsx(Bold, { children: price }, void 0)] }, void 0)] }), void 0), _jsx(SizeSelection, __assign({ active: active, hasSelection: Boolean(item.sizeId) ||
                        (article.isAccessoire && article.sizes.length === 0) }, { children: this.renderSizeSelection() }), void 0)] }, item.id));
    };
    return WatchlistExpandedItem;
}(Component));
export default WatchlistExpandedItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
