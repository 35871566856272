var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { StyledArticleItem, StyledBrandName, StyledContent, StyledImage, StyledImageWrapper, StyledInformation, StyledPriceTag } from "./style";
import SearchResultPrice from "../../../terminal/searchResultPrice/components/SearchResultPrice";
var ArticleItem = /** @class */ (function (_super) {
    __extends(ArticleItem, _super);
    function ArticleItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // This is added as work around to allow translations on ids with variables not static
    ArticleItem.prototype.FormattedMessageFixed = function (props) {
        return _jsx(FormattedMessage, __assign({}, props), void 0);
    };
    ArticleItem.prototype.render = function () {
        var _a, _b, _c, _d;
        var _e = this.props, article = _e.article, createWatchlistItem = _e.createWatchlistItem, deleteWatchlistItem = _e.deleteWatchlistItem, onClick = _e.onClick, watchlistItem = _e.watchlistItem;
        var brand = article.brand, color = article.color, displayName = article.displayName, articleNumber = article.articleNumber;
        return (_jsx(StyledArticleItem, __assign({ "data-cy-id": "article-list-item", id: article.id }, { children: _jsxs(StyledContent, __assign({ onClick: onClick }, { children: [_jsx(StyledImageWrapper, { children: _jsx(StyledImage, { children: _jsx("img", { src: (_a = article === null || article === void 0 ? void 0 : article.images) === null || _a === void 0 ? void 0 : _a.medium }, void 0) }, void 0) }, void 0), _jsxs(StyledInformation, { children: [_jsx(StyledBrandName, { children: ((_b = article === null || article === void 0 ? void 0 : article.v2) === null || _b === void 0 ? void 0 : _b.brand) || "-" }, void 0), _jsx("div", { children: ((_c = article === null || article === void 0 ? void 0 : article.v2) === null || _c === void 0 ? void 0 : _c.color) || "-" }, void 0), _jsx("div", { children: ((_d = article === null || article === void 0 ? void 0 : article.v2) === null || _d === void 0 ? void 0 : _d.material) || "-" }, void 0), _jsxs("div", { children: ["Art.Nr. ", articleNumber || "-"] }, void 0), _jsx(StyledPriceTag, { children: _jsx(SearchResultPrice, { price: article === null || article === void 0 ? void 0 : article.price }, void 0) }, void 0)] }, void 0)] }), void 0) }), void 0));
    };
    return ArticleItem;
}(Component));
export default ArticleItem;
