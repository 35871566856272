var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { checkWebConnection } from '@store/connection/actions';
import { addError } from '@store/error/actions';
import { sessionNotFoundError } from '@shared/utils/regexChecks';
/**
 * This handle the errors during the API calls, like no-connection,
 * server-not-reachable, unauthorized, ...
 *
 * @param {object} error - The error returned from the API call.
 * @param {action} complete - Action to be call on requiren action complete.
 * @param {action} original  - The original action.
 * @param  {...any} params - Required params for the origin action.
 */
var errorHandler = function (error, complete, original) {
    var params = [];
    for (var _i = 3; _i < arguments.length; _i++) {
        params[_i - 3] = arguments[_i];
    }
    if (!Boolean(error.response)) {
        return checkWebConnection.apply(void 0, __spreadArray([original], params, false));
    }
    if (error.response.status === 401) {
        complete(error);
        return addError({
            message: 'Nicht authorisiert! Die UUID des Gerätes ist nicht korrekt oder es wurde keine angegeben',
            showError: true,
        });
    }
    if (error.response.status === 404 &&
        sessionNotFoundError(error.response.statusText)) {
        complete(error);
        return addError({
            message: 'Sitzung wurde nicht gefunden! Es wird eine neue generiert',
            showError: false,
        });
    }
    return complete(error);
};
export default errorHandler;
