var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
import { useField } from 'react-final-form';
import styled from 'styled-components';
import H1 from '@shared/atoms/H1';
import Icon from '@shared/atoms/Icon';
export var StyledIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  height: auto;\n"], ["\n  display: flex;\n  height: auto;\n"])));
export var StyledScanSearchFormWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  padding: 1.875rem 1.25rem;\n  justify-content: center;\n"], ["\n  align-items: center;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  padding: 1.875rem 1.25rem;\n  justify-content: center;\n"])));
export var StyledInputWrapper = styled.form(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: 35rem;\n"], ["\n  display: flex;\n  width: 35rem;\n"])));
export var StyledInputContainer = styled.input(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: none;\n  background-color: #efefef;\n  flex: 1;\n  font-size: ", ";\n  height: 2.8125rem;\n  padding: 0 0.625rem;\n"], ["\n  border: none;\n  background-color: #efefef;\n  flex: 1;\n  font-size: ", ";\n  height: 2.8125rem;\n  padding: 0 0.625rem;\n"])), fontSize('small'));
var ErrorText = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 5px;\n  color: #e74c3c;\n  font-size: 15px;\n  padding: 0px 4px;\n  min-height: 24px;\n"], ["\n  margin-top: 5px;\n  color: #e74c3c;\n  font-size: 15px;\n  padding: 0px 4px;\n  min-height: 24px;\n"])));
export function StyledInput(props) {
    var _a = useField(props.name, {
        initialValue: props.initialValue,
        validate: props.validate,
    }), input = _a.input, _b = _a.meta, error = _b.error, touched = _b.touched;
    var inputProps = __assign(__assign(__assign({}, props), { error: touched && error && true }), input);
    return (_jsx(Fragment, { children: _jsx(StyledInputContainer, __assign({}, inputProps), void 0) }, void 0));
}
export var StyledSubmitButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  display: flex;\n  justify-content: center;\n  cursor: pointer;\n  align-items: center;\n  /*width: 2.8125rem;*/\n"], ["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  display: flex;\n  justify-content: center;\n  cursor: pointer;\n  align-items: center;\n  /*width: 2.8125rem;*/\n"])), palette('primaryHighlight'), palette('white'));
export var StyledHeadline = styled(H1)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin: 0 0 1.25rem 0;\n"], ["\n  font-weight: ", ";\n  margin: 0 0 1.25rem 0;\n"])), fontWeight('bold'));
export var StyledGiftcardSearchError = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: red;\n  font-size: ", ";\n  margin-top: 0.9375rem;\n  max-width: 37.5rem;\n  min-height: 1rem;\n  width: 100%;\n"], ["\n  color: red;\n  font-size: ", ";\n  margin-top: 0.9375rem;\n  max-width: 37.5rem;\n  min-height: 1rem;\n  width: 100%;\n"])), fontSize('small'));
export var StyledGiftcardBalance = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin: 0 0 1.5rem 0;\n  max-width: 100%;\n  min-height: 1rem;\n  display: block;\n  width: 100%;\n  font-size: 2rem;\n  text-align: center;\n"], ["\n  margin: 0 0 1.5rem 0;\n  max-width: 100%;\n  min-height: 1rem;\n  display: block;\n  width: 100%;\n  font-size: 2rem;\n  text-align: center;\n"])));
export var StyledGiftcardBalanceAmount = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
