var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import { palette, fontWeight, fontSize } from '@shared/utils/styled';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
// components
import { Link } from 'react-router-dom';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  font-size: 0;\n  grid-gap: 0.625rem;\n  grid-template-columns: 1fr 1fr 1fr;\n  margin-top: 4.375rem;\n  padding: 0 6%;\n  position: relative;\n"], ["\n  display: grid;\n  font-size: 0;\n  grid-gap: 0.625rem;\n  grid-template-columns: 1fr 1fr 1fr;\n  margin-top: 4.375rem;\n  padding: 0 6%;\n  position: relative;\n"])));
var Banner = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  left: 30%;\n  position: absolute;\n  top: -1.75rem;\n  width: 20%;\n"], ["\n  left: 30%;\n  position: absolute;\n  top: -1.75rem;\n  width: 20%;\n"])));
var Tile = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  line-height: 1.25rem;\n  text-align: center;\n  text-decoration: none;\n  width: 100%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  line-height: 1.25rem;\n  text-align: center;\n  text-decoration: none;\n  width: 100%;\n"])), palette('text'), fontSize('default'));
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border: 0.625rem solid ", ";\n  margin-bottom: 0.625rem;\n  width: 100%;\n"], ["\n  border: 0.625rem solid ", ";\n  margin-bottom: 0.625rem;\n  width: 100%;\n"])), palette('white'));
var Descripction = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var TilesSmall = /** @class */ (function (_super) {
    __extends(TilesSmall, _super);
    function TilesSmall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TilesSmall.prototype.render = function () {
        var _a = this.props, tiles = _a.tiles, bannerUrl = _a.bannerUrl;
        return (_jsxs(Wrapper, { children: [sortBy(tiles, 'sort').map(function (tile) { return (_jsxs(Tile, __assign({ to: tile.targetUrl }, { children: [_jsx(Image, { src: tile.imageUrl }, void 0), tile.headline, _jsx(Descripction, { children: tile.description }, void 0)] }), "" + tile.headline + tile.targetUrl + tile.imageUrl)); }), bannerUrl && _jsx(Banner, { src: bannerUrl }, void 0)] }, void 0));
    };
    return TilesSmall;
}(Component));
export default TilesSmall;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
