import { createSelector } from 'reselect';
export var selectTeaserState = function (state) { return state.get('teaser'); };
export var selectTeasers = createSelector(selectTeaserState, function (teaserState) {
    return teaserState.get('data');
});
export var selectSortedTeasers = createSelector(selectTeasers, function (teasers) {
    return teasers.sortBy(function (teaser) { return teaser.get('order'); });
});
export var selectLoading = createSelector(selectTeaserState, function (teaserState) {
    return teaserState.get('loading');
});
export var selectLoaded = createSelector(selectTeaserState, function (teaserState) {
    return teaserState.get('loaded');
});
