var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from "react";
import { palette } from "@shared/utils/styled";
import styled from "styled-components";
import Icon from "@shared/atoms/Icon";
import Slider from "@shared/organisms/Slider";
import { getImageUrl } from "@shared/utils/images";
var FullScreen = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  bottom: 0;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 9999;\n"], ["\n  background: ", ";\n  bottom: 0;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 9999;\n"])), palette("transparentBlack"));
var FullWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  position: relative;\n  width: 100%;\n"], ["\n  height: 100%;\n  position: relative;\n  width: 100%;\n"])));
var Close = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  cursor: pointer;\n  height: 1rem;\n  position: absolute;\n  right: 1.25rem;\n  top: 1.25rem;\n  width: 1rem;\n  z-index: 55;\n"], ["\n  cursor: pointer;\n  height: 1rem;\n  position: absolute;\n  right: 1.25rem;\n  top: 1.25rem;\n  width: 1rem;\n  z-index: 55;\n"])));
var Slide = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  text-decoration: none;\n"], ["\n  align-items: center;\n  cursor: pointer;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  text-decoration: none;\n"])));
var SlideInnerWrap = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  flex: 0 1 auto;\n  height: 90%;\n  max-width: 90%;\n  padding: 3.125rem;\n  position: relative;\n  width: auto;\n"], ["\n  background-color: ", ";\n  flex: 0 1 auto;\n  height: 90%;\n  max-width: 90%;\n  padding: 3.125rem;\n  position: relative;\n  width: auto;\n"])), palette("white"));
var SingleWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"])));
var SlideImg = styled.img(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  display: block;\n  height: 100%;\n  max-width: 100%;\n"], ["\n  display: block;\n  height: 100%;\n  max-width: 100%;\n"])));
var ArticleImagesModal = /** @class */ (function (_super) {
    __extends(ArticleImagesModal, _super);
    function ArticleImagesModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ArticleImagesModal.prototype.render = function () {
        var _a;
        var _b = this.props, article = _b.article, toggleAction = _b.toggleAction, index = _b.index;
        var mainSlide = (_jsx(Slide, { children: _jsxs(SlideInnerWrap, { children: [_jsx(SlideImg, { src: getImageUrl(article.images.main, "large") }, void 0), _jsx(Close, __assign({ onClick: toggleAction }, { children: _jsx(Icon, { icon: "closex", width: 16 }, void 0) }), void 0)] }, void 0) }, (_a = article.images.main) === null || _a === void 0 ? void 0 : _a.large));
        var slides = article.images.detail &&
            article.images.detail.map(function (img) { return (_jsx(Slide, { children: _jsxs(SlideInnerWrap, { children: [_jsx(SlideImg, { src: img.large }, void 0), _jsx(Close, __assign({ onClick: toggleAction }, { children: _jsx(Icon, { icon: "closex", width: 16 }, void 0) }), void 0)] }, void 0) }, img.large)); });
        var allSlides = [mainSlide].concat(slides);
        return (_jsx(FullScreen, { children: _jsx(FullWrapper, { children: article.images.detail && article.images.detail.length > 0 ? (_jsx(Slider, __assign({ config: {
                        loop: true,
                        initialSlide: index
                    } }, { children: allSlides }), void 0)) : (_jsx(SingleWrap, { children: _jsx(Slide, { children: _jsxs(SlideInnerWrap, { children: [_jsx(SlideImg, { src: getImageUrl(article.images.main) }, void 0), _jsx(Close, __assign({ onClick: toggleAction }, { children: _jsx(Icon, { icon: "closex", width: 16 }, void 0) }), void 0)] }, void 0) }, void 0) }, void 0)) }, void 0) }, void 0));
    };
    return ArticleImagesModal;
}(Component));
export default ArticleImagesModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
