var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import history from '@store/history';
import store from 'store';
import { io } from 'socket.io-client';
import errorHandler from 'services/connection';
import { resetGender } from '@store/gender/actions';
import { callSessionComplete, callSession as callSessionAction, checkout as checkoutAction, checkoutComplete, createSessionComplete, createSession as createSessionAction, sendOrderComplete, sendOrder as sendOrderAction, shareSessionComplete, shareSession as shareSessionAction, deleteSessionComplete, deleteSession as deleteSessionAction, updateSessionComplete, updateSession as updateSessionAction, archiveSession as archiveSessionAction, archiveSessionComplete, } from './actions';
import { resetWatchlist, updateWatchlistItem } from '../watchlist/actions';
import { selectThemeState } from '@store/theme/selectors';
var socket = io('wss://sock-tu33eykv3a-ey.a.run.app/', {
    transports: ['websocket'],
});
export function createSession(api, action) {
    var body, themeState, _a, customerId, storeId, error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 6]);
                return [4 /*yield*/, call([api, api.post], 'sessions', action.payload)];
            case 1:
                body = (_b.sent()).body;
                console.log(body, "session");
                return [4 /*yield*/, put(createSessionComplete(fromJS(body)))];
            case 2:
                _b.sent();
                return [4 /*yield*/, select(selectThemeState)];
            case 3:
                themeState = _b.sent();
                _a = themeState.toJS().data, customerId = _a.customerId, storeId = _a.storeId;
                console.log(themeState, "session");
                fireSocketEvent({ customerId: customerId, storeId: storeId });
                return [3 /*break*/, 6];
            case 4:
                error_1 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_1, createSessionComplete, createSessionAction, action.payload))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function shareSession(api, action) {
    var body, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call([api, api.post], "sessions/" + action.meta.id + "/share", action.payload)];
            case 1:
                body = (_a.sent()).body;
                console.log(body, "sesesese");
                return [4 /*yield*/, put(shareSessionComplete(fromJS(body)))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_2 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_2, shareSessionComplete, shareSessionAction, action.payload, action.meta.id))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function deleteSession(api, action) {
    var body, themeState, _a, customerId, storeId, error_3;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 9]);
                return [4 /*yield*/, call([api, api.delete], "sessions/" + action.payload)];
            case 1:
                body = (_b.sent()).body;
                return [4 /*yield*/, put(deleteSessionComplete(fromJS(body)))];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(resetWatchlist())];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(resetGender())];
            case 4:
                _b.sent();
                return [4 /*yield*/, select(selectThemeState)];
            case 5:
                themeState = _b.sent();
                _a = themeState.toJS().data, customerId = _a.customerId, storeId = _a.storeId;
                fireSocketEvent({ customerId: customerId, storeId: storeId });
                return [4 /*yield*/, call(history.push, '/')];
            case 6:
                _b.sent();
                return [3 /*break*/, 9];
            case 7:
                error_3 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_3, deleteSessionComplete, deleteSessionAction, action.payload))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function archiveSession(api, action) {
    var body, themeState, _a, customerId, storeId, error_4;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 7, , 9]);
                return [4 /*yield*/, call([api, api.put], "sessions/" + action.payload + "/archive")];
            case 1:
                body = (_b.sent()).body;
                return [4 /*yield*/, put(archiveSessionComplete(fromJS(body)))];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(resetWatchlist())];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(resetGender())];
            case 4:
                _b.sent();
                return [4 /*yield*/, select(selectThemeState)];
            case 5:
                themeState = _b.sent();
                _a = themeState.toJS().data, customerId = _a.customerId, storeId = _a.storeId;
                fireSocketEvent({ customerId: customerId, storeId: storeId });
                return [4 /*yield*/, call(history.push, '/')];
            case 6:
                _b.sent();
                return [3 /*break*/, 9];
            case 7:
                error_4 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_4, archiveSessionComplete, archiveSessionAction, action.payload))];
            case 8:
                _b.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export function updateSession(api, action) {
    var payload, meta, body, themeState, _a, customerId, storeId, error_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                payload = action.payload, meta = action.meta;
                console.log(payload, "load");
                console.log(meta, "meta");
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                return [4 /*yield*/, call([api, api.put], "sessions/" + meta.id, payload)];
            case 2:
                body = (_b.sent()).body;
                console.log(body);
                return [4 /*yield*/, put(updateSessionComplete(fromJS(body)))];
            case 3:
                _b.sent();
                return [4 /*yield*/, updateWatchlistItem({ usage: 'remember' })];
            case 4:
                _b.sent();
                return [4 /*yield*/, select(selectThemeState)];
            case 5:
                themeState = _b.sent();
                _a = themeState.toJS().data, customerId = _a.customerId, storeId = _a.storeId;
                fireSocketEvent({ customerId: customerId, storeId: storeId });
                return [3 /*break*/, 8];
            case 6:
                error_5 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_5, updateSessionComplete, updateSessionAction, payload, meta.id))];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function callSession(api, action) {
    var payload, body, themeState, _a, customerId, storeId, error_6;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                payload = action.payload;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, call([api, api.put], "sessions/" + payload + "/call")];
            case 2:
                body = (_b.sent()).body;
                return [4 /*yield*/, put(callSessionComplete(fromJS(body)))];
            case 3:
                _b.sent();
                return [4 /*yield*/, select(selectThemeState)];
            case 4:
                themeState = _b.sent();
                _a = themeState.toJS().data, customerId = _a.customerId, storeId = _a.storeId;
                fireSocketEvent({ customerId: customerId, storeId: storeId });
                return [3 /*break*/, 7];
            case 5:
                error_6 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_6, callSessionComplete, callSessionAction, payload))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function checkout(api, action) {
    var body, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call([api, api.post], "sessions/" + action.meta.id + "/checkout", action.payload)];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(checkoutComplete(body))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_7 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_7, checkoutComplete, checkoutAction, action.payload, action.meta.id))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function sendOrder(api, action) {
    var body, error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                action.payload.footId = store.get('footId');
                return [4 /*yield*/, call([api, api.post], "sessions/" + action.meta.id + "/order", action.payload)];
            case 1:
                body = (_a.sent()).body;
                return [4 /*yield*/, put(sendOrderComplete(body))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                error_8 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_8, sendOrderComplete, sendOrderAction, action.payload, action.meta.id))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export function sessionCreateWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('CREATE_SESSION', createSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionDeleteWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('DELETE_SESSION', deleteSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionArchiveWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('ARCHIVE_SESSION', archiveSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionUpdateWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('UPDATE_SESSION', updateSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionShareWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('SHARE_SESSION', shareSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sendOrderWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('SEND_ORDER', sendOrder, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionCallWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('CALL_SESSION', callSession, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function sessionCheckoutWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('CHECKOUT', checkout, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
var fireSocketEvent = function (data, eventTitle) {
    if (eventTitle === void 0) { eventTitle = 'session-behaviour-changed'; }
    console.log("socket is on going");
    socket.emit(eventTitle, data);
};
