var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { getImageUrl } from '@shared/utils/images';
import { palette, fontWeight } from '@shared/utils/styled';
import styled from 'styled-components';
import { getPriceString, hasDiscounts } from '@shared/utils/currency';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import PriceTag from '@shared/molecules/PriceTag';
import VerticalFlex from '@shared/atoms/VerticalFlex';
import Icon from '@shared/atoms/Icon';
import { FormattedMessage } from 'react-intl';
import DiscountBadgeSmall from 'molecules/DiscountBadgeSmall';
var ItemWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-top: 0.3125rem;\n  padding: 0 0.625rem;\n  position: relative;\n"], ["\n  margin-top: 0.3125rem;\n  padding: 0 0.625rem;\n  position: relative;\n"])));
var ItemInnerWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  align-items: center;\n  opacity: ", ";\n  padding: 0.3125rem 0.5rem;\n  position: relative;\n  transition: background-color 0.2s ease;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  align-items: center;\n  opacity: ", ";\n  padding: 0.3125rem 0.5rem;\n  position: relative;\n  transition: background-color 0.2s ease;\n  width: 100%;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('white'), function (props) { return (props.deleting ? 0.5 : 1); }, palette('activeWhite'));
var ThumbnailImage = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border: 0.0625rem solid ", ";\n  flex: 2;\n  margin-right: 0.3125rem;\n  max-width: 35%;\n  font-size: 0;\n  position: relative;\n  img {\n    width: 100%;\n    max-width: 100%;\n  }\n"], ["\n  background: ", ";\n  border: 0.0625rem solid ", ";\n  flex: 2;\n  margin-right: 0.3125rem;\n  max-width: 35%;\n  font-size: 0;\n  position: relative;\n  img {\n    width: 100%;\n    max-width: 100%;\n  }\n"])), palette('white'), palette('border'));
var InfoWrapper = styled(VerticalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  flex: 3;\n  font-weight: ", ";\n  justify-content: center;\n"], ["\n  color: ", ";\n  display: flex;\n  flex: 3;\n  font-weight: ", ";\n  justify-content: center;\n"])), palette('text'), fontWeight('light'));
var DeleteIcon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  opacity: ", ";\n  padding: 1rem;\n  position: absolute;\n  right: 0.625rem;\n  top: 0;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  opacity: ", ";\n  padding: 1rem;\n  position: absolute;\n  right: 0.625rem;\n  top: 0;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) { return (props.deleting ? 0.5 : 1); }, palette('activeWhite'));
var Bold = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var Tag = styled.span(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: ", ";\n"], ["\n  color: ", ";\n  text-decoration: ", ";\n"])), function (props) { return (props.discount ? '#b52025' : 'inherit'); }, function (props) { return (props.crossed ? 'line-through' : 'none'); });
var WatchlistReducedItem = /** @class */ (function (_super) {
    __extends(WatchlistReducedItem, _super);
    function WatchlistReducedItem(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            deleting: false,
        };
        _this.deleteItem = function () {
            _this.setState({
                deleting: !_this.state.deleting,
            });
            _this.props.deleteWatchlistItem(_this.props.item.id);
        };
        _this.getLabelSize = function () {
            var item = _this.props.item;
            if (!Boolean(item.sizeId) ||
                !Boolean(_this.props.item.article) ||
                !Boolean(_this.props.item.article.sizes)) {
                return _jsx("span", {}, void 0);
            }
            var size = _this.props.item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
            return (_jsxs("span", { children: [_jsx(FormattedMessage, { id: 'watchlist.item.size', defaultMessage: 'Gr\u00F6\u00DFe:' }, void 0), ' ', "" + (size ? size.labelSize : '')] }, void 0));
        };
        _this.getCurrentPrice = function () {
            var item = _this.props.item;
            var article = item.article;
            var prices = article.prices, targetPrices = article.targetPrices;
            if (item.sizeId) {
                var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
                if (size) {
                    if (size.price === size.targetPrice) {
                        return _jsx(Tag, { children: getPriceString(size.price) }, void 0);
                    }
                    return (_jsxs("span", { children: [_jsx(Tag, __assign({ crossed: true }, { children: getPriceString(size.targetPrice) }), void 0), ' ', _jsx(Tag, __assign({ discount: true }, { children: getPriceString(size.price) }), void 0), ' '] }, void 0));
                }
            }
            return _jsx(PriceTag, { prices: prices, targetPrices: targetPrices }, void 0);
        };
        _this.isDiscounted = function () {
            var item = _this.props.item;
            if (!item.sizeId) {
                return hasDiscounts(item.article.prices, item.article.targetPrices);
            }
            var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
            if (size) {
                return size.price !== size.targetPrice;
            }
            return false;
        };
        return _this;
    }
    WatchlistReducedItem.prototype.render = function () {
        var _this = this;
        var _a = this.props, item = _a.item, openArticleStack = _a.openArticleStack;
        var deleting = this.state.deleting;
        var article = item.article;
        return (_jsxs(ItemWrapper, { children: [_jsxs(ItemInnerWrapper, __assign({ deleting: deleting, onClick: function () { return openArticleStack(); } }, { children: [_jsxs(ThumbnailImage, { children: [_jsx("img", { src: getImageUrl(article.images.main), alt: article.displayName }, void 0), this.isDiscounted() && _jsx(DiscountBadgeSmall, {}, void 0)] }, void 0), _jsxs(InfoWrapper, { children: [_jsx(Bold, { children: article.brand && article.brand.name }, void 0), article.displayName && article.displayName + " ", article.color && article.color, (item.sizeId && this.getLabelSize()) || '', article.prices && _jsx(Bold, { children: this.getCurrentPrice() }, void 0)] }, void 0)] }), void 0), _jsx(DeleteIcon, __assign({ deleting: deleting, onClick: function () { return _this.deleteItem(); } }, { children: _jsx(Icon, { icon: 'closex', width: 14 }, void 0) }), void 0)] }, item.id));
    };
    return WatchlistReducedItem;
}(Component));
export default WatchlistReducedItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
