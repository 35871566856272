var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import { connect } from 'react-redux';
import { toJS } from '@shared/utils/immutable';
import { withRouter } from 'react-router-dom';
// actions
import { getCategories } from '@store/category/actions';
import { getPrimaryCategories } from '@store/primaryCategory/actions';
// selectors
import { selectSortedAll as selectSortedCategories, selectLoadingAll as selectCategoriesLoading, selectPageCount as selectCategoriesPageCount, } from '@store/category/selectors';
import { selectAll as selectPrimaryCategories, selectShouldLoadAll as selectShouldLoadPrimaryCategories, selectLoadingAll as selectPrimaryCategoriesLoading, } from '@store/primaryCategory/selectors';
// components
import Session from 'containers/Session';
import Page from 'pages/Search';
// Filter
import queryString from 'query-string';
import { isEqual } from 'lodash';
var Search = /** @class */ (function (_super) {
    __extends(Search, _super);
    function Search() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Search.prototype.componentDidMount = function () {
        var _a = this.props, location = _a.location, shouldLoadPriCats = _a.shouldLoadPriCats, getPrimaryCategories = _a.getPrimaryCategories, match = _a.match;
        var primaryCategoryId = match.params.primaryCategoryId;
        var queryParams = queryString.parse(location.search, { arrayFormat: 'bracket' });
        if (shouldLoadPriCats) {
            getPrimaryCategories();
        }
        this.loadCategories(primaryCategoryId, queryParams);
    };
    Search.prototype.componentDidUpdate = function (prevProps) {
        var _a = this.props, location = _a.location, match = _a.match;
        var search = location.search;
        var primaryCategoryId = match.params.primaryCategoryId;
        var prevSearch = prevProps.location.search;
        var prevPrimaryCategoryId = prevProps.match.params.primaryCategoryId;
        var currentSearch = queryString.parse(search);
        var previousSearch = queryString.parse(prevSearch);
        if (primaryCategoryId !== prevPrimaryCategoryId || !isEqual(currentSearch, previousSearch)) {
            this.loadCategories(primaryCategoryId, currentSearch);
        }
    };
    Search.prototype.loadCategories = function (primaryCategoryId, queryParams) {
        this.props.getCategories(__assign({ page: 1, primaryCategory: primaryCategoryId }, queryParams));
    };
    Search.prototype.render = function () {
        var _a = this.props, categories = _a.categories, categoriesLoading = _a.categoriesLoading, categoriesPageCount = _a.categoriesPageCount, getCategories = _a.getCategories, // eslint-disable-line no-shadow
        location = _a.location, match = _a.match, primaryCategories = _a.primaryCategories, primaryCategoriesLoading = _a.primaryCategoriesLoading;
        return (_jsx(Session, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { categories: categories, categoriesLoading: categoriesLoading, categoriesPageCount: categoriesPageCount, currentLocation: location.pathname, getCategories: getCategories, location: location, match: match, primaryCategories: primaryCategories, primaryCategoriesLoading: primaryCategoriesLoading }), void 0)); } }, void 0));
    };
    return Search;
}(Component));
export var SearchContainer = Search;
var select = function () { return function (state) { return ({
    categories: selectSortedCategories(state),
    categoriesLoading: selectCategoriesLoading(state),
    categoriesPageCount: selectCategoriesPageCount(state),
    primaryCategories: selectPrimaryCategories(state),
    primaryCategoriesLoading: selectPrimaryCategoriesLoading(state),
    shouldLoadPriCats: selectShouldLoadPrimaryCategories(state),
}); }; };
export default withRouter(connect(select, {
    getCategories: getCategories,
    getPrimaryCategories: getPrimaryCategories,
})(toJS(Search)));
