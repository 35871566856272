var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import HomeBar from 'organisms/HomeBar';
import Logo from 'molecules/Logo';
import VerticalFlex from '@shared/atoms/VerticalFlex';
var StyledLogo = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: flex-start;\n  flex: 1;\n  justify-content: center;\n"], ["\n  align-items: flex-start;\n  flex: 1;\n  justify-content: center;\n"])));
var StyledHomeBar = styled(HomeBar)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 2;\n"], ["\n  flex: 2;\n"])));
var StyledFlexContainer = styled(VerticalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  box-sizing: border-box;\n  flex: 1;\n  min-height: 14rem;\n  padding: 0 2.5%;\n"], ["\n  box-sizing: border-box;\n  flex: 1;\n  min-height: 14rem;\n  padding: 0 2.5%;\n"])));
var HomeHeader = function (props) { return (_jsxs(StyledFlexContainer, { children: [_jsx(StyledLogo, { children: _jsx(Logo, { logo: props.logo }, void 0) }, void 0), _jsx(StyledHomeBar, { activeSlide: props.activeSlide, currentGender: props.currentGender, toggleSelection: props.toggleSelection, theme: props.theme }, void 0)] }, void 0)); };
export default HomeHeader;
var templateObject_1, templateObject_2, templateObject_3;
