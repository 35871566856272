// @flow
// @ts-nocheck
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    checking: false,
    hasError: false,
    message: null,
    showError: false,
});
var reducer = handleActions({
    ADD_ERROR: function (state, action) {
        var _a = action.payload, message = _a.message, showError = _a.showError;
        return state
            .set('checking', false)
            .set('hasError', true)
            .set('message', message)
            .set('showError', showError);
    },
    REMOVE_ERROR: function (state) {
        return state
            .set('checking', false)
            .set('hasError', false)
            .set('errorMsg', null)
            .set('showError', false);
    },
}, initialState);
export default reducer;
