import { createSelector } from 'reselect';
var selectArticlesState = function (state) { return state.get('article'); };
// export const selectArticleId = (_state: any, props: any) =>
//   parseInt(props.match.params.id, 10);
export var selectLoading = createSelector(selectArticlesState, function (articleState) { return articleState.get('loading'); });
export var selectLoadingAll = createSelector(selectArticlesState, function (articleState) { return articleState.get('loadingAll'); });
export var selectPage = createSelector(selectArticlesState, function (articleState) {
    return articleState.get('page');
});
export var selectLoadingAllComplete = createSelector(selectArticlesState, function (articleState) {
    return !articleState.get('loadingAll') && articleState.get('loadedAll');
});
export var selectTotal = createSelector(selectArticlesState, function (articleState) {
    return articleState.get('total');
});
export var selectArticles = createSelector(selectArticlesState, function (articleState) { return articleState.get('data'); });
export var selectArticlePage = createSelector(selectArticlesState, function (articleState) { return articleState.get('page'); });
export var selectArticlePageCount = createSelector(selectArticlesState, function (articleState) { return articleState.get('pageCount'); });
export var selectArticleError = createSelector(selectArticlesState, function (articleState) { return articleState.get('error'); });
export var selectScanError = createSelector(selectArticlesState, function (articleState) { return articleState.get('scanError'); });
export var makeSelectArticle = function () {
    return createSelector([selectArticles, function (_state, articleId) { return articleId; }], function (articles, articleId) {
        return articles.find(function (article) { return article.get('id') === articleId; });
    });
};
export var loadSingleArticle = function () {
    return createSelector([
        selectArticles,
        selectLoading,
        function (_state, articleId) { return articleId; },
    ], function (articles, loading, id) {
        var a = articles.find(function (article) { return article.get('id') === id; });
        return !Boolean(a) || (!loading && !a.get('singleLoaded'));
    });
};
