var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { handleActions } from 'redux-actions';
import { List, Map, fromJS } from 'immutable';
export var initialState = Map({
    data: List(),
});
var updateStackArticle = function (action) { return function (existingStacks) {
    var meta = action.meta, payload = action.payload;
    var stackIndex = existingStacks.findIndex(function (exitem) { return exitem.id === meta.stackId; });
    return existingStacks.update(stackIndex, function (stack) {
        var articleIndex = typeof meta.baseId !== 'undefined'
            ? meta.baseId
            : stack
                .get('data')
                .findIndex(function (art) { return art.get('id') === payload.get('id'); });
        return stack.update('data', function (data) {
            return data.set(articleIndex, fromJS(payload));
        });
    });
}; };
var appendArticlesToStack = function (action) { return function (existingStacks) {
    var meta = action.meta, payload = action.payload;
    var stackIndex = existingStacks.findIndex(function (exitem) { return exitem.id === meta.stackId; });
    return existingStacks.update(stackIndex, function (stack) {
        return stack
            .update('params', function (params) {
            return params.set('page', stack.get('params').get('page') + 1);
        })
            .update('data', function (data) { return data.push(fromJS(__spreadArray([], payload, true))); });
    });
}; };
var reducer = handleActions({
    CREATE_ARTICLE_STACK: function (state, action) {
        var newStack = {
            id: state.get('data').size + 1,
            data: action.payload.articles,
            initial: action.payload.initial,
            name: action.payload.name,
            params: action.payload.params,
            amountTotal: action.payload.amountTotal,
        };
        return state.update('data', function (data) { return data.push(fromJS(newStack)); });
    },
    DELETE_ARTICLE_STACK: function (state, action) {
        return state.set('data', state
            .get('data')
            .filter(function (item) { return item.get('id') !== action.payload; }));
    },
    GET_STACK_ARTICLE: function (state) { return state.set('loading', true); },
    GET_STACK_ARTICLE_COMPLETE: function (state, action) {
        return state.set('loading', false).update('data', updateStackArticle(action));
    },
    GET_STACK_ARTICLES: function (state) { return state.set('loadingAll', true); },
    GET_STACK_ARTICLES_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .update('data', appendArticlesToStack(action));
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
    RESET_ARTICLE_STACKS: function (state) { return state.set('data', List()); },
}, initialState);
export default reducer;
