var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { palette, fontSize } from '@shared/utils/styled';
import styled, { withTheme } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import WatchlistShareForm from 'molecules/WatchlistShareForm';
import WatchlistShareSuccess from '@shared/molecules/WatchlistShareSuccess';
import H2 from '@shared/atoms/H2';
import QRCode from 'qrcode.react';
var Headline = styled(H2)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1.25rem;\n  margin-top: 0;\n"], ["\n  margin-bottom: 1.25rem;\n  margin-top: 0;\n"])));
var ShareOptions = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr 31.8125rem;\n"], ["\n  display: grid;\n  grid-template-columns: 1fr 31.8125rem;\n"])));
var QRCodeWrapper = styled(HorizontalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: flex-start;\n  display: flex;\n  padding-right: 1.875rem;\n  justify-content: flex-end;\n"], ["\n  align-items: flex-start;\n  display: flex;\n  padding-right: 1.875rem;\n  justify-content: flex-end;\n"])));
var Text = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), palette('text'), fontSize('large'));
var ButtonRow = styled(HorizontalFlex)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n"])));
var FinishButton = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  font-size: ", ";\n  height: 2.875rem;\n  padding: 0 1.75rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  font-size: ", ";\n  height: 2.875rem;\n  padding: 0 1.75rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) {
    return props.isPrimary
        ? palette('primaryHighlight')
        : palette('secondaryBackground');
}, palette('white'), fontSize('large'), function (props) {
    return props.isPrimary
        ? palette('activePrimaryHighlight')
        : palette('activeSecondaryBackground');
});
var WatchlistShare = /** @class */ (function (_super) {
    __extends(WatchlistShare, _super);
    function WatchlistShare(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            showShareSuccess: false,
        };
        _this.shareSession = function (values) {
            _this.props.shareSession(values);
            _this.toggleShareSuccess();
        };
        _this.toggleShareSuccess = function () {
            _this.setState({
                showShareSuccess: !_this.state.showShareSuccess,
            });
        };
        return _this;
    }
    WatchlistShare.prototype.render = function () {
        var _a = this.props, sessionId = _a.sessionId, deleteSession = _a.deleteSession, theme = _a.theme;
        var showShareSuccess = this.state.showShareSuccess;
        return (_jsxs("div", { children: [!showShareSuccess && (_jsxs(ShareOptions, { children: [_jsx(QRCodeWrapper, { children: _jsx(QRCode, { value: "" + theme.customerAppUrl + sessionId + "?" + theme.customerAppId, fgColor: '#5D5D5D', size: 100 }, void 0) }, void 0), _jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'modal.share.headline', defaultMessage: '\u00DCbertragen Sie Ihre Merkliste auf Ihr Ger\u00E4t' }, void 0) }, void 0), _jsx(Text, { children: _jsx(FormattedMessage, { id: 'modal.share.description', defaultMessage: 'Nutzen Sie dazu den QR Code oder tragen Sie Ihre E-Mail-Adresse ein.' }, void 0) }, void 0), _jsx(WatchlistShareForm, { onSubmit: this.shareSession }, void 0)] }, void 0)] }, void 0)), showShareSuccess && _jsx(WatchlistShareSuccess, {}, void 0), _jsx(ButtonRow, { children: _jsx(FinishButton, __assign({ onClick: function () { return deleteSession(); }, isPrimary: this.state.showShareSuccess }, { children: _jsx(FormattedMessage, { id: 'modal.share.finish', defaultMessage: 'Schlie\u00DFen und Terminal freigeben' }, void 0) }), void 0) }, void 0)] }, void 0));
    };
    return WatchlistShare;
}(Component));
export var WatchlistShareComp = WatchlistShare;
export default withTheme(WatchlistShare);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
