var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
/* NEED-336 */
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 3 rem;\n"], ["\n  height: 3 rem;\n"])));
var AvailableLegend = /** @class */ (function (_super) {
    __extends(AvailableLegend, _super);
    function AvailableLegend() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AvailableLegend.prototype.render = function () {
        var size = this.props.size;
        var text = '';
        if (size && Boolean(size)) {
            switch (size.stock) {
                case 'available':
                    text = 'Der Artikel ist in dieser Filiale verfügbar.';
                    break;
                case 'inStock':
                case 'inDifferentStore':
                case 'orderable':
                    text =
                        'Dieser Artikel ist in dieser Filiale nicht verfügbar, aber als Einzelpaar für Sie bestellbar. Bitte fragen Sie bei einem Verkäufer an.';
                    break;
                case 'scheduled':
                    text = 'Bald für Sie verfügbar, schon heute reservieren!';
                    break;
                default:
                    text = '';
                    break;
            }
        }
        return _jsx(Container, { children: text }, void 0);
    };
    return AvailableLegend;
}(Component));
export default AvailableLegend;
var templateObject_1;
