var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { getImageUrl } from '@shared/utils/images';
import { getPriceString } from '@shared/utils/currency';
import { palette, fontWeight, fontSize, key } from '@shared/utils/styled';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
// components
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import VerticalFlex from '@shared/atoms/VerticalFlex';
import CheckButton from '@shared/molecules/CheckButton';
import DiscountBadgeSmall from 'molecules/DiscountBadgeSmall';
var ItemWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 0.0625rem;\n  grid-template-columns: 3.75rem 1fr;\n  margin-bottom: 0.625rem;\n  min-height: 8.375rem;\n  overflow: hidden;\n  width: 100%;\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  @media screen and (min-width: ", ") {\n    grid-template-columns: 3.75rem 1fr;\n  }\n"], ["\n  display: grid;\n  grid-gap: 0.0625rem;\n  grid-template-columns: 3.75rem 1fr;\n  margin-bottom: 0.625rem;\n  min-height: 8.375rem;\n  overflow: hidden;\n  width: 100%;\n  &:last-child {\n    margin-bottom: 0;\n  }\n\n  @media screen and (min-width: ", ") {\n    grid-template-columns: 3.75rem 1fr;\n  }\n"])), key('breakpoints.medium', '1280px'));
var ItemInnerWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  padding: 0.625rem 1.25rem;\n  position: relative;\n  width: 100%;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  padding: 0.625rem 1.25rem;\n  position: relative;\n  width: 100%;\n"])), palette('white'));
var ThumbnailColumn = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background: ", ";\n  border: 0.0625rem solid ", ";\n  flex: 1.7;\n  font-size: 0;\n  max-width: 35%;\n  position: relative;\n  img {\n    width: 100%;\n    max-width: 100%;\n  }\n"], ["\n  background: ", ";\n  border: 0.0625rem solid ", ";\n  flex: 1.7;\n  font-size: 0;\n  max-width: 35%;\n  position: relative;\n  img {\n    width: 100%;\n    max-width: 100%;\n  }\n"])), palette('white'), palette('border'));
var AddButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0;\n  bottom: 0.625rem;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  font-weight: ", ";\n  width: 100%;\n  right: 1.25rem;\n  &:active {\n    background-color: ", ";\n  }\n  &:focus {\n    outline: 0;\n  }\n"], ["\n  background-color: ", ";\n  border: 0;\n  bottom: 0.625rem;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  font-weight: ", ";\n  width: 100%;\n  right: 1.25rem;\n  &:active {\n    background-color: ", ";\n  }\n  &:focus {\n    outline: 0;\n  }\n"])), function (props) {
    return props.selected
        ? palette('secondaryHighlight')
        : palette('primaryHighlight');
}, palette('white'), fontSize('large'), fontWeight('bold'), function (props) {
    return props.selected
        ? palette('activeSecondaryHighlight')
        : palette('activePrimaryHighlight');
});
var BelongingArticlesHeadline = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0.3125rem 0;\n"], ["\n  margin: 0.3125rem 0;\n"])));
var BelongingArticleGrid = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  grid-template-columns: ", ";\n  grid-gap: 0.625rem;\n  display: grid;\n  width: 100%;\n"], ["\n  grid-template-columns: ", ";\n  grid-gap: 0.625rem;\n  display: grid;\n  width: 100%;\n"])), function (props) {
    return props.amount > 2 ? '1fr 1fr 1fr' : '1fr 1fr';
});
var BelongingArticles = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  right: 1.25rem;\n  top: 0.625rem;\n  width: 100%;\n"], ["\n  right: 1.25rem;\n  top: 0.625rem;\n  width: 100%;\n"])));
var BelongingArticle = styled.img(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border: 0.0625rem solid ", ";\n  width: 100%;\n"], ["\n  border: 0.0625rem solid ", ";\n  width: 100%;\n"])), palette('border'));
var InfoColumn = styled(VerticalFlex)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: ", ";\n  display: flex;\n  flex: 3;\n  font-weight: ", ";\n  justify-content: center;\n  padding-left: 0.625rem;\n"], ["\n  color: ", ";\n  display: flex;\n  flex: 3;\n  font-weight: ", ";\n  justify-content: center;\n  padding-left: 0.625rem;\n"])), palette('text'), fontWeight('default'));
var RightColumn = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  align-self: stretch;\n  display: grid;\n  grid-gap: 0.625rem;\n  grid-template-rows: 1fr 2.5rem;\n  min-height: 100%;\n  width: 12.5rem;\n"], ["\n  align-self: stretch;\n  display: grid;\n  grid-gap: 0.625rem;\n  grid-template-rows: 1fr 2.5rem;\n  min-height: 100%;\n  width: 12.5rem;\n"])));
var Selections = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n"])), palette('white'));
var Bold = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: ", ";\n"], ["\n  font-size: ", ";\n  font-weight: ", ";\n"])), fontSize('large'), fontWeight('bold'));
var Desc = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  padding-bottom: 2rem;\n"], ["\n  padding-bottom: 2rem;\n"])));
var Tag = styled.span(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: ", ";\n"], ["\n  color: ", ";\n  text-decoration: ", ";\n"])), function (props) { return (props.discount ? '#b52025' : 'inherit'); }, function (props) { return (props.crossed ? 'line-through' : 'none'); });
var Name = styled.span(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('light'));
var Item = /** @class */ (function (_super) {
    __extends(Item, _super);
    function Item(props) {
        var _this = _super.call(this, props) || this;
        _this.getCurrentPrice = function () {
            var furniture = _this.props.furniture;
            var size = furniture.size;
            if (size.price === size.targetPrice) {
                return _jsx(Tag, { children: getPriceString(size.price) }, void 0);
            }
            return (_jsxs("span", { children: [_jsx(Tag, __assign({ crossed: true }, { children: getPriceString(size.targetPrice) }), void 0), ' ', _jsx(Tag, __assign({ discount: true }, { children: getPriceString(size.price) }), void 0), ' '] }, void 0));
        };
        _this.isDiscounted = function () {
            var furniture = _this.props.furniture;
            return furniture.size.price < furniture.size.targetPrice;
        };
        _this.checkoutItem = function () {
            var _a = _this.props, furniture = _a.furniture, watchlist = _a.watchlist;
            return watchlist.find(function (item) { return item.articleId === furniture.id; });
        };
        _this.isSelected = function () { return Boolean(_this.checkoutItem()); };
        _this.toggleItemSelection = function () {
            var _a = _this.props, furniture = _a.furniture, addAction = _a.addAction, removeAction = _a.removeAction;
            var checkoutItem = _this.checkoutItem();
            if (checkoutItem && Boolean(checkoutItem)) {
                return removeAction(checkoutItem.id);
            }
            return addAction({
                articleId: furniture.id,
                sizeId: furniture.size.sizeId,
            });
        };
        return _this;
    }
    Item.prototype.render = function () {
        var _this = this;
        var furniture = this.props.furniture;
        var selected = this.isSelected();
        var price = this.getCurrentPrice();
        return (_jsxs(ItemWrapper, { children: [_jsx(Selections, __assign({ active: selected }, { children: _jsx(CheckButton, { checked: selected, onChange: this.toggleItemSelection }, void 0) }), void 0), _jsxs(ItemInnerWrapper, __assign({ active: selected }, { children: [_jsxs(ThumbnailColumn, { children: [_jsx("img", { src: getImageUrl(furniture.images.main), alt: '' }, void 0), this.isDiscounted() && _jsx(DiscountBadgeSmall, {}, void 0)] }, void 0), _jsxs(InfoColumn, { children: [furniture.brand && furniture.brand.name && (_jsx(Bold, { children: furniture.brand.name }, void 0)), furniture.displayName && (_jsxs(Name, { children: [furniture.displayName, " -", ' ', _jsx(FormattedMessage, { id: 'checkout.buy.furnitures.type', defaultMessage: 'Pflegemittel' }, void 0)] }, void 0)), _jsx(Desc, { children: furniture.size && furniture.size.labelSize }, void 0), price && _jsx(Bold, { children: price }, void 0)] }, void 0), _jsxs(RightColumn, { children: [_jsxs(BelongingArticles, { children: [_jsx(BelongingArticlesHeadline, { children: _jsx(FormattedMessage, { id: 'checkout.buy.furnitures.belongingArticles', defaultMessage: 'Passend zu' }, void 0) }, void 0), _jsx(BelongingArticleGrid, __assign({ amount: Boolean(furniture.belongsTo) ? furniture.belongsTo.length : 0 }, { children: furniture.belongsTo.map(function (item) { return (_jsx(BelongingArticle, { src: getImageUrl(item.images.detail, 'thumbnail') }, item.id + "-belongingTo-" + furniture.id)); }) }), void 0)] }, void 0), _jsx(AddButton, __assign({ selected: selected, onClick: function () { return _this.toggleItemSelection(); } }, { children: selected ? (_jsx(FormattedMessage, { id: 'checkout.buy.furnitures.deselect', defaultMessage: 'Nein, Danke' }, void 0)) : (_jsx(FormattedMessage, { id: 'checkout.buy.furnitures.select', defaultMessage: 'Auch mitnehmen' }, void 0)) }), void 0)] }, void 0)] }), void 0)] }, void 0));
    };
    return Item;
}(Component));
export default Item;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15;
