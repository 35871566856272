var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '@shared/utils/immutable';
import { getInspiration } from '@store/inspiration/actions';
import { selectItem as selectInspiration, selectShouldLoadItem as selectShouldLoadInspiration, selectLoading as selectInspirationLoading, } from '@store/inspiration/selectors';
import Session from 'containers/Session';
import Inspiration from 'pages/Inspiration';
var Container = /** @class */ (function (_super) {
    __extends(Container, _super);
    function Container() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Container.prototype.componentDidMount = function () {
        var _a = this.props, shouldLoadInspiration = _a.shouldLoadInspiration, match = _a.match;
        if (Boolean(match.params.genderId) && shouldLoadInspiration) {
            this.props.getInspiration(match.params.genderId);
        }
    };
    Container.prototype.componentWillReceiveProps = function (nextProps) {
        var shouldLoadInspiration = nextProps.shouldLoadInspiration, match = nextProps.match;
        if (Boolean(match.params.genderId) &&
            shouldLoadInspiration &&
            this.props.match.params.genderId !== match.params.genderId) {
            nextProps.getInspiration(match.params.genderId);
        }
    };
    Container.prototype.render = function () {
        var _a = this.props, inspiration = _a.inspiration, inspirationLoading = _a.inspirationLoading;
        return (_jsx(Session, { children: function (sessionProps) { return (_jsx(Inspiration, __assign({}, sessionProps, { inspiration: inspiration, inspirationLoading: inspirationLoading }), void 0)); } }, void 0));
    };
    return Container;
}(Component));
var select = function () { return function (state, props) { return ({
    inspiration: selectInspiration()(state, parseInt(props.match.params.genderId, 10)),
    inspirationLoading: selectInspirationLoading(state),
    shouldLoadInspiration: selectShouldLoadInspiration()(state, parseInt(props.match.params.genderId, 10)),
}); }; };
export default connect(select, {
    getInspiration: getInspiration,
})(toJS(Container));
