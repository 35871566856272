var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import styled from "styled-components";
import Icon from "@shared/atoms/Icon";
import MenuIcon from "@shared/atoms/MenuIcon";
import Text from "@shared/atoms/Text";
import { injectIntl } from "react-intl";
import { palette, key } from "../../../utils/styled";
var Wrapper = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: stretch;\n  background-color: transparent;\n  border: 0;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  outline: none;\n  padding: 0 0.625rem;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    padding: 0 1rem;\n  }\n"], ["\n  align-items: stretch;\n  background-color: transparent;\n  border: 0;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  outline: none;\n  padding: 0 0.625rem;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    padding: 0 1rem;\n  }\n"])), key("breakpoints.medium", "1280px"));
var MenuIconWrapper = styled(MenuIcon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  flex: 1 auto;\n  position: relative;\n"], ["\n  display: block;\n  flex: 1 auto;\n  position: relative;\n"])));
var TextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: flex-end;\n  display: flex;\n  justify-content: center;\n  padding: 0 0.5rem;\n  width: 100%;\n"], ["\n  align-items: flex-end;\n  display: flex;\n  justify-content: center;\n  padding: 0 0.5rem;\n  width: 100%;\n"])));
var StyledText = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  background-color: ", ";\n  border-radius: 0.1875rem;\n  padding: 0 0.5rem;\n  border-bottom-color: ", ";\n  ", ";\n"], ["\n  color: ", ";\n  background-color: ", ";\n  border-radius: 0.1875rem;\n  padding: 0 0.5rem;\n  border-bottom-color: ", ";\n  ", ";\n"])), function (props) { return (props.active ? palette("white") : palette("text")); }, function (props) {
    return props.active ? palette("secondaryHighlight") : "transparent";
}, palette("tertiaryHighlight"), function (props) {
    return props.selected &&
        "\n      border-bottom-style: solid;\n      border-bottom-width: 3px;\n      border-radius: 0;\n      margin-bottom: -3px;\n      padding: 0;\n  ";
});
var Cancel = styled(Icon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: #5d5d5d; // Fixed color, for every theme\n  position: absolute;\n  right: 0;\n  top: 0;\n"], ["\n  color: #5d5d5d; // Fixed color, for every theme\n  position: absolute;\n  right: 0;\n  top: 0;\n"])));
var ContentMenuButton = function (props) { return (_jsxs(Wrapper, { children: [_jsx(MenuIconWrapper, __assign({ icon: props.active ? "active" + props.icon : props.icon }, { children: props.active && _jsx(Cancel, { width: 10, icon: "closex" }, void 0) }), void 0), _jsx(TextWrapper, { children: _jsx(StyledText, __assign({ active: props.active, selected: props.selected }, { children: props.intl.formatMessage(props.active && props.activeText ? props.activeText : props.text) }), void 0) }, void 0)] }, void 0)); };
/** @component */
export default injectIntl(ContentMenuButton);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
