var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette } from '@shared/utils/styled';
import Button from '@shared/atoms/Button';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 0.625rem solid ", ";\n  display: block;\n  font-size: 0;\n  position: relative;\n"], ["\n  border: 0.625rem solid ", ";\n  display: block;\n  font-size: 0;\n  position: relative;\n"])), palette('white'));
var LinkedWrapper = Wrapper.withComponent(Link);
var Image = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var ButtonRow = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: ", ";\n  bottom: 0;\n  display: flex;\n  flex: 1;\n  justify-content: ", ";\n  padding: 1.25rem 1.5rem;\n  position: absolute;\n  width: 100%;\n"], ["\n  align-items: ", ";\n  bottom: 0;\n  display: flex;\n  flex: 1;\n  justify-content: ", ";\n  padding: 1.25rem 1.5rem;\n  position: absolute;\n  width: 100%;\n"])), function (props) {
    return props.buttonPosition === 'left' ? 'flex-start' : 'flex-end';
}, function (props) {
    return props.buttonPosition === 'left' ? 'flex-start' : 'flex-end';
});
var StyledButton = styled(Button)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  height: 3.125rem;\n  width: 13.75rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  color: ", ";\n  height: 3.125rem;\n  width: 13.75rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) { return props.bg || 'white'; }, function (props) { return props.color || palette('text'); }, palette('activeWhite'));
var Banner = /** @class */ (function (_super) {
    __extends(Banner, _super);
    function Banner() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Banner.prototype.render = function () {
        var _a = this.props, buttonText = _a.buttonText, imageUrl = _a.imageUrl, targetUrl = _a.targetUrl, buttonPosition = _a.buttonPosition, buttonColor = _a.buttonColor, buttonTextColor = _a.buttonTextColor;
        var position = buttonPosition || 'right';
        var image = _jsx(Image, { src: imageUrl }, void 0);
        if (!Boolean(targetUrl)) {
            return _jsx(Wrapper, { children: image }, void 0);
        }
        return (_jsxs(LinkedWrapper, __assign({ to: targetUrl }, { children: [image, _jsx(ButtonRow, __assign({ buttonPosition: position }, { children: _jsx(StyledButton, __assign({ bg: buttonColor, color: buttonTextColor }, { children: buttonText }), void 0) }), void 0)] }), void 0));
    };
    return Banner;
}(Component));
export default Banner;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
