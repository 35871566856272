var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { keyframes } from 'styled-components';
import { fontWeight, palette } from '@shared/utils/styled';
var flash = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n"], ["\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n"])));
export var StyledArticleItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n  position: relative;\n"], ["\n  margin-bottom: 1rem;\n  position: relative;\n"])));
export var StyledBrandName = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
export var StyledContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
export var StyledInformation = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 0.625rem;\n  line-height: 1.25rem;\n"], ["\n  margin-top: 0.625rem;\n  line-height: 1.25rem;\n"])));
export var StyledImageWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      background: ", ";\n      position: relative;\n      width: 100%;\n\n      &::before {\n        content: '';\n        display: block;\n        padding-top: 100%;\n      }\n\n      &:active {\n        animation: ", " 0.25s linear;\n      }\n    "], ["\n      background: ", ";\n      position: relative;\n      width: 100%;\n\n      &::before {\n        content: '';\n        display: block;\n        padding-top: 100%;\n      }\n\n      &:active {\n        animation: ", " 0.25s linear;\n      }\n    "])), palette('white'), flash);
export var StyledImage = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    img {\n      padding: 1rem;\n      bottom: 0;\n      left: 0;\n      margin: auto;\n      position: absolute;\n      right: 0;\n      top: 0;\n      max-height: 100%;\n      max-width: 100%;\n      animation: fadeIn 1s;\n    }\n  "], ["\n    img {\n      padding: 1rem;\n      bottom: 0;\n      left: 0;\n      margin: auto;\n      position: absolute;\n      right: 0;\n      top: 0;\n      max-height: 100%;\n      max-width: 100%;\n      animation: fadeIn 1s;\n    }\n  "])));
export var StyledPriceTag = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
export var WmsSize = styled.span(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #b52025;\n  line-height: 1.25rem;\n  margin-left: 0.75rem;\n"], ["\n  color: #b52025;\n  line-height: 1.25rem;\n  margin-left: 0.75rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
