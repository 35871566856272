var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import styled from 'styled-components';
import { key, palette, fontWeight } from '@shared/utils/styled';
// mockdata
// components
import ControlledSlider from '@shared/molecules/ControlledSlider';
import SliderArticleExtended from 'molecules/SliderArticleExtended';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 1.25rem;\n"], ["\n  margin-bottom: 1.25rem;\n"])));
var Headline = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  text-align: center;\n  margin-bottom: 1.25rem;\n  font-size: 1.25rem;\n  color: ", ";\n  @media screen and (min-width: ", ") {\n    font-size: 1.5rem;\n  }\n"], ["\n  font-weight: ", ";\n  text-align: center;\n  margin-bottom: 1.25rem;\n  font-size: 1.25rem;\n  color: ", ";\n  @media screen and (min-width: ", ") {\n    font-size: 1.5rem;\n  }\n"])), fontWeight('bold'), palette('text'), key('breakpoints.medium', '1280px'));
var Slide = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var SimilarWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  grid-column: 1 / 3;\n  min-height: 0;\n  min-width: 0;\n"], ["\n  grid-column: 1 / 3;\n  min-height: 0;\n  min-width: 0;\n"])));
var ArticleSlider = /** @class */ (function (_super) {
    __extends(ArticleSlider, _super);
    function ArticleSlider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.openArticleStack = function (articleId) {
            var _a = _this.props, articles = _a.articles, headline = _a.headline, createArticleStack = _a.createArticleStack;
            createArticleStack({
                articles: articles,
                initial: articleId,
                name: headline,
                amountTotal: articles.length,
            });
        };
        return _this;
    }
    ArticleSlider.prototype.render = function () {
        var _this = this;
        var _a = this.props, articles = _a.articles, headline = _a.headline, watchlist = _a.watchlist;
        return (_jsx(Wrapper, { children: _jsxs(SimilarWrapper, { children: [Boolean(headline) && _jsx(Headline, { children: headline }, void 0), _jsx(ControlledSlider, { children: articles.map(function (article) {
                            var watchlistEntry = watchlist.find(function (item) { return item.articleId === article.id; });
                            return (_jsx(Slide, __assign({ big: true }, { children: _jsx(SliderArticleExtended, { openArticleStack: function () { return _this.openArticleStack(article.id); }, createWatchlistItem: function () {
                                        return _this.props.createWatchlistItem({ articleId: article.id });
                                    }, deleteWatchlistItem: function () {
                                        if (watchlistEntry) {
                                            _this.props.deleteWatchlistItem(
                                            // $FlowIgnoreNextLine
                                            watchlistEntry.id);
                                        }
                                    }, article: article, isOnWatchlist: Boolean(watchlistEntry), active: false }, void 0) }), article.id));
                        }) }, void 0)] }, void 0) }, void 0));
    };
    return ArticleSlider;
}(Component));
export default ArticleSlider;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
