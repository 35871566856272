var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import IdleTimer from 'react-idle-timer';
import isEmpty from 'lodash/isEmpty';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { removeError } from '@store/error/actions';
import { formattedDate } from '@shared/utils/times';
import { idleTime, inactiveTimeout } from '@shared/utils/constants';
import { selectLoaded as identitySelectLoaded, selectError as identitySelectError, } from '@store/identity/selectors';
import { selectError } from '@store/error/selectors';
import { selectScanError } from '@store/article/selectors';
import { selectShowConnectionError } from '@store/connection/selectors';
import { selectSession } from '@store/session/selectors';
import { setIdentity } from '@store/identity/actions';
import PageSpinner from '@shared/atoms/PageSpinner';
import { toJS } from '@shared/utils/immutable';
import { updateSession, archiveSession, createSession, } from '@store/session/actions';
import ScanSearchErrorModal from 'organisms/ScanSearch/ScanSearchErrorModal';
import { searchArticle, resetArticlesState } from '@store/article/actions';
import { getDeviceTheme } from '@store/device/actions';
import ModalText from '@shared/atoms/ModalText';
import Error from '@shared/molecules/ConnectionError';
import CloseIcon from '@shared/atoms/CloseIcon';
import { selectThemeConfig } from '@store/theme/selectors';
import { loadGiftcardBalance, scanGiftCardComplete, scanGiftCardReset, } from '@store/giftcard/actions';
import metaData from '@shared/utils/metadata.json';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n"], ["\n  height: 100%;\n"])));
// TODO Pull from env-variable or something like that
var VERSION = '1.0.2';
var NeedleProvider = /** @class */ (function (_super) {
    __extends(NeedleProvider, _super);
    function NeedleProvider() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            scan: '',
            scanning: true,
        };
        _this.onErrorCloseClick = function () {
            if (_this.props.identityError)
                return;
            _this.props.removeError();
        };
        _this.onActive = function () {
            var session = _this.props.session;
            if (!isEmpty(session)) {
                _this.props.updateSession({
                    inactiveAt: null,
                }, session.uuid);
            }
        };
        _this.onScan = function (event) {
            var _a = _this.state, scanning = _a.scanning, scan = _a.scan;
            var giftcardQRUrl = process.env.REACT_APP_GIFT_CARD_QR_URL;
            switch (event.which) {
                case 13:
                    if (scanning) {
                        _this.setTerminalResetCountdown();
                        _this.setState({ scanning: false });
                        // let strScanned = scan.substr(1); //* wird zu %2A
                        var strScanned = scan.replace(/^(\*|\}|(%2A)|(%7D%0A))/gi, ''); //* wird zu %2A
                        // Weiche einbauen: Wir scannen eine Gift Card (1D: 4441972000000103, 2D: TGCVX4VYXQD bzw. UWEHCUG6 bzw TGCECJ97724 bzw TGCA6395RMY bzw TGCSMY5RVUA) und keine Artikelnummer
                        if (giftcardQRUrl && giftcardQRUrl.length !== 0) {
                            // QR-Code mit URL davor muss entfernt werden
                            var giftcardQRUrlMatch = new RegExp("^" + giftcardQRUrl + ".+");
                            if (strScanned.match(giftcardQRUrlMatch)) {
                                strScanned = strScanned.substr(giftcardQRUrl.length);
                            }
                        }
                        // 4441972000000103 TGCVX4VYXQD
                        if ((strScanned.length === 16 && strScanned.match(/^(\d){16}$/)) ||
                            (strScanned.length === 11 && strScanned.match(/^TGC/))) {
                            _this.props.history.push('/giftcard');
                            _this.props.loadGiftcardBalance(strScanned);
                            _this.props.scanGiftCardComplete(strScanned);
                        }
                        else {
                            _this.props.searchArticle({ scan: "*" + strScanned }); // *EA42099221300075 -> ohne Stern! EA42099221300075
                        }
                    }
                    break;
                case 42:
                case 125:
                    if (!scanning) {
                        _this.cancelTerminalResetCountdown();
                        _this.setState({ scanning: true, scan: event.key });
                    }
                    break;
                default:
                    if (scanning) {
                        _this.setState({
                            scan: "" + _this.state.scan + event.key,
                        });
                    }
                    break;
            }
        };
        _this.onInactive = function () {
            var session = _this.props.session;
            if (!isEmpty(session)) {
                _this.props.updateSession({
                    inactiveAt: formattedDate(),
                }, session.uuid);
            }
        };
        _this.cancelTerminalResetCountdown = function () {
            if (_this.resetTerminal !== undefined) {
                console.info('Cancel reset Terminal countdown');
                clearTimeout(_this.resetTerminal);
            }
        };
        _this.setTerminalResetCountdown = function () {
            // We stop this during the development phase but not during E2E tsest
            /*  if (process.env.NODE_ENV === 'development' && !window.Cypress) {
              console.info('Terminal reset functionality turned off during development, enjoy');
              return;
            } */
            _this.cancelTerminalResetCountdown();
            var inActiveIn = _this.props.themeConfig && _this.props.themeConfig.resetDelay
                ? _this.props.themeConfig.resetDelay
                : inactiveTimeout;
            console.info("Terminal reset in " + inActiveIn / 60 + " min");
            _this.resetTerminal = window.setTimeout(function () {
                console.info("Terminal reset...");
                window.dispatchEvent(new Event('onToggleModalGenderSelection'));
                _this.props.archiveSession(_this.props.session.uuid);
                _this.props.createSession({ deviceId: _this.props.deviceId });
            }, inActiveIn * 1000);
        };
        return _this;
    }
    NeedleProvider.prototype.componentDidMount = function () {
        var _this = this;
        window.needleVersion = metaData.buildMajor + "." + metaData.buildMinor + "." + metaData.buildRevision;
        console.debug("%cNeedle Terminal version " + window.needleVersion, 'background: #e7ebda; color: #d36e70');
        /* NEED-330 */
        this.props.setIdentity({
            deviceId: this.props.deviceId,
            footId: this.props.footId,
        });
        this.props.getDeviceTheme(this.props.deviceId);
        document.addEventListener('keypress', this.onScan);
        ['click', 'touchstart'].forEach(function (event) {
            return window.addEventListener(event, function () {
                _this.setTerminalResetCountdown();
            });
        });
    };
    NeedleProvider.prototype.render = function () {
        var _a = this.props, children = _a.children, loaded = _a.loaded, error = _a.error, identityError = _a.identityError, scanError = _a.scanError, resetArticlesState = _a.resetArticlesState;
        if (!loaded && !error.showError) {
            return _jsx(PageSpinner, {}, void 0);
        }
        return (_jsxs(Wrapper, { children: [_jsx(IdleTimer, __assign({ element: document, timeout: idleTime, onActive: this.onActive, onIdle: this.onInactive }, { children: children }), void 0), ((error.hasError && error.showError) || identityError) && (_jsxs(Error, __assign({ onClick: this.onErrorCloseClick }, { children: [_jsx(ModalText, { children: error.message }, void 0), _jsx(CloseIcon, { icon: 'closex', width: 10, fill: 'white' }, void 0)] }), void 0)), _jsx(ScanSearchErrorModal, { scanError: scanError, resetArticlesState: resetArticlesState, shouldCloseOnOverlayClick: false }, void 0)] }, void 0));
    };
    return NeedleProvider;
}(Component));
var select = createStructuredSelector({
    identityError: identitySelectError,
    loaded: identitySelectLoaded,
    session: selectSession,
    showConnectionError: selectShowConnectionError,
    themeConfig: selectThemeConfig,
    error: selectError,
    scanError: selectScanError,
});
export default withRouter(connect(select, {
    getDeviceTheme: getDeviceTheme,
    searchArticle: searchArticle,
    resetArticlesState: resetArticlesState,
    setIdentity: setIdentity,
    updateSession: updateSession,
    archiveSession: archiveSession,
    createSession: createSession,
    removeError: removeError,
    loadGiftcardBalance: loadGiftcardBalance,
    scanGiftCardComplete: scanGiftCardComplete,
    scanGiftCardReset: scanGiftCardReset,
})(toJS(NeedleProvider)));
var templateObject_1;
