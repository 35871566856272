var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BackgroundImage from '@shared/atoms/BackgroundImage';
import Slider from '@shared/organisms/Slider';
import SliderButton from '@shared/molecules/SliderButton';
import TextSlide from 'organisms/TextSlide';
var sliderConfig = {
    spaceBetween: 100,
    centeredSlides: true,
    autoplay: {
        delay: 5000,
        disableOnInteraction: false,
    },
};
var Slide = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  text-decoration: none;\n"], ["\n  height: 100%;\n  text-decoration: none;\n"])));
var GenderSlide = Slide.withComponent('div');
var Image = styled(BackgroundImage)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-size: cover;\n"], ["\n  background-size: cover;\n"])));
var HomeSlider = /** @class */ (function (_super) {
    __extends(HomeSlider, _super);
    function HomeSlider() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HomeSlider.prototype.componentDidMount = function () {
        var slides = document.querySelectorAll('.swiper-slide');
        var fakeFirstSlide = slides[slides.length - 1];
        var fakeLastSlide = slides[0];
        if (fakeFirstSlide.id.includes('needsFakeSlide')) {
            fakeFirstSlide.addEventListener('click', this.props.toggleSelection);
        }
        if (fakeLastSlide.id.includes('needsFakeSlide')) {
            fakeLastSlide.addEventListener('click', this.props.toggleSelection);
        }
    };
    HomeSlider.prototype.renderTeaser = function (teaser, theme) {
        var switches = (theme && theme.switches) || {};
        var hide = switches.hide || [];
        return (_jsx(Image, __assign({ url: teaser.url }, { children: !hide.includes('viewNow') && (_jsx(SliderButton, { position: teaser.buttonPosition }, void 0)) }), void 0));
    };
    HomeSlider.prototype.renderTextSlide = function (slide) {
        return (_jsx(TextSlide, { buttonText: slide.buttonText, firstLine: slide.firstLine, menuKey: slide.menuKey, secondLine: slide.secondLine, thirdLine: slide.thirdLine }, void 0));
    };
    HomeSlider.prototype.renderSlide = function (slide, theme) {
        return slide.isTextSlide
            ? this.renderTextSlide(slide)
            : this.renderTeaser(slide, theme);
    };
    HomeSlider.prototype.render = function () {
        var _this = this;
        var _a = this.props, teasers = _a.teasers, currentGender = _a.currentGender, toggleSelection = _a.toggleSelection, theme = _a.theme;
        return (_jsx(Slider, __assign({ config: sliderConfig }, { children: Boolean(teasers) &&
                teasers.map(function (slide) {
                    return !currentGender &&
                        !Boolean(currentGender) &&
                        (slide.target === '/search' || slide.target === '/inspiration') ? (_jsx(GenderSlide, __assign({ onClick: function () { return toggleSelection(slide.target); }, id: "needsFakeSlide_" + slide.id }, { children: _this.renderSlide(slide, theme) }), slide.id)) : (_jsx(Slide, __assign({ to: slide.target }, { children: _this.renderSlide(slide, theme) }), slide.id));
                }) }), void 0));
    };
    return HomeSlider;
}(Component));
export default HomeSlider;
var templateObject_1, templateObject_2;
