var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from "react";
import { parse } from "query-string";
import styled from "styled-components";
import { key } from "styled-theme";
import ArticleItem from "molecules/ArticleItem";
import InfiniteScroll from "@shared/organisms/InfiniteScroll";
import ResultsNotFound from "molecules/ResultsNotFound";
// RF Components
import FilterBox from "./Filters/FilterBox";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      display: grid;\n      grid-template-areas: 'header' 'content';\n      grid-auto-rows: minmax(8.875rem, auto) 1fr;\n      width: 100%;\n    "], ["\n      display: grid;\n      grid-template-areas: 'header' 'content';\n      grid-auto-rows: minmax(8.875rem, auto) 1fr;\n      width: 100%;\n    "])));
var Grid = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      display: grid;\n      flex: 1;\n      grid-area: content;\n      grid-gap: 1.25rem;\n      grid-template-areas:\n    'header header header header header'\n    'footer footer footer footer footer';\n      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n      grid-auto-rows: max-content;\n      overflow-y: auto;\n      padding: 0 1.875rem 1.875rem;\n\n      ::-webkit-scrollbar {\n        display: none;\n      }\n\n      @media screen and (max-width: ", ") {\n        grid-template-areas:\n      'header header header header'\n      'footer footer footer footer';\n        grid-template-columns: 1fr 1fr 1fr 1fr;\n      }\n      @media screen and (max-width: ", ") {\n        grid-gap: 1.375rem;\n        grid-template-areas: 'header header header' 'footer footer footer';\n        grid-template-columns: 1fr 1fr 1fr;\n      }\n    "], ["\n      display: grid;\n      flex: 1;\n      grid-area: content;\n      grid-gap: 1.25rem;\n      grid-template-areas:\n    'header header header header header'\n    'footer footer footer footer footer';\n      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n      grid-auto-rows: max-content;\n      overflow-y: auto;\n      padding: 0 1.875rem 1.875rem;\n\n      ::-webkit-scrollbar {\n        display: none;\n      }\n\n      @media screen and (max-width: ", ") {\n        grid-template-areas:\n      'header header header header'\n      'footer footer footer footer';\n        grid-template-columns: 1fr 1fr 1fr 1fr;\n      }\n      @media screen and (max-width: ", ") {\n        grid-gap: 1.375rem;\n        grid-template-areas: 'header header header' 'footer footer footer';\n        grid-template-columns: 1fr 1fr 1fr;\n      }\n    "])), key("breakpoints.medium", "1720px"), key("breakpoints.medium", "1280px"));
var ArticleGrid = /** @class */ (function (_super) {
    __extends(ArticleGrid, _super);
    function ArticleGrid(props) {
        var _this = _super.call(this, props) || this;
        _this.fetch = function () {
            _this.props.fetch({ page: _this.props.currentPage + 1 });
        };
        _this.getArticleWatchlistItem = function (articleId) {
            var watchlistEntry = _this.props.watchlist.find(function (item) { return item.articleId === articleId && item.sizeId === null; });
            return watchlistEntry;
        };
        _this.openArticleStack = function (articleId) {
            var _a = _this.props, articles = _a.articles, title = _a.title, params = _a.params, total = _a.total, createArticleStack = _a.createArticleStack;
            var fetchParams = parse(params, { arrayFormat: "bracket" });
            fetchParams.page = _this.props.currentPage;
            fetchParams.pageCount = _this.props.pageCount;
            createArticleStack({
                articles: articles,
                initial: articleId,
                name: title,
                params: fetchParams,
                amountTotal: total
            });
        };
        return _this;
    }
    ArticleGrid.prototype.componentDidMount = function () {
        var _a = this.props, loading = _a.loading, articles = _a.articles, fetch = _a.fetch;
        if (!loading) {
            fetch({ page: this.props.currentPage + 1 });
        }
        if (articles.length > 0 &&
            window.sessionStorage.getItem("trackedArticleId")) {
            var section = document.getElementById(window.sessionStorage.getItem("trackedArticleId"));
            if (section) {
                section.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
    };
    ArticleGrid.prototype.componentWillUnmount = function () {
        window.sessionStorage.removeItem("trackedArticleId");
    };
    ArticleGrid.prototype.renderItems = function () {
        var _this = this;
        var _a = this.props, createWatchlistItem = _a.createWatchlistItem, articles = _a.articles, deleteWatchlistItem = _a.deleteWatchlistItem, loading = _a.loading;
        var result = articles.map(function (article) {
            return (_jsx(ArticleItem, { article: article, createWatchlistItem: createWatchlistItem, deleteWatchlistItem: deleteWatchlistItem, onClick: function () { return _this.openArticleStack(article.id); }, watchlistItem: _this.getArticleWatchlistItem(article.id) }, article.id));
        });
        if (!loading && articles.length === 0) {
            result = result.concat(_jsx(ResultsNotFound, {}, "noResults"));
        }
        return result;
    };
    ArticleGrid.prototype.render = function () {
        var _a = this.props, activeFilterSelections = _a.activeFilterSelections, applyFilterSelections = _a.applyFilterSelections, currentPage = _a.currentPage, currentSortId = _a.currentSortId, deselectAndApplyFilItem = _a.deselectAndApplyFilItem, deselectFilterItem = _a.deselectFilterItem, getFilter = _a.getFilter, hasActiveFilterSelections = _a.hasActiveFilterSelections, loading = _a.loading, pageCount = _a.pageCount, params = _a.params, selectFilterItem = _a.selectFilterItem, sorts = _a.sorts, source = _a.source, tempFilterSelections = _a.tempFilterSelections, title = _a.title, total = _a.total;
        var items = this.renderItems();
        return (_jsxs(Wrapper, { children: [_jsx(FilterBox, { activeFilterSelections: activeFilterSelections, applyFilterSelections: applyFilterSelections, currentSortId: currentSortId, deselectFilterItem: deselectFilterItem, deselectAndApplyFilItem: deselectAndApplyFilItem, getFilter: getFilter, hasActiveFilterSelections: hasActiveFilterSelections, headline: title, navigationSource: source, params: params, selectFilterItem: selectFilterItem, sorts: sorts, tempFilterSelections: tempFilterSelections, total: total }, void 0), _jsx(InfiniteScroll, { items: items, hasMore: currentPage < pageCount, customThreshold: 1, currentPage: currentPage, height: "calc(100vh - 8.8125rem)", loading: loading, loadMore: this.fetch, waypointAsElement: true, wrapper: Grid }, void 0)] }, void 0));
    };
    return ArticleGrid;
}(Component));
export default ArticleGrid;
var templateObject_1, templateObject_2;
