var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import { palette, fontSize } from '@shared/utils/styled';
import BrandModal from 'molecules/BrandModal';
import styled from 'styled-components';
import H2 from '@shared/atoms/H2';
import Icon from '@shared/atoms/Icon';
import { FormattedMessage } from 'react-intl';
var ResetInfo = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-bottom: 3.75rem;\n  padding-bottom: 1.25rem;\n  display: grid;\n  grid-template-columns: 1fr 31.8125rem;\n"], ["\n  margin-bottom: 3.75rem;\n  padding-bottom: 1.25rem;\n  display: grid;\n  grid-template-columns: 1fr 31.8125rem;\n"])));
var DeleteIconWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 1.875rem;\n"], ["\n  display: flex;\n  justify-content: flex-end;\n  padding-right: 1.875rem;\n"])));
var Headline = styled(H2)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-bottom: 1.25rem;\n  margin-top: 1.125rem;\n"], ["\n  margin-bottom: 1.25rem;\n  margin-top: 1.125rem;\n"])));
var ButtonRow = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 1.25rem;\n  grid-template-columns: 1fr 1fr;\n"], ["\n  display: grid;\n  grid-gap: 1.25rem;\n  grid-template-columns: 1fr 1fr;\n"])));
var FinishButton = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  font-size: ", ";\n  height: 2.875rem;\n  padding: 0 1.25rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  font-size: ", ";\n  height: 2.875rem;\n  padding: 0 1.25rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('primaryHighlight'), palette('white'), fontSize('large'), palette('activePrimaryHighlight'));
var ShareButton = styled(FinishButton)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('secondaryHighlight'), palette('activeSecondaryHighlight'));
var Text = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n"])), palette('text'), fontSize('large'));
var TerminalResetModal = function (_a) {
    var deleteSession = _a.deleteSession, open = _a.open, sessionId = _a.sessionId, shareSession = _a.shareSession, toggleAction = _a.toggleAction, openShareModal = _a.openShareModal, theme = _a.theme, props = __rest(_a, ["deleteSession", "open", "sessionId", "shareSession", "toggleAction", "openShareModal", "theme"]);
    var switches = (theme && theme.switches) || {};
    var hide = switches.hide || [];
    return (_jsxs(BrandModal, __assign({ open: open, toggleAction: toggleAction }, props, { children: [_jsxs(ResetInfo, { children: [_jsx(DeleteIconWrapper, { children: _jsx(Icon, { icon: 'terminalReset', width: '140' }, void 0) }, void 0), _jsxs("div", { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'modal.resetSession.headline', defaultMessage: 'L\u00F6schen Sie die Merkliste' }, void 0) }, void 0), _jsx(Text, { children: _jsx(FormattedMessage, { id: 'modal.resetSession.description', defaultMessage: 'Ihre Daten werden damit von diesem Ger\u00E4t gel\u00F6scht und Sie gelangen zur Startseite.' }, void 0) }, void 0)] }, void 0)] }, void 0), _jsxs(ButtonRow, { children: [!hide.includes('share') && (_jsx(ShareButton, __assign({ onClick: function () { return openShareModal(); } }, { children: _jsx(FormattedMessage, { id: 'modal.resetSession.close', defaultMessage: 'Zun\u00E4chst Merkliste teilen' }, void 0) }), void 0)), _jsx(FinishButton, __assign({ onClick: function () { return deleteSession(); } }, { children: _jsx(FormattedMessage, { id: 'modal.resetSession.finish', defaultMessage: 'L\u00F6schen und Terminal freigeben' }, void 0) }), void 0)] }, void 0)] }), void 0));
};
export default TerminalResetModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
