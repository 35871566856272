var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import VerticalFlex from '@shared/atoms/VerticalFlex';
import Icon from '@shared/atoms/Icon';
import { FormattedMessage } from 'react-intl';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  grid-area: footer;\n  height: calc(100vh - 29rem);\n  justify-content: center;\n"], ["\n  align-items: center;\n  display: flex;\n  grid-area: footer;\n  height: calc(100vh - 29rem);\n  justify-content: center;\n"])));
var Description = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: 0.625rem;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-top: 0.625rem;\n"])), palette('tertiaryHighlight'), fontSize('large'), fontWeight('bold'));
var FilterAutocomplete = /** @class */ (function (_super) {
    __extends(FilterAutocomplete, _super);
    function FilterAutocomplete() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FilterAutocomplete.prototype.render = function () {
        return (_jsxs(Wrapper, { children: [_jsx(Icon, { icon: 'noRequests', width: 300 }, void 0), _jsx(Description, { children: _jsx(FormattedMessage, { id: 'stock.search.noResult', defaultMessage: 'Keine Treffer' }, void 0) }, void 0)] }, void 0));
    };
    return FilterAutocomplete;
}(Component));
export default FilterAutocomplete;
var templateObject_1, templateObject_2;
