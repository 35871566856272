var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { fontWeight, palette } from '@shared/utils/styled';
import H1 from '@shared/atoms/H1';
import H3 from '@shared/atoms/H3';
import Icon from '@shared/atoms/Icon';
import Image from '@shared/atoms/Image';
export var StyledGiftcardTitle = styled(H1)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-top: 2rem;\n"], ["\n  font-weight: ", ";\n  margin-top: 2rem;\n"])), fontWeight('bold'));
export var StyledGiftcardSubtitle = styled(H3)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: 20px;\n  margin: 0 0 1.25rem 0;\n  width: 60%;\n  text-align: center;\n"], ["\n  font-weight: ", ";\n  font-size: 20px;\n  margin: 0 0 1.25rem 0;\n  width: 60%;\n  text-align: center;\n"])), fontWeight('bold'));
export var StyledIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  height: auto;\n  svg {\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  height: auto;\n  svg {\n    color: ", ";\n  }\n"])), palette('primaryHighlight'));
export var StyledScanSearch = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin: 1.875rem;\n  margin-bottom: 0;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin: 1.875rem;\n  margin-bottom: 0;\n"])), palette('white'));
export var StyledGiftcardHint = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 16px;\n  text-align: center;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 16px;\n  text-align: center;\n"])));
export var StyledGiftcardHintTitle = styled(H3)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: 20px;\n  margin: 0 0 1.25rem 0;\n  text-align: center;\n"], ["\n  font-weight: ", ";\n  font-size: 20px;\n  margin: 0 0 1.25rem 0;\n  text-align: center;\n"])), fontWeight('bold'));
export var StyledGiftcardWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin: auto;\n  margin-bottom: 16px;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin: auto;\n  margin-bottom: 16px;\n"])));
export var StyledGiftcard = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-items: stretch;\n  justify-content:center;\n  display: flex;\n  flex-direction: row;\n  margin_auto;\n  margin-bottom: 16px;\n  padding: 10px 10%;\n  text-align:center;\n"], ["\n  align-items: stretch;\n  justify-content:center;\n  display: flex;\n  flex-direction: row;\n  margin_auto;\n  margin-bottom: 16px;\n  padding: 10px 10%;\n  text-align:center;\n"])));
export var StyledGiftcardFront = styled(Image)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  height: auto;\n  width: 35%;\n  margin-right: 10px;\n  border-radius: 10px;\n"], ["\n  display: flex;\n  height: auto;\n  width: 35%;\n  margin-right: 10px;\n  border-radius: 10px;\n"])));
export var StyledGiftcardBack = styled(Image)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  height: auto;\n  width: 35%;\n  padding: 0;\n  margin: 0;\n  margin-left: 10px;\n  border-radius: 10px;\n"], ["\n  display: flex;\n  height: auto;\n  width: 35%;\n  padding: 0;\n  margin: 0;\n  margin-left: 10px;\n  border-radius: 10px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
/*
export const StyledGiftcardBack = styled.div<any>`
  display: flex;
  height: 100%;
  width: 50%;
  border-radius: 10px;
  margin: auto;
  margin-left: 20px;
  padding: 70px;
`;

export const StyledGiftcardTextHighlight = styled.strong`
  font-weight: ${fontWeight('bold')};
`;

export const StyledGiftcardText = styled(H3)`
  font-weight: ${fontWeight('normal')};
  margin: 0 0 1.25rem 0;
  font-size: 30px;
  line-height: 1.5em;
`;
*/
