import { createSelector } from 'reselect';
export var selectState = function (state) { return state.get('collection'); };
// const selectCollectionId = (state: any, props: any) =>
//   parseInt(props.match.params.id, 10);
export var selectAll = createSelector(selectState, function (teaserState) {
    return teaserState.get('data');
});
export var selectItem = function () {
    return createSelector([selectAll, function (_state, collectionId) { return collectionId; }], function (collections, collectionId) {
        return collections.find(function (collection) { return collection.get('id') === collectionId; });
    });
};
export var selectLoading = createSelector(selectState, function (state) {
    return state.get('loading');
});
//TODO should be refactored but after adding typescript to everything
export var selectShouldLoadItem = function () {
    return createSelector(selectState, function (state, number) { return selectItem()(state, number); }, function (state, item) { return !state.get('loading') && !Boolean(item); });
};
