var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import H2 from '@shared/atoms/H2';
import styled from 'styled-components';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
var CheckoutHeadline = styled(H2)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-self: flex-start;\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: 2.5rem;\n  margin-top: 1.25rem;\n"], ["\n  align-self: flex-start;\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: 2.5rem;\n  margin-top: 1.25rem;\n"])), palette('white'), fontSize('large'), fontWeight('light'));
export default CheckoutHeadline;
var templateObject_1;
