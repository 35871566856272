var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import { getPriceString } from '@shared/utils/currency';
import moment from "moment";
import { available, rare } from '@shared/utils/constants';
import { FormattedMessage, defineMessages } from 'react-intl';
// components
import DeliveryOptions from 'organisms/DeliveryOptions';
import CustomerData from 'organisms/CustomerData';
import CustomerDataBasic from 'organisms/CustomerDataBasic';
import FurnitureStep from 'organisms/FurnitureStep';
import InfoBubble from 'molecules/InfoBubble';
import CheckoutFinishModal from 'organisms/CheckoutFinishModal';
import OrderFinishModal from 'organisms/OrderFinishModal';
import WatchlistAvailability from 'organisms/WatchlistAvailability';
import WatchlistExpanded from 'organisms/WatchlistExpanded';
import WatchlistExpandedHeader from 'molecules/WatchlistExpandedHeader';
import CheckoutButton from 'molecules/CheckoutButton';
import CustomerFormsSubmitBtn from 'molecules/CustomerFormsSubmitBtn';
import Spinner from '@shared/atoms/Spinner';
import CheckoutHeadline from 'atoms/CheckoutHeadline';
import { Wrapper, Breadcrumb, ActiveBreadcrumbItem, ContentWrapper, Content, InfoColumn, TotalPrice, CheckoutText, CancelButton, NextButton, ColumnScrollWrapper, ColumnWrapper, } from './components';
var WatchlistAreaCheckout = /** @class */ (function (_super) {
    __extends(WatchlistAreaCheckout, _super);
    function WatchlistAreaCheckout(props) {
        var _this = _super.call(this, props) || this;
        _this.resetTerminal = function () {
            _this.props.deleteSession();
        };
        _this.state = {
            currentStepId: 1,
            currentStepName: 'sizeSelect',
            needsSizeSelect: true,
            showFinishModal: false,
            customerFormIsReady: false,
            oneClickFurnitureLoading: false,
            moreArticles: false,
            initialItemSelection: [],
            deliveryOption: 'store',
            initOrder: false,
            basicCustomerData: {},
        };
        _this.toggleCheckout = function () {
            // Reset steps when checkout is done
            if (_this.props.show) {
                _this.setState({
                    currentStepId: 1,
                    initialItemSelection: [],
                });
            }
            _this.state.initOrder = true;
            _this.props.toggleCheckout();
        };
        _this.toggleFinishModal = function () {
            if (!_this.state.showFinishModal) {
                _this.toggleCheckout();
            }
            _this.setState({
                showFinishModal: !_this.state.showFinishModal,
            });
        };
        _this.updateWatchlistItem = function (data, id) {
            _this.props.updateWatchlistItem(data, id);
        };
        _this.finishCheckout = function () {
            var _a = _this.props, checkout = _a.checkout, initialStateValue = _a.initialStateValue, nextStateValue = _a.nextStateValue, session = _a.session, updateSession = _a.updateSession, watchlist = _a.watchlist;
            var selectedWatchlistItems = watchlist.filter(function (item) { return item.selected; });
            var deselectedWatchlistItems = watchlist.filter(function (item) { return !item.selected; });
            var articles = [];
            var now = moment().format();
            updateSession({ serviceCallAt: now });
            selectedWatchlistItems.forEach(function (item) {
                articles.push({
                    articleId: item.articleId,
                    sizeId: item.sizeId,
                });
                _this.updateWatchlistItem({ usage: nextStateValue }, item.id);
            });
            deselectedWatchlistItems.forEach(function (item) {
                _this.updateWatchlistItem({ usage: initialStateValue }, item.id);
            });
            checkout({
                articles: articles,
                customerId: session.buyerId,
            });
            _this.toggleFinishModal();
        };
        _this.nextStep = function (name) {
            var currentStepId = _this.state.currentStepId;
            var nextStep = currentStepId + 1;
            var steps = _this.steps();
            if (nextStep > steps.length) {
                // Do checkout
                return;
            }
            _this.setState({
                currentStepId: nextStep,
                currentStepName: name,
            });
        };
        _this.watchlistIsReady = function () {
            /* NEED-334 */
            var selectedItems = _this.props.watchlist.filter(function (item) { return item.selected; });
            var validItems = selectedItems.filter(function (item) {
                return item.sizeId ||
                    (item.article.isAccessoire && !item.article.sizez.length);
            });
            return (selectedItems.length > 0 && selectedItems.length === validItems.length);
        };
        _this.prevStep = function () {
            var _a = _this.state, currentStepId = _a.currentStepId, currentStepName = _a.currentStepName, needsSizeSelect = _a.needsSizeSelect;
            var isOneClick = _this.props.isOneClick;
            if (currentStepId === 1 ||
                (currentStepName === 'furnitureSelect' &&
                    !needsSizeSelect &&
                    isOneClick)) {
                _this.toggleCheckout();
                return;
            }
            var steps = _this.steps();
            var prevStep = currentStepId !== 1 ? steps[currentStepId - 1] : null;
            if (prevStep && typeof prevStep.prevAction === 'function') {
                prevStep.prevAction();
            }
            _this.setState({
                currentStepName: Boolean(prevStep) && prevStep ? prevStep.name : 'sizeSelect',
                currentStepId: currentStepId - 1,
            });
        };
        _this.getArticlesByAvailability = function (stock) {
            if (stock === void 0) { stock = available; }
            return _this.props.watchlist.filter(function (item) {
                if (!item.selected &&
                    !_this.state.initialItemSelection.find(function (id) { return id === item.id; })) {
                    return false;
                }
                var accessoireCheck = stock === available
                    ? item.article.isAccessoire && item.article.sizes.length === 0
                    : false;
                var size = item.article.sizes.find(function (si) { return si.sizeId === item.sizeId; });
                if (!Boolean(size) && !accessoireCheck) {
                    return false;
                }
                return ((size && Boolean(stock.find(function (sto) { return sto === size.stock; }))) ||
                    accessoireCheck);
            });
        };
        _this.updateCustomerFormState = function (valid) {
            _this.setState({
                customerFormIsReady: valid,
            });
        };
        _this.setDeliveryOption = function (deliveryOption) {
            if (_this.state.deliveryOption !== deliveryOption) {
                _this.setState({ deliveryOption: deliveryOption });
            }
        };
        _this.submitCustomerData = function () {
            if (_this.state.deliveryOption === 'store') {
                _this.props.submitCustomerBasicForm();
            }
            else {
                _this.props.submitCustomerForm();
            }
        };
        _this.finishSizeSelection = function () {
            var watchlist = _this.props.watchlist;
            var selectedWatchlistItemIds = watchlist.reduce(function (acc, item) {
                if (item.selected) {
                    acc.push(item.id);
                }
                return acc;
            }, []);
            _this.setState({ initialItemSelection: selectedWatchlistItemIds });
        };
        _this.finishSizeSelectionAndProceed = function (name) {
            _this.finishSizeSelection();
            _this.nextStep(name || 'deliverySelect');
        };
        _this.isDeliveyArticleSelectReady = function (storeArticles, deliveryArticles) {
            return Boolean(__spreadArray(__spreadArray([], storeArticles, true), deliveryArticles, true).find(function (item) { return item.selected; }));
        };
        _this.isDeliveyArticleSelectDisabled = function (deliveryArticles) {
            if (_this.props.isOneClick) {
                return true;
            }
            if (_this.state.currentStepName === 'deliverySelection') {
                return false;
            }
            return !Boolean(deliveryArticles.length);
        };
        _this.hasSelectedFurnitures = function () {
            var _a = _this.props, watchlist = _a.watchlist, furniture = _a.furniture;
            var funritureIds = furniture.reduce(function (acc, furn) {
                acc.push(furn.id);
                return acc;
            }, []);
            var mathingWlItems = watchlist.filter(function (wlItem) {
                return funritureIds.includes(wlItem.articleId);
            });
            return mathingWlItems && Boolean(mathingWlItems.length);
        };
        return _this;
    }
    WatchlistAreaCheckout.prototype.componentWillReceiveProps = function (nextProps) {
        var deliveryArticles = this.getArticlesByAvailability(rare);
        var ready = this.watchlistIsReady();
        var watchlist = nextProps.watchlist;
        var moreArticles = watchlist.length > 1;
        if (nextProps.show && !this.props.show) {
            if (!nextProps.furnituresReady) {
                this.props.getFurnitures();
            }
            if (!nextProps.isOneClick ||
                (nextProps.isOneClick && nextProps.furnituresReady)) {
                this.setState({
                    moreArticles: moreArticles,
                    needsSizeSelect: !ready,
                    oneClickFurnitureLoading: false,
                });
                if (nextProps.isOneClick &&
                    ready &&
                    deliveryArticles.length === 0 &&
                    nextProps.furniture.length === 0) {
                    this.finishCheckout();
                }
                if (ready && deliveryArticles.length > 0) {
                    this.finishSizeSelection();
                }
            }
            if (nextProps.isOneClick) {
                // this is a workaround for the async loading
                this.setState({
                    moreArticles: moreArticles,
                    needsSizeSelect: !ready,
                    oneClickFurnitureLoading: !nextProps.furnituresReady,
                });
            }
        }
        if (nextProps.furnituresReady !== this.props.furnituresReady &&
            this.state.oneClickFurnitureLoading) {
            this.setState({
                oneClickFurnitureLoading: false,
            });
            if (ready &&
                deliveryArticles.length === 0 &&
                nextProps.furniture.length === 0) {
                this.finishSizeSelection();
                this.finishCheckout();
            }
            if (ready && deliveryArticles.length > 0) {
                this.finishSizeSelection();
            }
        }
    };
    WatchlistAreaCheckout.prototype.steps = function () {
        var _this = this;
        var _a = this.props, createCustomer = _a.createCustomer, createWatchlistItem = _a.createWatchlistItem, deleteWatchlistItem = _a.deleteWatchlistItem, currentStoreId = _a.currentStoreId, furniture = _a.furniture, stores = _a.stores, watchlist = _a.watchlist;
        var _b = this.state, deliveryOption = _b.deliveryOption, needsSizeSelect = _b.needsSizeSelect, moreArticles = _b.moreArticles;
        var storeArticles = this.getArticlesByAvailability(available);
        var deliveryArticles = this.getArticlesByAvailability(rare);
        var selectedDeliveryArticles = deliveryArticles.filter(function (item) { return item.selected; });
        var tryOnReadyDescription = defineMessages({
            description: {
                id: 'checkout.buy.articleSelect.description.isReady',
                defaultMessage: 'Alles gewählt? Dann weiter zum nächsten Schritt.',
            },
        });
        var tryOnNotReadyDescription = defineMessages({
            description: {
                id: 'checkout.buy.articleSelect.description.notReady',
                defaultMessage: 'Wählen Sie Artikel und geben Sie die Größe an.',
            },
        });
        var initialArticleSelectReady = this.watchlistIsReady();
        var initialArticleSelectDescription = initialArticleSelectReady
            ? tryOnReadyDescription
            : tryOnNotReadyDescription;
        var deliveryOptionStore = deliveryOption === 'store';
        var deliveryOptionStoreDesription = defineMessages({
            title: {
                id: 'checkout.buy.customerDataBasic.headline',
                defaultMessage: 'E-Mail-Adresse',
            },
            description: {
                id: 'checkout.buy.customerDataBasic.description',
                defaultMessage: 'Geben Sie Ihre E-Mail-Adresse an.',
            },
        });
        var deliveryOptionDeliveryDescription = defineMessages({
            title: {
                id: 'checkout.buy.customerData.headline',
                defaultMessage: 'Lieferadresse',
            },
            description: {
                id: 'checkout.buy.customerData.description',
                defaultMessage: 'Geben Sie Ihre Daten für den Versand an.',
            },
        });
        var deliveryOptionDescription = deliveryOptionStore
            ? deliveryOptionStoreDesription
            : deliveryOptionDeliveryDescription;
        var isDeliveyArticleSelectReady = this.isDeliveyArticleSelectReady(storeArticles, deliveryArticles);
        var hasSelectedFurnitures = this.hasSelectedFurnitures();
        var CustomerDataBasicFormId = 'customer-data-basic-form';
        var CustomerDataFormId = 'customer-data-form';
        var baseSteps = [
            __assign(__assign({ 
                // initial article select
                disabled: !(moreArticles || needsSizeSelect), name: 'sizeSelect', isReady: this.watchlistIsReady(), icon: 'tryOn', content: (_jsx(ColumnScrollWrapper, { children: _jsxs(ColumnWrapper, { children: [_jsx(CheckoutHeadline, { children: _jsx(FormattedMessage, { id: 'checkout.buy.articleSelect.title', defaultMessage: 'Ihre Artikel aus der Merkliste' }, void 0) }, void 0), _jsx(WatchlistExpanded, { watchlist: watchlist, updateWatchlistItem: this.updateWatchlistItem }, void 0)] }, void 0) }, void 0)), title: defineMessages({
                    articleSelect: {
                        id: 'checkout.buy.articleSelect.headline',
                        defaultMessage: 'Artikelauswahl',
                    },
                    sizeSelect: {
                        id: 'checkout.buy.sizeSelect.headline',
                        defaultMessage: 'Größenauswahl',
                    },
                })[needsSizeSelect ? 'sizeSelect' : 'articleSelect'] }, initialArticleSelectDescription), { nextButton: function () {
                    var toFurniture = !Boolean(deliveryArticles.length) && Boolean(furniture.length);
                    if (Boolean(deliveryArticles.length) || toFurniture) {
                        return (_jsx(NextButton, __assign({ onClick: function () {
                                return initialArticleSelectReady
                                    ? _this.finishSizeSelectionAndProceed(toFurniture ? 'furnitureSelect' : 'deliverySelect')
                                    : {};
                            } }, { children: _jsx(CheckoutText, __assign({ disabled: !initialArticleSelectReady }, { children: _jsx(FormattedMessage, { id: 'checkout.buy.next', defaultMessage: 'Weiter' }, void 0) }), void 0) }), void 0));
                    }
                    return (_jsx(CheckoutButton, { submitCustomerForm: function () { }, disabled: !initialArticleSelectReady, finishCheckout: _this.finishCheckout }, void 0));
                } }),
            __assign(__assign({ 
                // delivery article select
                name: 'deliverySelect', disabled: this.isDeliveyArticleSelectDisabled(deliveryArticles), isReady: this.isDeliveyArticleSelectReady(storeArticles, deliveryArticles) }, defineMessages({
                title: {
                    id: 'checkout.buy.availability.headline',
                    defaultMessage: 'Verfügbarkeit',
                },
                description: {
                    id: 'checkout.buy.availability.description',
                    defaultMessage: 'Überprüfen Sie Ihre Auswahl. Einige Artikel werden für Sie bestellt.',
                },
            })), { content: (_jsx(ColumnScrollWrapper, { children: _jsx(ColumnWrapper, { children: _jsx(WatchlistAvailability, { deliveryArticles: deliveryArticles, storeArticles: storeArticles, updateWatchlistItem: this.updateWatchlistItem }, void 0) }, void 0) }, void 0)), nextButton: function () {
                    return selectedDeliveryArticles.length > 0 ? (_jsx(NextButton, __assign({ onClick: function () {
                            return isDeliveyArticleSelectReady
                                ? _this.nextStep(Boolean(furniture.length)
                                    ? 'furnitureSelect'
                                    : 'deliveryOptionsSelect')
                                : {};
                        } }, { children: _jsx(CheckoutText, __assign({ disabled: !isDeliveyArticleSelectReady }, { children: _jsx(FormattedMessage, { id: 'checkout.buy.next', defaultMessage: 'Weiter' }, void 0) }), void 0) }), void 0)) : (_jsx(CheckoutButton, { submitCustomerForm: function () { }, disabled: !initialArticleSelectReady, finishCheckout: _this.finishCheckout }, void 0));
                }, prevAction: function () { return _this.setState({ initialItemSelection: [] }); } }),
            __assign(__assign({ 
                // furnitures recommends
                name: 'furnitureSelect', disabled: furniture.length === 0, isReady: true, content: (_jsx(ColumnScrollWrapper, { children: _jsx(ColumnWrapper, { children: _jsx(FurnitureStep, { furniture: furniture, addAction: createWatchlistItem, removeAction: deleteWatchlistItem, watchlist: watchlist }, void 0) }, void 0) }, void 0)) }, defineMessages({
                title: {
                    id: 'checkout.buy.furnitures.headline',
                    defaultMessage: 'Pflegemittel',
                },
                description: {
                    id: 'checkout.buy.furnitures.description',
                    defaultMessage: 'Nehmen Sie gleich die passenden Pflegemittel mit.',
                },
            })), { nextButton: function () {
                    if (Boolean(deliveryArticles.length)) {
                        return (_jsx(NextButton, __assign({ onClick: function () {
                                return initialArticleSelectReady
                                    ? _this.finishSizeSelectionAndProceed('deliveryOptionsSelect')
                                    : {};
                            }, isSecondary: !hasSelectedFurnitures }, { children: _jsx(CheckoutText, __assign({ disabled: !initialArticleSelectReady }, { children: hasSelectedFurnitures ? (_jsx(FormattedMessage, { id: 'checkout.buy.next', defaultMessage: 'Weiter' }, void 0)) : (_jsx(FormattedMessage, { id: 'checkout.buy.furniture.defaulNext', defaultMessage: 'Weiter ohne Pflegemittel' }, void 0)) }), void 0) }), void 0));
                    }
                    return (_jsx(CheckoutButton, { submitCustomerForm: function () { }, disabled: !initialArticleSelectReady, finishCheckout: _this.finishCheckout }, void 0));
                }, prevAction: function () {
                    return Boolean(deliveryArticles.length)
                        ? _this.setState({ initialItemSelection: [] })
                        : {};
                } }),
            __assign({ 
                // delivery options
                name: 'deliveryOptionsSelect', disabled: selectedDeliveryArticles.length <= 0, isReady: true, content: (_jsx(DeliveryOptions, { stores: stores, currentStoreId: currentStoreId, deliveryAmount: selectedDeliveryArticles.length, setDeliveryOption: this.setDeliveryOption, selectedDeliveryOption: deliveryOption }, void 0)) }, defineMessages({
                title: {
                    id: 'checkout.buy.deliveryOptions.headline',
                    defaultMessage: 'Ihre Bestellung',
                },
                description: {
                    id: 'checkout.buy.deliveryOptions.description',
                    defaultMessage: 'Wählen Sie eine Filiale zur Abholung oder lassen Sie sich Ihre Artikel nach Hause liefern.',
                },
            })),
            __assign({ 
                // customer data
                name: 'customerDataSelect', isReady: this.state.customerFormIsReady, disabled: selectedDeliveryArticles.length <= 0, content: deliveryOptionStore ? (_jsx(CustomerDataBasic, { createCustomer: createCustomer, updateCustomerFormState: this.updateCustomerFormState, finishCheckout: this.finishCheckout, formId: CustomerDataBasicFormId }, void 0)) : (_jsx(CustomerData, { createCustomer: createCustomer, updateCustomerFormState: this.updateCustomerFormState, finishCheckout: this.finishCheckout, formId: CustomerDataFormId }, void 0)), nextButton: function (disabled) { return (_jsx(CustomerFormsSubmitBtn, { disabled: disabled, finishCheckout: _this.finishCheckout, formId: deliveryOptionStore ? CustomerDataBasicFormId : CustomerDataFormId }, void 0)); } }, deliveryOptionDescription),
            __assign({ name: 'success', content: _jsx("div", {}, void 0), isReady: true }, defineMessages({
                description: {
                    defaultMessage: 'Bitte bezahlen Sie Ihre Artikel an der Kasse',
                    id: 'checkout.buy.cashpoint.desc',
                },
                title: {
                    id: 'checkout.buy.cashpoint.headline',
                    defaultMessage: 'Kasse',
                },
            })),
        ];
        // Only show needed steps
        // return baseSteps.filter((item: any) => !item.disabled).map((item,index) => { return {...item, sort:index+1 } });
        return baseSteps.reduce(function (acc, item) {
            var sortedItem = __assign(__assign({}, item), { sort: acc.length + 1 });
            if (!sortedItem.disabled) {
                acc.push(sortedItem);
            }
            return acc;
        }, []);
    };
    WatchlistAreaCheckout.prototype.getTotalPrice = function () {
        var watchlist = this.props.watchlist;
        var total = watchlist.reduce(function (acc, item) {
            if (item.selected && item.sizeId) {
                var si = item.article.sizes.find(function (size) { return size.sizeId === item.sizeId; });
                return si ? acc + si.price : acc;
            }
            else if (item.article.sizes.length === 0 &&
                item.article.isAccessoire &&
                item.selected) {
                return item.article.prices.min + acc;
            }
            return acc;
        }, 0);
        return total ? getPriceString(total) : null;
    };
    WatchlistAreaCheckout.prototype.renderBreadcrumb = function () {
        var currentStepId = this.state.currentStepId;
        var steps = this.steps();
        return (_jsx(Breadcrumb, { children: steps.map(function (step) {
                var separator = step.sort !== steps.length ? ' » ' : '';
                var title = step.sort === currentStepId ? (_jsx(ActiveBreadcrumbItem, { children: _jsx(FormattedMessage, __assign({}, step.title), void 0) }, void 0)) : (_jsx(FormattedMessage, __assign({}, step.title), void 0));
                return (_jsxs("span", { children: [title, separator] }, step.sort));
            }) }, void 0));
    };
    WatchlistAreaCheckout.prototype.render = function () {
        var _this = this;
        var _a = this.props, checkingOut = _a.checkingOut, checkoutNumber = _a.checkoutNumber, logo = _a.logo, show = _a.show, switches = _a.switches, nextStateValue = _a.nextStateValue, deleteSession = _a.deleteSession, sendOrder = _a.sendOrder, orderNumber = _a.orderNumber, orderError = _a.orderError;
        var _b = this.state, currentStepId = _b.currentStepId, showFinishModal = _b.showFinishModal, oneClickFurnitureLoading = _b.oneClickFurnitureLoading;
        var steps = this.steps();
        var currentStep = steps.find(function (step) { return step.sort === currentStepId; });
        if (!currentStep) {
            return _jsx("div", {}, void 0);
        }
        var nextStep = steps.find(function (step) { return step.sort === currentStepId; });
        var isReady = currentStep.isReady;
        return (_jsxs(Wrapper, __assign({ show: show }, { children: [_jsx(WatchlistExpandedHeader, __assign({ backAction: this.prevStep, logo: logo }, defineMessages({
                    title: {
                        id: 'checkout.buy.headline',
                        defaultMessage: 'Kaufen',
                    },
                })), void 0), oneClickFurnitureLoading ? (_jsx(Spinner, {}, void 0)) : (_jsxs(ContentWrapper, { children: [this.renderBreadcrumb(), _jsxs(Content, { children: [currentStep.content, _jsxs(InfoColumn, { children: [_jsx(TotalPrice, { children: this.getTotalPrice() }, void 0), _jsx(InfoBubble, { description: currentStep.description, icon: currentStep.icon }, void 0), _jsx(CancelButton, __assign({ onClick: function () { return _this.toggleCheckout(); } }, { children: _jsx(FormattedMessage, { id: 'checkout.buy.cancel', defaultMessage: 'Abbrechen' }, void 0) }), void 0), Boolean(currentStep.nextButton) ? (currentStep.nextButton(!isReady)) : (_jsx(NextButton, __assign({ disabled: !isReady, onClick: function () {
                                                return isReady
                                                    ? _this.nextStep(nextStep ? nextStep.name : 'success')
                                                    : {};
                                            } }, { children: _jsx(CheckoutText, __assign({ disabled: !isReady }, { children: _jsx(FormattedMessage, { id: 'checkout.buy.next', defaultMessage: 'Weiter' }, void 0) }), void 0) }), void 0))] }, void 0)] }, void 0)] }, void 0)), switches && switches.order && (_jsx(OrderFinishModal, { nextStateValue: nextStateValue, open: showFinishModal, toggleAction: this.toggleFinishModal, finish: sendOrder, reset: this.resetTerminal, orderNumber: orderNumber, orderError: orderError, init: this.state.initOrder }, void 0)), switches && switches.buy && (_jsx(CheckoutFinishModal, { checkingOut: checkingOut, checkoutNumber: checkoutNumber, nextStateValue: nextStateValue, open: showFinishModal, toggleAction: this.toggleFinishModal, finish: deleteSession }, void 0))] }), void 0));
    };
    return WatchlistAreaCheckout;
}(Component));
export default WatchlistAreaCheckout;
