var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
import { injectIntl, FormattedMessage, defineMessages, } from 'react-intl';
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  font-size: ", ";\n  grid-template-columns: 1fr 9.375rem;\n  margin-bottom: 3.75rem;\n  margin-top: 1.875rem;\n  width: 100%;\n"], ["\n  display: grid;\n  font-size: ", ";\n  grid-template-columns: 1fr 9.375rem;\n  margin-bottom: 3.75rem;\n  margin-top: 1.875rem;\n  width: 100%;\n"])), fontSize('large'));
var StyledInput = styled.input(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-width: 0.0625rem 0 0.0625rem 0.0625rem;\n  border-color: ", ";\n  border-style: solid;\n  flex: 7;\n  height: 2.875rem;\n  padding: 0 0.675rem;\n  ::placeholder {\n    font-weight: ", ";\n  }\n"], ["\n  border-width: 0.0625rem 0 0.0625rem 0.0625rem;\n  border-color: ", ";\n  border-style: solid;\n  flex: 7;\n  height: 2.875rem;\n  padding: 0 0.675rem;\n  ::placeholder {\n    font-weight: ", ";\n  }\n"])), palette('border'), fontWeight('light'));
var SubmitButton = styled.button(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  flex: 3;\n  height: 2.875rem;\n  padding: 0 1.25rem;\n"], ["\n  background-color: ", ";\n  border: 0;\n  color: ", ";\n  flex: 3;\n  height: 2.875rem;\n  padding: 0 1.25rem;\n"])), palette('primaryHighlight'), palette('white'));
export var PureComponent = function (props) {
    var onSubmit = props.onSubmit, intl = props.intl;
    var inputPlaceholder = defineMessages({
        inputPlaceholder: {
            id: 'modal.share.mailPlaceholder',
            defaultMessage: 'Ihre E-Mail-Adresse',
        },
    }).inputPlaceholder;
    return (_jsx(Form, { onSubmit: onSubmit, render: function (_a) {
            var handleSubmit = _a.handleSubmit;
            return (_jsxs(StyledForm, __assign({ onSubmit: handleSubmit }, { children: [_jsx(Field, { render: StyledInput, autoComplete: 'off', component: 'input', name: 'email', placeholder: intl.formatMessage(inputPlaceholder), required: true, type: 'email' }, void 0), _jsx(SubmitButton, __assign({ type: 'submit' }, { children: _jsx(FormattedMessage, { id: 'modal.share.mailSubmit', defaultMessage: 'Abschicken' }, void 0) }), void 0)] }), void 0));
        } }, void 0));
};
export default injectIntl(PureComponent);
var templateObject_1, templateObject_2, templateObject_3;
