var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import { key } from 'styled-theme';
import ArticleItem from 'molecules/ArticleItem';
import GoBack from 'molecules/GoBack';
import VerticalFlex from '@shared/atoms/VerticalFlex';
import H2 from '@shared/atoms/H2';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  margin-top: 0.0625rem;\n  padding: 0 1.875rem 1.875rem;\n"], ["\n  flex: 1;\n  display: flex;\n  margin-top: 0.0625rem;\n  padding: 0 1.875rem 1.875rem;\n"])));
var Banner = styled.img(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 0.625rem solid white;\n  width: 100%;\n  max-width: 100%;\n  grid-area: banner;\n"], ["\n  border: 0.625rem solid white;\n  width: 100%;\n  max-width: 100%;\n  grid-area: banner;\n"])));
var Grid = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  flex: 1;\n  grid-gap: 1.25rem;\n  grid-template-areas: 'header header header header header' 'banner banner banner banner banner' 'footer footer footer footer footer';\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n  grid-auto-rows: max-content;\n  overflow-y: auto;\n  padding: 0 1.875rem 1.875rem;\n  @media screen and (max-width: ", ") {\n    grid-template-areas: 'header header header header' 'banner banner banner banner' 'footer footer footer footer';\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n  }\n  @media screen and (max-width: ", ") {\n    grid-gap: 1.375rem;\n    grid-template-areas: 'header header header' 'banner banner banner' 'footer footer footer';\n    grid-template-columns: 1fr 1fr 1fr;\n  }\n"], ["\n  display: grid;\n  flex: 1;\n  grid-gap: 1.25rem;\n  grid-template-areas: 'header header header header header' 'banner banner banner banner banner' 'footer footer footer footer footer';\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;\n  grid-auto-rows: max-content;\n  overflow-y: auto;\n  padding: 0 1.875rem 1.875rem;\n  @media screen and (max-width: ", ") {\n    grid-template-areas: 'header header header header' 'banner banner banner banner' 'footer footer footer footer';\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n  }\n  @media screen and (max-width: ", ") {\n    grid-gap: 1.375rem;\n    grid-template-areas: 'header header header' 'banner banner banner' 'footer footer footer';\n    grid-template-columns: 1fr 1fr 1fr;\n  }\n"])), key('breakpoints.medium', '1720px'), key('breakpoints.medium', '1280px'));
var Head = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 1rem;\n  grid-template-columns: 1fr 4fr 1fr;\n  min-height: 3.125rem;\n  width: 100%;\n  grid-area: header;\n"], ["\n  display: grid;\n  grid-gap: 1rem;\n  grid-template-columns: 1fr 4fr 1fr;\n  min-height: 3.125rem;\n  width: 100%;\n  grid-area: header;\n"])));
var CenteredH2 = styled(H2)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 1.25rem;\n  text-align: center;\n"], ["\n  margin-top: 1.25rem;\n  text-align: center;\n"])));
var CollectionArticleGrid = /** @class */ (function (_super) {
    __extends(CollectionArticleGrid, _super);
    function CollectionArticleGrid(props) {
        var _this = _super.call(this, props) || this;
        _this.getArticleWatchlistItem = function (articleId) {
            var watchlistEntry = _this.props.watchlist.find(function (item) { return item.articleId === articleId; });
            return watchlistEntry;
        };
        _this.openArticleStack = function (articleId) {
            var _a = _this.props, collection = _a.collection, createArticleStack = _a.createArticleStack;
            createArticleStack({
                articles: collection.articles,
                initial: articleId,
                name: collection.headline,
                amountTotal: collection.articles.length,
            });
        };
        return _this;
    }
    CollectionArticleGrid.prototype.render = function () {
        var _this = this;
        var _a = this.props, collection = _a.collection, createWatchlistItem = _a.createWatchlistItem, deleteWatchlistItem = _a.deleteWatchlistItem;
        return (_jsxs(Wrapper, { children: [_jsxs(Head, { children: [_jsx("div", { children: _jsx(GoBack, {}, void 0) }, void 0), _jsx("div", { children: collection.headline && (_jsx(CenteredH2, { children: collection.headline }, void 0)) }, void 0)] }, 'head'), collection.bannerUrl && (_jsx(Banner, { src: collection.bannerUrl }, 'banner')), collection.articles && (_jsx(Grid, { children: collection.articles.map(function (article) { return (_jsx(ArticleItem, { onClick: function () { return _this.openArticleStack(article.id); }, article: article, createWatchlistItem: createWatchlistItem, deleteWatchlistItem: deleteWatchlistItem, watchlistItem: _this.getArticleWatchlistItem(article.id) }, article.id)); }) }, void 0))] }, void 0));
    };
    return CollectionArticleGrid;
}(Component));
export default CollectionArticleGrid;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
