var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { palette, fontWeight, key } from '@shared/utils/styled';
import styled, { withTheme } from 'styled-components';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
var Wrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n  justify-content: flex-end;\n  letter-spacing: 0.025rem;\n  margin: 2.0625rem 0;\n  @media screen and (max-width: ", ") {\n    margin-top: 1.0625rem;\n  }\n"], ["\n  font-weight: ", ";\n  justify-content: flex-end;\n  letter-spacing: 0.025rem;\n  margin: 2.0625rem 0;\n  @media screen and (max-width: ", ") {\n    margin-top: 1.0625rem;\n  }\n"])), fontWeight('light'), key('breakpoints.medium', '1280px'));
export var Tab = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  line-height: 2rem;\n  margin-left: 0.625rem;\n  outline: 0;\n  position: relative;\n  text-decoration: none;\n  transition: background-color 0.2s ease;\n  &:last-child:after {\n    content: none;\n  }\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  display: flex;\n  line-height: 2rem;\n  margin-left: 0.625rem;\n  outline: 0;\n  position: relative;\n  text-decoration: none;\n  transition: background-color 0.2s ease;\n  &:last-child:after {\n    content: none;\n  }\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) {
    return props.isActive ? palette('secondaryHighlight') : 'transparent';
}, function (props) {
    return props.isActive ? palette('white') : palette('linkText');
}, function (props) {
    return props.isActive ? palette('secondaryHighlight') : palette('activeWhite');
});
var StyledLink = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  color: inherit;\n  display: flex;\n  justify-content: center;\n  padding: 0rem 0.5rem;\n  text-decoration: none;\n  width: 100%;\n"], ["\n  align-items: center;\n  color: inherit;\n  display: flex;\n  justify-content: center;\n  padding: 0rem 0.5rem;\n  text-decoration: none;\n  width: 100%;\n"])));
var TabMenu = /** @class */ (function (_super) {
    __extends(TabMenu, _super);
    function TabMenu() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TabMenu.prototype.render = function () {
        var _a = this.props, tabs = _a.tabs, checkIfTabIsActive = _a.checkIfTabIsActive;
        return (_jsx(Wrapper, { children: tabs.map(function (tab) { return (_jsx(Tab, __assign({ isActive: checkIfTabIsActive(tab.target) }, { children: _jsx(StyledLink, __assign({ to: tab.target }, { children: tab.title }), void 0) }), tab.title)); }) }, void 0));
    };
    return TabMenu;
}(Component));
export default withTheme(TabMenu);
var templateObject_1, templateObject_2, templateObject_3;
