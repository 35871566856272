var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import Modal from 'molecules/Modal';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ModalHeaderTitle, ModalBody, ModalFlex, ModalFlexLeft, ModalFlexRight, ActionButton, ButtonContainer, IconSection, ModalIconWrapper, ModalIcon, ModalIconTitle, } from './style';
var ScanSearchErrorModal = /** @class */ (function (_super) {
    __extends(ScanSearchErrorModal, _super);
    function ScanSearchErrorModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScanSearchErrorModal.prototype.render = function () {
        var _a = this.props, resetArticlesState = _a.resetArticlesState, scanError = _a.scanError;
        return (_jsxs(Modal, __assign({ open: scanError, toggleAction: resetArticlesState, shouldCloseOnOverlayClick: false }, { children: [_jsx("div", { children: _jsx(ModalHeaderTitle, { children: _jsx(FormattedMessage, { id: 'article.search', defaultMessage: 'Artikelsuche' }, void 0) }, void 0) }, void 0), _jsxs(ModalBody, { children: [_jsx("p", { children: _jsx(FormattedMessage, { id: 'scan.article.not.found', defaultMessage: 'Zu diesem Scanvorgang konnte kein Artikel gefunden wurden' }, void 0) }, void 0), _jsxs(ModalFlex, { children: [_jsx(ModalFlexLeft, { children: _jsx(FormattedMessage, { id: 'scan.article.try.again.message', defaultMessage: 'Versuche es erneut oder tippe die Artikelnummer manuell \u00FCber die Suchfunktion in der oberen Men\u00FCleiste ein' }, void 0) }, void 0), _jsx(ModalFlexRight, __assign({ onClick: resetArticlesState }, { children: _jsxs(IconSection, { children: [_jsx(ModalIconWrapper, { children: _jsx(ModalIcon, { icon: 'searchContent' }, void 0) }, void 0), _jsx(ModalIconTitle, { children: _jsx(FormattedMessage, { id: 'contentMenu.scan', defaultMessage: 'Suchen' }, void 0) }, void 0)] }, void 0) }), void 0)] }, void 0), _jsx("p", { children: _jsx(FormattedMessage, { id: 'scan.article.hint.message', defaultMessage: 'In der Suchfunktion kannst du \u00FCbrigens auch nach Modellnamen suchen' }, void 0) }, void 0), _jsx(ButtonContainer, { children: _jsx(ActionButton, __assign({ onClick: resetArticlesState }, { children: "Ok" }), void 0) }, void 0)] }, void 0)] }), void 0));
    };
    return ScanSearchErrorModal;
}(Component));
export default injectIntl(ScanSearchErrorModal);
