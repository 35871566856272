var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getImageUrl } from '@shared/utils/images';
import { fontSize, key, palette } from '@shared/utils/styled';
import VerticalFlex from '@shared/atoms/VerticalFlex';
import { filterSelected } from '@store/filterSelections/filterActions';
import { useDispatch } from 'react-redux';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  grid-column: 1 / 4;\n  justify-content: center;\n  padding-bottom: 0.375rem;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    grid-column: 1 / 5;\n    flex-wrap: wrap;\n    padding-bottom: 1rem;\n  }\n  @media screen and (min-width: ", ") {\n    grid-column: 1 / 6;\n  }\n"], ["\n  display: flex;\n  grid-column: 1 / 4;\n  justify-content: center;\n  padding-bottom: 0.375rem;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    grid-column: 1 / 5;\n    flex-wrap: wrap;\n    padding-bottom: 1rem;\n  }\n  @media screen and (min-width: ", ") {\n    grid-column: 1 / 6;\n  }\n"])), key('breakpoints.medium', '1280px'), key('breakpoints.medium', '1720px'));
var InnerWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  flex: 2;\n  grid-gap: 0.625rem;\n  grid-template-columns: repeat(", ", 1fr);\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    grid-gap: 1.25rem;\n  }\n"], ["\n  display: grid;\n  flex: 2;\n  grid-gap: 0.625rem;\n  grid-template-columns: repeat(", ", 1fr);\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    grid-gap: 1.25rem;\n  }\n"])), function (props) { return props.amountChildren; }, key('breakpoints.medium', '1280px'));
var Tile = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  text-align: center;\n  text-decoration: none;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  text-align: center;\n  text-decoration: none;\n"])), palette('white'));
var Headline = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  margin-top: 0.625rem;\n  @media screen and (min-width: ", ") {\n    font-size: 1.875rem;\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  margin-top: 0.625rem;\n  @media screen and (min-width: ", ") {\n    font-size: 1.875rem;\n  }\n"])), palette('linkText'), fontSize('extraLarge'), key('breakpoints.medium', '1720px'));
var Image = styled.img(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  height: auto;\n  width: 100%;\n"], ["\n  height: auto;\n  width: 100%;\n"])));
var PrimaryPreCategorySelect = function (_a) {
    var primaryCategories = _a.primaryCategories, onPrimaryCategorySelect = _a.onPrimaryCategorySelect;
    var dispatch = useDispatch();
    useEffect(function () {
        dispatch(filterSelected([]));
    }, []);
    return (_jsx(Wrapper, { children: _jsx(InnerWrapper, __assign({ amountChildren: primaryCategories.length }, { children: primaryCategories.map(function (primaryCategory) { return (_jsxs(Tile, __assign({ "data-cy-id": "primary-category-pre-select-" + primaryCategory.displayName, onClick: function () {
                    onPrimaryCategorySelect(primaryCategory);
                } }, { children: [_jsx(Image, { src: getImageUrl(primaryCategory.image) }, void 0), _jsx(Headline, { children: primaryCategory.displayName }, void 0)] }), primaryCategory.id)); }) }), void 0) }, void 0));
};
export default PrimaryPreCategorySelect;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
