var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import Icon from '@shared/atoms/Icon';
var Wrapper = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  border-radius: 50%;\n  cursor: pointer;\n  justify-content: center;\n  padding: 0.8125rem;\n  transition: background-color 0.2s ease;\n  display: flex;\n  svg {\n    display: block;\n  }\n  &:active {\n    background-color: rgba(0, 0, 0, 0.1);\n  }\n"], ["\n  align-items: center;\n  border-radius: 50%;\n  cursor: pointer;\n  justify-content: center;\n  padding: 0.8125rem;\n  transition: background-color 0.2s ease;\n  display: flex;\n  svg {\n    display: block;\n  }\n  &:active {\n    background-color: rgba(0, 0, 0, 0.1);\n  }\n"])));
var NavIcon = function (props) { return _jsx(Wrapper, __assign({}, props, { width: 40 }), void 0); };
export default NavIcon;
var templateObject_1;
