var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toJS } from '@shared/utils/immutable';
// actions
import { checkout, deleteSession, sendOrder, shareSession, updateSession } from '@store/session/actions';
import { createWatchlistItem, deleteWatchlistItem, updateWatchlistItem } from '@store/watchlist/actions';
import { createArticleStack, deleteArticleStack, getStackArticle, getStackArticles, resetArticleStacks } from '@store/articleStack/actions';
import { getFurnitures } from '@store/furniture/actions';
import { getPrimaryCategories } from '@store/primaryCategory/actions';
import { getStores } from '@store/store/actions';
import { getWaitingTime } from '@store/metric/actions';
import { selectLanguage } from '@store/intl/actions';
import { setGender } from '@store/gender/actions';
import { createCustomer } from '@store/customer/actions';
import { resetHidden, saveHidden } from '@store/hiddenWatchlist/actions';
// selectors
import { selectAvarageWaitingTime } from '@store/metric/selectors';
import { selectCheckingOut as sessionCheckingOut, selectCheckoutNumber as sessionCheckoutNumber, selectCreating as sessionSelectCreating, selectLoaded as sessionSelectLoaded, selectOrderError as sessionOrderError, selectOrderNumber as sessionOrderNumber, selectSession, } from '@store/session/selectors';
import { selectWatchlist } from '@store/watchlist/selectors';
import { selectCurrentStoreId, selectShouldLoadAll as selectShouldLoadStores, selectSortedStores } from '@store/store/selectors';
import { selectAll as selectArticleStacks } from '@store/articleStack/selectors';
import { selectCurrentLanguage } from '@store/intl/selectors';
import { selectAll as selectAllPCs, selectShouldLoadAll as selectShouldLoadPCs } from '@store/primaryCategory/selectors';
import { selectCurrentGender } from '@store/gender/selectors';
import { selectHiddenItem } from '@store/hiddenWatchlist/selectors';
import { selectFurnitures, selectLoadingComplete as furnitureReady } from '@store/furniture/selectors';
// components
import PageSpinner from '@shared/atoms/PageSpinner';
var SessionContainer = /** @class */ (function (_super) {
    __extends(SessionContainer, _super);
    function SessionContainer(props) {
        var _this = _super.call(this, props) || this;
        _this.getCurrentPageUrl = function () {
            var location = _this.props.location;
            return location.pathname.match(/^\/[^/]*/).pop();
        };
        _this.shareSession = function (values) {
            _this.props.shareSession(values, _this.props.session.uuid);
        };
        return _this;
    }
    SessionContainer.prototype.componentDidMount = function () {
        var _this = this;
        var _a = this.props, shouldLoadStores = _a.shouldLoadStores, shouldLoadPrimaryCategories = _a.shouldLoadPrimaryCategories;
        if (shouldLoadStores) {
            this.props.getStores();
        }
        if (shouldLoadPrimaryCategories) {
            this.props.getPrimaryCategories();
        }
        this.props.history.listen(function () {
            if (Boolean(_this.props.articleStacks) &&
                _this.props.articleStacks.length > 0) {
                _this.props.resetArticleStacks();
            }
        });
    };
    SessionContainer.prototype.render = function () {
        var _this = this;
        var _a = this.props, articleStacks = _a.articleStacks, checkingOut = _a.checkingOut, checkoutNumber = _a.checkoutNumber, children = _a.children, currentGender = _a.currentGender, currentLanguage = _a.currentLanguage, currentStoreId = _a.currentStoreId, furniture = _a.furniture, furnituresReady = _a.furnituresReady, hiddenItem = _a.hiddenItem, loaded = _a.loaded, loading = _a.loading, orderNumber = _a.orderNumber, orderError = _a.orderError, primaryCategories = _a.primaryCategories, sendOrder = _a.sendOrder, session = _a.session, stores = _a.stores, waitingDuration = _a.waitingDuration, watchlist = _a.watchlist;
        if ((loading || !loaded) && isEmpty(session)) {
            return _jsx(PageSpinner, {}, void 0);
        }
        return children({
            articleStacks: articleStacks,
            checkingOut: checkingOut,
            checkout: function (payload) { return _this.props.checkout(payload, session.uuid); },
            checkoutNumber: checkoutNumber,
            createArticleStack: function (payload) {
                return _this.props.createArticleStack(payload);
            },
            createCustomer: function (payload) {
                return _this.props.createCustomer(payload, session.uuid);
            },
            createWatchlistItem: function (payload) {
                return _this.props.createWatchlistItem(payload, session.uuid);
            },
            currentGender: currentGender,
            currentLanguage: currentLanguage,
            currentPageUrl: this.getCurrentPageUrl(),
            currentStoreId: currentStoreId,
            deleteArticleStack: function (stackId) {
                return _this.props.deleteArticleStack(stackId);
            },
            deleteSession: function () { return _this.props.deleteSession(session.uuid); },
            deleteWatchlistItem: function (itemId) {
                return _this.props.deleteWatchlistItem(itemId, session.uuid);
            },
            furniture: furniture,
            furnituresReady: furnituresReady,
            getDuration: function () { return _this.props.getWaitingTime(); },
            getFurnitures: function () { return _this.props.getFurnitures(session.uuid); },
            getStackArticle: function (articleId, stackId, baseId) {
                return _this.props.getStackArticle(articleId, stackId, baseId);
            },
            getStackArticles: function (params, stackId) {
                return _this.props.getStackArticles(params, stackId);
            },
            hiddenItem: hiddenItem,
            orderNumber: orderNumber,
            orderError: orderError,
            primaryCategories: primaryCategories,
            resetHidden: function () { return _this.props.resetHidden(); },
            saveHidden: function (payload) { return _this.props.saveHidden(payload); },
            sendOrder: function (payload) {
                return _this.props.sendOrder(payload, session.uuid);
            },
            selectLanguage: function (lang) { return _this.props.selectLanguage(lang); },
            session: session,
            setGender: this.props.setGender,
            shareSession: function (payload) { return _this.shareSession(payload); },
            stores: stores,
            updateSession: function (payload) {
                return _this.props.updateSession(payload, session.uuid);
            },
            updateWatchlistItem: function (payload, id) {
                return _this.props.updateWatchlistItem(payload, id, session.uuid);
            },
            waitingDuration: waitingDuration,
            watchlist: watchlist,
        });
    };
    return SessionContainer;
}(Component));
export { SessionContainer };
var select = createStructuredSelector({
    articleStacks: selectArticleStacks,
    checkingOut: sessionCheckingOut,
    checkoutNumber: sessionCheckoutNumber,
    currentGender: selectCurrentGender,
    currentLanguage: selectCurrentLanguage,
    currentStoreId: selectCurrentStoreId,
    furniture: selectFurnitures,
    furnituresReady: furnitureReady,
    hiddenItem: selectHiddenItem,
    loaded: sessionSelectLoaded,
    loading: sessionSelectCreating,
    orderNumber: sessionOrderNumber,
    orderError: sessionOrderError,
    primaryCategories: selectAllPCs,
    session: selectSession,
    shouldLoadPrimaryCategories: selectShouldLoadPCs,
    shouldLoadStores: selectShouldLoadStores,
    stores: selectSortedStores,
    waitingDuration: selectAvarageWaitingTime,
    watchlist: selectWatchlist,
});
var mapDispatchToProps = function (dispatch) { return ({
    checkout: function (payload, sessionUuid) {
        return dispatch(checkout(payload, sessionUuid));
    },
    createArticleStack: function (payload) { return dispatch(createArticleStack(payload)); },
    createWatchlistItem: function (payload, sessionUuid) {
        return dispatch(createWatchlistItem(payload, sessionUuid));
    },
    deleteArticleStack: function (payload) { return dispatch(deleteArticleStack(payload)); },
    deleteSession: function (sessionUuid) { return dispatch(deleteSession(sessionUuid)); },
    deleteWatchlistItem: function (itemId, sessionId) {
        return dispatch(deleteWatchlistItem(itemId, sessionId));
    },
    getFurnitures: function (sessionId) { return dispatch(getFurnitures(sessionId)); },
    getStackArticle: function (articleId, stackId, baseId) {
        return dispatch(getStackArticle(articleId, stackId, baseId));
    },
    getStackArticles: function (params, stackId) {
        return dispatch(getStackArticles(params, stackId));
    },
    getStores: function () { return dispatch(getStores()); },
    getWaitingTime: function () { return dispatch(getWaitingTime()); },
    getPrimaryCategories: function () { return dispatch(getPrimaryCategories()); },
    resetArticleStacks: function () { return dispatch(resetArticleStacks()); },
    resetHidden: function () { return dispatch(resetHidden()); },
    saveHidden: function (payload) { return dispatch(saveHidden(payload)); },
    sendOrder: function (payload, sessionUuid) {
        return dispatch(sendOrder(payload, sessionUuid));
    },
    shareSession: function (payload, sessionUuid) {
        return dispatch(shareSession(payload, sessionUuid));
    },
    updateSession: function (payload, sessionUuid) {
        return dispatch(updateSession(payload, sessionUuid));
    },
    updateWatchlistItem: function (payload, itemId, sessionUuid) {
        return dispatch(updateWatchlistItem(payload, itemId, sessionUuid));
    },
    createCustomer: function (payload, sessionUuid) {
        return dispatch(createCustomer(payload, sessionUuid));
    },
    setGender: function (payload) { return dispatch(setGender(payload)); },
    selectLanguage: function (payload) { return dispatch(selectLanguage(payload)); },
}); };
export default withRouter(connect(select, mapDispatchToProps)(toJS(SessionContainer)));
