var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React, { Component } from "react";
import styled from "styled-components";
import FlexButton from "@shared/atoms/FlexButton";
import { palette } from "../../../utils/styled";
var Button = styled(FlexButton)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 0.125rem;\n  bottom: 2.5rem;\n  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n  height: 3.375rem;\n  left: ", ";\n  padding: 0 3.75rem;\n  position: absolute;\n  right: ", ";\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  border-radius: 0.125rem;\n  bottom: 2.5rem;\n  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n  height: 3.375rem;\n  left: ", ";\n  padding: 0 3.75rem;\n  position: absolute;\n  right: ", ";\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) { return (props.position === "left" ? "3.75rem" : "auto"); }, function (props) { return (props.position === "left" ? "auto" : "3.75rem"); }, palette("activeWhite"));
var SliderButton = /** @class */ (function (_super) {
    __extends(SliderButton, _super);
    function SliderButton() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SliderButton.prototype.render = function () {
        return _jsx(Button, __assign({ position: this.props.position }, { children: "Jetzt ansehen" }), void 0);
    };
    return SliderButton;
}(Component));
export default SliderButton;
var templateObject_1;
