var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import { palette, fontSize } from '@shared/utils/styled';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
// components
import DeliveryOptionsForm from 'molecules/DeliveryOptionsForm';
import H2 from '@shared/atoms/H2';
import VerticalFlex from '@shared/atoms/VerticalFlex';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  padding: 1.25rem 5rem 3.75rem 5rem;\n"], ["\n  background-color: ", ";\n  padding: 1.25rem 5rem 3.75rem 5rem;\n"])), palette('white'));
var Headline = styled(H2)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 3.125rem;\n  margin-top: 1.875rem;\n"], ["\n  margin-bottom: 3.125rem;\n  margin-top: 1.875rem;\n"])));
var Description = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  line-height: 18pt;\n  margin-bottom: 4.375rem;\n  max-width: 35rem;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  line-height: 18pt;\n  margin-bottom: 4.375rem;\n  max-width: 35rem;\n"])), palette('text'), fontSize('large'));
var DeliveryOptions = /** @class */ (function (_super) {
    __extends(DeliveryOptions, _super);
    function DeliveryOptions() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DeliveryOptions.prototype.render = function () {
        var _a = this.props, stores = _a.stores, setDeliveryOption = _a.setDeliveryOption, deliveryAmount = _a.deliveryAmount;
        return (_jsxs(Wrapper, { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'checkout.buy.availability.form.headline', defaultMessage: "{deliveryAmount, number} Artikel {deliveryAmount, plural,\n              one {wird}\n              other {werden}\n            } f\u00FCr Sie bestellt", values: { deliveryAmount: deliveryAmount } }, void 0) }, void 0), _jsx(Description, { children: _jsx(FormattedMessage, { id: 'checkout.buy.availability.form.description', defaultMessage: 'Ihre Bestellung ist in drei Tagen bei uns f\u00FCr Sie abholbereit oder kann Ihnen direkt nach Hause geliefert werden.' }, void 0) }, void 0), _jsx(DeliveryOptionsForm, { stores: stores, setDeliveryOption: setDeliveryOption, deliveryAmount: deliveryAmount }, void 0)] }, void 0));
    };
    return DeliveryOptions;
}(Component));
export default DeliveryOptions;
var templateObject_1, templateObject_2, templateObject_3;
