var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { palette, fontSize, key } from '@shared/utils/styled';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: grid;\n  flex: 2;\n  grid-gap: 0.625rem;\n  grid-template-columns: 1fr 1fr 1fr;\n  margin-top: 0.625rem;\n  @media screen and (min-width: ", ") {\n    grid-gap: 1.25rem;\n    margin-top: 1.25rem;\n  }\n"], ["\n  display: grid;\n  flex: 2;\n  grid-gap: 0.625rem;\n  grid-template-columns: 1fr 1fr 1fr;\n  margin-top: 0.625rem;\n  @media screen and (min-width: ", ") {\n    grid-gap: 1.25rem;\n    margin-top: 1.25rem;\n  }\n"])), key('breakpoints.medium', '1280px'));
var Tile = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 1.25rem;\n  text-align: center;\n  text-decoration: none;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 1.25rem;\n  text-align: center;\n  text-decoration: none;\n"])), palette('white'));
var Headline = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  margin-top: 0.625rem;\n  @media screen and (min-width: ", ") {\n    font-size: 1.875rem;\n  }\n"], ["\n  color: ", ";\n  font-size: ", ";\n  margin-top: 0.625rem;\n  @media screen and (min-width: ", ") {\n    font-size: 1.875rem;\n  }\n"])), palette('linkText'), fontSize('extraLarge'), key('breakpoints.medium', '1720px'));
var Image = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: auto;\n  max-width: 90%;\n  width: 100%;\n"], ["\n  height: auto;\n  max-width: 90%;\n  width: 100%;\n"])));
var InspirationGenderSelect = /** @class */ (function (_super) {
    __extends(InspirationGenderSelect, _super);
    function InspirationGenderSelect() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InspirationGenderSelect.prototype.render = function () {
        var genders = this.props.genders;
        return (_jsx(Wrapper, { children: sortBy(genders, 'sort').map(function (gender) { return (_jsxs(Tile, __assign({ to: "/inspiration/" + gender.id }, { children: [_jsx(Image, { src: gender.imageUrl }, void 0), _jsx(Headline, { children: gender.headline }, void 0)] }), gender.id)); }) }, void 0));
    };
    return InspirationGenderSelect;
}(Component));
export default InspirationGenderSelect;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
