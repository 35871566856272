var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { fontWeight, palette, key } from '@shared/utils/styled';
import H1 from '@shared/atoms/H1';
import H3 from '@shared/atoms/H3';
import Icon from '@shared/atoms/Icon';
import MenuIcon from '@shared/atoms/MenuIcon';
import Button from '@shared/atoms/Button';
export var StyledScanHintTitle = styled(H1)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-weight: ", ";\n  margin-bottom: 0.25rem;\n"], ["\n  font-weight: ", ";\n  margin-bottom: 0.25rem;\n"])), fontWeight('bold'));
export var StyledScanHintSubtitle = styled(H3)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: 12px;\n  margin: 0 0 1.25rem 0;\n"], ["\n  font-weight: ", ";\n  font-size: 12px;\n  margin: 0 0 1.25rem 0;\n"])), fontWeight('bold'));
export var StyledIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  height: auto;\n  svg {\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  height: auto;\n  svg {\n    color: ", ";\n  }\n"])), palette('primaryHighlight'));
export var StyledScanSearch = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin: 1.875rem;\n  margin-bottom: 0;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  margin: 1.875rem;\n  margin-bottom: 0;\n"])), palette('white'));
export var StyledScanHint = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 16px;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 16px;\n"])));
export var ModalHeaderTitle = styled.h3(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 60%;\n  text-align: center;\n  margin: 0 auto;\n  padding-bottom: 0.5rem;\n  border-bottom: 0.0625rem solid black;\n"], ["\n  width: 60%;\n  text-align: center;\n  margin: 0 auto;\n  padding-bottom: 0.5rem;\n  border-bottom: 0.0625rem solid black;\n"])));
export var ModalBody = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 3rem;\n"], ["\n  margin-top: 3rem;\n"])));
export var ModalFlex = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n"], ["\n  display: flex;\n  align-items: center;\n"])));
export var ModalFlexLeft = styled.p(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  flex: 75;\n"], ["\n  flex: 75;\n"])));
export var ModalFlexRight = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  flex: 15;\n  background-color: transparent;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  @media screen and (min-width: ", ") {\n    padding: 0 1rem;\n  }\n"], ["\n  flex: 15;\n  background-color: transparent;\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  justify-content: center;\n  @media screen and (min-width: ", ") {\n    padding: 0 1rem;\n  }\n"])), key('breakpoints.medium', '1280px'));
export var IconSection = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 0.5rem 1rem;\n  background-color: ", ";\n"], ["\n  padding: 0.5rem 1rem;\n  background-color: ", ";\n"])), palette('secondaryBackground'));
export var ModalIcon = styled(MenuIcon)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  display: block;\n  position: relative;\n"], ["\n  display: block;\n  position: relative;\n"])));
export var ModalIconWrapper = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  height: 3rem;\n  padding-bottom: 0.25rem;\n  margin-bottom: 0.5rem;\n  border-bottom: 0.15rem solid ", ";\n"], ["\n  height: 3rem;\n  padding-bottom: 0.25rem;\n  margin-bottom: 0.5rem;\n  border-bottom: 0.15rem solid ", ";\n"])), palette('border'));
export var ModalIconTitle = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  font-size: 1rem;\n  text-align: center;\n"], ["\n  font-size: 1rem;\n  text-align: center;\n"])));
export var ButtonContainer = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  text-align: center;\n  margin-top: 2rem;\n"], ["\n  text-align: center;\n  margin-top: 2rem;\n"])));
export var ActionButton = styled(Button)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  width: 25%;\n  height: 3rem;\n"], ["\n  width: 25%;\n  height: 3rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
