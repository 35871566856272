var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Logo from 'molecules/Logo';
import H2 from '@shared/atoms/H2';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import VerticalFlex from '@shared/atoms/VerticalFlex';
import NavIcon from 'atoms/NavIcon';
var Headline = styled(H2)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 2rem;\n"], ["\n  font-size: 2rem;\n"])));
var BackIconWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  margin-left: 1.875rem;\n  margin-right: 7rem;\n"], ["\n  cursor: pointer;\n  margin-left: 1.875rem;\n  margin-right: 7rem;\n"])));
var LogoArea = styled(VerticalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  flex: 3;\n  justify-content: center;\n  margin-right: 1.875rem;\n  max-width: 14.625rem;\n"], ["\n  align-items: center;\n  flex: 3;\n  justify-content: center;\n  margin-right: 1.875rem;\n  max-width: 14.625rem;\n"])));
var Head = styled(HorizontalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  background-color: #f5f5f5;\n  font-size: 1.75rem;\n  height: 7.625rem;\n  justify-content: space-between;\n"], ["\n  align-items: center;\n  background-color: #f5f5f5;\n  font-size: 1.75rem;\n  height: 7.625rem;\n  justify-content: space-between;\n"])));
var WatchlistExpandedHeader = /** @class */ (function (_super) {
    __extends(WatchlistExpandedHeader, _super);
    function WatchlistExpandedHeader() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WatchlistExpandedHeader.prototype.render = function () {
        var _a = this.props, logo = _a.logo, backAction = _a.backAction, title = _a.title;
        return (_jsxs(Head, { children: [_jsx(BackIconWrapper, { children: _jsx(NavIcon, { icon: 'iconBack', onClick: function () { return backAction(); } }, void 0) }, void 0), _jsx(Headline, { children: _jsx(FormattedMessage, __assign({}, title), void 0) }, void 0), _jsx(LogoArea, { children: _jsx(Logo, { logo: logo }, void 0) }, void 0)] }, void 0));
    };
    return WatchlistExpandedHeader;
}(Component));
export default WatchlistExpandedHeader;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
