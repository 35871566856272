var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CloseIcon, FilterItem, HintBoxWrapper, Image, ImageContainer } from "../DropDownStyles";
var HintBox = function (_a) {
    var showResult = _a.showResult, selectedItem = _a.selectedItem, closeBox = _a.closeBox;
    return (_jsx(HintBoxWrapper, { children: showResult &&
            selectedItem.map(function (item, key) { return (_jsxs(FilterItem, __assign({ className: "selected-filter-item" }, { children: [item.image && (_jsx(ImageContainer, __assign({ height: 1, width: 1 }, { children: _jsx(Image, { src: item.image }, void 0) }), void 0)), item.name, _jsx(CloseIcon, { icon: "closex", width: 10, onClick: function () { return closeBox(item.value); } }, void 0)] }), key)); }) }, void 0));
};
export default HintBox;
