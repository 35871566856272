import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    articleId: null,
    sizeId: null,
});
var reducer = handleActions({
    RESET_HIDDEN: function () { return initialState; },
    SAVE_HIDDEN: {
        next: function (state, action) {
            return state
                .set('articleId', action.payload.articleId)
                .set('sizeId', action.payload.sizeId);
        },
        throw: function (state) {
            return state;
        },
    },
}, initialState);
export default reducer;
