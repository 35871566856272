var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from "react";
import styled from "styled-components";
// utils
import { palette } from "@shared/utils/styled";
import { withRouter } from "react-router-dom";
// components
import AspectRatio from "@shared/atoms/AspectRatio";
import BackgroundImage from "@shared/atoms/BackgroundImage";
import FlashItem from "@shared/atoms/FlashItem";
import Spinner from "@shared/atoms/Spinner";
var Wrapper = styled(AspectRatio)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: 0.0625rem solid ", ";\n  width: 100%;\n  max-height: 100%;\n"], ["\n  border: 0.0625rem solid ", ";\n  width: 100%;\n  max-height: 100%;\n"])), function (props) { return (props.active ? palette("highlightText") : "transparent"); });
var ArticleLink = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      cursor: pointer;\n    "], ["\n      cursor: pointer;\n    "])));
var Image = FlashItem.withComponent(BackgroundImage);
var SpinnerWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      position: absolute;\n      top: 0;\n      left: 0;\n      bottom: 0;\n      right: 0;\n      display: flex;\n    "], ["\n      position: absolute;\n      top: 0;\n      left: 0;\n      bottom: 0;\n      right: 0;\n      display: flex;\n    "])));
var SliderArticle = /** @class */ (function (_super) {
    __extends(SliderArticle, _super);
    function SliderArticle(props) {
        var _this = _super.call(this, props) || this;
        _this.openArticle = function () {
            var _a = _this.props, openArticle = _a.openArticle, history = _a.history, article = _a.article;
            if (typeof openArticle === "function") {
                _this.setState({ loading: true });
                openArticle(article.id);
            }
            else {
                history.push("/articles/" + article.id);
            }
        };
        _this.state = {
            loading: false
        };
        return _this;
    }
    SliderArticle.prototype.render = function () {
        var _this = this;
        var _a;
        var _b = this.props, active = _b.active, article = _b.article;
        var loading = this.state.loading;
        return (_jsx(Wrapper, __assign({ active: active, width: 1, height: 1 }, { children: loading ? (_jsx(SpinnerWrapper, { children: _jsx(Spinner, {}, void 0) }, void 0)) : (_jsx(ArticleLink, __assign({ onClick: function () { return _this.openArticle(); }, className: "ratioContainer" }, { children: _jsx(Image, { url: article.images.thumbnail || ((_a = article.images.main) === null || _a === void 0 ? void 0 : _a.thumbnail) }, void 0) }), void 0)) }), void 0));
    };
    return SliderArticle;
}(Component));
export default withRouter(SliderArticle);
var templateObject_1, templateObject_2, templateObject_3;
