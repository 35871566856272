var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { getImageUrl } from '@shared/utils/images';
import { palette, fontWeight } from '@shared/utils/styled';
import { hasDiscounts } from '@shared/utils/currency';
import WatchlistToggleIcon from 'molecules/WatchlistToggleIcon';
import PriceTag from '@shared/molecules/PriceTag';
import DiscountBadgeMedium from 'molecules/DiscountBadgeMedium';
var flash = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n"], ["\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n"])));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n"], ["\n  cursor: pointer;\n  position: relative;\n"])));
var ThumbnailImage = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  bottom: 0;\n  left: 0;\n  margin: auto;\n  max-height: 100%;\n  max-width: 100%;\n  position: absolute;\n  right: 0;\n  top: 0;\n"], ["\n  bottom: 0;\n  left: 0;\n  margin: auto;\n  max-height: 100%;\n  max-width: 100%;\n  position: absolute;\n  right: 0;\n  top: 0;\n"])));
var ImageWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background: ", ";\n  position: relative;\n  width: 100%;\n  border: 0.0625rem solid ", ";\n  &::before {\n    content: '';\n    display: block;\n    padding-top: 100%;\n  }\n  &:active {\n    animation: ", " 0.25s linear;\n  }\n"], ["\n  background: ", ";\n  position: relative;\n  width: 100%;\n  border: 0.0625rem solid ", ";\n  &::before {\n    content: '';\n    display: block;\n    padding-top: 100%;\n  }\n  &:active {\n    animation: ", " 0.25s linear;\n  }\n"])), palette('white'), palette('border'), flash);
var Bold = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('bold'));
var InfoWrapper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-top: 0.625rem;\n  line-height: 1.25rem;\n  color: ", ";\n"], ["\n  margin-top: 0.625rem;\n  line-height: 1.25rem;\n  color: ", ";\n"])), palette('text'));
var ActionWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var ArticleGridItem = /** @class */ (function (_super) {
    __extends(ArticleGridItem, _super);
    function ArticleGridItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ArticleGridItem.prototype.render = function () {
        var _a = this.props, article = _a.article, createWatchlistItem = _a.createWatchlistItem, deleteWatchlistItem = _a.deleteWatchlistItem, openArticleStack = _a.openArticleStack, isOnWatchlist = _a.isOnWatchlist;
        var brand = article.brand, displayName = article.displayName, images = article.images, prices = article.prices, targetPrices = article.targetPrices;
        return (_jsxs(Wrapper, { children: [_jsx(WatchlistToggleIcon, { createWatchlistItem: createWatchlistItem, deleteWatchlistItem: deleteWatchlistItem, isOnWatchlist: isOnWatchlist }, void 0), _jsxs(ActionWrapper, __assign({ onClick: function () { return openArticleStack(); } }, { children: [_jsxs(ImageWrapper, { children: [_jsx(ThumbnailImage, { src: getImageUrl(images.main) }, void 0), hasDiscounts(prices, targetPrices) && _jsx(DiscountBadgeMedium, {}, void 0)] }, void 0), _jsxs(InfoWrapper, { children: [brand && brand.name, displayName && " \u2013 " + displayName, _jsx(Bold, { children: _jsx(PriceTag, { prices: prices, targetPrices: targetPrices }, void 0) }, void 0)] }, void 0)] }), void 0)] }, void 0));
    };
    return ArticleGridItem;
}(Component));
export default ArticleGridItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
