var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { fontSize, fontWeight, key, palette } from '@shared/utils/styled';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
export var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 1;\n  height: 100%;\n  overflow-y: auto;\n  padding: 0 1.875rem 1.875rem;\n  position: relative;\n"], ["\n  flex: 1;\n  height: 100%;\n  overflow-y: auto;\n  padding: 0 1.875rem 1.875rem;\n  position: relative;\n"])));
export var MainWrapper = styled(HorizontalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  justify-content: center;\n  background: ", ";\n  position: relative;\n"], ["\n  justify-content: center;\n  background: ", ";\n  position: relative;\n"])), palette('white'));
export var Main = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  display: grid;\n  grid-auto-rows: max-content;\n  grid-gap: 1.5rem;\n  max-width: 80rem;\n  padding: 1.5rem;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    grid-gap: 1.875rem;\n    grid-template-columns: 1fr 31.875rem;\n    padding: 2.5rem;\n  }\n"], ["\n  color: ", ";\n  display: grid;\n  grid-auto-rows: max-content;\n  grid-gap: 1.5rem;\n  max-width: 80rem;\n  padding: 1.5rem;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    grid-gap: 1.875rem;\n    grid-template-columns: 1fr 31.875rem;\n    padding: 2.5rem;\n  }\n"])), palette('text'), key('breakpoints.medium', '1280px'));
var GridItem = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  min-height: 0;\n  min-width: 0;\n  grid-area: footer;\n  @media screen and (min-width: ", ") {\n    grid-area: unset;\n  }\n"], ["\n  min-height: 0;\n  min-width: 0;\n  grid-area: footer;\n  @media screen and (min-width: ", ") {\n    grid-area: unset;\n  }\n"])), key('breakpoints.medium', '1280px'));
export var InfoArea = styled(GridItem)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  grid-column: 1 / 3;\n  grid-template-columns: 8.75rem 1fr;\n  position: relative;\n  @media screen and (min-width: ", ") {\n    grid-template-columns: unset;\n    grid-column: unset;\n    display: block;\n  }\n"], ["\n  display: grid;\n  grid-column: 1 / 3;\n  grid-template-columns: 8.75rem 1fr;\n  position: relative;\n  @media screen and (min-width: ", ") {\n    grid-template-columns: unset;\n    grid-column: unset;\n    display: block;\n  }\n"])), key('breakpoints.medium', '1280px'));
export var Light = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-weight: ", ";\n"], ["\n  font-weight: ", ";\n"])), fontWeight('light'));
export var Headline = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n  letter-spacing: 0.4px;\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n  letter-spacing: 0.4px;\n"])), fontWeight('bold'), fontSize('large'));
export var SubHeadline = styled(Headline)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), fontSize('default'));
export var Block = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  margin-top: 1rem;\n"], ["\n  margin-top: 1rem;\n"])));
export var InfoBlock = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  min-height: 0;\n  min-width: 0;\n"], ["\n  min-height: 0;\n  min-width: 0;\n"])));
export var Price = styled.span(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: ", ";\n  text-decoration: ", ";\n"], ["\n  color: ", ";\n  text-decoration: ", ";\n"])), function (props) { return (props.discount ? '#b52025' : 'inherit'); }, function (props) { return (props.crossed ? 'line-through' : 'none'); });
export var ActionsBlock = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  padding-right: 1.0635rem;\n"], ["\n  padding-right: 1.0635rem;\n"])));
export var Slide = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  width: ", ";\n"], ["\n  width: ", ";\n"])), function (props) { return (props.big ? '14rem' : '4rem'); });
export var BrandImage = styled.img(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  width: 6.25rem;\n  margin-bottom: 1rem;\n"], ["\n  width: 6.25rem;\n  margin-bottom: 1rem;\n"])));
export var CloseStack = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  left: 2.5rem;\n  position: absolute;\n  top: 0.625rem;\n  z-index: 1;\n"], ["\n  left: 2.5rem;\n  position: absolute;\n  top: 0.625rem;\n  z-index: 1;\n"])));
export var WmsSize = styled.span(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  color: #b52025;\n  line-height: 1.25rem;\n  margin-left: 0.75rem;\n"], ["\n  color: #b52025;\n  line-height: 1.25rem;\n  margin-left: 0.75rem;\n"])));
export var StockInformation = styled.table(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n  width: 100%;\n  max-height: 15rem;\n  overflow: auto;\n  box-shadow: 0px 3px 6px #00000029;\n  border-collapse: separate;\n  border-spacing: 0 0.5rem;\n  display: block;\n  overflow-x: auto;\n  white-space: nowrap;\n\n  tr {\n    border-bottom: 1px solid black;\n  }\n\n  /* tbody > tr:nth-child(even) {\n    background-color: ", ";\n    color: white;\n  } */\n\n  tr > th {\n    color: black;\n    font-weight: bold;\n    text-align: left;\n    padding: 0 1rem;\n  }\n\n  tr > td:nth-child(-n + 2) {\n    border-right: 1px solid black;\n  }\n\n  tr > td {\n    text-align: center;\n    padding: 0.25rem 0.5rem;\n  }\n"], ["\n  margin-top: 0.5rem;\n  width: 100%;\n  max-height: 15rem;\n  overflow: auto;\n  box-shadow: 0px 3px 6px #00000029;\n  border-collapse: separate;\n  border-spacing: 0 0.5rem;\n  display: block;\n  overflow-x: auto;\n  white-space: nowrap;\n\n  tr {\n    border-bottom: 1px solid black;\n  }\n\n  /* tbody > tr:nth-child(even) {\n    background-color: ", ";\n    color: white;\n  } */\n\n  tr > th {\n    color: black;\n    font-weight: bold;\n    text-align: left;\n    padding: 0 1rem;\n  }\n\n  tr > td:nth-child(-n + 2) {\n    border-right: 1px solid black;\n  }\n\n  tr > td {\n    text-align: center;\n    padding: 0.25rem 0.5rem;\n  }\n"])), palette('secondaryHighlight'));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17;
