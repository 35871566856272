var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { key } from 'styled-theme';
import { palette, fontWeight } from '@shared/utils/styled';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Button from '@shared/atoms/Button';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 0 4.6875rem 3.125rem 4.6875rem;\n  @media screen and (min-width: ", ") {\n    padding-bottom: 10%;\n  }\n"], ["\n  align-items: center;\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  padding: 0 4.6875rem 3.125rem 4.6875rem;\n  @media screen and (min-width: ", ") {\n    padding-bottom: 10%;\n  }\n"])), palette('secondaryBackground'), key('breakpoints.medium', '1280px'));
var TextContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 3.125rem);\n  max-width: 93.75rem;\n  padding: 4.6875rem 3.125rem 3.125rem 4.6875rem;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    height: calc(100% - 10%);\n  }\n"], ["\n  background-color: ", ";\n  display: flex;\n  flex-direction: column;\n  height: calc(100% - 3.125rem);\n  max-width: 93.75rem;\n  padding: 4.6875rem 3.125rem 3.125rem 4.6875rem;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    height: calc(100% - 10%);\n  }\n"])), palette('white'), key('breakpoints.medium', '1280px'));
var Line = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 26pt;\n  font-weight: ", ";\n  margin-bottom: 0.625rem;\n  @media screen and (min-width: ", ") {\n    font-size: 32pt;\n  }\n  @media screen and (min-width: ", ") {\n    font-size: 40pt;\n  }\n"], ["\n  color: ", ";\n  font-size: 26pt;\n  font-weight: ", ";\n  margin-bottom: 0.625rem;\n  @media screen and (min-width: ", ") {\n    font-size: 32pt;\n  }\n  @media screen and (min-width: ", ") {\n    font-size: 40pt;\n  }\n"])), palette('highlightText'), function (props) {
    return props.bold ? fontWeight('bold') : fontWeight('light');
}, key('breakpoints.medium', '1280px'), key('breakpoints.medium', '1720px'));
var TextWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  flex: 2;\n"], ["\n  flex: 2;\n"])));
var ButtonWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1;\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n"], ["\n  flex: 1;\n  display: flex;\n  align-items: flex-end;\n  justify-content: flex-end;\n"])));
var ColorButton = styled(Button)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  height: 3.125rem;\n  width: 13.75rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  height: 3.125rem;\n  width: 13.75rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('activePrimaryHighlight'));
var ArrowLeft = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  border-color: transparent transparent transparent #fff;\n  border-style: solid;\n  border-width: 3.125rem 0 0 1.875rem;\n  visibility: ", ";\n  height: 0;\n  width: 0;\n"], ["\n  border-color: transparent transparent transparent #fff;\n  border-style: solid;\n  border-width: 3.125rem 0 0 1.875rem;\n  visibility: ", ";\n  height: 0;\n  width: 0;\n"])), function (props) { return (props.visible ? 'visible' : 'hidden'); });
var ArrowRight = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-color: transparent transparent #fff transparent;\n  border-style: solid;\n  border-width: 0 0 3.125rem 1.875rem;\n  visibility: ", ";\n  height: 0;\n  width: 0;\n"], ["\n  border-color: transparent transparent #fff transparent;\n  border-style: solid;\n  border-width: 0 0 3.125rem 1.875rem;\n  visibility: ", ";\n  height: 0;\n  width: 0;\n"])), function (props) { return (props.visible ? 'visible' : 'hidden'); });
var ArrowWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  justify-content: space-between;\n  max-width: 93.75rem;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: space-between;\n  max-width: 93.75rem;\n  width: 100%;\n"])));
var TextSlide = /** @class */ (function (_super) {
    __extends(TextSlide, _super);
    function TextSlide() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TextSlide.prototype.renderArrow = function (menuKey) {
        return (_jsxs(ArrowWrapper, { children: [_jsx(ArrowLeft, { visible: menuKey === 'inspiration' }, void 0), _jsx(ArrowRight, { visible: menuKey === 'search' }, void 0), _jsx(ArrowRight, { visible: menuKey === 'scan' }, void 0), _jsx(ArrowRight, { visible: menuKey === 'support' }, void 0)] }, void 0));
    };
    TextSlide.prototype.render = function () {
        var _a = this.props, buttonText = _a.buttonText, firstLine = _a.firstLine, menuKey = _a.menuKey, secondLine = _a.secondLine, thirdLine = _a.thirdLine;
        return (_jsxs(Wrapper, { children: [this.renderArrow(menuKey), _jsxs(TextContainer, { children: [_jsxs(TextWrapper, { children: [_jsx(Line, { children: _jsx(FormattedMessage, __assign({}, firstLine), void 0) }, void 0), _jsx(Line, { children: _jsx(FormattedMessage, __assign({}, secondLine), void 0) }, void 0), _jsx(Line, __assign({ bold: true }, { children: _jsx(FormattedMessage, __assign({}, thirdLine), void 0) }), void 0)] }, void 0), _jsx(ButtonWrapper, { children: _jsx(ColorButton, { children: _jsx(FormattedMessage, __assign({}, buttonText), void 0) }, void 0) }, void 0)] }, void 0)] }, void 0));
    };
    return TextSlide;
}(Component));
export default TextSlide;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
