import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
export var initialState = Map({
    creating: false,
    data: List(),
    deleting: false,
    deletingAll: false,
    loadedAll: false,
    loadingAll: false,
});
export var updateItem = function (item) { return function (existing) {
    // $FlowIgnoreNextLine
    var index = existing.findIndex(function (exitem) { return exitem.get('id') === item.get('id'); });
    if (index === -1) {
        // $FlowIgnoreNextLine
        return existing.push(item);
    }
    // $FlowIgnoreNextLine
    return existing.set(index, item);
}; };
var reducer = handleActions({
    CREATE_WATCHLIST_ITEM: function (state) { return state.set('creating', true); },
    CREATE_WATCHLIST_ITEM_COMPLETE: {
        next: function (state, action) {
            return state
                .set('creating', false)
                .update('data', function (watchlistItems) {
                // $FlowIgnoreNextLine
                return watchlistItems.push(action.payload);
            });
        },
        throw: function (state) {
            return state.set('creating', false);
        },
    },
    DELETE_WATCHLIST_ITEM: function (state) { return state.set('deleting', true); },
    DELETE_WATCHLIST_ITEM_COMPLETE: {
        next: function (state, action) {
            return state
                .set('deleting', false)
                .update('data', function (existing) {
                return existing.filter(function (item) { return item.get('id') !== action.payload.get('id'); });
            });
        },
        throw: function (state) {
            return state.set('deleting', false);
        },
    },
    DELETE_WATCHLIST: function (state) { return state.set('deletingAll', true); },
    DELETE_WATCHLIST_COMPLETE: {
        next: function (state) {
            return state.set('deletingAll', false).set('data', List());
        },
        throw: function (state) {
            return state.set('deletingAll', false);
        },
    },
    GET_WATCHLIST: function (state) { return state.set('loadingAll', true); },
    GET_WATCHLIST_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingAll', false)
                .set('loadedAll', true)
                .set('data', action.payload);
        },
        throw: function (state) {
            return state.set('loadingAll', false).set('loadedAll', false);
        },
    },
    RESET_WATCHLIST: function (state) { return state.set('data', List()); },
    UPDATE_WATCHLIST_ITEM: function (state) { return state; },
    UPDATE_WATCHLIST_ITEM_COMPLETE: {
        next: function (state, action) {
            return state.update('data', updateItem(action.payload));
        },
        throw: function (state) {
            return state;
        },
    },
}, initialState);
export default reducer;
