// @flow
// @ts-nocheck
import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    data: List(),
    intro: Map(),
    loadedIntro: false,
    loading: false,
    loadingIntro: false,
});
var updateEntry = function (entry) { return function (existing) {
    // $FlowIgnoreNextLine
    var index = existing.findIndex(function (existingEntry) { return existingEntry.get('id') === entry.get('id'); });
    if (index === -1) {
        // $FlowIgnoreNextLine
        return existing.push(entry);
    }
    // $FlowIgnoreNextLine
    return existing.set(index, entry);
}; };
var reducer = handleActions({
    GET_INSPIRATION: function (state) { return state.set('loading', true); },
    GET_INSPIRATION_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .update('data', updateEntry(action.payload));
        },
        throw: function (state) {
            return state.set('loading', false);
        },
    },
    GET_INTRO: function (state) { return state.set('loadingIntro', true); },
    GET_INTRO_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loadingIntro', false)
                .set('loadedIntro', true)
                .set('intro', action.payload);
        },
        throw: function (state) {
            return state.set('loadingIntro', false);
        },
    },
}, initialState);
export default reducer;
