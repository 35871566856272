export var loadGiftcardBalance = function (gcnr) {
    return {
        type: 'LOAD_GIFTCARD_BALANCE',
        giftcardNumber: gcnr,
    };
};
export var loadGiftcardBalanceComplete = function (balanceResult) {
    return {
        type: 'LOAD_GIFTCARD_BALANCE_COMPLETE',
        balanceResult: balanceResult,
    };
};
export var navigateAndSetGiftcard = function (gcNumber) {
    return {
        type: 'NAVIGATE_AND_SET_GIFTCARD',
        gcNumber: gcNumber,
    };
};
export var scanGiftCardComplete = function (scanValue) {
    return {
        type: 'SCAN_GIFT_CARD_COMPLETE',
        scanValue: scanValue,
    };
};
export var scanGiftCardReset = function () {
    return {
        type: 'SCAN_GIFT_CARD_RESET',
    };
};
