var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import { palette, fontSize } from '@shared/utils/styled';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
// components
import CustomerDataBasicForm from 'molecules/CustomerDataBasicForm';
import H2 from '@shared/atoms/H2';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  overflow: auto;\n  padding: 1.25rem 5rem 1.875rem 5rem;\n"], ["\n  background-color: ", ";\n  overflow: auto;\n  padding: 1.25rem 5rem 1.875rem 5rem;\n"])), palette('white'));
var Headline = styled(H2)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 3.125rem;\n  margin-top: 1.875rem;\n"], ["\n  margin-bottom: 3.125rem;\n  margin-top: 1.875rem;\n"])));
var Description = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  line-height: 18pt;\n  margin-bottom: 1.875rem;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  line-height: 18pt;\n  margin-bottom: 1.875rem;\n"])), palette('text'), fontSize('large'));
var CustomerDataBasic = /** @class */ (function (_super) {
    __extends(CustomerDataBasic, _super);
    function CustomerDataBasic() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomerDataBasic.prototype.render = function () {
        var _a = this.props, updateCustomerFormState = _a.updateCustomerFormState, createCustomer = _a.createCustomer, finishCheckout = _a.finishCheckout, formId = _a.formId;
        return (_jsxs(Wrapper, { children: [_jsx(Headline, { children: _jsx(FormattedMessage, { id: 'checkout.buy.customerDataBasic.form.headline', defaultMessage: 'E-Mail-Adresse' }, void 0) }, void 0), _jsx(Description, { children: _jsx(FormattedMessage, { id: 'checkout.buy.customerDataBasic.form.description', defaultMessage: 'Damit wir Sie benachrichtigen k\u00F6nnen sobald die Artikel eingetroffen sind geben Sie bitte hier Ihre E-Mail-Adresse ein.' }, void 0) }, void 0), _jsx(CustomerDataBasicForm, { createCustomer: createCustomer, updateCustomerFormState: updateCustomerFormState, finishCheckout: finishCheckout, formId: formId }, void 0)] }, void 0));
    };
    return CustomerDataBasic;
}(Component));
export default CustomerDataBasic;
var templateObject_1, templateObject_2, templateObject_3;
