//@ts-nocheck
import { createSelector } from 'reselect';
var selectState = function (state) { return state.get('inspiration'); };
// const selectInspirationId = (state: any, props: any) =>
//   parseInt(props.match.params.genderId, 10);
export var selectAll = createSelector(selectState, function (state) {
    return state.get('data');
});
export var selectIntro = createSelector(selectState, function (state) {
    return state.get('intro');
});
export var selectIntroLoading = createSelector(selectState, function (state) {
    return state.get('loadingIntro');
});
export var selectLoading = createSelector(selectState, function (state) {
    return state.get('loading');
});
export var selectItem = function () {
    return createSelector([selectAll, function (state, inspirationId) { return inspirationId; }], function (inspirations, inspirationId) {
        return inspirations.find(function (inspiration) { return inspiration.get('id') === inspirationId; });
    });
};
export var selectShouldLoadItem = function () {
    return createSelector([selectState, function (state, inspirationId) { return selectItem()(state, inspirationId); }], function (state, item) { return !state.get('loading') && !Boolean(item); });
};
export var selectShouldLoadIntro = function () {
    return createSelector([selectState], function (state) { return !state.get('loadingIntro') && !state.get('loadedIntro'); });
};
