var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import BrandModal from 'molecules/BrandModal';
import PrimaryCategoryPreSelect from 'molecules/PrimaryCategoryPreSelect';
import { useHistory } from 'react-router-dom';
var GenderSelectionArticleSearchModal = function (_a) {
    var open = _a.open, onClose = _a.onClose, primaryCategories = _a.primaryCategories;
    var history = useHistory();
    return (_jsx(BrandModal, __assign({ label: 'Dialog', open: open, toggleAction: onClose }, { children: _jsx(PrimaryCategoryPreSelect, { onPrimaryCategorySelect: function (primaryCategory) {
                history.push("/search/" + primaryCategory.id);
            }, primaryCategories: primaryCategories }, void 0) }), void 0));
};
export default GenderSelectionArticleSearchModal;
