var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import styled, { keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { fontSize, palette } from "@shared/utils/styled";
import CenterText from "@shared/atoms/CenterText";
var flash = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n"], ["\n  0%, 100% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n"])));
var CustomGridItem = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background: ", ";\n  padding: 1rem;\n"], ["\n  background: ", ";\n  padding: 1rem;\n"])), palette('white'));
var CustomLink = styled(Link)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  height: 100%;\n  display: grid;\n  flex: 1;\n  grid-template-areas: 'image' 'footer ';\n  grid-template-columns: 1fr;\n  text-decoration: none;\n  transition: opacity;\n"], ["\n  height: 100%;\n  display: grid;\n  flex: 1;\n  grid-template-areas: 'image' 'footer ';\n  grid-template-columns: 1fr;\n  text-decoration: none;\n  transition: opacity;\n"])));
var Item = styled.img(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 100%;\n  grid-area: image;\n  object-fit: cover;\n  margin: 0 auto;\n  align-self: center;\n  animation: fadeIn 0.5s;\n  \n  &:active {\n    animation: ", " 0.25s linear;\n  }\n"], ["\n  width: 100%;\n  grid-area: image;\n  object-fit: cover;\n  margin: 0 auto;\n  align-self: center;\n  animation: fadeIn 0.5s;\n  \n  &:active {\n    animation: ", " 0.25s linear;\n  }\n"])), flash);
var Centered = styled(CenterText)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: ", ";\n  grid-area: footer;\n  align-self: end;\n  padding-top: 0.5rem;\n"], ["\n  font-size: ", ";\n  grid-area: footer;\n  align-self: end;\n  padding-top: 0.5rem;\n"])), fontSize('large'));
var Badge = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  position: absolute;\n  top: 0.5rem;\n  right: 0.5rem;\n  background-color: #f05545;\n  color: #fff;\n  font-size: 12px;\n  font-weight: bold;\n  padding: 0.25rem 0.5rem;\n  border-radius: 20px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n"], ["\n  position: absolute;\n  top: 0.5rem;\n  right: 0.5rem;\n  background-color: #f05545;\n  color: #fff;\n  font-size: 12px;\n  font-weight: bold;\n  padding: 0.25rem 0.5rem;\n  border-radius: 20px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);\n"])));
var CategoryLink = function (_a) {
    var basePath = _a.basePath, category = _a.category, primaryCategoryId = _a.primaryCategoryId, theme = _a.theme;
    var getTargetLink = function () {
        var target = basePath + "/results?category=" + category.id;
        if (primaryCategoryId && Boolean(primaryCategoryId)) {
            target += "&primaryCategory=" + primaryCategoryId;
        }
        // Add current query string to target
        var queryString = window.location.search.replaceAll("?", "");
        if (queryString) {
            target += queryString.replaceAll("?", "");
        }
        return target;
    };
    return (_jsx(CustomGridItem, { children: _jsxs(CustomLink, __assign({ to: getTargetLink() }, { children: [Boolean(category.image) ? (_jsx(_Fragment, { children: _jsx(Item, { alt: category.name, src: category.image }, category.id) }, void 0)) : (_jsx(_Fragment, { children: _jsx(Item, { alt: category.name, src: "https://storage.googleapis.com/c91b2bb74b7db747a90a1741add6745f/needle_images/app/fallback.svg" }, category.id) }, void 0)), _jsx(Centered, { children: category.displayName }, void 0)] }), void 0) }, void 0));
};
export default CategoryLink;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
