var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import styled, { withTheme } from 'styled-components';
import Image from '@shared/atoms/Image';
import Modal from 'molecules/Modal';
var Logo = styled(Image)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 3.0625rem;\n  margin-bottom: 2rem;\n  margin-left: 1.25rem;\n"], ["\n  height: 3.0625rem;\n  margin-bottom: 2rem;\n  margin-left: 1.25rem;\n"])));
var BrandModal = function (_a) {
    var children = _a.children, label = _a.label, open = _a.open, theme = _a.theme, toggleAction = _a.toggleAction, props = __rest(_a, ["children", "label", "open", "theme", "toggleAction"]);
    return (_jsxs(Modal, __assign({ contentMargin: '0 0 15% 0', open: open, theme: theme, toggleAction: toggleAction }, props, { children: [_jsx(Logo, { src: theme.logoUrl }, void 0), children] }), void 0));
};
export default withTheme(BrandModal);
var templateObject_1;
