import { handleActions } from 'redux-actions';
import { Map, List } from 'immutable';
export var initialState = Map({
    creating: false,
    data: List(),
});
var reducer = handleActions({
    CREATE_CUSTOMER: function (state) { return state.set('creating', true); },
    CREATE_CUSTOMER_COMPLETE: {
        next: function (state, action) {
            return state.set('creating', false).update('data', function (customers) {
                // $FlowIgnoreNextLine
                return customers.push(action.payload);
            });
        },
        throw: function (state) {
            return state.set('creating', false);
        },
    },
}, initialState);
export default reducer;
