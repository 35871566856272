var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component } from 'react';
import styled, { withTheme } from 'styled-components';
import HomeHeader from 'organisms/HomeHeader';
import HomeSlider from 'organisms/HomeSlider';
import LanguageModal from 'organisms/LanguageModal';
import VerticalFlex from '@shared/atoms/VerticalFlex';
import GenderSelectionInspirationModal from 'organisms/GenderSelectionModal/GenderSelectionInspirationModal';
import GenderSelectionArticleSearchModal from 'organisms/GenderSelectionModal/GenderSelectionArticleSearchModal';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: #f5f5f5; // Fixed color, for every theme\n  position: relative;\n"], ["\n  background-color: #f5f5f5; // Fixed color, for every theme\n  position: relative;\n"])));
var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  flex: 1.62;\n"], ["\n  flex: 1.62;\n"])));
var HomePage = /** @class */ (function (_super) {
    __extends(HomePage, _super);
    function HomePage(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            target: null,
            showGenderSelectionInspirationModal: false,
            showGenderSelectionArticleSearchModal: false,
            showLanguageModal: false,
        };
        _this.targetSelected = function (target) {
            if (target === 'search') {
                _this.showGenderSelectionArticleSearchModal();
                return;
            }
            if (target === 'inspiration') {
                _this.showGenderSelectionInspirationModal();
                return;
            }
        };
        _this.toggleLanguageModal = function () {
            _this.setState({
                showLanguageModal: !_this.state.showLanguageModal,
            });
        };
        _this.showGenderSelectionArticleSearchModal = function () {
            _this.setState({ showGenderSelectionArticleSearchModal: true });
        };
        _this.closeGenderSelectionArticleSearchModal = function () {
            _this.setState({ showGenderSelectionArticleSearchModal: false });
        };
        _this.showGenderSelectionInspirationModal = function () {
            _this.setState({ showGenderSelectionInspirationModal: true });
        };
        _this.closeGenderSelectionInspirationModal = function () {
            _this.setState({ showGenderSelectionInspirationModal: false });
        };
        return _this;
    }
    HomePage.prototype.getCurrentInspirationId = function (currentGender, categories) {
        if (currentGender) {
            var result = categories.find(function (category) { return category.id === currentGender; });
            if (result) {
                return result.inspirationId;
            }
        }
        return null;
    };
    HomePage.prototype.render = function () {
        var _this = this;
        var _a = this.props, activeSlide = _a.activeSlide, currentGender = _a.currentGender, currentLanguage = _a.currentLanguage, loaded = _a.loaded, primaryCategories = _a.primaryCategories, selectLanguage = _a.selectLanguage, teasers = _a.teasers, theme = _a.theme;
        var currentInspirationId = this.getCurrentInspirationId(currentGender, primaryCategories);
        return (_jsxs(Wrapper, { children: [_jsx(HomeHeader, { activeSlide: activeSlide, logo: theme.logoUrl, currentGender: currentInspirationId || currentGender, toggleSelection: function (target) {
                        _this.targetSelected(target);
                    }, theme: theme }, void 0), _jsx(Content, { children: loaded && teasers.length !== 0 && (_jsx(HomeSlider, { teasers: teasers, currentGender: currentGender, toggleSelection: function (target) {
                            _this.targetSelected(target);
                        }, theme: theme }, void 0)) }, void 0), _jsx(LanguageModal, { currentLanguage: currentLanguage, open: this.state.showLanguageModal, selectLanguage: selectLanguage, toggleAction: this.toggleLanguageModal }, void 0), _jsx(GenderSelectionArticleSearchModal, { open: this.state.showGenderSelectionArticleSearchModal, onClose: function () {
                        _this.closeGenderSelectionArticleSearchModal();
                    }, primaryCategories: localStorage.getItem("customerId") == "59192" ? primaryCategories.slice(0, 2) : primaryCategories }, void 0), _jsx(GenderSelectionInspirationModal, { open: this.state.showGenderSelectionInspirationModal, onClose: function () {
                        _this.closeGenderSelectionInspirationModal();
                    }, primaryCategories: localStorage.getItem("customerId") == "59192" ? primaryCategories.slice(0, 2) : primaryCategories }, void 0), _jsx(_Fragment, { children: console.log(localStorage.getItem("customerId") == "59192" ? primaryCategories.slice(0, 2) : primaryCategories) }, void 0)] }, void 0));
    };
    return HomePage;
}(Component));
export default withTheme(HomePage);
var templateObject_1, templateObject_2;
