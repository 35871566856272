var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { put, call, delay, takeLatest, select } from 'redux-saga/effects';
import { fromJS } from 'immutable';
import isEmpty from 'lodash/isEmpty';
import history from '@store/history';
import store from 'store';
import { pollInterval } from '@shared/utils/constants';
import errorHandler from 'services/connection';
import { selectSession } from '@store/session/selectors';
import { getSessionComplete, resetSession } from '@store/session/actions';
import { getWatchlist, resetWatchlist } from '@store/watchlist/actions';
import { getThemeComplete } from '@store/theme/actions';
import { resetGender, setDefaultGender } from '@store/gender/actions';
import { getDeviceComplete, getDevice as getDeviceAction, getDeviceTheme as getDeviceThemeAction, } from './actions';
export function getDevice(api, action) {
    var currentSession, body, immutableTerminal, error_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 16, , 21]);
                return [4 /*yield*/, select(selectSession)];
            case 1:
                currentSession = _b.sent();
                return [4 /*yield*/, call([api, api.get], "devices/" + action.payload)];
            case 2:
                body = (_b.sent()).body;
                store.set('customerId', ((_a = body === null || body === void 0 ? void 0 : body.session) === null || _a === void 0 ? void 0 : _a.customerId) || "");
                immutableTerminal = fromJS(body);
                return [4 /*yield*/, put(getDeviceComplete(immutableTerminal))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(getSessionComplete(immutableTerminal))];
            case 4:
                _b.sent();
                if (!(Boolean(currentSession) &&
                    currentSession.size === 0 &&
                    !isEmpty(body.session))) return [3 /*break*/, 6];
                return [4 /*yield*/, put(getWatchlist(body.session.uuid))];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6:
                if (!(Boolean(currentSession) &&
                    currentSession.size !== 0 &&
                    isEmpty(body.session))) return [3 /*break*/, 11];
                return [4 /*yield*/, put(resetSession())];
            case 7:
                _b.sent();
                return [4 /*yield*/, put(resetWatchlist())];
            case 8:
                _b.sent();
                return [4 /*yield*/, put(resetGender())];
            case 9:
                _b.sent();
                return [4 /*yield*/, call(history.push, '/')];
            case 10:
                _b.sent();
                _b.label = 11;
            case 11:
                if (!(Boolean(currentSession) && Boolean(currentSession.get('uuid')))) return [3 /*break*/, 13];
                return [4 /*yield*/, put(getWatchlist(currentSession.get('uuid')))];
            case 12:
                _b.sent();
                _b.label = 13;
            case 13: return [4 /*yield*/, delay(pollInterval)];
            case 14:
                _b.sent();
                return [4 /*yield*/, put(getDeviceAction(action.payload))];
            case 15:
                _b.sent();
                return [3 /*break*/, 21];
            case 16:
                error_1 = _b.sent();
                return [4 /*yield*/, put(errorHandler(error_1, getDeviceComplete, getDeviceAction, action.payload))];
            case 17:
                _b.sent();
                if (!Boolean(error_1.response)) return [3 /*break*/, 20];
                return [4 /*yield*/, delay(pollInterval)];
            case 18:
                _b.sent();
                return [4 /*yield*/, put(getDeviceAction(action.payload))];
            case 19:
                _b.sent();
                _b.label = 20;
            case 20: return [3 /*break*/, 21];
            case 21: return [2 /*return*/];
        }
    });
}
export function getDeviceTheme(api, action) {
    var body, localStorageCurrency, localStorageCurrencySign, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call([api, api.get], "devices/" + action.payload + "/themes")];
            case 1:
                body = (_a.sent()).body;
                localStorageCurrency = store.get('currency');
                if (localStorageCurrency !== body.currency) {
                    store.set('currency', body.currency);
                    window.location.reload();
                }
                localStorageCurrencySign = store.get('currencySign');
                if (localStorageCurrencySign !== body.currencySign) {
                    store.set('currencySign', body.currencySign);
                    window.location.reload();
                }
                if (!body.defaultGender) return [3 /*break*/, 3];
                return [4 /*yield*/, put(setDefaultGender(body.defaultGender))];
            case 2:
                _a.sent();
                _a.label = 3;
            case 3: return [4 /*yield*/, put(getThemeComplete(fromJS(body)))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                error_2 = _a.sent();
                return [4 /*yield*/, put(errorHandler(error_2, getThemeComplete, getDeviceThemeAction, action.payload))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function deviceWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_DEVICE', getDevice, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function deviceThemeWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeLatest('GET_DEVICE_THEME', getDeviceTheme, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
