var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import sortBy from 'lodash/sortBy';
// components
import CategoryGrid from 'organisms/CategoryGrid';
import ListLayout from 'templates/ListLayout';
var Search = /** @class */ (function (_super) {
    __extends(Search, _super);
    function Search(props) {
        var _this = _super.call(this, props) || this;
        _this.checkIfTabIsActive = function (tabTarget) {
            return _this.props.currentLocation === tabTarget;
        };
        _this.getTabs = function () {
            var primaryCategories = _this.props.primaryCategories;
            if (!Boolean(primaryCategories) || primaryCategories.length === 0) {
                return [];
            }
            return sortBy(primaryCategories, 'sort').reduce(function (acc, item) {
                if (!item.id) {
                    return acc;
                }
                acc.push({
                    title: item.displayName,
                    target: "/search/" + item.id,
                });
                return acc;
            }, []);
        };
        return _this;
    }
    Search.prototype.componentWillReceiveProps = function () {
        var _a = this.props, match = _a.match, currentGender = _a.currentGender;
        var selected = match.params.primaryCategoryId || null;
        if (Boolean(selected) && selected !== currentGender) {
            this.props.setGender(parseInt(selected, 10));
        }
    };
    Search.prototype.render = function () {
        var _a = this.props, articleStacks = _a.articleStacks, categories = _a.categories, categoriesLoading = _a.categoriesLoading, categoriesPageCount = _a.categoriesPageCount, checkingOut = _a.checkingOut, checkout = _a.checkout, checkoutNumber = _a.checkoutNumber, createArticleStack = _a.createArticleStack, createCustomer = _a.createCustomer, createWatchlistItem = _a.createWatchlistItem, currentGender = _a.currentGender, currentLanguage = _a.currentLanguage, currentPageUrl = _a.currentPageUrl, currentStoreId = _a.currentStoreId, deleteArticleStack = _a.deleteArticleStack, deleteSession = _a.deleteSession, deleteWatchlistItem = _a.deleteWatchlistItem, furniture = _a.furniture, furnituresReady = _a.furnituresReady, getCategories = _a.getCategories, getDuration = _a.getDuration, getFurnitures = _a.getFurnitures, getStackArticle = _a.getStackArticle, getStackArticles = _a.getStackArticles, hiddenItem = _a.hiddenItem, match = _a.match, orderNumber = _a.orderNumber, orderError = _a.orderError, primaryCategories = _a.primaryCategories, primaryCategoriesLoading = _a.primaryCategoriesLoading, resetHidden = _a.resetHidden, saveHidden = _a.saveHidden, selectLanguage = _a.selectLanguage, sendOrder = _a.sendOrder, session = _a.session, setGender = _a.setGender, shareSession = _a.shareSession, stores = _a.stores, submitCustomerBasicForm = _a.submitCustomerBasicForm, submitCustomerForm = _a.submitCustomerForm, updateSession = _a.updateSession, updateWatchlistItem = _a.updateWatchlistItem, waitingDuration = _a.waitingDuration, watchlist = _a.watchlist;
        var tabs = this.getTabs();
        return (_jsx(ListLayout, __assign({ articleStacks: articleStacks, checkingOut: checkingOut, checkout: checkout, checkoutNumber: checkoutNumber, createArticleStack: createArticleStack, createCustomer: createCustomer, createWatchlistItem: createWatchlistItem, currentGender: currentGender, currentLanguage: currentLanguage, currentPageUrl: currentPageUrl, currentStoreId: currentStoreId, deleteArticleStack: deleteArticleStack, deleteSession: deleteSession, deleteWatchlistItem: deleteWatchlistItem, furniture: furniture, furnituresReady: furnituresReady, getDuration: getDuration, getFurnitures: getFurnitures, getStackArticle: getStackArticle, getStackArticles: getStackArticles, hiddenItem: hiddenItem, orderNumber: orderNumber, orderError: orderError, primaryCategories: primaryCategories, resetHidden: resetHidden, saveHidden: saveHidden, selectLanguage: selectLanguage, sendOrder: sendOrder, session: session, setGender: setGender, shareSession: shareSession, stores: stores, submitCustomerBasicForm: submitCustomerBasicForm, submitCustomerForm: submitCustomerForm, updateSession: updateSession, updateWatchlistItem: updateWatchlistItem, waitingDuration: waitingDuration, watchlist: watchlist }, { children: _jsx(CategoryGrid, { basePath: '', categories: categories, categoriesLoading: categoriesLoading, checkIfTabIsActive: this.checkIfTabIsActive, getCategories: getCategories, match: match, pageCount: categoriesPageCount, primaryCategoriesLoading: primaryCategoriesLoading, primaryCategories: primaryCategories, primaryCategoryId: parseInt(match.params.primaryCategoryId, 10) || null, tabs: tabs }, void 0) }), void 0));
    };
    return Search;
}(Component));
export default Search;
