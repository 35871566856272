var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { palette, fontWeight } from '@shared/utils/styled';
import Icon from '@shared/atoms/Icon';
import { FormattedMessage } from 'react-intl';
import { appNames } from '@shared/utils/constants';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  max-height: ", ";\n  overflow: hidden;\n  position: relative;\n  transition: max-height 0.5s linear;\n"], ["\n  max-height: ", ";\n  overflow: hidden;\n  position: relative;\n  transition: max-height 0.5s linear;\n"])), function (props) { return (props.visible ? '37.5rem' : 0); });
var Headline = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: black;\n  font-weight: ", ";\n  margin-bottom: 0.9375rem;\n  width: 100%;\n"], ["\n  color: black;\n  font-weight: ", ";\n  margin-bottom: 0.9375rem;\n  width: 100%;\n"])), fontWeight('bold'));
var InnerWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border: 0.0625rem solid ", ";\n  padding: 1.25rem;\n  margin-bottom: 1rem;\n"], ["\n  border: 0.0625rem solid ", ";\n  padding: 1.25rem;\n  margin-bottom: 1rem;\n"])), palette('border'));
var TextWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: grid;\n  max-width: 50rem;\n  grid-template-columns: 1fr 3.5fr;\n  grid-column-gap: 5px;\n  padding-bottom: 1.25rem;\n"], ["\n  display: grid;\n  max-width: 50rem;\n  grid-template-columns: 1fr 3.5fr;\n  grid-column-gap: 5px;\n  padding-bottom: 1.25rem;\n"])));
var CancelIcon = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  cursor: pointer;\n  font-size: 0; // Fix whitespace problem\n  padding: 0.625rem;\n  position: absolute;\n  right: 0.625rem;\n  top: 0.625rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  color: ", ";\n  cursor: pointer;\n  font-size: 0; // Fix whitespace problem\n  padding: 0.625rem;\n  position: absolute;\n  right: 0.625rem;\n  top: 0.625rem;\n  transition: background-color 0.2s ease;\n  &:active {\n    background-color: ", ";\n  }\n"])), palette('border'), palette('activeWhite'));
var AttributeList = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  margin-bottom: 1rem;\n  margin-left: 2rem;\n  @media (max-width: 500px) {\n    margin-left: 1px;\n  }\n"], ["\n  margin-bottom: 1rem;\n  margin-left: 2rem;\n  @media (max-width: 500px) {\n    margin-left: 1px;\n  }\n"])));
var TextRow = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: black;\n  display: flex;\n  padding: 2px 8px;\n"], ["\n  color: black;\n  display: flex;\n  padding: 2px 8px;\n"])));
var RowHeader = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  flex: 0 0 auto;\n  display: flex;\n  min-width: 400px;\n  overflow: hidden;\n  margin-right: 5px;\n  @media (max-width: 500px) {\n    min-width: 110px;\n  }\n"], ["\n  flex: 0 0 auto;\n  display: flex;\n  min-width: 400px;\n  overflow: hidden;\n  margin-right: 5px;\n  @media (max-width: 500px) {\n    min-width: 110px;\n  }\n"])));
var DescriptionWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  white-space: pre-wrap;\n"], ["\n  white-space: pre-wrap;\n"])));
var TableComp = function (props) {
    var name = props.name, value = props.value, unit = props.unit, index = props.index; // eslint-disable-line
    return (_jsxs(TextRow, __assign({ className: index % 2 ? 'odd' : undefined }, { children: [_jsx(RowHeader, { children: _jsx(FormattedMessage, { id: "prop." + name }, void 0) }, void 0), typeof value !== typeof true && _jsx("span", { children: value }, void 0), typeof value === typeof true && (_jsx(FormattedMessage, { id: "bool." + (value ? 'yes' : 'no') }, void 0)), unit && _jsx(FormattedMessage, { id: "unit." + unit }, void 0)] }), name));
};
var ArticleDescription = /** @class */ (function (_super) {
    __extends(ArticleDescription, _super);
    function ArticleDescription() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ArticleDescription.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.container && nextProps.visible && !this.props.visible) {
            this.container.scrollIntoView({
                behavior: 'smooth',
                inline: 'nearest',
            });
        }
    };
    ArticleDescription.prototype.render = function () {
        var _this = this;
        var _a = this.props, appName = _a.appName, toggleDescription = _a.toggleDescription, visible = _a.visible, articleNumber = _a.articleNumber, fabart = _a.fabart, description = _a.description, content = _a.content, attributes = _a.attributes;
        return (_jsx(Wrapper, __assign({ "data-cy-id": 'article-description', visible: visible, innerRef: function (wrap) {
                if (wrap) {
                    _this.container = wrap;
                }
            } }, { children: _jsxs(InnerWrapper, { children: [_jsx(Headline, { children: "Produktbeschreibung" }, void 0), _jsxs(AttributeList, { children: [content
                                .filter(function (c) {
                                return appName !== appNames.ASSISTANT ? !c.assistant : c;
                            })
                                .map(function (c, index) {
                                var localC = c;
                                return (_jsx(TableComp, __assign({}, localC, { index: index }), "" + localC.name));
                            }), appName === appNames.ASSISTANT &&
                                Array.isArray(attributes) &&
                                attributes.map(function (attr, index) { return (_jsx(TableComp, { name: attr.groupName, value: attr.attributeText, index: index }, "" + attr.groupId + attr.attributeText)); })] }, void 0), description && (_jsxs(Fragment, { children: [_jsx("div", { children: "Mehr zu diesem Produkt" }, void 0), _jsx(DescriptionWrapper, { children: description }, void 0)] }, void 0)), toggleDescription && (_jsx(CancelIcon, __assign({ onClick: toggleDescription }, { children: _jsx(Icon, { icon: 'closex', width: 12 }, void 0) }), void 0))] }, void 0) }), void 0));
    };
    ArticleDescription.defaultProps = {
        attributes: [],
        content: [],
    };
    return ArticleDescription;
}(Component));
/** @component */
export default ArticleDescription;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
