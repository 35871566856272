var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { palette } from '@shared/utils/styled';
import styled, { css } from 'styled-components';
import Icon from '@shared/atoms/Icon';
var FullScreen = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background: ", ";\n  bottom: 0;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 9999;\n"], ["\n  background: ", ";\n  bottom: 0;\n  left: 0;\n  position: fixed;\n  right: 0;\n  top: 0;\n  z-index: 9999;\n"])), palette('transparentBlack'));
var Wrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n\tbackground: ", ";\n\twidth:60%;\n\theight:80%;\n\tmargin 5% auto;\n\tpadding:1rem;\n\tdisplay:flex;\n\tflex-flow:column nowrap;\n"], ["\n\tbackground: ", ";\n\twidth:60%;\n\theight:80%;\n\tmargin 5% auto;\n\tpadding:1rem;\n\tdisplay:flex;\n\tflex-flow:column nowrap;\n"])), palette('white'));
var Header = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: right;\n"], ["\n  text-align: right;\n"])));
var Content = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  flex: 1 0;\n  padding: 0.5rem;\n  overflow: auto;\n  align-self: center;\n  display: flex;\n  flex-flow: column;\n  ", ";\n  word-break: break-word;\n"], ["\n  flex: 1 0;\n  padding: 0.5rem;\n  overflow: auto;\n  align-self: center;\n  display: flex;\n  flex-flow: column;\n  ", ";\n  word-break: break-word;\n"])), function (props) {
    return props.x === 'imprint' && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      justify-content: center;\n    "], ["\n      justify-content: center;\n    "])));
});
var SubContent = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  color: black;\n  column-count: ", ";\n  column-gap: 1rem;\n  font-size: 10pt;\n  strong {\n    font-weight: bold;\n  }\n  div {\n    margin-top: 0.5rem;\n    color: #200;\n    text-align: justify;\n  }\n"], ["\n  color: black;\n  column-count: ", ";\n  column-gap: 1rem;\n  font-size: 10pt;\n  strong {\n    font-weight: bold;\n  }\n  div {\n    margin-top: 0.5rem;\n    color: #200;\n    text-align: justify;\n  }\n"])), function (props) { return (props.name === 'imprint' ? 1 : 2); });
var StyledIcon = styled(Icon)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  align-self: right;\n  border-radius: 50%;\n  cursor: pointer;\n  height: 2.5rem;\n  padding: 0.8125rem;\n  transition: background-color 0.2s ease;\n  width: 2.5rem;\n  &:active {\n    background-color: rgba(0, 0, 0, 0.1);\n  }\n"], ["\n  align-self: right;\n  border-radius: 50%;\n  cursor: pointer;\n  height: 2.5rem;\n  padding: 0.8125rem;\n  transition: background-color 0.2s ease;\n  width: 2.5rem;\n  &:active {\n    background-color: rgba(0, 0, 0, 0.1);\n  }\n"])));
var TextDisplayModal = /** @class */ (function (_super) {
    __extends(TextDisplayModal, _super);
    function TextDisplayModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TextDisplayModal.prototype.render = function () {
        var _this = this;
        var _a = this.props, name = _a.name, text = _a.text;
        return (_jsx(FullScreen, __assign({ onClick: function () { return _this.props.close(); } }, { children: _jsxs(Wrapper, { children: [_jsx(Header, { children: _jsx(StyledIcon, { icon: 'closex', width: 15, onClick: function () { return _this.props.close(); }, noMetrics: true }, void 0) }, void 0), _jsx(Content, __assign({ x: name }, { children: _jsx(SubContent, { name: name, className: 'injectedHtml', dangerouslySetInnerHTML: { __html: text } }, void 0) }), void 0)] }, void 0) }), void 0));
    };
    return TextDisplayModal;
}(Component));
export default TextDisplayModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
