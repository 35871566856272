var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
// utils
import styled from 'styled-components';
import { palette, fontSize, fontWeight } from '@shared/utils/styled';
import { FormattedMessage } from 'react-intl';
// componentes
import NavIcon from 'atoms/NavIcon';
import H2 from '@shared/atoms/H2';
import HorizontalFlex from '@shared/atoms/HorizontalFlex';
import SortDropdown from 'molecules/SortDropdown';
// RF
import DropDown from './components/DropDownHandler';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
var OuterWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  grid-area: header;\n  padding: 0 1.875rem;\n"], ["\n  grid-area: header;\n  padding: 0 1.875rem;\n"])));
var Head = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 0.25rem;\n  grid-template-columns: 2.5rem 1fr 2.5rem;\n  height: 5.625rem;\n"], ["\n  display: grid;\n  grid-gap: 0.25rem;\n  grid-template-columns: 2.5rem 1fr 2.5rem;\n  height: 5.625rem;\n"])));
var Grid6 = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 1.25rem;\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;\n"], ["\n  display: grid;\n  grid-gap: 1.25rem;\n  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;\n"])));
var Column = styled(HorizontalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  justify-content: flex-end;\n"], ["\n  justify-content: flex-end;\n"])));
var HeadlineWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  flex-direction: column;\n"], ["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  flex-direction: column;\n"])));
var CenteredH2 = styled(H2)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  line-height: 1.5rem;\n  margin: 0;\n"], ["\n  line-height: 1.5rem;\n  margin: 0;\n"])));
var LinkWrap = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  margin-top: 1.5rem;\n"], ["\n  margin-top: 1.5rem;\n"])));
var ArticleInfo = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n"])), palette('text'), fontSize('default'), fontWeight('light'));
var FilterBox = function (_a) {
    var currentSortId = _a.currentSortId, navigationSource = _a.navigationSource, headline = _a.headline, params = _a.params, sorts = _a.sorts, total = _a.total;
    var history = useHistory();
    var search = useLocation().search;
    var goBack = function () {
        var _a = queryString.parse(search), category = _a.category, primaryCategory = _a.primaryCategory, sort = _a.sort, queryParams = __rest(_a, ["category", "primaryCategory", "sort"]);
        var query = queryString.stringify(queryParams);
        var url = '/';
        if (navigationSource) {
            url = navigationSource;
            if (query) {
                url += "/?&" + query;
            }
        }
        history.push(url);
    };
    return (_jsx(_Fragment, { children: _jsxs(OuterWrapper, { children: [_jsxs(Head, { children: [_jsx(LinkWrap, { children: _jsx(NavIcon, { icon: 'iconBack', onClick: goBack }, void 0) }, void 0), _jsxs(HeadlineWrapper, { children: [_jsx(CenteredH2, { children: headline }, void 0), _jsx(ArticleInfo, { children: total > 0 && (_jsx(FormattedMessage, { id: 'stock.search.amount', defaultMessage: "{total, number} {total, plural, one {Artikel} other {Artikel}}", values: { total: total } }, void 0)) }, void 0)] }, void 0), _jsx(Column, { children: Boolean(sorts) && sorts.length !== 0 && (_jsx(SortDropdown, { currentSortId: currentSortId, params: params, sorts: sorts }, void 0)) }, void 0)] }, void 0), _jsx(DropDown, { endPoint: 'article' }, void 0)] }, void 0) }, void 0));
};
export default FilterBox;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
