var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { toJS } from '@shared/utils/immutable';
import { connect } from 'react-redux';
import { searchArticle } from '@store/article/actions';
import Session from 'containers/Session';
import ScanResultsPage from 'pages/ScanResults';
import { selectArticlePageCount, selectArticles, selectLoadingAll, selectTotal, } from '@store/article/selectors';
import history from '@store/history';
import { withRouter } from 'react-router-dom';
import { parse } from 'query-string';
var ScanResults = /** @class */ (function (_super) {
    __extends(ScanResults, _super);
    function ScanResults() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Redirects to the articles detail page.
         *
         * @param id - Article id to view more details.
         */
        _this.handleArticleItemClick = function (id) {
            history.push("/articles/" + id);
        };
        return _this;
    }
    ScanResults.prototype.componentDidUpdate = function (prevProps) {
        if (prevProps.articles.length <= 0 && this.props.articles.length <= 0) {
            history.push("/scan");
        }
    };
    /**
     * Loads more articles based on a given page.
     *
     * @param page - Page until articles should be loaded.
     */
    ScanResults.prototype.handleLoadMore = function (page) {
        var _a = this.props, location = _a.location, searchArticleAction = _a.searchArticle;
        var text = parse(location.search, {
            arrayFormat: 'bracket',
        }).text;
        searchArticleAction({
            scan: text,
            page: page,
        });
    };
    ScanResults.prototype.render = function () {
        var _this = this;
        var _a = this.props, articles = _a.articles, isLoading = _a.isLoading, location = _a.location, totalArticles = _a.totalArticles, totalPages = _a.totalPages;
        var text = parse(location.search, {
            arrayFormat: 'bracket',
        }).text;
        return (_jsx(Session, { children: function (sessionProps) {
                var createWatchlistItem = sessionProps.createWatchlistItem, deleteWatchlistItem = sessionProps.deleteWatchlistItem, watchlist = sessionProps.watchlist, others = __rest(sessionProps, ["createWatchlistItem", "deleteWatchlistItem", "watchlist"]);
                return (_jsx(ScanResultsPage, __assign({}, others, { articles: articles, isLoading: isLoading, loadMore: function (page) { return _this.handleLoadMore(page); }, onArticleClick: function (id) { return _this.handleArticleItemClick(id); }, onAddWatchlist: createWatchlistItem, onRemoveWatchlist: deleteWatchlistItem, query: text, totalArticles: totalArticles, totalPages: totalPages, watchlist: watchlist }), void 0));
            } }, void 0));
    };
    return ScanResults;
}(Component));
var select = function () { return function (state) { return ({
    articles: selectArticles(state),
    isLoading: selectLoadingAll(state),
    totalArticles: selectTotal(state),
    totalPages: selectArticlePageCount(state),
}); }; };
export var Container = searchArticle;
export default connect(select, { searchArticle: searchArticle })(toJS(withRouter(ScanResults)));
