var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { palette, fontWeight, fontSize } from '@shared/utils/styled';
var H3 = styled.h3(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.9375px;\n  line-height: 18pt;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  letter-spacing: 0.9375px;\n  line-height: 18pt;\n"])), palette('highlightText'), fontSize('large'), fontWeight('light'));
/** @component */
export default H3;
var templateObject_1;
