import { createSelector } from 'reselect';
// const selectCategoryId = (state: any, props: any) => {
//   const params: any = getSearchParams(props.location.search);
//   return parseInt(params.category, 10);
// };
var selectState = function (state) { return state.get('category'); };
export var selectAll = createSelector(selectState, function (state) {
    return state.get('data');
});
export var selectSortedAll = createSelector(selectAll, function (categories) {
    return categories.sortBy(function (category) { return category.get('sort'); });
});
export var selectPageCount = createSelector(selectState, function (state) {
    return state.get('pageCount');
});
export var selectCurrentPage = createSelector(selectState, function (state) {
    return state.get('currentPage');
});
export var selectLoadingAll = createSelector(selectState, function (state) {
    return state.get('loadingAll');
});
export var selectLoading = createSelector(selectState, function (state) {
    return state.get('loading');
});
export var selectItem = function () {
    return createSelector([selectAll, function (_state, categoryId) { return categoryId; }], function (categories, categoryId) {
        return categories.find(function (category) { return category.get('id') === categoryId; });
    });
};
