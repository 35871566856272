import { createSelector } from 'reselect';
var selectState = function (state) { return state.get('watchlist'); };
export var selectLoading = createSelector(selectState, function (watchlistState) {
    return watchlistState.get('loading');
});
export var selectLoadingAll = createSelector(selectState, function (watchlistState) {
    return watchlistState.get('loadingAll');
});
export var selectLoaded = createSelector(selectState, function (watchlistState) {
    return watchlistState.get('loaded');
});
export var selectLoadingAllComplete = createSelector(selectState, function (watchlistState) {
    return !watchlistState.get('loadingAll') && watchlistState.get('loadedAll');
});
export var selectWatchlist = createSelector(selectState, function (watchlistState) {
    return watchlistState.get('data');
});
