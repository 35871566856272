var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import { palette, key } from '@shared/utils/styled';
import InspirationGenderSelect from 'molecules/InspirationGenderSelect';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding: 1.25rem;\n  margin: 0 auto;\n  overflow-y: auto;\n  flex: 1;\n  font-size: 0;\n  display: flex;\n  flex-direction: column;\n  @media screen and (min-width: ", ") {\n    padding: 2.5rem;\n  }\n  @media screen and (min-width: ", ") {\n    max-width: 90%;\n  }\n"], ["\n  padding: 1.25rem;\n  margin: 0 auto;\n  overflow-y: auto;\n  flex: 1;\n  font-size: 0;\n  display: flex;\n  flex-direction: column;\n  @media screen and (min-width: ", ") {\n    padding: 2.5rem;\n  }\n  @media screen and (min-width: ", ") {\n    max-width: 90%;\n  }\n"])), key('breakpoints.medium', '1280px'), key('breakpoints.medium', '1720px'));
var IntroImage = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 0.625rem solid ", ";\n  width: 100%;\n  flex: 4;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: 50% 50%;\n  @media screen and (min-width: ", ") {\n    background-image: url(", ");\n  }\n"], ["\n  border: 0.625rem solid ", ";\n  width: 100%;\n  flex: 4;\n  background-image: url(", ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  background-position: 50% 50%;\n  @media screen and (min-width: ", ") {\n    background-image: url(", ");\n  }\n"])), palette('white'), function (props) { return props.bgImage; }, key('breakpoints.medium', '1720px'), function (props) { return props.bgImageLarge; });
var InspirationIntro = /** @class */ (function (_super) {
    __extends(InspirationIntro, _super);
    function InspirationIntro() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InspirationIntro.prototype.render = function () {
        var data = this.props.data;
        return (data && (_jsxs(Wrapper, { children: [_jsx(IntroImage, { bgImage: data.imageUrl, bgImageLarge: data.imageUrlLarge }, void 0), data.pages && _jsx(InspirationGenderSelect, { genders: data.pages }, void 0)] }, void 0)));
    };
    return InspirationIntro;
}(Component));
export default InspirationIntro;
var templateObject_1, templateObject_2;
