var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import { palette } from '@shared/utils/styled';
import styled from 'styled-components';
import moment from "moment";
import { FormattedMessage, defineMessages } from 'react-intl';
// components
import HighlightButton from '@shared/atoms/HighlightButton';
import InfoBubble from 'molecules/InfoBubble';
import TryonFinishModal from 'organisms/TryonFinishModal';
import ReserveFinishModal from 'organisms/ReserveFinishModal';
import VerticalFlex from '@shared/atoms/VerticalFlex';
import WatchlistExpanded from 'organisms/WatchlistExpanded';
import WatchlistExpandedHeader from 'molecules/WatchlistExpandedHeader';
import CheckoutHeadline from 'atoms/CheckoutHeadline';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: ", ";\n  position: absolute;\n  right: 0;\n  top: 0;\n  transition: background-color 900ms, visibility 0.15s, opacity 0.15s linear;\n  visibility: ", ";\n  z-index: ", ";\n"], ["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: ", ";\n  position: absolute;\n  right: 0;\n  top: 0;\n  transition: background-color 900ms, visibility 0.15s, opacity 0.15s linear;\n  visibility: ", ";\n  z-index: ", ";\n"])), palette('secondaryBackground'), function (props) { return (props.show ? 1 : 0); }, function (props) { return (props.show ? 'visibile' : 'hidden'); }, function (props) { return (props.show ? 2 : 1); });
var ContentWrapper = styled(VerticalFlex)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex: 7;\n  overflow-y: auto;\n  position: relative;\n"], ["\n  align-items: center;\n  display: flex;\n  flex: 7;\n  overflow-y: auto;\n  position: relative;\n"])));
var Content = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  flex: 1;\n  grid-gap: 0.125rem;\n  grid-template-areas: 'articles info';\n  grid-template-columns: 1fr 22.5rem;\n  max-width: 76.25rem;\n  overflow: hidden;\n  padding: 1rem 0 1.875rem 0;\n  width: 100%;\n"], ["\n  display: grid;\n  flex: 1;\n  grid-gap: 0.125rem;\n  grid-template-areas: 'articles info';\n  grid-template-columns: 1fr 22.5rem;\n  max-width: 76.25rem;\n  overflow: hidden;\n  padding: 1rem 0 1.875rem 0;\n  width: 100%;\n"])));
var InfoColumn = styled(VerticalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: rgba(255, 255, 255, 0.2);\n  grid-area: info;\n  position: sticky;\n"], ["\n  background-color: rgba(255, 255, 255, 0.2);\n  grid-area: info;\n  position: sticky;\n"])));
var CheckoutButton = styled(HighlightButton)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border: 0.3125rem solid #f5f5f5; // Fixed color, for every theme\n  max-height: 4.5rem;\n  width: 100%;\n"], ["\n  border: 0.3125rem solid #f5f5f5; // Fixed color, for every theme\n  max-height: 4.5rem;\n  width: 100%;\n"])));
var CheckoutText = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  opacity: ", ";\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  opacity: ", ";\n"])), function (props) { return (props.disabled ? 0.3 : 1); });
var ColumnWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  background-color: rgba(255, 255, 255, 0.2);\n  padding: 1.25rem;\n"], ["\n  background-color: rgba(255, 255, 255, 0.2);\n  padding: 1.25rem;\n"])));
var WatchlistAreaExpanded = /** @class */ (function (_super) {
    __extends(WatchlistAreaExpanded, _super);
    function WatchlistAreaExpanded(props) {
        var _this = _super.call(this, props) || this;
        _this.toggleFinishModal = function () {
            _this.setState({
                showFinishModal: !_this.state.showFinishModal,
            });
        };
        _this.state = {
            showFinishModal: false,
        };
        _this.sendRequests = function () {
            var _a = _this.props, initialStateValue = _a.initialStateValue, nextStateValue = _a.nextStateValue, toggleFullWatchlist = _a.toggleFullWatchlist, updateSession = _a.updateSession, updateWatchlistItem = _a.updateWatchlistItem, watchlist = _a.watchlist;
            var selectedWatchlistItems = watchlist.filter(function (item) { return item.selected == true; });
            var deselectedWatchlistItems = watchlist.filter(function (item) { return item.selected == false; });
            selectedWatchlistItems.forEach(function (item) {
                updateWatchlistItem({ usage: nextStateValue }, item.id);
            });
            deselectedWatchlistItems.forEach(function (item) {
                updateWatchlistItem({ usage: initialStateValue }, item.id);
            });
            var now = moment().format();
            updateSession({ serviceCallAt: now });
            _this.toggleFinishModal();
            toggleFullWatchlist(initialStateValue);
        };
        _this.currentView = function () {
            var messages = defineMessages({
                tryOn: {
                    id: 'checkout.tryOn.headline',
                    defaultMessage: 'Anprobieren',
                },
                reserve: {
                    id: 'checkout.reserve.headline',
                    defaultMessage: 'Reservieren',
                },
            });
            return __assign({}, messages[_this.props.nextStateValue]);
        };
        _this.isReady = function () {
            /* NEED-334 */
            var selectedItems = _this.props.watchlist.filter(function (item) { return item.selected; });
            var validItems = selectedItems.filter(function (item) {
                return item.sizeId ||
                    (item.article.isAccessoire && !item.article.sizez.length);
            });
            return (selectedItems.length > 0 && selectedItems.length === validItems.length);
        };
        return _this;
    }
    WatchlistAreaExpanded.prototype.renderBubble = function () {
        var nextStateValue = this.props.nextStateValue;
        var isReady = this.isReady();
        var description = isReady
            ? defineMessages({
                description: {
                    id: 'checkout.reserve.infoBubble.isReady',
                    defaultMessage: 'Wählen Sie „Reservieren“: Ein Verkäufer legt Ihnen Ihre Artikel zurück.',
                },
            })
            : defineMessages({
                description: {
                    id: 'checkout.reserve.infoBubble.notReady',
                    defaultMessage: 'Wählen Sie Artikel und geben Sie Ihre Größe an.',
                },
            });
        var defaultDescription = isReady
            ? defineMessages({
                description: {
                    id: 'checkout.tryOn.infoBubble.isReady',
                    defaultMessage: 'Wählen Sie „Anprobieren“: Ein Verkäufer bringt Ihnen die Artikel in Ihrer Größe.',
                },
            })
            : defineMessages({
                description: {
                    id: 'checkout.tryOn.infoBubble.notReady',
                    defaultMessage: 'Wählen Sie Artikel und geben Sie Ihre Größe an.',
                },
            });
        switch (nextStateValue) {
            case 'reserve':
                return (_jsx(InfoBubble, __assign({}, description, { icon: isReady ? 'tryOn' : 'bubbleSize' }), void 0));
            default:
                return (_jsx(InfoBubble, __assign({}, defaultDescription, { icon: isReady ? 'tryOn' : 'bubbleSize' }), void 0));
        }
    };
    WatchlistAreaExpanded.prototype.renderFinishModal = function () {
        var _a = this.props, nextStateValue = _a.nextStateValue, assistantPhoto = _a.assistantPhoto;
        var showFinishModal = this.state.showFinishModal;
        switch (nextStateValue) {
            case 'reserve':
                return (_jsx(ReserveFinishModal, { image: assistantPhoto, open: showFinishModal, toggleAction: this.toggleFinishModal, finish: this.sendRequests }, void 0));
            case 'tryOn':
            default:
                return (_jsx(TryonFinishModal, { image: assistantPhoto, open: showFinishModal, toggleAction: this.toggleFinishModal, finish: this.sendRequests }, void 0));
        }
    };
    WatchlistAreaExpanded.prototype.render = function () {
        var _this = this;
        var _a = this.props, initialStateValue = _a.initialStateValue, logo = _a.logo, show = _a.show, toggleFullWatchlist = _a.toggleFullWatchlist, updateWatchlistItem = _a.updateWatchlistItem, watchlist = _a.watchlist;
        var isReady = this.isReady();
        var currentView = this.currentView();
        return (_jsxs(Wrapper, __assign({ show: show }, { children: [Object.entries(currentView).length > 0 && (_jsx(WatchlistExpandedHeader, { logo: logo, title: currentView, backAction: function () { return toggleFullWatchlist(initialStateValue); } }, void 0)), _jsx(ContentWrapper, { children: _jsxs(Content, { children: [_jsxs(ColumnWrapper, { children: [_jsx(CheckoutHeadline, { children: _jsx(FormattedMessage, { id: 'checkout.buy.articleSelect.title', defaultMessage: 'Ihre Artikel aus der Merkliste' }, void 0) }, void 0), _jsx(WatchlistExpanded, { watchlist: watchlist, updateWatchlistItem: updateWatchlistItem }, void 0)] }, void 0), _jsxs(InfoColumn, { children: [this.renderBubble(), _jsx(CheckoutButton, __assign({ disabled: !isReady, onClick: function () { return isReady && _this.toggleFinishModal(); } }, { children: _jsx(CheckoutText, __assign({ disabled: !isReady }, { children: Object.entries(currentView).length > 0 && (_jsx(FormattedMessage, __assign({}, currentView), void 0)) }), void 0) }), void 0)] }, void 0)] }, void 0) }, void 0), this.renderFinishModal()] }), void 0));
    };
    return WatchlistAreaExpanded;
}(Component));
export default WatchlistAreaExpanded;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
