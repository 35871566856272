var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import CheckoutHeadline from 'atoms/CheckoutHeadline';
import WatchlistExpanded from 'organisms/WatchlistExpanded';
import VerticalFlex from '@shared/atoms/VerticalFlex';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  grid-area: articles;\n  align-items: center;\n  overflow-y: auto;\n  > div {\n    width: 100%;\n  }\n"], ["\n  grid-area: articles;\n  align-items: center;\n  overflow-y: auto;\n  > div {\n    width: 100%;\n  }\n"])));
var DeliveryList = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  margin-bottom: 1.25rem;\n"], ["\n  margin-bottom: 1.25rem;\n"])));
var WatchlistAvailability = /** @class */ (function (_super) {
    __extends(WatchlistAvailability, _super);
    function WatchlistAvailability() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WatchlistAvailability.prototype.render = function () {
        var _a = this.props, deliveryArticles = _a.deliveryArticles, storeArticles = _a.storeArticles, updateWatchlistItem = _a.updateWatchlistItem;
        return (_jsxs(Wrapper, { children: [!isEmpty(storeArticles) && storeArticles.length > 0 && (_jsxs(DeliveryList, { children: [_jsx(CheckoutHeadline, { children: _jsx(FormattedMessage, { id: 'checkout.buy.availability.store.headline', defaultMessage: 'In dieser Filiale verf\u00FCgbar' }, void 0) }, void 0), _jsx(WatchlistExpanded, { watchlist: storeArticles, updateWatchlistItem: updateWatchlistItem, disableSizeSelect: true }, void 0)] }, void 0)), !isEmpty(deliveryArticles) && (_jsxs("div", { children: [_jsx(CheckoutHeadline, { children: _jsx(FormattedMessage, { id: 'checkout.buy.availability.delivery.headline', defaultMessage: 'Diese Artikel k\u00F6nnen f\u00FCr Sie bestellt werden' }, void 0) }, void 0), _jsx(WatchlistExpanded, { watchlist: deliveryArticles, updateWatchlistItem: updateWatchlistItem, disableSizeSelect: true }, void 0)] }, void 0))] }, void 0));
    };
    return WatchlistAvailability;
}(Component));
export default WatchlistAvailability;
var templateObject_1, templateObject_2;
