var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import orderBy from 'lodash/orderBy';
import styled from 'styled-components';
// components
import WatchlistExpandedItem from 'organisms/WatchlistExpandedItem';
import VerticalFlex from '@shared/atoms/VerticalFlex';
var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  grid-area: articles;\n  overflow-y: auto;\n"], ["\n  align-items: center;\n  grid-area: articles;\n  overflow-y: auto;\n"])));
var WatchlistExpanded = /** @class */ (function (_super) {
    __extends(WatchlistExpanded, _super);
    function WatchlistExpanded() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WatchlistExpanded.prototype.render = function () {
        var _a = this.props, watchlist = _a.watchlist, updateWatchlistItem = _a.updateWatchlistItem, disableSizeSelect = _a.disableSizeSelect;
        return (_jsx(Wrapper, { children: watchlist &&
                orderBy(watchlist, 'createdAt', 'desc').map(function (watchlistItem) { return (_jsx(WatchlistExpandedItem, { item: watchlistItem, updateWatchlistItem: updateWatchlistItem, watchlist: watchlist, disableSizeSelect: disableSizeSelect }, watchlistItem.id)); }) }, void 0));
    };
    return WatchlistExpanded;
}(Component));
export default WatchlistExpanded;
var templateObject_1;
