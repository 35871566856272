var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { Field, Form, FormSpy, useField } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
// utils
import { fontSize, palette } from '@shared/utils/styled';
import styled from 'styled-components';
// components
import VerticalFlex from '@shared/atoms/VerticalFlex';
import Icon from '@shared/atoms/Icon';
var StyledForm = styled.form(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  font-size: ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  font-size: ", ";\n"])), fontSize('large'));
var MainOptions = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  font-size: ", ";\n  grid-template-columns: 1fr 1fr;\n  margin-bottom: 0.3125rem;\n  > div:first-child {\n    border-right: 0.0625rem solid ", ";\n  }\n  > div:last-child {\n    border-left: 0.0625rem solid ", ";\n  }\n"], ["\n  display: grid;\n  font-size: ", ";\n  grid-template-columns: 1fr 1fr;\n  margin-bottom: 0.3125rem;\n  > div:first-child {\n    border-right: 0.0625rem solid ", ";\n  }\n  > div:last-child {\n    border-left: 0.0625rem solid ", ";\n  }\n"])), fontSize('large'), palette('border'), palette('border'));
var MainOption = styled(VerticalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  padding: 0.125rem 0 0.625rem 0;\n  text-align: center;\n  white-space: pre-line;\n"], ["\n  align-items: center;\n  color: ", ";\n  cursor: pointer;\n  font-size: ", ";\n  padding: 0.125rem 0 0.625rem 0;\n  text-align: center;\n  white-space: pre-line;\n"])), palette('text'), fontSize('large'));
var OptionIcon = styled(Icon)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 50%;\n  height: 8.75rem;\n  margin-bottom: 1.25rem;\n  padding: 1.25rem;\n  width: 8.75rem;\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  background-color: ", ";\n  border-radius: 50%;\n  height: 8.75rem;\n  margin-bottom: 1.25rem;\n  padding: 1.25rem;\n  width: 8.75rem;\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) {
    return props.checked
        ? palette('primaryHighlight')
        : palette('secondaryHighlight');
}, function (props) {
    return props.checked
        ? palette('activePrimaryHighlight')
        : palette('activeSecondaryHighlight');
});
var ChangeStore = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: #eaecee;\n  color: ", ";\n  cursor: pointer;\n  display: inline-block;\n  font-size: ", ";\n  padding: 0.3125rem 1.25rem;\n"], ["\n  background-color: #eaecee;\n  color: ", ";\n  cursor: pointer;\n  display: inline-block;\n  font-size: ", ";\n  padding: 0.3125rem 1.25rem;\n"])), palette('linkText'), fontSize('default'));
var StoreSelectWrapper = styled(VerticalFlex)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  margin-bottom: 0.625rem;\n  padding-right: 0.0625rem;\n  width: 50%;\n"], ["\n  align-items: center;\n  margin-bottom: 0.625rem;\n  padding-right: 0.0625rem;\n  width: 50%;\n"])));
var SelectStyles = styled.select(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  appearance: none;\n  background-color: ", ";\n  background-image: url('https://image.flaticon.com/icons/png/512/60/60995.png');\n  background-position: 96%;\n  background-repeat: no-repeat;\n  background-size: 1rem;\n  border-radius: 0;\n  border: 0.0625rem solid ", ";\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: 1.25rem;\n  max-width: 30rem;\n  outline: none;\n  padding: 0.625rem;\n  width: 80%;\n"], ["\n  appearance: none;\n  background-color: ", ";\n  background-image: url('https://image.flaticon.com/icons/png/512/60/60995.png');\n  background-position: 96%;\n  background-repeat: no-repeat;\n  background-size: 1rem;\n  border-radius: 0;\n  border: 0.0625rem solid ", ";\n  color: ", ";\n  display: block;\n  font-size: ", ";\n  font-weight: ", ";\n  margin-bottom: 1.25rem;\n  max-width: 30rem;\n  outline: none;\n  padding: 0.625rem;\n  width: 80%;\n"])), palette('white'), palette('secondaryBackground'), palette('text'), fontSize('large'), palette('light'));
export function StoreSelect(props) {
    var input = useField(props.name).input;
    var inputProps = __assign(__assign({}, props), input);
    return _jsx(SelectStyles, __assign({}, inputProps), void 0);
}
var AllHomeWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  background-color: #eaecee;\n  display: grid;\n  grid-gap: 1rem;\n  grid-template-columns: 2fr 1.5fr;\n  padding: 2.5rem;\n"], ["\n  background-color: #eaecee;\n  display: grid;\n  grid-gap: 1rem;\n  grid-template-columns: 2fr 1.5fr;\n  padding: 2.5rem;\n"])));
var AllHomeButton = styled.button(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-self: flex-end;\n  background-color: ", ";\n  border: 0;\n  color: white;\n  font-size: ", ";\n  padding: 0.625rem 1.25rem;\n  &:focus {\n    outline: none;\n  }\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  align-self: flex-end;\n  background-color: ", ";\n  border: 0;\n  color: white;\n  font-size: ", ";\n  padding: 0.625rem 1.25rem;\n  &:focus {\n    outline: none;\n  }\n  &:active {\n    background-color: ", ";\n  }\n"])), function (props) {
    return props.checked
        ? palette('primaryHighlight')
        : palette('secondaryHighlight');
}, fontSize('large'), function (props) {
    return props.checked
        ? palette('activePrimaryHighlight')
        : palette('activeSecondaryHighlight');
});
var AllHomeCol = styled(VerticalFlex)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  color: ", ";\n  justify-content: center;\n"], ["\n  color: ", ";\n  justify-content: center;\n"])), palette('text'));
var DeliveryOptionsComp = /** @class */ (function (_super) {
    __extends(DeliveryOptionsComp, _super);
    function DeliveryOptionsComp(props) {
        var _this = _super.call(this, props) || this;
        _this.renderStoreOption = function (_a) {
            var _b = _a.input, value = _b.value, checked = _b.checked, onChange = _b.onChange, icon = _a.icon, favoriteStore = _a.favoriteStore;
            return (_jsxs(MainOption, __assign({ onClick: function () {
                    onChange(value);
                } }, { children: [_jsx(OptionIcon, { checked: checked, icon: icon }, void 0), _jsx(FormattedMessage, { id: 'checkout.buy.availability.form.store', defaultMessage: 'In Filiale abholen:' }, void 0), favoriteStore] }), void 0));
        };
        _this.renderHomeOption = function (_a) {
            var _b = _a.input, value = _b.value, checked = _b.checked, onChange = _b.onChange, icon = _a.icon;
            return (_jsxs(MainOption, __assign({ onClick: function () {
                    onChange(value);
                } }, { children: [_jsx(OptionIcon, { checked: checked, icon: icon }, void 0), _jsx(FormattedMessage, { id: 'checkout.buy.availability.form.home', defaultMessage: "Direkt nach\nHause liefern lassen" }, void 0)] }), void 0));
        };
        _this.renderAllHomeButton = function (_a) {
            var _b = _a.input, value = _b.value, checked = _b.checked, onChange = _b.onChange;
            return (_jsx(AllHomeButton, __assign({ checked: checked, onClick: function () {
                    onChange(value);
                } }, { children: _jsx(FormattedMessage, { id: 'checkout.buy.availability.form.homeAll', defaultMessage: 'Alles liefern lassen' }, void 0) }), void 0));
        };
        _this.state = {
            showStoreSelect: false,
            favoriteStoreId: null,
        };
        _this.getFavoriteStore = function () {
            var stores = _this.props.stores;
            var favoriteStoreId = _this.state.favoriteStoreId;
            var favStore = stores.find(function (store) { return store.id === favoriteStoreId; });
            return favStore ? favStore.displayName : '';
        };
        return _this;
    }
    DeliveryOptionsComp.prototype.render = function () {
        var _this = this;
        var _a = this.props, stores = _a.stores, deliveryAmount = _a.deliveryAmount, setDeliveryOption = _a.setDeliveryOption;
        var showStoreSelect = this.state.showStoreSelect;
        var favoriteStore = this.getFavoriteStore();
        return (_jsx(Form, { onSubmit: function (values) {
                // wtf?!?!
            }, render: function (_a) {
                var handleSubmit = _a.handleSubmit;
                return (_jsxs(StyledForm, __assign({ onSubmit: handleSubmit }, { children: [_jsxs(MainOptions, { children: [_jsx(Field, { render: _this.renderStoreOption, type: 'radio', name: 'deliveryOption', value: 'store', icon: 'store', checked: true, favoriteStore: favoriteStore }, void 0), _jsx(Field, { render: _this.renderHomeOption, type: 'radio', name: 'deliveryOption', value: 'home', icon: 'shipping' }, void 0)] }, void 0), Boolean(stores) && (_jsx(StoreSelectWrapper, { children: !showStoreSelect ? (_jsx(ChangeStore, __assign({ onClick: function () {
                                    return _this.setState({
                                        showStoreSelect: true,
                                    });
                                } }, { children: _jsx(FormattedMessage, { id: 'checkout.buy.availability.form.store.change', defaultMessage: '\u00E4ndern' }, void 0) }), void 0)) : (_jsx(StoreSelect, __assign({ name: 'favoriteStoreId' }, { children: stores.map(function (store) { return (_jsx("option", __assign({ value: store.id }, { children: store.displayName }), store.id)); }) }), void 0)) }, void 0)), deliveryAmount > 1 && (_jsxs(AllHomeWrapper, { children: [_jsx(AllHomeCol, { children: _jsx(FormattedMessage, { id: 'checkout.buy.availability.form.homeAll.description', defaultMessage: 'Sie k\u00F6nnen sich auch ganz bequem alle Artikel nach Hause liefern lassen' }, void 0) }, void 0), _jsx(AllHomeCol, { children: _jsx(Field, { component: _this.renderAllHomeButton, type: 'radio', name: 'deliveryOption', value: 'allHome' }, void 0) }, void 0)] }, void 0)), _jsx(FormSpy, { subscription: { values: true }, onChange: function (_a) {
                                var values = _a.values;
                                setDeliveryOption(values.deliveryOption);
                                _this.setState({
                                    favoriteStoreId: values.favoriteStoreId !== undefined
                                        ? values.favoriteStoreId
                                        : null,
                                });
                            } }, void 0)] }), void 0));
            } }, void 0));
    };
    return DeliveryOptionsComp;
}(Component));
export default DeliveryOptionsComp;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
