var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { palette, fontWeight, fontSize } from '@shared/utils/styled';
import styled from 'styled-components';
import HighlightButton from '@shared/atoms/HighlightButton';
import VerticalFlex from '@shared/atoms/VerticalFlex';
import FlexButton from '@shared/atoms/FlexButton';
export var Wrapper = styled(VerticalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: ", ";\n  padding-bottom: 0.125rem;\n  position: absolute;\n  right: 0;\n  top: 0;\n  transition: background-color 900ms, visibility 0.15s, opacity 0.15s linear;\n  visibility: ", ";\n  z-index: ", ";\n"], ["\n  background-color: ", ";\n  bottom: 0;\n  left: 0;\n  opacity: ", ";\n  padding-bottom: 0.125rem;\n  position: absolute;\n  right: 0;\n  top: 0;\n  transition: background-color 900ms, visibility 0.15s, opacity 0.15s linear;\n  visibility: ", ";\n  z-index: ", ";\n"])), palette('secondaryBackground'), function (props) { return (props.show ? 1 : 0); }, function (props) { return (props.show ? 'visibile' : 'hidden'); }, function (props) { return (props.show ? 2 : 1); });
export var Breadcrumb = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin: 1.875rem 0 0.9375rem 0;\n  text-align: center;\n  width: 100%;\n"], ["\n  color: ", ";\n  font-size: ", ";\n  font-weight: ", ";\n  margin: 1.875rem 0 0.9375rem 0;\n  text-align: center;\n  width: 100%;\n"])), palette('white'), fontSize('large'), fontWeight('bold'));
export var ActiveBreadcrumbItem = styled.span(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  border-bottom: 0.25rem solid ", ";\n  padding-bottom: 0.125rem;\n"], ["\n  border-bottom: 0.25rem solid ", ";\n  padding-bottom: 0.125rem;\n"])), palette('white'));
export var ContentWrapper = styled(VerticalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  flex: 7;\n  overflow-y: auto;\n  position: relative;\n"], ["\n  align-items: center;\n  flex: 7;\n  overflow-y: auto;\n  position: relative;\n"])));
export var Content = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  flex: 1;\n  grid-gap: 0.125rem;\n  grid-template-columns: 1fr 22.5rem;\n  max-width: 76.25rem;\n  overflow: hidden;\n  padding: 1rem 0 1.875rem 0;\n  width: 100%;\n"], ["\n  display: grid;\n  flex: 1;\n  grid-gap: 0.125rem;\n  grid-template-columns: 1fr 22.5rem;\n  max-width: 76.25rem;\n  overflow: hidden;\n  padding: 1rem 0 1.875rem 0;\n  width: 100%;\n"])));
export var InfoColumn = styled(VerticalFlex)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: rgba(255, 255, 255, 0.2);\n"], ["\n  background-color: rgba(255, 255, 255, 0.2);\n"])));
export var TotalPrice = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 2rem;\n  font-weight: ", ";\n  margin-top: 2.1875rem;\n  height: 2.5rem;\n  text-align: center;\n"], ["\n  color: ", ";\n  font-size: 2rem;\n  font-weight: ", ";\n  margin-top: 2.1875rem;\n  height: 2.5rem;\n  text-align: center;\n"])), palette('white'), fontWeight('bold'));
export var CheckoutText = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  opacity: ", ";\n"], ["\n  align-items: center;\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  opacity: ", ";\n"])), fontWeight('bold'), function (props) { return (props.disabled ? 0.3 : 1); });
export var CancelButton = styled(FlexButton)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  cursor: pointer;\n  max-height: 3rem;\n  margin-bottom: 0.0625rem;\n"], ["\n  cursor: pointer;\n  max-height: 3rem;\n  margin-bottom: 0.0625rem;\n"])));
export var NextButton = styled(HighlightButton)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  border: 0.3125rem solid #f5f5f5;\n  max-height: 4.5rem;\n  width: 100%;\n  ", ";\n"], ["\n  border: 0.3125rem solid #f5f5f5;\n  max-height: 4.5rem;\n  width: 100%;\n  ", ";\n"])), function (props) {
    return props.isSecondary &&
        "\n    background-color: " + props.theme.palette.secondaryHighlight + ";\n    &:active {\n      background-color: " + props.theme.palette.activeSecondaryHighlight + ";\n    }\n  ";
});
export var ColumnWrapper = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  padding: 1.25rem;\n  overflow-y: auto;\n"], ["\n  padding: 1.25rem;\n  overflow-y: auto;\n"])));
export var ColumnScrollWrapper = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  background-color: rgba(255, 255, 255, 0.2);\n  overflow-y: auto;\n"], ["\n  background-color: rgba(255, 255, 255, 0.2);\n  overflow-y: auto;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
