var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { fontSize, fontWeight, palette } from '@shared/utils/styled';
// components
import ArticleView from 'organisms/ArticleView';
import H2 from '@shared/atoms/H2';
import Slider from '@shared/organisms/Slider';
import Icon from '@shared/atoms/Icon';
import Spinner from '@shared/atoms/Spinner';
import VerticalFlex from '@shared/atoms/VerticalFlex';
var Head = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      align-items: center;\n      display: grid;\n      grid-gap: 1rem;\n      grid-template-columns: 4.375rem 1fr 4.375rem;\n      height: 5.625rem;\n      padding: 0 7.5rem;\n      width: 100%;\n    "], ["\n      align-items: center;\n      display: grid;\n      grid-gap: 1rem;\n      grid-template-columns: 4.375rem 1fr 4.375rem;\n      height: 5.625rem;\n      padding: 0 7.5rem;\n      width: 100%;\n    "])));
var HeadlineWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      align-items: center;\n      display: flex;\n      height: 100%;\n      justify-content: center;\n      flex-direction: column;\n    "], ["\n      align-items: center;\n      display: flex;\n      height: 100%;\n      justify-content: center;\n      flex-direction: column;\n    "])));
var ArticleInfo = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      color: ", ";\n      font-size: ", ";\n      font-weight: ", ";\n    "], ["\n      color: ", ";\n      font-size: ", ";\n      font-weight: ", ";\n    "])), palette('text'), fontSize('default'), fontWeight('light'));
var CenteredH2 = styled(H2)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin: 0;\n  line-height: 1.5rem;\n"], ["\n  margin: 0;\n  line-height: 1.5rem;\n"])));
var Wrapper = styled(VerticalFlex)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  animation: 0.2s ease-in 0s 1 fadeIn\n  display: flex;\n  flex: 1;\n  margin-top: 0.0625rem;\n"], ["\n  animation: 0.2s ease-in 0s 1 fadeIn\n  display: flex;\n  flex: 1;\n  margin-top: 0.0625rem;\n"])));
var Slide = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      height: 100%;\n      min-width: 100%;\n      overflow-y: auto;\n      position: relative;\n\n      ::-webkit-scrollbar {\n        display: none;\n      }\n    "], ["\n      height: 100%;\n      min-width: 100%;\n      overflow-y: auto;\n      position: relative;\n\n      ::-webkit-scrollbar {\n        display: none;\n      }\n    "])));
var SliderWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  height: 10px;\n      flex: 1;\n      overflow: hidden;\n\n      > div {\n        height: 100%;\n      }\n    "], ["\n  height: 10px;\n      flex: 1;\n      overflow: hidden;\n\n      > div {\n        height: 100%;\n      }\n    "])));
var SpinnerWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      display: flex;\n      height: 100%;\n    "], ["\n      display: flex;\n      height: 100%;\n    "])));
var NavArrow = styled(VerticalFlex)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  color: ", ";\n  cursor: ", ";\n  font-size: ", ";\n  height: 100%;\n  justify-content: center;\n  padding-top: 0.1875rem;\n  width: 100%;\n  ", ";\n"], ["\n  align-items: center;\n  color: ", ";\n  cursor: ", ";\n  font-size: ", ";\n  height: 100%;\n  justify-content: center;\n  padding-top: 0.1875rem;\n  width: 100%;\n  ", ";\n"])), palette('text'), function (props) { return (props.disabled ? 'not-allowed' : 'pointer'); }, fontSize('small'), function (props) { return (props.disabled ? 'opacity: 0.3;' : ''); });
var ArticleStack = /** @class */ (function (_super) {
    __extends(ArticleStack, _super);
    function ArticleStack(props) {
        var _this = _super.call(this, props) || this;
        _this.swiper = null;
        _this.state = {
            showNext: false,
            showPrev: false,
        };
        _this.init = function () {
            var _a = _this.props, articleStack = _a.articleStack, saveHidden = _a.saveHidden;
            var initialIndex = articleStack.data.findIndex(function (item) { return item.id === articleStack.initial; });
            var current = _this.getArticleByIndex(initialIndex);
            if (current) {
                saveHidden({ articleId: current.id, sizeId: null });
            }
            var surroundingArticles = [
                current,
                _this.getArticleByIndex(initialIndex + 1),
                _this.getArticleByIndex(initialIndex - 1),
            ];
            surroundingArticles.forEach(function (article) {
                return article && !article.singleLoaded ? _this.props.getStackArticle(article.id, articleStack.id) : null;
            });
        };
        _this.getArticleByIndex = function (index) {
            var articleStack = _this.props.articleStack;
            return articleStack.data[index] ? articleStack.data[index] : null;
        };
        _this.getCurrentIndex = function () {
            var swiper = _this.swiper;
            if (!swiper || swiper.realIndex === undefined || swiper.realIndex === null) {
                return null;
            }
            return _this.swiper.realIndex;
        };
        _this.handleSwipe = function () {
            var swiper = _this.swiper;
            if (!swiper ||
                swiper.realIndex === undefined ||
                swiper.realIndex === null)
                return;
            var articleStack = _this.props.articleStack;
            // sophistcated behaviour end
            var curIndex = swiper.realIndex;
            var current = _this.getArticleByIndex(curIndex);
            if (current) {
                _this.props.saveHidden({ articleId: current.id, sizeId: null });
            }
            var surroundingArticles = [
                _this.getArticleByIndex(curIndex + 1),
                _this.getArticleByIndex(curIndex - 1),
                current
            ];
            surroundingArticles.forEach(function (article) {
                return article && !article.singleLoaded ? _this.props.getStackArticle(article.id, _this.props.articleStack.id) : null;
            });
            // If there's a pagination, fetch further articles
            if (!articleStack.params || !articleStack.params.page) {
                return;
            }
            // Allways fetch next page five articles before current end
            if (curIndex >= articleStack.data.length - 5 && articleStack.params.page < articleStack.params.pageCount) {
                var newParams = __assign({}, articleStack.params);
                newParams.page = articleStack.params.page + 1;
                delete newParams.pageCount;
                _this.props.getStackArticles(__assign({}, newParams), articleStack.id);
            }
            // Allways update the slider one slide after we fetched the next page.
            // We only can do this, when the articles of the next page are visible. Unfortunately we cant use componentWillReceiveProps here.
            if (curIndex > (articleStack.params.page - 1) * 10 + 6 && curIndex < (articleStack.params.page - 1) * 10 + 8) {
                swiper.update();
            }
        };
        _this.connectSwiper = function (swiperInstance) {
            if (swiperInstance && !_this.swiper) {
                _this.swiper = swiperInstance;
                _this.setVisibility(swiperInstance);
            }
        };
        _this.goNext = function () {
            if (_this.swiper) {
                _this.swiper.slideNext();
                _this.handleSwipe();
                _this.setVisibility();
            }
        };
        _this.goPrev = function () {
            if (_this.swiper) {
                _this.swiper.slidePrev();
                _this.handleSwipe();
                _this.setVisibility(_this.swiper);
            }
        };
        _this.setVisibility = function () {
            if (_this.swiper) {
                _this.setState({
                    showNext: !_this.swiper.isEnd,
                    showPrev: !_this.swiper.isBeginning,
                });
            }
        };
        return _this;
    }
    ArticleStack.prototype.componentDidMount = function () {
        this.init();
    };
    ArticleStack.prototype.render = function () {
        var _this = this;
        var _a = this.props, articleStack = _a.articleStack, createArticleStack = _a.createArticleStack, createWatchlistItem = _a.createWatchlistItem, deleteArticleStack = _a.deleteArticleStack, deleteWatchlistItem = _a.deleteWatchlistItem, getStackArticle = _a.getStackArticle, openImagesModal = _a.openImagesModal, saveHidden = _a.saveHidden, watchlist = _a.watchlist;
        var _b = this.state, showPrev = _b.showPrev, showNext = _b.showNext;
        var initialIndex = articleStack.data.findIndex(function (item) { return item.id === articleStack.initial; });
        return (_jsxs(Wrapper, { children: [_jsxs(Head, { children: [_jsxs(NavArrow, __assign({ onClick: function () { return (showPrev ? _this.goPrev() : {}); }, disabled: !showPrev }, { children: [_jsx(Icon, { icon: 'swipePrevious' }, void 0), _jsx(FormattedMessage, { id: 'articleStack.prev', defaultMessage: 'vorheriger' }, void 0)] }), void 0), _jsxs(HeadlineWrapper, { children: [_jsx(CenteredH2, { children: articleStack.name }, void 0), _jsx(ArticleInfo, { children: articleStack.amountTotal > 0 && (_jsx(FormattedMessage, { id: 'articleStack.amount', defaultMessage: "{total, number} {total, plural, one {Artikel} other {Artikel}}", values: { total: articleStack.amountTotal } }, void 0)) }, void 0)] }, void 0), _jsxs(NavArrow, __assign({ onClick: function () { return (showNext ? _this.goNext() : {}); }, disabled: !showNext }, { children: [_jsx(Icon, { icon: 'swipeNext' }, void 0), _jsx(FormattedMessage, { id: 'articleStack.next', defaultMessage: 'n\u00E4chster' }, void 0)] }), void 0)] }, void 0), _jsx(SliderWrapper, { children: _jsx(Slider, __assign({ config: {
                            initialSlide: initialIndex,
                            shortSwipes: false,
                            grabCursor: true,
                            onSwiper: function (leSwiper) {
                                _this.connectSwiper(leSwiper);
                            }
                        } }, { children: this.props.articleStack.data.map(function (item, index) {
                            return (
                            // eslint-disable-next-line react/no-array-index-key
                            _jsx(Slide, { children: item ? (_jsx(ArticleView, { article: item, createArticleStack: createArticleStack, createWatchlistItem: createWatchlistItem, deleteWatchlistItem: deleteWatchlistItem, identifier: "articleStack" + articleStack.id + item.id, params: {}, nested: true, openImagesModal: openImagesModal, openColor: function (articleId) {
                                        return getStackArticle(articleId, articleStack.id, index);
                                    }, hidden: index !== _this.getCurrentIndex(), saveHidden: saveHidden, watchlist: watchlist, deleteArticleStack: function () {
                                        return deleteArticleStack(articleStack.id);
                                    } }, void 0)) : (_jsx(SpinnerWrapper, { children: _jsx(Spinner, {}, void 0) }, void 0)) }, "" + index + item.id));
                        }) }), void 0) }, void 0)] }, void 0));
    };
    return ArticleStack;
}(Component));
export default ArticleStack;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
