// @flow
// @ts-nocheck
import { handleActions } from 'redux-actions';
import { List, Map } from 'immutable';
// eslint-disable-next-line flowtype/no-weak-types
export var initialState = Map({
    data: List(),
    loading: false,
    loaded: false,
});
var updateEntry = function (entry) { return function (existing) {
    // $FlowIgnoreNextLine
    var index = existing.findIndex(function (existingEntry) { return existingEntry.get('id') === entry.get('id'); });
    if (index === -1) {
        // $FlowIgnoreNextLine
        return existing.push(entry);
    }
    // $FlowIgnoreNextLine
    return existing.set(index, entry);
}; };
var reducer = handleActions({
    GET_COLLECTION: function (state) { return state.set('loading', true); },
    GET_COLLECTION_COMPLETE: {
        next: function (state, action) {
            return state
                .set('loading', false)
                .set('loaded', true)
                .update('data', updateEntry(action.payload));
        },
        throw: function (state) {
            return state.set('loading', false).set('loaded', false);
        },
    },
}, initialState);
export default reducer;
