import { required, email, containsNoInteger } from '@shared/utils/validation';
export var formValidation = function (values) {
    var errors = {
        salutation: required(values.salutation),
        firstName: required(values.firstName) || containsNoInteger(values.firstName),
        lastName: required(values.lastName) || containsNoInteger(values.lastName),
        street: required(values.street) || containsNoInteger(values.street),
        streetNo: required(values.streetNo),
        city: required(values.city) || containsNoInteger(values.city),
        zip: required(values.zip),
        phone: required(values.phone),
        email: required(values.email) || email(values.email),
    };
    return errors;
};
