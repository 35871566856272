import { createSelector } from 'reselect';
var selectState = function (state) { return state.get('hiddenWatchlist'); };
export var selectHiddenItem = createSelector(selectState, function (hiddenState) {
    return Boolean(hiddenState.get('articleId'))
        ? {
            articleId: hiddenState.get('articleId'),
            sizeId: hiddenState.get('sizeId'),
        }
        : null;
});
