var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import ScanGiftcardForm from 'molecules/ScanGiftcardForm';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StyledGiftcardWrapper, StyledGiftcard, StyledGiftcardFront, StyledGiftcardBack, StyledIcon, StyledGiftcardHintTitle, StyledGiftcardHint, StyledGiftcardSubtitle, StyledGiftcardTitle, } from './style';
var GiftcardSearch = /** @class */ (function (_super) {
    __extends(GiftcardSearch, _super);
    function GiftcardSearch() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    GiftcardSearch.prototype.render = function () {
        return (_jsxs(StyledGiftcardWrapper, { children: [_jsx(StyledGiftcardTitle, { children: _jsx(FormattedMessage, { id: 'giftcard.headline', defaultMessage: 'Tamaris Gift Card - Abfrage des Guthabens' }, void 0) }, void 0), _jsx(StyledGiftcardSubtitle, { children: _jsx(FormattedMessage, { id: 'giftcard.description', defaultMessage: 'Hier kann die Tamaris Gift Card gescannt oder auch die Nummer der Tamaris Gift Card eingegeben werden' }, void 0) }, void 0), _jsxs(StyledGiftcard, { children: [_jsx(StyledGiftcardFront, { src: 'public/Tamaris_Giftcard_Vorderseite_VKA.png', alt: 'Gift Card Vorderseite' }, void 0), _jsx(StyledGiftcardBack, { src: 'public/Tamaris_Giftcard_Rueckseite_VKA.png', alt: 'Gift Card R\u00FCckseite' }, void 0)] }, void 0), _jsx(ScanGiftcardForm, {}, void 0), _jsxs(StyledGiftcardHint, { children: [_jsx(StyledGiftcardHintTitle, { children: _jsx(FormattedMessage, { id: 'giftcard.scan.title', defaultMessage: 'Gift Card scannen' }, void 0) }, void 0), _jsx(StyledIcon, { icon: 'arrow-scan', width: 48 }, void 0)] }, void 0)] }, void 0));
    };
    return GiftcardSearch;
}(Component));
export default injectIntl(GiftcardSearch);
