var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { palette } from '@shared/utils/styled';
import Icon from '@shared/atoms/Icon';
import styled from 'styled-components';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  max-height: 4.125rem;\n  max-width: 4.125rem;\n  padding: 0.625rem;\n  position: absolute;\n  right: 0;\n  top: 0;\n  z-index: 1;\n"], ["\n  cursor: pointer;\n  max-height: 4.125rem;\n  max-width: 4.125rem;\n  padding: 0.625rem;\n  position: absolute;\n  right: 0;\n  top: 0;\n  z-index: 1;\n"])));
var WatchlistIcon = styled(Icon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: auto;\n  svg {\n    fill: ", ";\n    border-radius: 50%;\n    transition: background-color 0.3s ease;\n    g {\n      fill: ", ";\n    }\n    &:active {\n      background-color: ", ";\n    }\n  }\n"], ["\n  height: auto;\n  svg {\n    fill: ", ";\n    border-radius: 50%;\n    transition: background-color 0.3s ease;\n    g {\n      fill: ", ";\n    }\n    &:active {\n      background-color: ", ";\n    }\n  }\n"])), function (props) {
    return props.isOnWatchlist ? palette('white') : '#292F35';
}, function (props) {
    return props.isOnWatchlist ? palette('tertiaryHighlight') : '#E5E5E7';
}, function (props) {
    return props.isOnWatchlist ? '#005c80' : '#c3c3c3';
});
var WatchlistToggleIcon = /** @class */ (function (_super) {
    __extends(WatchlistToggleIcon, _super);
    function WatchlistToggleIcon() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WatchlistToggleIcon.prototype.render = function () {
        var _a = this.props, createWatchlistItem = _a.createWatchlistItem, deleteWatchlistItem = _a.deleteWatchlistItem, isOnWatchlist = _a.isOnWatchlist;
        return (_jsx(Wrapper, __assign({ onClick: function () {
                return isOnWatchlist ? deleteWatchlistItem() : createWatchlistItem();
            } }, { children: _jsx(WatchlistIcon, { icon: isOnWatchlist ? 'watchlistThumbRemove' : 'watchlistThumb', isOnWatchlist: isOnWatchlist, width: 36 }, void 0) }), void 0));
    };
    return WatchlistToggleIcon;
}(Component));
export default WatchlistToggleIcon;
var templateObject_1, templateObject_2;
