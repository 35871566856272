import { createSelector } from 'reselect';
export var selectSessionState = function (state) { return state.get('session'); };
export var selectSession = createSelector(selectSessionState, function (sessionState) { return sessionState.get('data'); });
export var selectCreating = createSelector(selectSessionState, function (sessionState) { return sessionState.get('creating'); });
export var selectLoaded = createSelector(selectSessionState, function (sessionState) { return sessionState.get('loaded'); });
export var selectCheckoutNumber = createSelector(selectSessionState, function (sessionState) { return sessionState.get('checkoutNumber'); });
export var selectOrderNumber = createSelector(selectSessionState, function (sessionState) {
    var t = sessionState.get('orderNumber');
    return t;
});
export var selectOrderError = createSelector(selectSessionState, function (sessionState) {
    var t = sessionState.get('orderError');
    return t;
});
export var selectCheckingOut = createSelector(selectSessionState, function (sessionState) { return sessionState.get('checkingOut'); });
