var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { key } from "@shared/utils/styled";
/** @component */
export var HideMediumDown = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: none;\n  height: 100%;\n  @media screen and (min-width: ", ") {\n    display: block;\n  }\n"], ["\n  display: none;\n  height: 100%;\n  @media screen and (min-width: ", ") {\n    display: block;\n  }\n"])), key("breakpoints.large", "1720px"));
/** @component */
export var HideLarge = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    display: none;\n  }\n"], ["\n  display: block;\n  width: 100%;\n  @media screen and (min-width: ", ") {\n    display: none;\n  }\n"])), key("breakpoints.large", "1720px"));
var templateObject_1, templateObject_2;
