var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
// components
import ListLayout from 'templates/ListLayout';
import ScanSearch from 'organisms/ScanSearch';
var Scan = function (props) { return (_jsx(ListLayout, __assign({}, props, { children: _jsx(ScanSearch, { searchArticle: props.searchArticle, searchError: props.searchError, scanError: props.scanError, textSearchError: props.textSearchError, resetArticlesState: props.resetArticlesState }, void 0) }), void 0)); };
export default Scan;
