var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
// components
import CheckoutHeadline from 'atoms/CheckoutHeadline';
// own components
import Item from './item';
var FurnitureStep = /** @class */ (function (_super) {
    __extends(FurnitureStep, _super);
    function FurnitureStep() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FurnitureStep.prototype.render = function () {
        var _a = this.props, furniture = _a.furniture, addAction = _a.addAction, removeAction = _a.removeAction, watchlist = _a.watchlist;
        return (_jsxs("div", { children: [_jsx(CheckoutHeadline, { children: _jsx(FormattedMessage, { id: 'checkout.buy.furnitures.title', defaultMessage: 'Wir empfehlen Ihnen die folgenden Pflegemittel' }, void 0) }, void 0), _jsx("div", { children: furniture.map(function (item) { return (_jsx(Item, { furniture: item, addAction: addAction, removeAction: removeAction, watchlist: watchlist }, item.displayName + item.id)); }) }, void 0)] }, void 0));
    };
    return FurnitureStep;
}(Component));
export default FurnitureStep;
