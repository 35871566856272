var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  &:after {\n    width: 0;\n    height: 0;\n    content: '';\n    border-left: 0.3125rem solid transparent;\n    border-right: 0.3125rem solid transparent;\n    border-top: 0.3125rem solid ", ";\n    position: relative;\n  }\n"], ["\n  cursor: pointer;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  &:after {\n    width: 0;\n    height: 0;\n    content: '';\n    border-left: 0.3125rem solid transparent;\n    border-right: 0.3125rem solid transparent;\n    border-top: 0.3125rem solid ", ";\n    position: relative;\n  }\n"])), palette('highlightText'));
var LanguageSelect = function (props) { return (_jsx(Wrapper, __assign({ onClick: function () { return props.toggleLanguageModal(); } }, { children: props.currentLanguage.toUpperCase() }), void 0)); };
export default LanguageSelect;
var templateObject_1;
