var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import { fontWeight } from '@shared/utils/styled';
import HighlightButton from '@shared/atoms/HighlightButton';
import Icon from '@shared/atoms/Icon';
import { FormattedMessage } from 'react-intl';
var CheckoutIcon = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin-right: 0.5rem;\n"], ["\n  margin-right: 0.5rem;\n"])));
var Wrapper = styled(HighlightButton)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border: 0.3125rem solid #f5f5f5;\n  max-height: 4.5rem;\n  width: 100%;\n"], ["\n  border: 0.3125rem solid #f5f5f5;\n  max-height: 4.5rem;\n  width: 100%;\n"])));
var CheckoutText = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  opacity: ", ";\n"], ["\n  align-items: center;\n  display: flex;\n  font-weight: ", ";\n  justify-content: center;\n  opacity: ", ";\n"])), fontWeight('bold'), function (props) { return (props.disabled ? 0.3 : 1); });
var CustomerFormsSubmitBtn = /** @class */ (function (_super) {
    __extends(CustomerFormsSubmitBtn, _super);
    function CustomerFormsSubmitBtn() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomerFormsSubmitBtn.prototype.render = function () {
        var _a = this.props, disabled = _a.disabled, formId = _a.formId;
        return (_jsx(Wrapper, __assign({ onClick: function () {
                var form = document.getElementById(formId);
                if (form !== null) {
                    form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                }
            } }, { children: _jsxs(CheckoutText, __assign({ disabled: disabled }, { children: [_jsx(CheckoutIcon, { icon: 'checkout' }, void 0), _jsx(FormattedMessage, { id: 'checkout.buy.finish', defaultMessage: 'Zur Kasse' }, void 0)] }), void 0) }), void 0));
    };
    return CustomerFormsSubmitBtn;
}(Component));
export default CustomerFormsSubmitBtn;
var templateObject_1, templateObject_2, templateObject_3;
