var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { Form } from 'react-final-form';
import { defineMessages, FormattedMessage, injectIntl, } from 'react-intl';
import { StyledHeadline, StyledIcon, StyledInput, StyledForm, StyledScanSearchFormWrapper, StyledSearchError, StyledSubmitButton, } from './style';
var ScanSearchForm = /** @class */ (function (_super) {
    __extends(ScanSearchForm, _super);
    function ScanSearchForm() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScanSearchForm.prototype.render = function () {
        var _a = this.props, onSubmit = _a.onSubmit, intl = _a.intl, searchError = _a.searchError, textSearchError = _a.textSearchError;
        var inputPlaceholder = defineMessages({
            inputPlaceholder: {
                id: 'scan.manual.placeholder',
                defaultMessage: 'Modellnamen eingeben oder Etikett vorne am Terminal scannen',
            },
        }).inputPlaceholder;
        return (_jsxs(StyledScanSearchFormWrapper, { children: [_jsx(StyledHeadline, { children: _jsx(FormattedMessage, { id: 'scan.manual.headline', defaultMessage: 'SUCHE' }, void 0) }, void 0), _jsx(Form, { onSubmit: onSubmit, render: function (_a) {
                        var handleSubmit = _a.handleSubmit;
                        return (_jsxs(StyledForm, __assign({ onSubmit: handleSubmit }, { children: [_jsx(StyledInput, { autoComplete: 'off', name: 'scan', placeholder: intl.formatMessage(inputPlaceholder), required: true }, void 0), _jsx(StyledSubmitButton, __assign({ type: 'submit' }, { children: _jsx(StyledIcon, { icon: 'scan-search' }, void 0) }), void 0)] }), void 0));
                    } }, void 0), _jsxs(StyledSearchError, { children: [searchError && (_jsx(FormattedMessage, { id: 'scan.manual.error', defaultMessage: 'Zu dieser Artikelnummer konnten wir keinen Artikel finden' }, void 0)), textSearchError && (_jsx(FormattedMessage, { id: 'scan.text.error', defaultMessage: 'Zu diesem Modellnamen konnten wir keinen Artikel finden' }, void 0))] }, void 0)] }, void 0));
    };
    return ScanSearchForm;
}(Component));
export default injectIntl(ScanSearchForm);
