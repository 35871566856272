// @flow
// @ts-nocheck
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import 'whatwg-fetch';
import { stringify } from 'query-string';
import store from 'store';
import { camelCase } from 'camel-case';
import merge from 'lodash/merge';
import { format } from '@shared/utils/api';
export var handleBrokenRequest = function (error) {
    throw error;
};
export var checkStatus = function (response) {
    if (response.ok) {
        return response;
    }
    var error = new Error(response.status + " " + response.statusText);
    // $FlowIgnoreNextLine
    error.response = response;
    throw error;
};
export var parseResponse = function (response) {
    var headers = {};
    if (response.headers) {
        response.headers.forEach(function (val, name) {
            headers[name] = val;
        });
    }
    return response
        .json()
        .then(function (body) { return ({ body: format(body, camelCase), headers: headers }); });
};
export var parseSettings = function (_a) {
    if (_a === void 0) { _a = {}; }
    var _b = _a.method, method = _b === void 0 ? 'get' : _b, data = _a.data, locale = _a.locale, authToken = _a.authToken, otherSettings = __rest(_a, ["method", "data", "locale", "authToken"]);
    var headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Accept-Language': locale,
        Authorization: authToken,
    };
    var settings = merge({
        body: data ? JSON.stringify(data) : undefined,
        method: method,
        headers: headers,
    }, otherSettings);
    return settings;
};
export var parseEndpoint = function (endpoint, params) {
    var apiUrl = process.env.REACT_APP_API_URL;
    var url = endpoint.indexOf('http') === 0 ? endpoint : apiUrl + endpoint;
    var lang = store.get('language') || 'de';
    var querystring = params ? "?" + stringify(__assign(__assign({}, params), { lang: lang })) : '';
    return "" + url + querystring;
};
var api = {};
api.request = function (endpoint, _a) {
    if (_a === void 0) { _a = {}; }
    var params = _a.params, settings = __rest(_a, ["params"]);
    return fetch(parseEndpoint(endpoint, params), parseSettings(settings))
        .then(checkStatus)
        .then(parseResponse)
        .catch(handleBrokenRequest);
};
['delete', 'get'].forEach(function (method) {
    api[method] = function (endpoint, settings) {
        return api.request(endpoint, __assign({ method: method }, settings));
    };
});
['post', 'put', 'patch'].forEach(function (method) {
    api[method] = function (endpoint, data, settings) {
        return api.request(endpoint, __assign({ method: method, data: data }, settings));
    };
});
api.create = function (settings) {
    if (settings === void 0) { settings = {}; }
    return ({
        settings: settings,
        setToken: function (token) {
            this.settings.headers = __assign(__assign({}, this.settings.headers), { Authorization: "Bearer " + token });
        },
        unsetToken: function () {
            this.settings.headers = __assign(__assign({}, this.settings.headers), { Authorization: undefined });
        },
        request: function (endpoint, requestSettings) {
            return api.request(endpoint, merge({}, this.settings, requestSettings));
        },
        post: function (endpoint, data, requestSettings) {
            return this.request(endpoint, __assign({ method: 'post', data: data }, requestSettings));
        },
        get: function (endpoint, requestSettings) {
            return this.request(endpoint, __assign({ method: 'get' }, requestSettings));
        },
        put: function (endpoint, data, requestSettings) {
            return this.request(endpoint, __assign({ method: 'put', data: data }, requestSettings));
        },
        patch: function (endpoint, data, requestSettings) {
            return this.request(endpoint, __assign({ method: 'patch', data: data }, requestSettings));
        },
        delete: function (endpoint, requestSettings) {
            return this.request(endpoint, __assign({ method: 'delete' }, requestSettings));
        },
    });
};
export default api;
