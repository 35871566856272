var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import orderBy from 'lodash/orderBy';
import styled from 'styled-components';
import WatchlistReducedItem from 'organisms/WatchlistReducedItem';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  flex: 7;\n  height: 100%;\n  opacity: ", ";\n  padding-top: 0.3125rem;\n  transition: opacity 0.8s;\n"], ["\n  flex: 7;\n  height: 100%;\n  opacity: ", ";\n  padding-top: 0.3125rem;\n  transition: opacity 0.8s;\n"])), function (props) { return (props.extended ? 1 : 0); });
var WatchlistReduced = /** @class */ (function (_super) {
    __extends(WatchlistReduced, _super);
    function WatchlistReduced() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    WatchlistReduced.prototype.render = function () {
        var _a = this.props, watchlist = _a.watchlist, deleteWatchlistItem = _a.deleteWatchlistItem, extended = _a.extended, createArticleStack = _a.createArticleStack;
        var pureArticleList = orderBy(watchlist, 'createdAt', 'desc').reduce(function (acc, item) {
            if (Boolean(item.article) &&
                !Boolean(acc.find(function (it) { return it.id === item.article.id; }))) {
                acc.push(item.article);
            }
            return acc;
        }, []);
        return (_jsx(Wrapper, __assign({ extended: extended }, { children: watchlist &&
                orderBy(watchlist, 'createdAt', 'desc').map(function (watchlistItem) { return (_jsx(WatchlistReducedItem, { openArticleStack: function () {
                        return createArticleStack({
                            articles: pureArticleList,
                            initial: watchlistItem.article.id,
                            name: 'Merkliste',
                            amountTotal: pureArticleList.length,
                        });
                    }, deleteWatchlistItem: deleteWatchlistItem, item: watchlistItem }, watchlistItem.id)); }) }), void 0));
    };
    return WatchlistReduced;
}(Component));
export default WatchlistReduced;
var templateObject_1;
