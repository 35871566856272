var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo from '@shared/atoms/Logo';
var LogoLink = styled(Link)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  font-size: 0;\n  img {\n    height: auto;\n    margin-left: 1.5rem;\n    max-height: 6.5rem;\n  }\n"], ["\n  display: inline-block;\n  font-size: 0;\n  img {\n    height: auto;\n    margin-left: 1.5rem;\n    max-height: 6.5rem;\n  }\n"])));
var AppLogo = function (props) { return (_jsx(LogoLink, __assign({ to: '/' }, { children: _jsx(Logo, { logo: props.logo }, void 0) }), void 0)); };
export default AppLogo;
var templateObject_1;
