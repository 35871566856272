// @flow
// @ts-nocheck
import { handleActions } from 'redux-actions';
import { Map } from 'immutable';
export var initialState = Map({
    avarageWaitingTime: null,
    error: null,
    loadingAvgTime: false,
});
var reducer = handleActions({
    GET_WAITING_TIME: function (state) {
        return state.set('error', null).set('loadingAvgTime', true);
    },
    GET_WAITING_TIME_COMPLETE: {
        next: function (state, action) {
            return state
                .set('avarageWaitingTime', action.payload)
                .set('error', null)
                .set('loadingAvgTime', false);
        },
        throw: function (state, action) {
            return state.set('error', action.error).set('loadingAvgTime', false);
        },
    },
}, initialState);
export default reducer;
