var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import ScanSearchForm from 'molecules/ScanSearchForm';
import ScanSearchErrorModal from './ScanSearchErrorModal';
import { injectIntl } from 'react-intl';
import { StyledIcon, StyledScanSearch, StyledScanHint, StyledScanHintSubtitle, StyledScanHintTitle, } from './style';
var ScanSearch = /** @class */ (function (_super) {
    __extends(ScanSearch, _super);
    function ScanSearch() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ScanSearch.prototype.render = function () {
        var _a = this.props, searchArticle = _a.searchArticle, searchError = _a.searchError, textSearchError = _a.textSearchError, resetArticlesState = _a.resetArticlesState, scanError = _a.scanError;
        return (_jsxs(StyledScanSearch, { children: [_jsx(ScanSearchForm, { onSubmit: function (values) { return searchArticle(values); }, searchError: searchError, textSearchError: textSearchError }, void 0), _jsxs(StyledScanHint, { children: [_jsx(StyledScanHintTitle, { children: "Direkt zur Artikelinfo" }, void 0), _jsx(StyledScanHintSubtitle, { children: "Scannen Sie hier den Artikel-Barcode" }, void 0), _jsx(StyledIcon, { icon: 'arrow-scan', width: 48 }, void 0)] }, void 0), _jsx(ScanSearchErrorModal, { scanError: scanError, resetArticlesState: resetArticlesState, shouldCloseOnOverlayClick: false }, void 0)] }, void 0));
    };
    return ScanSearch;
}(Component));
export default injectIntl(ScanSearch);
