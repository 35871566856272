var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
// utils
import styled from 'styled-components';
import { fontWeight, fontSize, key } from '@shared/utils/styled';
import { FormattedMessage, defineMessages } from 'react-intl';
import union from 'lodash/union';
// components
import ControlledSlider from '@shared/molecules/ControlledSlider';
import SliderArticleExtended from 'molecules/SliderArticleExtended';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  grid-column: 1 / 3;\n"], ["\n  grid-column: 1 / 3;\n"])));
var Headline = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-weight: ", ";\n  font-size: ", ";\n  letter-spacing: 0.4px;\n"], ["\n  font-weight: ", ";\n  font-size: ", ";\n  letter-spacing: 0.4px;\n"])), fontWeight('bold'), fontSize('large'));
var SubHeadline = styled(Headline)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n"], ["\n  font-size: ", ";\n"])), fontSize('default'));
var Slide = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: ", ";\n"], ["\n  width: ", ";\n"])), function (props) { return (props.big ? '14rem' : '4rem'); });
var SliderWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  grid-column: 1 / 3;\n  margin-bottom: 2.25rem;\n  min-height: 0;\n  min-width: 0;\n  padding-left: 0;\n  padding-right: 1.0625rem;\n  @media screen and (min-width: ", ") {\n    padding-left: 7.5rem;\n  }\n"], ["\n  grid-column: 1 / 3;\n  margin-bottom: 2.25rem;\n  min-height: 0;\n  min-width: 0;\n  padding-left: 0;\n  padding-right: 1.0625rem;\n  @media screen and (min-width: ", ") {\n    padding-left: 7.5rem;\n  }\n"])), key('breakpoints.medium', '1720px'));
var ArticleCrossSelling = /** @class */ (function (_super) {
    __extends(ArticleCrossSelling, _super);
    function ArticleCrossSelling() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    //   getAccessoires: () => Array<Article>;
    ArticleCrossSelling.prototype.getAccessoires = function () {
        var article = this.props.article;
        var accessoires = Boolean(article.accessoires) && article.accessoires
            ? article.accessoires
            : [];
        var furnitures = Boolean(article.furnitures) && article.furnitures
            ? article.furnitures
            : [];
        return union(accessoires, furnitures);
    };
    ArticleCrossSelling.prototype.render = function () {
        var _a = this.props, article = _a.article, createArticleStack = _a.createArticleStack, createWatchlistItem = _a.createWatchlistItem, deleteWatchlistItem = _a.deleteWatchlistItem, nested = _a.nested, watchlist = _a.watchlist;
        var _b = defineMessages({
            similarArticlesHeadline: {
                id: 'article.similarArticles.headline',
                defaultMessage: 'Ähnliche Artikel',
            },
            accessoiresHeadline: {
                id: 'article.accessoires.headline',
                defaultMessage: 'Accessoires und Furnituren',
            },
        }), similarArticlesHeadline = _b.similarArticlesHeadline, accessoiresHeadline = _b.accessoiresHeadline;
        var similarArticlesSliderHeadline = article.displayName
            ? article.displayName + " - " + similarArticlesHeadline.defaultMessage
            : similarArticlesHeadline.defaultMessage;
        var accessoiresSliderHeadline = article.displayName
            ? article.displayName + " - " + accessoiresHeadline.defaultMessage
            : accessoiresHeadline.defaultMessage;
        var accessoires = this.getAccessoires();
        return (_jsxs(Wrapper, { children: [article.similarArticles &&
                    Boolean(article.similarArticles) &&
                    article.similarArticles.length > 0 && (_jsxs(SliderWrapper, { children: [_jsx(SubHeadline, { children: _jsx(FormattedMessage, __assign({}, similarArticlesHeadline), void 0) }, void 0), _jsx(ControlledSlider, { children: article.similarArticles.map(function (similar) {
                                var watchlistEntry = watchlist.find(function (item) { return item.articleId === similar.id; });
                                return (_jsx(Slide, __assign({ big: true }, { children: _jsx(SliderArticleExtended, { openArticleStack: function () {
                                            return createArticleStack({
                                                articles: article.similarArticles,
                                                name: similarArticlesSliderHeadline,
                                                initial: similar.id,
                                                // $FlowIgnoreNextLine
                                                amountTotal: article.similarArticles.length,
                                            });
                                        }, createWatchlistItem: function () {
                                            return createWatchlistItem({
                                                articleId: similar.id,
                                            });
                                        }, deleteWatchlistItem: function () {
                                            // $FlowIgnoreNextLine
                                            return deleteWatchlistItem(watchlistEntry.id);
                                        }, article: similar, isOnWatchlist: Boolean(watchlistEntry), active: false }, void 0) }), "similar" + similar.id));
                            }) }, void 0)] }, void 0)), !article.isAccessoire && accessoires.length > 0 && (_jsxs(SliderWrapper, { children: [_jsx(SubHeadline, { children: _jsx(FormattedMessage, __assign({}, accessoiresHeadline), void 0) }, void 0), _jsx(ControlledSlider, { children: accessoires.map(function (accessoire) {
                                var watchlistEntry = watchlist.find(function (item) { return item.articleId === accessoire.id; });
                                return (_jsx(Slide, __assign({ big: true }, { children: _jsx(SliderArticleExtended, { openArticleStack: function () {
                                            return createArticleStack({
                                                articles: accessoires,
                                                name: accessoiresSliderHeadline,
                                                initial: accessoire.id,
                                                amountTotal: accessoires.length,
                                            });
                                        }, createWatchlistItem: function () {
                                            return createWatchlistItem({
                                                articleId: accessoire.id,
                                            });
                                        }, deleteWatchlistItem: function () {
                                            return deleteWatchlistItem(
                                            // $FlowIgnoreNextLine
                                            watchlistEntry.id);
                                        }, article: accessoire, isOnWatchlist: Boolean(watchlistEntry), active: false }, void 0) }), "accessoire" + accessoire.id));
                            }) }, void 0)] }, void 0))] }, void 0));
    };
    return ArticleCrossSelling;
}(Component));
export default ArticleCrossSelling;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
