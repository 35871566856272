var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import includes from 'lodash/includes';
// utils
import { palette } from '@shared/utils/styled';
// components
import Icon from '@shared/atoms/Icon';
var Item = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-left: 0.0625rem solid\n    ", ";\n  cursor: pointer;\n  width: 11%;\n  line-height: 1.5rem;\n  margin-bottom: 0.625rem;\n  padding: 0.25rem 0 0.125rem;\n  position: relative;\n  text-align: center;\n  transition: background-color 0.2s ease, border-color 0.2s ease;\n  color: ", ";\n  &:first-child, \n  &:nth-child(9n + 1) {\n    border-left: 0;\n  }\n  &:active {\n    background-color: ", ";\n    border-color: transparent;\n    + div {\n      border-color: transparent;\n    }\n  }\n  ", " ", ";\n"], ["\n  background-color: ", ";\n  border-left: 0.0625rem solid\n    ", ";\n  cursor: pointer;\n  width: 11%;\n  line-height: 1.5rem;\n  margin-bottom: 0.625rem;\n  padding: 0.25rem 0 0.125rem;\n  position: relative;\n  text-align: center;\n  transition: background-color 0.2s ease, border-color 0.2s ease;\n  color: ", ";\n  &:first-child, \n  &:nth-child(9n + 1) {\n    border-left: 0;\n  }\n  &:active {\n    background-color: ", ";\n    border-color: transparent;\n    + div {\n      border-color: transparent;\n    }\n  }\n  ", " ", ";\n"])), function (props) {
    return props.active ? palette('primaryHighlight') : 'transparent';
}, function (props) { return (props.active ? 'transparent' : palette('border')); }, function (props) { return (props.active ? palette('white') : palette('text')); }, palette('activeWhite'), function (props) {
    return props.active &&
        "\n    + div {\n      border-color: transparent;\n    }\n  ";
}, function (props) {
    return props.disabled &&
        !props.active &&
        "\n    cursor: not-allowed;\n    span {\n      opacity: 0.3;\n    }\n  ";
});
var Lights = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 1rem;\n  border: 0.0625rem solid ", ";\n  content: ' ';\n  height: 0.625rem;\n  left: 0;\n  margin-left: auto;\n  margin-right: auto;\n  positon: absolute;\n  right: 0;\n  top: 0;\n  width: 0.625rem;\n"], ["\n  background-color: ", ";\n  border-radius: 1rem;\n  border: 0.0625rem solid ", ";\n  content: ' ';\n  height: 0.625rem;\n  left: 0;\n  margin-left: auto;\n  margin-right: auto;\n  positon: absolute;\n  right: 0;\n  top: 0;\n  width: 0.625rem;\n"])), function (props) { return (props.rare ? '#FECD2F' : '#5ca52d'); }, palette('white'));
/* NEED-332 */
var StyledIcon = styled(Icon)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  color: ", ";\n  width: 128px;\n  > svg {\n    width: 80px;\n    height: 80px;\n    > path {\n      opacity: 0.2;\n    }\n  }\n"], ["\n  color: ", ";\n  width: 128px;\n  > svg {\n    width: 80px;\n    height: 80px;\n    > path {\n      opacity: 0.2;\n    }\n  }\n"])), palette('secondaryBackground'));
var Thumb = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 80px;\n  height: 80px;\n  background-size: contain;\n  background-image: url(", ");\n  background-position: center;\n  background-repeat: no-repeat;\n  margin: 4px 12px;\n"], ["\n  width: 80px;\n  height: 80px;\n  background-size: contain;\n  background-image: url(", ");\n  background-position: center;\n  background-repeat: no-repeat;\n  margin: 4px 12px;\n"])), function (props) { return props.icon; });
var SizeSelectItem = /** @class */ (function (_super) {
    __extends(SizeSelectItem, _super);
    function SizeSelectItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SizeSelectItem.prototype.render = function () {
        var _a = this.props, size = _a.size, active = _a.active, clickHandler = _a.clickHandler, disabled = _a.disabled, icon = _a.icon;
        return (_jsxs(Item, __assign({ onClick: !disabled ? clickHandler : undefined, active: active, disabled: disabled }, { children: [icon && _jsx(Thumb, { icon: icon }, void 0), _jsx(Lights, { rare: includes(['orderable', 'inStock', 'inDifferentStore', 'scheduled'], size.stock) }, void 0), _jsx("span", { children: size.labelSize }, void 0)] }), void 0));
    };
    return SizeSelectItem;
}(Component));
export default SizeSelectItem;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
