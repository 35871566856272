var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { Map } from 'immutable';
import localStore from 'store';
import configureStore from '@store/configure';
import history from '@store/history';
import api from 'services/api';
import App from 'components/App';
import NeedleProvider from 'providers/NeedleProvider';
import { getUUID, getAuthHeader, parseUrl } from '@shared/utils/auth';
import ConnectedIntlProvider from 'providers/ConnectedIntlProvider';
var deviceId = getUUID(window.location.search) || localStore.get('deviceId');
/* NEED-330 */
var urlParams = parseUrl(window.location.href);
var footId = urlParams ? urlParams.footId : localStore.get('footId');
var lang = localStore.get('currentLanguage');
var store = configureStore(Map({
    intl: Map({
        currentLanguage: lang || 'de',
    }),
}), {
    api: api.create({ authToken: getAuthHeader(deviceId) }),
});
ReactDOM.render(_jsx(React.StrictMode, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(ConnectedIntlProvider, { children: _jsx(Router, __assign({ history: history }, { children: _jsx(NeedleProvider, __assign({ deviceId: deviceId, footId: footId }, { children: _jsx(App, {}, void 0) }), void 0) }), void 0) }, void 0) }), void 0) }, void 0), document.getElementById('app'));
