var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { createSession } from '@store/session/actions';
import { selectLanguage } from '@store/intl/actions';
import isEmpty from 'lodash/isEmpty';
import { getTeasers } from '@store/teaser/actions';
import { setGender } from '@store/gender/actions';
import { toJS } from '@shared/utils/immutable';
import { getPrimaryCategories } from '@store/primaryCategory/actions';
import { selectSession } from '@store/session/selectors';
import { selectCurrentGender } from '@store/gender/selectors';
import { selectAll as selectAllPrimaryCategories, selectShouldLoadAll as selectShouldLoadPrimaryCategories, } from '@store/primaryCategory/selectors';
import { selectCurrentLanguage } from '@store/intl/selectors';
import { selectSortedTeasers, selectLoading as teaserSelectLoading, selectLoaded as teaserSelectLoaded, } from '@store/teaser/selectors';
import { selectId as identitySelectId } from '@store/identity/selectors';
import Page from 'pages/HomePage';
var HomePage = /** @class */ (function (_super) {
    __extends(HomePage, _super);
    function HomePage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HomePage.prototype.componentDidMount = function () {
        if (this.props.shouldLoadPrimaries) {
            this.props.getPrimaryCategories();
        }
        if (!this.props.loading && !this.props.loaded) {
            this.props.getTeasers();
        }
    };
    HomePage.prototype.componentWillUnmount = function () {
        var _a = this.props, deviceId = _a.deviceId, session = _a.session;
        if (isEmpty(session) && Boolean(deviceId)) {
            this.props.createSession({ deviceId: deviceId });
        }
    };
    HomePage.prototype.render = function () {
        var _a = this.props, currentGender = _a.currentGender, currentLanguage = _a.currentLanguage, loaded = _a.loaded, primaryCategories = _a.primaryCategories, teasers = _a.teasers;
        return (_jsx(Page, { currentGender: currentGender, currentLanguage: currentLanguage, loaded: loaded, primaryCategories: primaryCategories, selectLanguage: this.props.selectLanguage, setGender: this.props.setGender, teasers: teasers }, void 0));
    };
    return HomePage;
}(Component));
var select = createStructuredSelector({
    currentGender: selectCurrentGender,
    currentLanguage: selectCurrentLanguage,
    teasers: selectSortedTeasers,
    session: selectSession,
    deviceId: identitySelectId,
    loading: teaserSelectLoading,
    loaded: teaserSelectLoaded,
    primaryCategories: selectAllPrimaryCategories,
    shouldLoadPrimaries: selectShouldLoadPrimaryCategories,
});
export var HomePageContainer = HomePage;
export default connect(select, {
    createSession: createSession,
    getPrimaryCategories: getPrimaryCategories,
    getTeasers: getTeasers,
    selectLanguage: selectLanguage,
    setGender: setGender,
})(toJS(HomePage));
