var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { getAuthHeader, getUUID } from "@shared/utils/auth";
import axios from "axios";
import _ from "lodash";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import localStore from "store";
import DropDownItems from "./dropDownItems/DropDownItems";
import HintBox from "./hintBox/HintBox";
import { useDispatch, useSelector } from "react-redux";
import { filterSelected } from "@store/filterSelections/filterActions";
import { filterSelection } from "@store/filterSelections/filterSelectors";
import styled from "styled-components";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n\n  .drop-down-wrapper:last-child {\n    margin-right: 0;\n  }\n"], ["\n  display: flex;\n\n  .drop-down-wrapper:last-child {\n    margin-right: 0;\n  }\n"])));
var DropDownWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 100%;\n  margin-right: 1rem;\n"], ["\n  width: 100%;\n  margin-right: 1rem;\n"])));
var HintWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  margin: 1rem 0;\n"], ["\n  display: flex;\n  flex-direction: row;\n  margin: 1rem 0;\n"])));
var deviceId = getUUID(window.location.search) || localStore.get("deviceId");
var apiUrl = process.env.REACT_APP_API_URL;
var headers = {
    Authorization: getAuthHeader(deviceId)
};
var DropDownHandler = function (_a) {
    var endPoint = _a.endPoint;
    var filterButtons = __spreadArray(__spreadArray([
        { id: 4, title: "Aktion" },
        { id: 2, title: "Anlass" },
        { id: 3, title: "Farbe" }
    ], (endPoint === "category" ? [] : [{ id: 7, title: "Größe" }]), true), [
        { id: 0, title: "Marke" },
        { id: 6, title: "Material" },
        { id: 8, title: "Modell" }
    ], false);
    var dispatch = useDispatch();
    var getSelectedFilter = useSelector(filterSelection);
    var search = useLocation().search;
    var history = useHistory();
    var primaryCategoryId = useParams().primaryCategoryId;
    var _b = useState([]), selectedItem = _b[0], setSelectedItem = _b[1];
    var _c = useState([]), hintItem = _c[0], setHintItem = _c[1];
    var _d = useState(false), showSearchHints = _d[0], setShowSearchHints = _d[1];
    var _e = useState([]), filterItems = _e[0], setFilterItems = _e[1];
    var _f = useState(), filterParams = _f[0], setFilterParams = _f[1];
    var _g = useState(false), panelOpen = _g[0], setPanelOpen = _g[1];
    var _h = useState(), currentFilter = _h[0], setCurrentFilter = _h[1];
    var _j = useState(false), loading = _j[0], setLoading = _j[1];
    // To prevent repeat the code of filtering in case of click buttons and close hint box
    var filterUtils = function (items) {
        var filterTypeMapping = {
            0: "brand",
            2: "style",
            3: "color",
            4: "advertising",
            6: "material",
            7: "size",
            8: "model"
        };
        var queryStringParams = [];
        items.forEach(function (item) {
            var filterKey = filterTypeMapping[item.type];
            if (filterKey) {
                var encodedValue = encodeURIComponent(String(item.value).replace(/&/g, "%26")); // convert to string and replace & with %26
                queryStringParams.push(filterKey + "=" + encodedValue);
            }
        });
        var queryStrings = queryStringParams.length > 0 ? "&" + queryStringParams.join("&") : "";
        var queryParams = queryString.parse(search);
        var sortKey = queryParams.sort;
        if (endPoint === "article") {
            history.push("results?category=" + filterParams.category + "&primaryCategory=" + filterParams.primaryCategory + (sortKey ? "&sort=" + sortKey : "") + queryStrings);
        }
        else {
            history.push("?" + queryStrings);
        }
        setHintItem(items);
    };
    // to close the hintBox
    var onCloseHintHandle = function (items) {
        var data = selectedItem.filter(function (item) { return item.value !== items; });
        setSelectedItem(data);
        dispatch(filterSelected(data));
        setHintItem(data);
        filterUtils(data);
    };
    // to close the panel after click
    var onFilterActionClick = function (e) {
        setShowSearchHints(true);
        filterUtils(selectedItem);
        dispatch(filterSelected(selectedItem));
        onCloseHandle(e);
    };
    // to close the panel after click
    var onCloseHandle = function (e) {
        e.stopPropagation();
        setPanelOpen(false);
    };
    // to handle state of selected Items for Post and style
    var onItemsClick = function (selection, filterType) {
        var targetElement = !_.isNil(selection.id) ? selection.id : selection.name;
        var displayName = selection.name;
        if (!selectedItem.some(function (items) { return items.value === targetElement; })) {
            setSelectedItem(selectedItem.concat({
                value: targetElement,
                type: filterType,
                name: displayName,
                image: selection.image
            }));
        }
        else {
            var removeSelectedItem = selectedItem.filter(function (item) { return item.value !== targetElement; });
            setSelectedItem(removeSelectedItem);
        }
    };
    // Handle get API and collect related data and handle open panel
    var onFilterClick = function (filterId) { return __awaiter(void 0, void 0, void 0, function () {
        var api, data, parseCustomNumber_1, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    api = {
                        article: apiUrl + "v2/filters/" + filterId + "?category=" + filterParams.category + "&lang=de&primaryCategory=" + filterParams.primaryCategory,
                        category: apiUrl + "v2/filters/" + filterId + "?lang=de&primaryCategory=" + primaryCategoryId
                    };
                    setFilterItems([]);
                    setCurrentFilter(filterId);
                    setLoading(true);
                    setPanelOpen(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axios.get(api[endPoint], { headers: headers })];
                case 2:
                    data = (_a.sent()).data;
                    parseCustomNumber_1 = function (str) { return str.includes('½') ? parseFloat(str.replace('½', '')) + 0.5 : parseFloat(str); };
                    if (filterId === 7) {
                        data.sort(function (a, b) { return parseCustomNumber_1(a.name) - parseCustomNumber_1(b.name); });
                    }
                    else {
                        data.sort(function (a, b) { return a.name.localeCompare(b.name, 'de'); });
                    }
                    setFilterItems(data);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // To get correct query parameter
    useEffect(function () {
        var queryParams = queryString.parse(search, { arrayFormat: "bracket" });
        var category = queryParams.category, primaryCategory = queryParams.primaryCategory, sort = queryParams.sort;
        setFilterParams(queryParams);
        setSelectedItem(getSelectedFilter);
        setShowSearchHints(true);
        setHintItem(getSelectedFilter);
        if (endPoint === "article" && !getSelectedFilter[0]) {
            var newSearch = queryString.stringify({ category: category, primaryCategory: primaryCategory, sort: sort });
            history.push("results?" + newSearch);
        }
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Wrapper, { children: filterButtons.map(function (item, key) { return (_jsx(DropDownWrapper, __assign({ className: "drop-down-wrapper" }, { children: _jsxs("div", __assign({ style: {
                            backgroundColor: selectedItem.some(function (items) { return items.type === item.id; }) ? "#cad1d8" : "transparent",
                            border: "0.0625rem solid rgb(165, 165, 165)",
                            marginBottom: "1.25rem",
                            marginRight: "1.25rem",
                            padding: "0.5rem 1.375rem 0.5rem 0.5rem",
                            position: "relative",
                            width: "100%",
                            color: "inherit",
                            zIndex: 10
                        }, onClick: function () { return onFilterClick(item.id); } }, { children: [_jsxs("div", __assign({ style: { display: "flex", justifyContent: "space-between" } }, { children: [item.title, _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", viewBox: "0 0 10.9 5.9" }, { children: _jsx("path", { opacity: "0.4", d: "M5.4 5.9c-.1 0-.3-.1-.4-.1L.1.9C0 .7 0 .3.1.1s.5-.2.7 0l4.6 4.6L10 .1c.2-.2.5-.2.7 0s.2.5 0 .7l-4.9 5c-.1.1-.2.1-.4.1z" }, void 0) }), void 0)] }), void 0), panelOpen && item.id === currentFilter && filterItems ? (_jsx(_Fragment, { children: _jsx(DropDownItems, { title: item.title, filterItems: filterItems, filterType: item.id, onItemsClick: function (selection) { return onItemsClick(selection, item.id); }, onCloseHandle: function (e) { return onCloseHandle(e); }, loading: loading, selectedItems: selectedItem, FilterAction: function (e) { return onFilterActionClick(e); } }, void 0) }, void 0)) : null] }), key) }), key)); }) }, void 0), _jsx(HintWrapper, { children: _jsx(HintBox, { showResult: showSearchHints, selectedItem: hintItem, closeBox: function (item) { return onCloseHintHandle(item); } }, void 0) }, void 0)] }, void 0));
};
export default DropDownHandler;
var templateObject_1, templateObject_2, templateObject_3;
