var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
import { palette } from "@shared/utils/styled";
import Icon from "@shared/atoms/Icon";
import AspectRatio from "@shared/atoms/AspectRatio";
import HorizontalFlex from "@shared/atoms/HorizontalFlex";
export var Main = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      position: absolute;\n      background-color: white;\n      z-index: 50;\n      left: 0;\n      top: 0;\n      width: 100%;\n      outline: 2px solid gray;\n    "], ["\n      position: absolute;\n      background-color: white;\n      z-index: 50;\n      left: 0;\n      top: 0;\n      width: 100%;\n      outline: 2px solid gray;\n    "])));
export var Header = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      display: flex;\n      justify-content: space-around;\n      align-items: center;\n    "], ["\n      display: flex;\n      justify-content: space-around;\n      align-items: center;\n    "])));
export var ItemBlock = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: ", ";\n  display: block;\n  width: 100%;\n"], ["\n  background-color: ", ";\n  display: block;\n  width: 100%;\n"])), palette("white"));
export var ItemButton = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      width: 100%;\n      border: none;\n      align-items: center;\n      background-color: transparent;\n      border-top: 0.0625rem solid rgb(202, 209, 216);\n      font-weight: 300;\n      justify-content: flex-start;\n      margin-block: 0.125rem 0;\n      min-height: 3.5rem;\n      padding: 0.625rem;\n      cursor: pointer;\n    "], ["\n      width: 100%;\n      border: none;\n      align-items: center;\n      background-color: transparent;\n      border-top: 0.0625rem solid rgb(202, 209, 216);\n      font-weight: 300;\n      justify-content: flex-start;\n      margin-block: 0.125rem 0;\n      min-height: 3.5rem;\n      padding: 0.625rem;\n      cursor: pointer;\n    "])));
export var FilterConfirm = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  height: 2.125rem;\n  width: 100%;\n  cursor: pointer;\n  border: none;\n"], ["\n  background-color: ", ";\n  color: ", ";\n  height: 2.125rem;\n  width: 100%;\n  cursor: pointer;\n  border: none;\n"])), palette("primaryHighlight"), palette("white"));
export var CancelConfirm = styled.button(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  height: 2.125rem;\n  width: 100%;\n  cursor: pointer;\n  border: none;\n"], ["\n  background-color: ", ";\n  color: ", ";\n  height: 2.125rem;\n  width: 100%;\n  cursor: pointer;\n  border: none;\n"])), palette("secondaryHighlight"), palette("white"));
export var Wrapper = styled(HorizontalFlex)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 1rem;\n"], ["\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 1rem;\n"])));
export var HintBoxWrapper = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-wrap: wrap;\n\n  .selected-filter-item:last-child {\n    margin-right: 0;\n  }\n"], ["\n  display: flex;\n  flex-wrap: wrap;\n\n  .selected-filter-item:last-child {\n    margin-right: 0;\n  }\n"])));
export var FilterItem = styled(HorizontalFlex)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  border-radius: 0.3125rem;\n  padding: 0.3125rem;\n  margin-right: 0.5rem;\n  margin-bottom: 1rem;\n  transition: background-color 0.2s ease;\n\n  &:active {\n    background-color: ", ";\n  }\n"], ["\n  align-items: center;\n  background-color: ", ";\n  border-radius: 0.3125rem;\n  padding: 0.3125rem;\n  margin-right: 0.5rem;\n  margin-bottom: 1rem;\n  transition: background-color 0.2s ease;\n\n  &:active {\n    background-color: ", ";\n  }\n"])), palette("white"), palette("activeWhite"));
export var ImageContainer = styled(AspectRatio)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 10%;\n  border: 0.0625rem solid ", ";\n  margin-right: 0.5rem;\n  width: 1.25rem;\n"], ["\n  background-color: ", ";\n  border-radius: 10%;\n  border: 0.0625rem solid ", ";\n  margin-right: 0.5rem;\n  width: 1.25rem;\n"])), palette("white"), palette("border"));
export var Image = styled.img(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n      bottom: 0;\n      height: auto;\n      left: 0;\n      margin: auto;\n      position: absolute;\n      right: 0;\n      top: 0;\n      width: 100%;\n    "], ["\n      bottom: 0;\n      height: auto;\n      left: 0;\n      margin: auto;\n      position: absolute;\n      right: 0;\n      top: 0;\n      width: 100%;\n    "])));
export var CloseIcon = styled(Icon)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin: 0 0.5rem;\n"], ["\n  margin: 0 0.5rem;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
