var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from "react";
import { withRouter } from "react-router";
import styled from "styled-components";
import { fontWeight, palette } from "@shared/utils/styled";
import { parse, stringify } from "query-string";
import HorizontalFlex from "@shared/atoms/HorizontalFlex";
import Icon from "@shared/atoms/Icon";
import sortBy from "lodash/sortBy";
import { FormattedMessage } from "react-intl";
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      margin-top: 2.1875rem;\n      position: relative;\n      white-space: nowrap;\n    "], ["\n      margin-top: 2.1875rem;\n      position: relative;\n      white-space: nowrap;\n    "])));
var ListWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      padding: 0.625rem;\n    "], ["\n      padding: 0.625rem;\n    "])));
var Dropdown = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      background-color: white;\n      border: 0.0625rem solid ", ";\n      max-height: calc(100vh - 10.625rem);\n      min-width: 12.8125rem;\n      overflow: auto;\n      position: absolute;\n      right: -0.4375rem;\n      top: -0.75rem;\n      z-index: 11;\n    "], ["\n      background-color: white;\n      border: 0.0625rem solid ", ";\n      max-height: calc(100vh - 10.625rem);\n      min-width: 12.8125rem;\n      overflow: auto;\n      position: absolute;\n      right: -0.4375rem;\n      top: -0.75rem;\n      z-index: 11;\n    "])), palette("border"));
var CurrentSort = styled(HorizontalFlex)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  position: relative;\n"], ["\n  cursor: pointer;\n  position: relative;\n"])));
var Drilldown = styled(Icon)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-left: 0.5rem;\n"], ["\n  margin-left: 0.5rem;\n"])));
var Drillup = styled(Icon)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  transform: rotate(180deg);\n  color: ", ";\n"], ["\n  transform: rotate(180deg);\n  color: ", ";\n"])), palette("selectedWhite"));
var SortItemWrapper = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      cursor: pointer;\n      border-top: 0.0625rem solid ", ";\n\n      &:first-child,\n      &:active:first-child {\n        border-top: 0;\n      }\n\n      ", ";\n\n      &:active {\n        border-top: 0.0625rem solid transparent;\n        background-color: ", ";\n        border-radius: 0.3125rem;\n\n        + div {\n          border-top: 0.0625rem solid transparent;\n        }\n      }\n    "], ["\n      cursor: pointer;\n      border-top: 0.0625rem solid ", ";\n\n      &:first-child,\n      &:active:first-child {\n        border-top: 0;\n      }\n\n      ", ";\n\n      &:active {\n        border-top: 0.0625rem solid transparent;\n        background-color: ", ";\n        border-radius: 0.3125rem;\n\n        + div {\n          border-top: 0.0625rem solid transparent;\n        }\n      }\n    "])), function (props) {
    return props.selected ? palette("activeWHite") : palette("border");
}, function (props) {
    return props.selected &&
        "\n      border-radius: 0.3125rem;\n      border-top: 0.0625rem solid transparent;\n      background-color: " + props.theme.palette.selectedWhite + ";\n      + div {\n        border-top: 0.0625rem solid transparent;\n      }\n    ";
}, palette("activeWhite"));
var SortItem = styled(HorizontalFlex)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  align-items: center;\n  font-weight: ", ";\n  justify-content: flex-start;\n  padding: 0.9375rem;\n"], ["\n  align-items: center;\n  font-weight: ", ";\n  justify-content: flex-start;\n  padding: 0.9375rem;\n"])), fontWeight("light"));
var Header = styled(HorizontalFlex)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  font-weight: ", ";\n  padding: 0.625rem;\n"], ["\n  align-items: center;\n  background-color: ", ";\n  color: ", ";\n  cursor: pointer;\n  font-weight: ", ";\n  padding: 0.625rem;\n"])), palette("secondaryBackground"), palette("white"), fontWeight("bold"));
var SortDropdown = /** @class */ (function (_super) {
    __extends(SortDropdown, _super);
    function SortDropdown(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            open: false
        };
        _this.toggleDropdown = function () {
            _this.setState({
                open: !_this.state.open
            });
        };
        _this.selectSort = function (sortId) {
            var _a = _this.props, params = _a.params, history = _a.history;
            var baseParams = parse(params, { arrayFormat: "bracket" });
            baseParams.sort = sortId;
            history.push({
                pathname: "/results",
                search: "?" + stringify(baseParams, { arrayFormat: "bracket" })
            });
            _this.toggleDropdown();
        };
        return _this;
    }
    SortDropdown.prototype.render = function () {
        var _this = this;
        var _a = this.props, sorts = _a.sorts, currentSortId = _a.currentSortId;
        var open = this.state.open;
        var currentSort = sorts.find(function (item) { return item.id === currentSortId; });
        var sortedSorts = sortBy(sorts, "sort");
        return (_jsxs(Wrapper, { children: [_jsxs(CurrentSort, __assign({ onClick: function () { return _this.toggleDropdown(); } }, { children: [currentSort ? (currentSort.displayName) : (_jsx(FormattedMessage, { id: "stock.sort.placeholder", defaultMessage: "Sortierung w\u00E4hlen" }, void 0)), (currentSort === null || currentSort === void 0 ? void 0 : currentSort.sort) === 1 ? _jsx(Drilldown, { icon: "sort", width: 20 }, void 0) : "", (currentSort === null || currentSort === void 0 ? void 0 : currentSort.sort) === 2 ? _jsx(Drillup, { icon: "sort", width: 20 }, void 0) : "", (currentSort === null || currentSort === void 0 ? void 0 : currentSort.sort) === 3 ? _jsx(Drilldown, { icon: "sort", width: 20 }, void 0) : ""] }), void 0), open && (_jsxs(Dropdown, { children: [_jsxs(Header, __assign({ onClick: function () { return _this.toggleDropdown(); } }, { children: [_jsx(FormattedMessage, { id: "stock.sort.headline", defaultMessage: "Sortierung" }, void 0), _jsx(Drillup, { icon: "drilldown", width: 15 }, void 0)] }), void 0), _jsx(ListWrapper, { children: Boolean(sortedSorts) &&
                                sortedSorts &&
                                sortedSorts.map(function (sort) {
                                    var selected = currentSortId === sort.id;
                                    return (_jsx(SortItemWrapper, __assign({ onClick: function () { return _this.selectSort(sort.id); }, selected: selected }, { children: _jsx(SortItem, { children: sort.displayName }, void 0) }), sort.id));
                                }) }, void 0)] }, void 0))] }, void 0));
    };
    return SortDropdown;
}(Component));
export default withRouter(SortDropdown);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;
