var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
import React from 'react';
import styled from 'styled-components';
import { palette, fontWeight } from '@shared/utils/styled';
import RadioButtonIcon from './icon_radiobutton-active.svg';
import RadioButtonInactiveIcon from './icon_radiobutton-inactive.svg';
var Input = styled.input(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  appearance: none;\n  background-image: url(", ");\n  background-position: 50% 50%;\n  background-repeat: no-repeat;\n  background-size: 2rem;\n  flex: 0 0 auto;\n  height: 2rem;\n  margin-right: 0.625rem;\n  width: 2rem;\n  outline: none;\n\n  &:checked {\n    background-image: url(", ");\n\n    & + label {\n      color: ", ";\n      font-weight: ", ";\n    }\n  }\n"], ["\n  appearance: none;\n  background-image: url(", ");\n  background-position: 50% 50%;\n  background-repeat: no-repeat;\n  background-size: 2rem;\n  flex: 0 0 auto;\n  height: 2rem;\n  margin-right: 0.625rem;\n  width: 2rem;\n  outline: none;\n\n  &:checked {\n    background-image: url(", ");\n\n    & + label {\n      color: ", ";\n      font-weight: ", ";\n    }\n  }\n"])), RadioButtonInactiveIcon, RadioButtonIcon, palette('text'), fontWeight('bold'));
var RadioButton = function (_a) {
    var checked = _a.checked, disabled = _a.disabled, id = _a.id, name = _a.name, value = _a.value, onBlur = _a.onBlur, onChange = _a.onChange, onDragStart = _a.onDragStart, onDrop = _a.onDrop, onFocus = _a.onFocus;
    return (_jsx(Input, { disabled: disabled, type: 'radio', checked: checked, id: id, name: name, value: value, onBlur: onBlur, onChange: onChange, onDragStart: onDragStart, onDrop: onDrop, onFocus: onFocus }, void 0));
};
/** @component */
export default RadioButton;
var templateObject_1;
