var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
import React from "react";
import styled from "styled-components";
import MenuIcon from "@shared/atoms/MenuIcon";
import Text from "@shared/atoms/Text";
import { injectIntl } from "react-intl";
import { palette, fontSize } from "@shared/utils/styled";
var Wrapper = styled.button(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: stretch;\n  background-color: ", ";\n  border-radius: 0.0625rem;\n  border: 0;\n  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n  box-sizing: border-box;\n  color: ", ";\n  cursor: pointer;\n  height: 100%;\n  opacity: ", ";\n  outline: none;\n  padding: 0 0.5rem;\n  transition: opacity 2s linear;\n  width: 100%;\n  &:active {\n    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);\n  }\n"], ["\n  align-items: stretch;\n  background-color: ", ";\n  border-radius: 0.0625rem;\n  border: 0;\n  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.2);\n  box-sizing: border-box;\n  color: ", ";\n  cursor: pointer;\n  height: 100%;\n  opacity: ", ";\n  outline: none;\n  padding: 0 0.5rem;\n  transition: opacity 2s linear;\n  width: 100%;\n  &:active {\n    box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);\n  }\n"])), palette("white"), palette("tertiaryHighlight"), function (props) { return (props.inactive ? 0.5 : 1); });
var MenuIconWrapper = styled(MenuIcon)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: block;\n  height: 50%;\n  margin-bottom: 0.5rem;\n  min-height: 11vh;\n  @media screen and (min-width: ", ") {\n    margin-bottom: 6%;\n  }\n"], ["\n  display: block;\n  height: 50%;\n  margin-bottom: 0.5rem;\n  min-height: 11vh;\n  @media screen and (min-width: ", ") {\n    margin-bottom: 6%;\n  }\n"])), function (props) {
    return props.theme.breakpoints.medium;
});
var TextWrapper = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 100%;\n"], ["\n  width: 100%;\n"])));
var ButtonText = styled(Text)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n  @media screen and (min-width: ", ") {\n    font-size: 1.875rem;\n  }\n"], ["\n  font-size: ", ";\n  @media screen and (min-width: ", ") {\n    font-size: 1.875rem;\n  }\n"])), fontSize("extraLarge"), function (props) {
    return props.theme.breakpoints.large;
});
var MenuButton = function (props) { return (_jsxs(Wrapper, __assign({ inactive: props.inactive }, { children: [_jsx(MenuIconWrapper, { icon: props.icon }, void 0), _jsx(TextWrapper, { children: _jsx(ButtonText, { children: props.intl.formatMessage(props.text) }, void 0) }, void 0)] }), void 0)); };
/** @component */
export default injectIntl(MenuButton);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
