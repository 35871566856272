var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { put, takeEvery, call, delay } from 'redux-saga/effects';
import { pollInterval } from '@shared/utils/constants';
import { addError as addErrorAction } from '../error/actions';
import { checkServiceConnection, checkServiceConnectionComplete, checkWebConnection, checkWebConnectionComplete, } from './actions';
/**
 * Check for internet connection.
 *
 * @param {object} api - API endpoint.
 * @param {action} action - Wanted action.
 */
export function checkWeb(api, action) {
    var error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 10]);
                if (!window.navigator.onLine) return [3 /*break*/, 3];
                return [4 /*yield*/, put(checkWebConnectionComplete())];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(checkServiceConnection(action.payload, __assign(__assign({}, action.meta.params), { createError: false }), false))];
            case 2:
                _a.sent();
                return [3 /*break*/, 4];
            case 3: throw new Error('Not connected to the internet');
            case 4: return [3 /*break*/, 10];
            case 5:
                error_1 = _a.sent();
                return [4 /*yield*/, put(checkWebConnectionComplete(error_1))];
            case 6:
                _a.sent();
                return [4 /*yield*/, put(addErrorAction({ message: error_1.message, showError: true }))];
            case 7:
                _a.sent();
                return [4 /*yield*/, delay(pollInterval)];
            case 8:
                _a.sent();
                return [4 /*yield*/, put(checkWebConnection.apply(void 0, __spreadArray([action.payload], action.meta.params, false)))];
            case 9:
                _a.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
/**
 * Check if the availability of the server by using the health-check endpoint.
 *
 * @param {object} api - API endpoint.
 * @param {action} action - Wanted action.
 */
export function checkService(api, action) {
    var error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 13]);
                return [4 /*yield*/, call([api, api.get], 'health-check')];
            case 1:
                _a.sent();
                return [4 /*yield*/, put(action.payload(action.meta.params[0][0]))];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(checkServiceConnectionComplete())];
            case 3:
                _a.sent();
                return [3 /*break*/, 13];
            case 4:
                error_2 = _a.sent();
                return [4 /*yield*/, put(checkServiceConnectionComplete(error_2))];
            case 5:
                _a.sent();
                if (!(action.meta.params[0].createError === true)) return [3 /*break*/, 9];
                // NEED-391 - Don't show an error immediatly if the backend isn't available...
                return [4 /*yield*/, put(addErrorAction({
                        message: error_2.message === 'Failed to fetch'
                            ? 'Server ist nicht erreichbar.'
                            : error_2.message,
                        showError: true,
                    }))];
            case 6:
                // NEED-391 - Don't show an error immediatly if the backend isn't available...
                _a.sent();
                return [4 /*yield*/, delay(pollInterval)];
            case 7:
                _a.sent();
                return [4 /*yield*/, put(checkServiceConnection(action.payload, __assign(__assign({}, action.meta.params), { createError: false })))];
            case 8:
                _a.sent();
                return [3 /*break*/, 12];
            case 9: return [4 /*yield*/, delay(2000)];
            case 10:
                _a.sent(); // NEED-391 - ... but rather try again after 2 seconds
                return [4 /*yield*/, put(checkServiceConnection(action.payload, __assign(__assign({}, action.meta.params), { createError: false })))];
            case 11:
                _a.sent();
                _a.label = 12;
            case 12: return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}
export function webWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeEvery('CHECK_WEB_CONNECTION', checkWeb, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export function serviceWatcher(_a) {
    var api = _a.api;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, takeEvery('CHECK_SERVICE_CONNECTION', checkService, api)];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
