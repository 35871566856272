var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '@shared/utils/immutable';
import { Form } from 'react-final-form';
import { defineMessages, FormattedMessage, injectIntl, } from 'react-intl';
import { StyledInput, StyledInputWrapper, StyledScanSearchFormWrapper, StyledGiftcardBalance, StyledGiftcardSearchError, StyledSubmitButton, StyledGiftcardBalanceAmount, } from './style';
import { loadGiftcardBalance, scanGiftCardReset, } from '@store/giftcard/actions';
var ScanGiftcardForm = /** @class */ (function (_super) {
    __extends(ScanGiftcardForm, _super);
    function ScanGiftcardForm() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {};
        return _this;
    }
    ScanGiftcardForm.prototype.componentWillUnmount = function () {
        this.props.scanGiftCardReset();
    };
    ScanGiftcardForm.prototype.render = function () {
        var _this = this;
        var _a = this.props, intl = _a.intl, loadGiftcardBalance = _a.loadGiftcardBalance, loading = _a.loading, balanceResult = _a.balanceResult, scanValue = _a.scanValue;
        var errorCode = this.state.errorCode;
        var giftcardNumber = (balanceResult || {}).giftcardNumber;
        var inputPlaceholder = defineMessages({
            inputPlaceholder: {
                id: 'giftcard.placeholder',
                defaultMessage: 'Tamaris Gift Card Nummer',
            },
        }).inputPlaceholder;
        var onSubmit = function (_a) {
            var scanValue = _a.scan;
            if (scanValue) {
                if (scanValue.length === 16 && !scanValue.match(/^\d+$/)) {
                    _this.setState({ errorCode: 'giftcard.error.numbers' });
                }
                else if (scanValue.match(/^\d+$/) && scanValue.length !== 16) {
                    _this.setState({ errorCode: 'giftcard.error.length1d' });
                }
                else if (!scanValue.match(/^\d+$/) && scanValue.length !== 11)
                    _this.setState({ errorCode: 'giftcard.error.length2d' });
                else {
                    // Code okay
                    _this.setState({ errorCode: false });
                    loadGiftcardBalance(scanValue);
                }
            }
        };
        return (_jsxs(StyledScanSearchFormWrapper, { children: [!loading && !errorCode && balanceResult && !balanceResult.error && (_jsxs(StyledGiftcardBalance, { children: [_jsx(FormattedMessage, { id: 'giftcard.balancemsg', defaultMessage: 'Das Guthaben dieser Tamaris Gift Card betr\u00E4gt:' }, void 0), _jsx(StyledGiftcardBalanceAmount, { children: (balanceResult.balance * 0.01).toFixed(2).replace('.', ',') +
                                ' €' }, void 0)] }, void 0)), _jsx(Form, { onSubmit: onSubmit, initialValues: {
                        scan: scanValue,
                    }, render: function (_a) {
                        var handleSubmit = _a.handleSubmit;
                        return (_jsxs(StyledInputWrapper, __assign({ onSubmit: handleSubmit }, { children: [_jsx(StyledInput, { value: giftcardNumber, autoComplete: 'off', component: 'input', name: 'scan', placeholder: intl.formatMessage(inputPlaceholder), required: true }, void 0), _jsx(StyledSubmitButton, __assign({ type: 'submit' }, { children: _jsx(FormattedMessage, { id: 'giftcard.input.button', defaultMessage: 'Guthaben abfragen' }, void 0) }), void 0)] }), void 0));
                    } }, void 0), _jsxs(StyledGiftcardSearchError, { children: [!loading && errorCode === 'giftcard.error.numbers' && (_jsx(FormattedMessage, { id: 'giftcard.error.numbers', defaultMessage: 'Bitte geben Sie nur Zahlen ein' }, void 0)), !loading && errorCode === 'giftcard.error.length2d' && (_jsx(FormattedMessage, { id: 'giftcard.error.length2d', defaultMessage: 'Die L\u00E4nge des Codes passt nicht. Es wird ein 11-stelliger Buchstabencode oder ein 16-stelliger Zahlencode erwartet' }, void 0)), !loading && errorCode === 'giftcard.error.length1d' && (_jsx(FormattedMessage, { id: 'giftcard.error.length1d', defaultMessage: 'Die L\u00E4nge des Codes passt nicht. Es wird ein 16-stelliger Zahlencode oder ein 11-stelliger Buchstabencode erwartet' }, void 0)), !loading &&
                            balanceResult &&
                            balanceResult.error === 'giftcard.error.server' && (_jsx(FormattedMessage, { id: 'giftcard.error.server', defaultMessage: 'Die Abfrage der Gift Card war leider momentan nicht m\u00F6glich. Bitte versuchen Sie es sp\u00E4ter noch einmal' }, void 0)), !loading &&
                            balanceResult &&
                            balanceResult.error === 'giftcard.error.notfound' && (_jsx(FormattedMessage, { id: 'giftcard.error.notfound', defaultMessage: 'Zu diesem Code konnten wir keine Gift Card finden.' }, void 0)), !loading &&
                            balanceResult &&
                            balanceResult.error === 'giftcard.error.config' && (_jsx(FormattedMessage, { id: 'giftcard.error.config', defaultMessage: 'Die Konfiguration ist unvollst\u00E4ndig. Dieses Feature kann momentan nicht genutzt werden.' }, void 0))] }, void 0)] }, void 0));
    };
    return ScanGiftcardForm;
}(Component));
var mapStateToProps = function (state) {
    var _a = state.get('giftcard'), loading = _a.loading, balanceResult = _a.balanceResult, scanValue = _a.scanValue;
    return {
        loading: loading,
        balanceResult: balanceResult,
        scanValue: scanValue,
    };
};
export default injectIntl(connect(mapStateToProps, {
    loadGiftcardBalance: loadGiftcardBalance,
    scanGiftCardReset: scanGiftCardReset,
})(toJS(ScanGiftcardForm)));
