var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from "react";
import styled from "styled-components";
import { key, palette } from "@shared/utils/styled";
import { defineMessages } from "react-intl";
import moment from "moment";
import BrandModal from "molecules/BrandModal";
import CallConfirm, { CancelConfirm } from "organisms/CallConfirm";
import ContentMenuButton from "@shared/molecules/ContentMenuButton";
import HorizontalFlex from "@shared/atoms/HorizontalFlex";
import LinkWrapper from "molecules/LinkWrapper";
import VerticalFlex from "@shared/atoms/VerticalFlex";
import { Link } from "react-router-dom";
var FlexWrapper = styled(HorizontalFlex)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  background-color: #f5f5f5; // Fixed color, for every theme\n  flex: 1;\n  max-height: 7.625rem;\n  min-height: 7.625rem;\n  position: relative;\n"], ["\n  display: flex;\n  align-items: center;\n  background-color: #f5f5f5; // Fixed color, for every theme\n  flex: 1;\n  max-height: 7.625rem;\n  min-height: 7.625rem;\n  position: relative;\n"])));
var Logo = styled(Link)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  flex: 3;\n\n  img {\n    margin: 0 auto;\n    max-height: 6.5rem;\n    max-width: calc(100% - 1.5rem);\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  flex: 3;\n\n  img {\n    margin: 0 auto;\n    max-height: 6.5rem;\n    max-width: calc(100% - 1.5rem);\n  }\n"])));
var MenuLinks = styled(HorizontalFlex)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  flex: 7;\n  height: 4.6875rem;\n  justify-content: flex-end;\n  position: relative;\n"], ["\n  flex: 7;\n  height: 4.6875rem;\n  justify-content: flex-end;\n  position: relative;\n"])));
var MenuLinkWrapper = styled(LinkWrapper)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  border-left: 0.0625rem solid ", ";\n  justify-content: center;\n  max-width: 10.625rem;\n  transition: background-color 0.2s ease, border-left 0.2s ease;\n\n  &:first-child,\n  &:first-child:active {\n    border-left: 0;\n  }\n\n  &:active {\n    background-color: ", ";\n    border-left: 0.0625rem solid ", ";\n\n    + div,\n    + a {\n      border-left: 0.0625rem solid ", ";\n    }\n  }\n\n  @media screen and (min-width: ", ") {\n    max-width: 13.75rem;\n  }\n"], ["\n  border-left: 0.0625rem solid ", ";\n  justify-content: center;\n  max-width: 10.625rem;\n  transition: background-color 0.2s ease, border-left 0.2s ease;\n\n  &:first-child,\n  &:first-child:active {\n    border-left: 0;\n  }\n\n  &:active {\n    background-color: ", ";\n    border-left: 0.0625rem solid ", ";\n\n    + div,\n    + a {\n      border-left: 0.0625rem solid ", ";\n    }\n  }\n\n  @media screen and (min-width: ", ") {\n    max-width: 13.75rem;\n  }\n"])), palette("border"), palette("activeGrey"), palette("activeGrey"), palette("activeGrey"), key("breakpoints.medium", "1280px"));
var CallSupport = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      border-left: 0.0625rem solid ", ";\n      cursor: pointer;\n      flex: 1;\n      height: 100%;\n      justify-content: center;\n      max-width: 10.675rem;\n      text-decoration: none;\n      transition: background-color 0.2s ease, border-left 0.2s ease;\n\n      &:active {\n        background-color: ", ";\n        border-left: 0.0625rem solid ", ";\n      }\n\n      @media screen and (min-width: ", ") {\n        max-width: 13.75rem;\n      }\n    "], ["\n      border-left: 0.0625rem solid ", ";\n      cursor: pointer;\n      flex: 1;\n      height: 100%;\n      justify-content: center;\n      max-width: 10.675rem;\n      text-decoration: none;\n      transition: background-color 0.2s ease, border-left 0.2s ease;\n\n      &:active {\n        background-color: ", ";\n        border-left: 0.0625rem solid ", ";\n      }\n\n      @media screen and (min-width: ", ") {\n        max-width: 13.75rem;\n      }\n    "])), palette("border"), palette("activeGrey"), palette("activeGrey"), key("breakpoints.medium", "1280px"));
var GenderButton = styled(VerticalFlex)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  align-items: center;\n  border-left: 0.0625rem solid ", ";\n  cursor: pointer;\n  height: 100%;\n  justify-content: center;\n  padding: 0 0.75rem;\n  min-width: 5.625rem;\n  text-decoration: none;\n  transition: background-color 0.2s ease, border-left 0.2s ease;\n\n  &:first-child:active {\n    border-left: 0.0625rem solid ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n    border-left: 0.0625rem solid ", ";\n\n    + div,\n    + a {\n      border-left: 0.0625rem solid ", ";\n    }\n  }\n\n  @media screen and (min-width: ", ") {\n    max-width: 13.75rem;\n  }\n"], ["\n  align-items: center;\n  border-left: 0.0625rem solid ", ";\n  cursor: pointer;\n  height: 100%;\n  justify-content: center;\n  padding: 0 0.75rem;\n  min-width: 5.625rem;\n  text-decoration: none;\n  transition: background-color 0.2s ease, border-left 0.2s ease;\n\n  &:first-child:active {\n    border-left: 0.0625rem solid ", ";\n  }\n\n  &:active {\n    background-color: ", ";\n    border-left: 0.0625rem solid ", ";\n\n    + div,\n    + a {\n      border-left: 0.0625rem solid ", ";\n    }\n  }\n\n  @media screen and (min-width: ", ") {\n    max-width: 13.75rem;\n  }\n"])), palette("border"), palette("activeGrey"), palette("activeGrey"), palette("activeGrey"), palette("activeGrey"), key("breakpoints.medium", "1280px"));
var GenderText = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      border-bottom-color: ", ";\n      ", ";\n    "], ["\n      border-bottom-color: ", ";\n      ", ";\n    "])), palette("tertiaryHighlight"), function (props) {
    return props.selected &&
        "\n      border-bottom-style: solid;\n      border-bottom-width: 0.1875rem;\n      border-radius: 0;\n      margin-bottom: -0.1875rem;\n  ";
});
var ContentMenu = /** @class */ (function (_super) {
    __extends(ContentMenu, _super);
    function ContentMenu(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            modalOpen: false
        };
        _this.openModal = function () {
            if (!_this.state.modelOpen) {
                _this.props.getDuration();
            }
            _this.toggleModal();
        };
        _this.toggleModal = function () {
            _this.setState({
                modalOpen: !_this.state.modalOpen
            });
        };
        _this.confirmCall = function () {
            var now = moment().format();
            props.updateSession({ serviceCallAt: now });
            _this.toggleModal();
        };
        _this.confirmCancel = function () {
            props.updateSession({ serviceCallAt: null });
            _this.toggleModal();
        };
        return _this;
    }
    ContentMenu.prototype.render = function () {
        var _a = this.props, logo = _a.logo, session = _a.session, currentPageUrl = _a.currentPageUrl, currentGender = _a.currentGender, primaryCategories = _a.primaryCategories, switchGender = _a.switchGender, toggleSelection = _a.toggleSelection, theme = _a.theme;
        var modalOpen = this.state.modalOpen;
        var hasGender = Boolean(currentGender);
        var currentPriCat = primaryCategories.find(function (item) { return item.id === currentGender; });
        var switches = (theme && theme.switches) || {};
        var hide = switches.hide || [];
        return (_jsxs(FlexWrapper, { children: [_jsx(Logo, __assign({ to: "/" }, { children: logo && _jsx("img", { src: logo, alt: "logo" }, void 0) }), void 0), _jsxs(MenuLinks, { children: [primaryCategories.map(function (pc) { return (_jsx(GenderButton, __assign({ onClick: function () { return switchGender(pc); } }, { children: _jsx(GenderText, __assign({ selected: currentPageUrl !== "/scan" && currentGender === pc.id }, { children: pc.displayName }), void 0) }), pc.displayName + pc.id)); }), hasGender && currentGender && currentPriCat
                            ? !hide.includes("inspiration") && (_jsx(MenuLinkWrapper, __assign({ to: !currentPriCat.inspirationId ? "#" : "/inspiration/" + currentPriCat.inspirationId }, { children: _jsx(ContentMenuButton, __assign({ icon: "inspirationContent" }, defineMessages({
                                    text: {
                                        id: "contentMenu.highlights",
                                        defaultMessage: "Highlights"
                                    }
                                }), { selected: currentPageUrl === "/inspiration" ||
                                        currentPageUrl === "/collections" }), void 0) }), void 0))
                            : !hide.includes("inspiration") && (_jsx(CallSupport, __assign({ onClick: function () { return toggleSelection("/inspiration"); } }, { children: _jsx(ContentMenuButton, __assign({ icon: "inspirationContent" }, defineMessages({
                                    text: {
                                        id: "contentMenu.highlights",
                                        defaultMessage: "Highlights"
                                    }
                                }), { selected: currentPageUrl === "/inspiration" ||
                                        currentPageUrl === "/collections" }), void 0) }), void 0)), hasGender && currentGender ? (_jsx(MenuLinkWrapper, __assign({ to: "/search/" + currentGender }, { children: _jsx(ContentMenuButton, __assign({ icon: "rangeContent" }, defineMessages({
                                text: {
                                    id: "contentMenu.stock",
                                    defaultMessage: "Alle Artikel anzeigen"
                                }
                            }), { selected: currentPageUrl === "/search" ||
                                    currentPageUrl === "/results" ||
                                    currentPageUrl === "/articles" }), void 0) }), void 0)) : (_jsx(CallSupport, __assign({ onClick: function () {
                                toggleSelection("/search");
                            } }, { children: _jsx(ContentMenuButton, __assign({ icon: "rangeContent" }, defineMessages({
                                text: {
                                    id: "contentMenu.stock",
                                    defaultMessage: "Alle Artikel anzeigen"
                                }
                            }), { selected: currentPageUrl === "/search" ||
                                    currentPageUrl === "/results" ||
                                    currentPageUrl === "/articles" }), void 0) }), void 0)), _jsx(MenuLinkWrapper, __assign({ to: "/scan" }, { children: _jsx(ContentMenuButton, __assign({}, defineMessages({
                                text: {
                                    id: "contentMenu.scan",
                                    defaultMessage: "Suchen"
                                }
                            }), { icon: "searchContent", selected: currentPageUrl === "/scan" }), void 0) }), void 0), switches.giftcard && (_jsx(MenuLinkWrapper, __assign({ to: "/giftcard" }, { children: _jsx(ContentMenuButton, __assign({}, defineMessages({
                                text: {
                                    id: "giftcard.name",
                                    defaultMessage: "Gift Card"
                                }
                            }), { icon: "searchContent", selected: currentPageUrl === "/giftcard" }), void 0) }), void 0)), !hide.includes("support") && (_jsx(CallSupport, __assign({ onClick: this.openModal }, { children: _jsx(ContentMenuButton, __assign({ icon: "supportContent", active: Boolean(session.serviceCallAt) }, defineMessages({
                                text: {
                                    id: "contentMenu.serviceRequest",
                                    defaultMessage: "Beratung anfragen"
                                },
                                activeText: {
                                    id: "contentMenu.serviceRequestActive",
                                    defaultMessage: "Anfrage läuft"
                                }
                            })), void 0) }), void 0))] }, void 0), _jsx(BrandModal, __assign({ open: modalOpen, toggleAction: this.toggleModal }, { children: Boolean(session.serviceCallAt) ? (_jsx(CancelConfirm, { cancel: this.toggleModal, confirm: this.confirmCancel }, void 0)) : (_jsx(CallConfirm, { cancel: this.toggleModal, confirm: this.confirmCall }, void 0)) }), void 0)] }, void 0));
    };
    return ContentMenu;
}(Component));
export default ContentMenu;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
