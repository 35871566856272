var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Spinner from "@shared/atoms/Spinner";
import { useEffect, useState } from "react";
import { CancelConfirm, FilterConfirm, Header, ItemBlock, ItemButton, Main } from "../DropDownStyles";
var DropDownItems = function (_a) {
    var title = _a.title, filterItems = _a.filterItems, onItemsClick = _a.onItemsClick, onCloseHandle = _a.onCloseHandle, loading = _a.loading, selectedItems = _a.selectedItems, filterType = _a.filterType, FilterAction = _a.FilterAction;
    var _b = useState(), filterItemsSearch = _b[0], setFilterItemsSearch = _b[1];
    var _c = useState(""), searchValue = _c[0], setSearchValue = _c[1];
    useEffect(function () {
        setFilterItemsSearch(filterItems);
    }, [filterItems]);
    var searchBox = function (e) {
        setFilterItemsSearch(filterItems.filter(function (item) { return item === null || item === void 0 ? void 0 : item.name.toLowerCase().includes(e.target.value); }));
        setSearchValue(e.target.value);
    };
    return (_jsx(_Fragment, { children: _jsxs(Main, { children: [_jsxs(Header, __assign({ onClick: function (e) { return onCloseHandle(e); } }, { children: [_jsx("h3", __assign({ style: { margin: "0.5rem 0" } }, { children: title }), void 0), _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", viewBox: "0 0 10.9 5.9" }, { children: _jsx("path", { opacity: "0.4", d: "M5.4 5.9c-.1 0-.3-.1-.4-.1L.1.9C0 .7 0 .3.1.1s.5-.2.7 0l4.6 4.6L10 .1c.2-.2.5-.2.7 0s.2.5 0 .7l-4.9 5c-.1.1-.2.1-.4.1z" }, void 0) }), void 0)] }), void 0), filterType !== 3 && (_jsxs("div", __assign({ style: { display: "flex", alignItems: "center", position: "relative" } }, { children: [_jsx("input", { placeholder: "Suche...", style: { width: "100%", height: "40px", border: "none", padding: "0.5rem" }, onChange: function (e) { return searchBox(e); }, value: searchValue, onClick: function (e) { return e.stopPropagation(); } }, void 0), _jsx("svg", __assign({ onClick: function () {
                                setSearchValue("");
                            }, style: { position: "absolute", right: "2px", cursor: "pointer" }, xmlns: "http://www.w3.org/2000/svg", height: "24", viewBox: "0 96 960 960", width: "24" }, { children: _jsx("path", { d: "m256 856-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" }, void 0) }), void 0), " "] }), void 0)), " ", _jsx("div", __assign({ style: { overflow: "auto", maxHeight: "500px" } }, { children: filterItemsSearch && !loading ? ((filterItemsSearch === null || filterItemsSearch === void 0 ? void 0 : filterItemsSearch.length) > 0 ? (filterItemsSearch.map(function (item, key) { return (_jsx("div", { children: _jsx(ItemBlock, { children: _jsxs(ItemButton, __assign({ onClick: function (e) {
                                    e.stopPropagation();
                                    onItemsClick(item);
                                }, id: item.id, style: {
                                    width: "100%",
                                    display: "flex",
                                    backgroundColor: selectedItems.some(function (items) {
                                        return item.id ? items.value === item.id && items.type === filterType : items.value === item.name;
                                    })
                                        ? "rgb(237, 237, 237)"
                                        : "transparent"
                                } }, { children: [filterType === 3 &&
                                        _jsx("img", { alt: "item.id", id: item.id, onClick: function (e) {
                                                e.stopPropagation();
                                                onItemsClick(item);
                                            }, style: {
                                                display: "flex",
                                                borderRadius: "0.5rem",
                                                width: "2rem",
                                                marginRight: "1rem",
                                                outline: selectedItems.some(function (items) { return items.value === item.id && items.type === filterType; })
                                                    ? "solid 3px gray"
                                                    : "transparent"
                                            }, src: item.image }, void 0), _jsx("div", __assign({ style: {
                                            display: filterType === 3 ? "flex" : "block",
                                            textAlign: "center",
                                            width: "100%"
                                        } }, { children: item.name }), void 0)] }), key) }, void 0) }, key)); })) : (_jsx("p", __assign({ style: { padding: "1rem" } }, { children: "Keine Ergebnisse gefunden" }), void 0))) : (_jsx(Spinner, {}, void 0)) }), void 0), selectedItems.some(function (items) { return items.type === filterType; }) ? (_jsx(FilterConfirm, __assign({ onClick: function (e) { return FilterAction(e); } }, { children: "Anwenden" }), void 0)) : (_jsx(CancelConfirm, __assign({ onClick: function (e) { return FilterAction(e); } }, { children: "Abbrechen" }), void 0))] }, void 0) }, void 0));
};
export default DropDownItems;
