var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import RModal from 'react-modal';
import styled from 'styled-components';
import { rgba } from 'polished';
import IconComponent from '@shared/atoms/Icon';
var getThemedStyles = function (theme, margin, padding) { return ({
    overlay: {
        alignItems: 'center',
        // backgroundColor: theme.secondaryBackground || '#1c394c',
        backgroundColor: rgba(0, 0, 0, 0.7),
        display: 'flex',
        justifyContent: 'center',
        padding: '0.625rem',
        zIndex: 200,
        animation: '0.2s ease-in 0s 1 fadeIn',
    },
    content: {
        border: 0,
        borderRadius: 0,
        overflow: 'hidden',
        bottom: 'inherit',
        left: 'inherit',
        margin: margin || 0,
        maxHeight: '43rem',
        maxWidth: '47.5rem',
        position: 'relative',
        right: 'inherit',
        top: 'inherit',
        width: '100%',
        padding: padding || '2.5rem 3.375rem 1.875rem 3.375rem',
    },
}); };
var ModalCloseButton = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  color: #5d5d5d; // Fixed color, for every theme\n  top: 2rem;\n  right: 2.375rem;\n  position: absolute;\n"], ["\n  color: #5d5d5d; // Fixed color, for every theme\n  top: 2rem;\n  right: 2.375rem;\n  position: absolute;\n"])));
var CloseIcon = styled(IconComponent)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  color: #5d5d5d; // Fixed color, for every theme\n"], ["\n  color: #5d5d5d; // Fixed color, for every theme\n"])));
var Modal = /** @class */ (function (_super) {
    __extends(Modal, _super);
    function Modal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Modal.prototype.componentWillMount = function () {
        RModal.setAppElement('body');
    };
    Modal.prototype.render = function () {
        var _a = this.props, children = _a.children, contentMargin = _a.contentMargin, contentPadding = _a.contentPadding, hideCloseIcon = _a.hideCloseIcon, open = _a.open, shouldCloseOnOverlayClick = _a.shouldCloseOnOverlayClick, theme = _a.theme, toggleAction = _a.toggleAction;
        return (_jsxs(RModal, __assign({ isOpen: open, onRequestClose: toggleAction, shouldCloseOnOverlayClick: shouldCloseOnOverlayClick, style: getThemedStyles(theme, contentMargin, contentPadding) }, { children: [!hideCloseIcon && (_jsx(ModalCloseButton, __assign({ onClick: toggleAction }, { children: _jsx(CloseIcon, { icon: 'closex', width: 15 }, void 0) }), void 0)), children] }), void 0));
    };
    return Modal;
}(Component));
export default Modal;
var templateObject_1, templateObject_2;
