var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { stringify, parse } from 'query-string';
// utils
import { toJS } from '@shared/utils/immutable';
// actions
import { getArticles } from '@store/article/actions';
import { getPrimaryCategories } from '@store/primaryCategory/actions';
import { getCategory } from '@store/category/actions';
import { getSorts } from '@store/sort/actions';
// selectors
import { selectArticles, selectArticlePageCount, selectLoadingAll as selectLoadingAllArticles, selectTotal, } from '@store/article/selectors';
import { selectItem as selectCategory, selectLoading as selectLoadingCat, } from '@store/category/selectors';
import { selectAll as selectPrimaryCategories, selectShouldLoadAll as selectShouldLoadPriCats, } from '@store/primaryCategory/selectors';
import { selectSortedSorts, shouldLoadSorts as selectShouldLoadSorts, } from '@store/sort/selectors';
// containers
import Session from 'containers/Session';
import Page from 'pages/Result';
import { getSearchParams } from '@shared/utils/params';
var Result = /** @class */ (function (_super) {
    __extends(Result, _super);
    function Result(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentPage: 0,
        };
        _this.getTitle = function () {
            var _a = _this.props, category = _a.category, location = _a.location;
            var params = parse(location.search, { arrayFormat: 'bracket' });
            var searchTitle = Boolean(params.search)
                ? "Suche: '" + params.search + "'"
                : 'Suche';
            var title = Boolean(category) ? category.displayName : searchTitle;
            return title;
        };
        _this.fetch = function (params) {
            _this.setState({ currentPage: _this.state.currentPage + 1 });
            var pageParams = parse(_this.props.location.search, {
                arrayFormat: 'bracket',
            });
            _this.props.getArticles(__assign(__assign({}, pageParams), params));
        };
        _this.updatePageParams = function () {
            var location = _this.props.location;
            var baseParams = parse(location.search, { arrayFormat: 'bracket' });
            // Get new filters depending on current filter selection
            _this.props.history.push({
                pathname: "/results",
                search: "?" + stringify(baseParams, { arrayFormat: 'bracket' }),
            });
        };
        _this.getCurrentSortId = function () {
            var location = _this.props.location;
            var params = parse(location.search, { arrayFormat: 'bracket' });
            return params.sort ? parseInt(params.sort, 10) : null;
        };
        return _this;
    }
    Result.prototype.componentDidMount = function () {
        var _a = this.props, location = _a.location, shouldLoadPriCats = _a.shouldLoadPriCats, isLoadingCategory = _a.isLoadingCategory, shouldLoadSorts = _a.shouldLoadSorts;
        var params = parse(location.search, { arrayFormat: 'bracket' });
        if (shouldLoadPriCats) {
            this.props.getPrimaryCategories();
        }
        if (shouldLoadSorts) {
            this.props.getSorts();
        }
        if (Boolean(params.category) && !isLoadingCategory) {
            this.props.getCategory(params.category);
        }
        var pageParams = parse(this.props.location.search, {
            arrayFormat: 'bracket',
        });
    };
    Result.prototype.componentWillReceiveProps = function (nextProps) {
        if (this.props.location.search !== nextProps.location.search) {
            this.setState({ currentPage: 1 });
            var pageParams = parse(nextProps.location.search);
            var params = {
                page: 1,
            };
            nextProps.getArticles(__assign(__assign({}, pageParams), params));
        }
    };
    Result.prototype.render = function () {
        var _this = this;
        var _a = this.props, articlePageCount = _a.articlePageCount, articles = _a.articles, articlesLoading = _a.articlesLoading, articlesTotal = _a.articlesTotal, filterLoading = _a.filterLoading, filters = _a.filters, location = _a.location, primaryCategories = _a.primaryCategories, sorts = _a.sorts;
        var currentPage = this.state.currentPage;
        return (_jsx(_Fragment, { children: _jsx(Session, { children: function (sessionProps) { return (_jsx(Page, __assign({}, sessionProps, { articlePage: currentPage, articlePageCount: articlePageCount, articles: articles, articlesLoading: articlesLoading, articlesTotal: articlesTotal, fetch: function (params) { return _this.fetch(params); }, filterLoading: filterLoading, filters: filters, params: location.search, primaryCategories: primaryCategories, sorts: sorts, title: _this.getTitle(), currentSortId: _this.getCurrentSortId(), deselectAndApplyFilItem: function (params) {
                        return _this.deselectAndApplyFilItem(params);
                    } }), void 0)); } }, void 0) }, void 0));
    };
    return Result;
}(Component));
var select = function () { return function (state, props) {
    var _a;
    return ({
        articlePageCount: selectArticlePageCount(state),
        articles: selectArticles(state),
        articlesLoading: selectLoadingAllArticles(state),
        articlesTotal: selectTotal(state),
        category: selectCategory()(state, parseInt((_a = getSearchParams(props.location.search)) === null || _a === void 0 ? void 0 : _a.category, 10) || 0),
        isLoadingCategory: selectLoadingCat(state),
        primaryCategories: selectPrimaryCategories(state),
        shouldLoadPriCats: selectShouldLoadPriCats(state),
        sorts: selectSortedSorts(state),
        shouldLoadSorts: selectShouldLoadSorts(state),
    });
}; };
export var ResultContainer = Result;
export default withRouter(connect(select, {
    getArticles: getArticles,
    getCategory: getCategory,
    getPrimaryCategories: getPrimaryCategories,
    getSorts: getSorts,
})(toJS(Result)));
