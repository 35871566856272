var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import last from 'lodash/last';
import ArticleStackComp from 'organisms/ArticleStack';
import ArticleImagesModal from 'organisms/ArticleImagesModal';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex: 1;\n  height: 100%;\n  margin-top: 0.0625rem;\n"], ["\n  display: flex;\n  flex: 1;\n  height: 100%;\n  margin-top: 0.0625rem;\n"])));
var StackWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  background-color: #e3e3e3;\n  bottom: 0;\n  height: 100%;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  z-index: ", ";\n"], ["\n  background-color: #e3e3e3;\n  bottom: 0;\n  height: 100%;\n  left: 0;\n  position: absolute;\n  right: 0;\n  top: 0;\n  z-index: ", ";\n"])), function (props) { return props.index; });
var ArticleStacks = /** @class */ (function (_super) {
    __extends(ArticleStacks, _super);
    function ArticleStacks(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currentItems: {},
            fullscreenImages: null,
        };
        _this.openImagesModal = function (article, index) {
            _this.setState({ fullscreenImages: { article: article, index: index } });
        };
        _this.closeImagesModal = function () {
            _this.setState({ fullscreenImages: null });
        };
        _this.removeCurrentItem = function (stackId) {
            var currentItems = _this.state.currentItems;
            delete currentItems[stackId];
            _this.setState({ currentItems: currentItems });
            _this.props.deleteArticleStack(stackId);
        };
        _this.persistCurrentItem = function (stackId, item) {
            var _a;
            _this.setState({
                currentItems: __assign(__assign({}, _this.state.currentItems), (_a = {}, _a[stackId] = item, _a)),
            });
            _this.props.saveHidden(item);
        };
        return _this;
    }
    ArticleStacks.prototype.componentWillUpdate = function (nextProps) {
        if (nextProps.articleStacks.length < this.props.articleStacks.length) {
            var lastStack = last(nextProps.articleStacks);
            var newCurrent = this.state.currentItems[lastStack.id];
            if (newCurrent) {
                this.props.saveHidden(newCurrent);
            }
        }
    };
    ArticleStacks.prototype.componentWillUnmount = function () {
        this.props.resetHidden();
    };
    ArticleStacks.prototype.render = function () {
        var _this = this;
        var _a = this.props, articleStacks = _a.articleStacks, createArticleStack = _a.createArticleStack, createWatchlistItem = _a.createWatchlistItem, deleteWatchlistItem = _a.deleteWatchlistItem, getStackArticle = _a.getStackArticle, getStackArticles = _a.getStackArticles, watchlist = _a.watchlist;
        var fullscreenImages = this.state.fullscreenImages;
        return (_jsxs(Wrapper, { children: [articleStacks.map(function (item, index) { return (_jsx(StackWrapper, __assign({ index: 100 + index }, { children: _jsx(ArticleStackComp, { watchlist: watchlist, articleStack: item, createArticleStack: createArticleStack, createWatchlistItem: createWatchlistItem, deleteArticleStack: _this.removeCurrentItem, deleteWatchlistItem: deleteWatchlistItem, openImagesModal: function (art, ind) {
                            return _this.openImagesModal(art, ind);
                        }, getStackArticle: getStackArticle, getStackArticles: getStackArticles, saveHidden: function (payload) {
                            return _this.persistCurrentItem(item.id, payload);
                        } }, void 0) }), item.id)); }), Boolean(fullscreenImages) && fullscreenImages && (_jsx(ArticleImagesModal, { toggleAction: this.closeImagesModal, article: fullscreenImages.article, index: fullscreenImages.index }, void 0))] }, void 0));
    };
    return ArticleStacks;
}(Component));
export default ArticleStacks;
var templateObject_1, templateObject_2;
