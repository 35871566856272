var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React from 'react';
// utils
import styled from 'styled-components';
import { defineMessages } from 'react-intl';
// components
import ListLayout from 'templates/ListLayout';
import WatchlistInfo from 'molecules/WatchlistInfo';
// the height is a hotfix for the back button
var ContentWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n"], ["\n  align-items: center;\n  display: flex;\n  height: 100%;\n  justify-content: center;\n  width: 100%;\n"])));
var Watchlist = function (props) { return (_jsx(ListLayout, __assign({}, props, { children: _jsx(ContentWrapper, { children: _jsx(WatchlistInfo, __assign({}, defineMessages({
            text: {
                id: 'watchlist.terminalTransfer.success',
                defaultMessage: 'Ihre Merkliste wurde übertragen!',
            },
        }), { customHeight: '30rem' }), void 0) }, void 0) }), void 0)); };
export default Watchlist;
var templateObject_1;
