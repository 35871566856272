var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { jsx as _jsx } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import { palette } from '@shared/utils/styled';
import Icon from '@shared/atoms/Icon';
var Button = styled(Icon)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  background-color: ", ";\n  border-radius: 50%;\n  bottom: 0.625rem;\n  cursor: pointer;\n  height: 2.25rem;\n  margin-left: 2.4375rem;\n  padding: 0.5625rem;\n  position: ", ";\n  width: 2.25rem;\n  z-index: 1;\n  &:focus {\n    outline: 0;\n  }\n"], ["\n  background-color: ", ";\n  border-radius: 50%;\n  bottom: 0.625rem;\n  cursor: pointer;\n  height: 2.25rem;\n  margin-left: 2.4375rem;\n  padding: 0.5625rem;\n  position: ", ";\n  width: 2.25rem;\n  z-index: 1;\n  &:focus {\n    outline: 0;\n  }\n"])), palette('secondaryBackground'), function (props) { return (props.isBottom ? 'fixed' : 'sticky'); });
var ScrollBottomButton = /** @class */ (function (_super) {
    __extends(ScrollBottomButton, _super);
    function ScrollBottomButton(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            isBottom: false,
        };
        // http://jsfiddle.net/0uwg96sh/
        _this.smoothScrollTo = function (element, target, duration) {
            var roundedTarget = Math.round(target);
            var roundedDuration = Math.round(duration);
            var elementCopy = element;
            if (roundedDuration < 0) {
                return;
            }
            if (roundedDuration === 0) {
                elementCopy.scrollTop = roundedTarget;
                return;
            }
            var startTime = Date.now();
            var endTime = startTime + roundedDuration;
            var startTop = elementCopy.scrollTop;
            var distance = roundedTarget - startTop;
            // based on http://en.wikipedia.org/wiki/Smoothstep
            var smoothStep = function (start, end, point) {
                if (point <= start) {
                    return 0;
                }
                if (point >= end) {
                    return 1;
                }
                var x = (point - start) / (end - start); // interpolation
                return Math.pow(x, 2) * (3 - 2 * x);
            };
            // This is to keep track of where the elementCopy's scrollTop is
            // supposed to be, based on what we're doing
            var previousTop = elementCopy.scrollTop;
            // This is like a think function from a game loop
            var scrollFrame = function () {
                if (elementCopy.scrollTop !== previousTop) {
                    return;
                }
                // set the scrollTop for this frame
                var now = Date.now();
                var point = smoothStep(startTime, endTime, now);
                var frameTop = Math.round(startTop + distance * point);
                elementCopy.scrollTop = frameTop;
                // check if we're done!
                if (now >= endTime) {
                    return;
                }
                // If we were supposed to scroll but didn't, then we
                // probably hit the limit, so consider it done; not
                // interrupted.
                if (elementCopy.scrollTop === previousTop &&
                    elementCopy.scrollTop !== frameTop) {
                    return;
                }
                previousTop = elementCopy.scrollTop;
                // schedule next frame for execution
                setTimeout(scrollFrame, 0);
            };
            // boostrap the animation process
            setTimeout(scrollFrame, 0);
        };
        _this.scroll = function () {
            var isBottom = _this.state.isBottom;
            var wrapper = _this.props.wrapper;
            if (isBottom) {
                _this.smoothScrollTo(wrapper, 0, 600);
                _this.setState({
                    isBottom: false,
                });
            }
            else {
                _this.smoothScrollTo(wrapper, wrapper.scrollTop + 355, 600);
            }
        };
        _this.scrollListener = function () {
            var wrapper = _this.props.wrapper;
            if (wrapper.scrollTop === wrapper.scrollHeight - wrapper.offsetHeight) {
                _this.setState({
                    isBottom: true,
                });
            }
            else {
                _this.setState({
                    isBottom: false,
                });
            }
        };
        return _this;
    }
    ScrollBottomButton.prototype.componentDidMount = function () {
        var _this = this;
        this.props.wrapper.addEventListener('scroll', function () { return _this.scrollListener(); });
    };
    ScrollBottomButton.prototype.componentWillUnmount = function () {
        var _this = this;
        this.props.wrapper.removeEventListener('scroll', function () {
            return _this.scrollListener();
        });
    };
    ScrollBottomButton.prototype.render = function () {
        var _this = this;
        var isBottom = this.state.isBottom;
        if (isBottom) {
            return (_jsx(Button, { isBottom: true, icon: 'scrollUp', onClick: function () {
                    _this.scroll();
                } }, void 0));
        }
        return (_jsx(Button, { isBottom: true, icon: 'scrollDown', onClick: function () {
                _this.scroll();
            } }, void 0));
    };
    return ScrollBottomButton;
}(Component));
export default ScrollBottomButton;
var templateObject_1;
