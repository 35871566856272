var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @flow
// @ts-nocheck
import React, { Component } from 'react';
import styled from 'styled-components';
import BrandModal from 'molecules/BrandModal';
import { palette, fontSize } from '@shared/utils/styled';
import { FormattedMessage } from 'react-intl';
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  padding-bottom: 1.25rem;\n  width: 100%;\n"], ["\n  padding-bottom: 1.25rem;\n  width: 100%;\n"])));
var LanguageList = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-gap: 3.125rem;\n  grid-template-columns: 1fr 1fr;\n  padding: 1.875rem 0;\n"], ["\n  display: grid;\n  grid-gap: 3.125rem;\n  grid-template-columns: 1fr 1fr;\n  padding: 1.875rem 0;\n"])));
var Language = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  align-items: center;\n  border-top: 0.06275rem solid ", ";\n  cursor: pointer;\n  display: flex;\n  font-size: ", ";\n  padding: 0.9375rem;\n  width: 100%;\n  &:first-child {\n    border-top: 0.06275rem solid transparent;\n  }\n  &:active {\n    background-color: ", ";\n    border-radius: 0.625rem;\n    border-top: 0.06275rem solid ", ";\n  }\n  background-color: ", ";\n\n  ", ";\n"], ["\n  align-items: center;\n  border-top: 0.06275rem solid ", ";\n  cursor: pointer;\n  display: flex;\n  font-size: ", ";\n  padding: 0.9375rem;\n  width: 100%;\n  &:first-child {\n    border-top: 0.06275rem solid transparent;\n  }\n  &:active {\n    background-color: ", ";\n    border-radius: 0.625rem;\n    border-top: 0.06275rem solid ", ";\n  }\n  background-color: ", ";\n\n  ", ";\n"])), palette('border'), fontSize('large'), palette('activeWhite'), palette('activeWhite'), function (props) {
    return props.active ? palette('selectedWhite') : palette('white');
}, function (props) {
    return props.active &&
        "\n    background-color: #EDEDED;\n    border-radius: 0.625rem;\n    border-top: 0.06275rem solid #EDEDED;\n    + div {\n      border-top: 0.06275rem solid transparent;\n    }\n  ";
});
var Shortcut = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  background-color: white;\n  border-radius: 0.625rem;\n  border: 0.0625rem solid ", ";\n  display: flex;\n  height: 3.125rem;\n  justify-content: center;\n  margin-right: 0.625rem;\n  width: 3.125rem;\n"], ["\n  align-items: center;\n  background-color: white;\n  border-radius: 0.625rem;\n  border: 0.0625rem solid ", ";\n  display: flex;\n  height: 3.125rem;\n  justify-content: center;\n  margin-right: 0.625rem;\n  width: 3.125rem;\n"])), palette('border'));
var LanguageModal = /** @class */ (function (_super) {
    __extends(LanguageModal, _super);
    function LanguageModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LanguageModal.prototype.render = function () {
        var _a = this.props, open = _a.open, toggleAction = _a.toggleAction, selectLanguage = _a.selectLanguage, currentLanguage = _a.currentLanguage;
        return (_jsx(BrandModal, __assign({ open: open, toggleAction: toggleAction }, this.props, { children: _jsx(Wrapper, { children: _jsxs(LanguageList, { children: [_jsxs(Language, __assign({ onClick: function () { return selectLanguage('de'); }, active: currentLanguage === 'de' }, { children: [_jsx(Shortcut, { children: "DE" }, void 0), _jsx(FormattedMessage, { id: 'modal.languageSelect.german', defaultMessage: 'Deutsch' }, void 0)] }), void 0), _jsxs(Language, __assign({ onClick: function () { return selectLanguage('en'); }, active: currentLanguage === 'en' }, { children: [_jsx(Shortcut, { children: "EN" }, void 0), _jsx(FormattedMessage, { id: 'modal.languageSelect.english', defaultMessage: 'English' }, void 0)] }), void 0)] }, void 0) }, void 0) }), void 0));
    };
    return LanguageModal;
}(Component));
export default LanguageModal;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4;
